import React, { useState, useEffect, useContext } from "react";

import CustomModal from "../CustomModal";
import PayNowModal from "../RcInvoices/PayNowModal";
import TilledPayment from "../TilledPayment";
import { PAYMENT_TILLED_ACH, PAYMENT_TILLED_CC, RC_INVOICE_PROPELR_GATEWAY, RC_INVOICE_TILLED_GATEWAY, TILLED_ACH_LABEL, TILLED_CC_LABEL, tilledObj, WALLET_TILLED_ACH, WALLET_TILLED_CC } from "../../../utils/paymentConstUtil";
import { rcInvoiceTriggerPayment } from "../../../redux/InvoicePayroll/action";
import { convertToDecimal, currencyFormat, filterArrayByKeyValue } from "../../../utils/utils";
import { handleValidations } from "../../../utils/cardValidation";
import { TILLEDACCID } from "../../../utils/tilledUtil";
import { getCompanyWallet, invoicePaymentViaCardpointe, saveCardpointeRcPaymentRequest } from "../../../redux/Billing/action";
import { useDispatch, useSelector } from "react-redux";
import { LanguageContext } from "../../containers/Language";
import { toast } from "react-toastify";
import Buttons from "../../atoms/Buttons";
import { microServices } from "../../../config/global";
import { checkIsInvoicePayable } from "../../../utils/invoiceTokenUtil";
import ConfirmModal from "../../atoms/ConfirmModal";
import useCardpointeModalUtils from "../CardpointeModelHandler";
import CardpointeWallet from "../CardpointeWallet";
import { cardpointeFormValidation, getPaymentToken, setTokenPayload } from "../../../utils/cardpointeUtil";
import { isLoader as apiLoader } from "../../../redux/global/action";
import PropelrPayNowModal from "../RcInvoices/PropelrPayNowModal";



const Invoicenotification = (props) => {

    let routeName =window.location.pathname;
    let tilledBlockMsg = ['/manager-view-invoices','/manager-rc-invoices','/cleaner-rc-invoices']

    const { localised } = useContext(LanguageContext);
    const dispatch = useDispatch();
    const error = useSelector((state) => state.form.error);
    const companyWalletData = useSelector((state) => state.billing.companyWallet) || [];
    const alert = useSelector((state) => state.global.alert);
    const invoicesNotification = useSelector((state) => state.InvoicePayroll.invoicesNotification);
    const [notification, setNotification] = useState([]);
    const [isCalled, setIsCalled] = useState(false);
    const [companyWalletList, setCompanyWalletList] = useState({});
    const [tilledType, setTilledType] = useState('');
    const [expiryDate, setExpiryDate] = useState('');
    const [showPayNowModal, setShowPayNowModal] = useState(false);
    const togglePayNowModal = () => setShowPayNowModal(!showPayNowModal);

    const [showAmountModal, setShowAmountModal] = useState(false);
    const toggleAmountModal = () => setShowAmountModal(!showAmountModal);

    const [notifyTrigger, setNotifyTrigger] = useState(false);
    const [showGlobalTilledPayModal, setShowGlobalTilledPayModal] = useState(false);
    const toggleGlobalTilledPayModal = () => setShowGlobalTilledPayModal(!showGlobalTilledPayModal);
    const [tilledPayloadObj, setTilledPayloadObj] = useState(tilledObj);
    const [formError, setFormError] = useState(
        {
            exp_month_year_error: '',
            number_error: '',
        });
    const [payForm, setPayForm] = useState([]);

    const [invoicePayForm, setInvoicePayForm] = useState({
        'invoice_id': '',
        'payment_method': '',
        'autopay_method': '',
        'existing_pay_now': false,
        'new_method_pay_now': false,
    })
    const [country, selectedCountry] = useState({
        value: '',
        label: 'Select Country'
    });
    const [stripeUserData, setStripeUserData] = useState({
        'email': '',
        'name': '',
    });
    const [isLoader, setIsLoader] = useState(false);
    const [state, selectedState] = useState([]);
    const states = useSelector((state) => state.global.states);
    const [countryWiseStates, setCountryWiseStates] = useState([]);
    const [saveCard, setSaveCard] = useState(false);

    const [isProccess,setIsProccess]=useState(false);

    const [showPaymentTriggerModal, setShowPaymentTriggerModal] = useState(false);
    const [invoiceId,setInvoiceId]=useState('');

    const addNewMethod = (data) => {
        setInvoicePayForm({
            ...invoicePayForm,
            new_method_pay_now: true,
            existing_pay_now: false,
        });
    }

    const setSaveMethod = (e) => {
        setSaveCard(e.target.checked);
    }

    const triggerInvoicePayment = () => {
        setIsLoader(true);
        let  origin='global';
        let paymentGateway = paymentGtwType == RC_INVOICE_TILLED_GATEWAY ? 'tilled' : 'cardpointe';
        dispatch(rcInvoiceTriggerPayment({ id: invoicePayForm.invoice_id, paymentGateway,origin}));
    }

    const getCountryWiseStates = (selectedValue) => {

        let state_arr = filterArrayByKeyValue(
            "country_code",
            selectedValue.value,
            states
        );
        setCountryWiseStates(state_arr);
    };

    const payNowModalFn = (notification) => {
       
        if (!notification?.rc_invoice_id) {
            return;
        }
        let endPt = microServices.base +'/v1/admin/invoice/'+notification?.rc_invoice_id+'/checkInvoice' 
        checkIsInvoicePayable(endPt).then((res) => {
            if (res.status == 200) {
                if (res.data.success == true) {
                    setInvoicePayForm({
                        ...invoicePayForm,
                        amount: currencyFormat(notification.amount_balance),
                        invoice_id: notification?.rc_invoice_id,
                        is_autopay: (companyWalletList?.is_autopay == 'Yes') ? true : false,
                        new_method_pay_now: (Object.keys(companyWalletList).length == 0) ? true : false,
                        existing_pay_now: (companyWalletList?.is_autopay == 'Yes' || companyWalletList?.is_autopay == 'No' || Object.keys(companyWalletList).length > 0) ? true : false,
                    });
                    clearCardpoint();

                    if (Number(notification.amount_balance) && notification?.rc_payment_gateway == RC_INVOICE_TILLED_GATEWAY) {
                        setShowGlobalTilledPayModal(true);
                        setPaymentGtwType(RC_INVOICE_TILLED_GATEWAY);
                    }else if(Number(notification.amount_balance) && notification?.rc_payment_gateway == RC_INVOICE_PROPELR_GATEWAY){
                        setShowGlobalPropelrPayModal(true);
                        setPaymentGtwType(RC_INVOICE_PROPELR_GATEWAY);
                    }else {
                        toast.error(localised.invoiceAmtZero)
                    }
                } else {
                    toast.error(res.data.messages);
                    return;
                }
            }
        },
        (error)=>{
            toast.error(error.message)
            setIsProccess(false)
        }
        )
    }

    const tiggerPayments = () => {

        if (!invoiceId) {
            return;
        }
        let rcInvoiceId = invoiceId;
        closePaymentTrigger();
        setIsLoader(true);
        let endPt = microServices.base + '/v1/admin/invoice/' + rcInvoiceId + '/checkInvoice';
        checkIsInvoicePayable(endPt).then((res) => {
            if (res.status == 200) {
                if (res.data.success == true) {
                    let paymentGateway = (paymentGtwType == RC_INVOICE_TILLED_GATEWAY) ? 'tilled' :'cardpointe'
                    dispatch(rcInvoiceTriggerPayment({ id: rcInvoiceId,isTrigger:true,paymentGateway }));
                 } else {
                    toast.error(res.data.messages);
                    setIsLoader(false);
                    return;
                }
            }
        },
            (error) => {
                toast.error(error.message)
                setIsLoader(false);
            }
        )
    }

    const paymentAlert1 = (Msg, showCloseButton, invoiceId) => {
        toast(<Msg />, {
            closeButton: showCloseButton, containerId: 'B',  onClose: (event) => {
                // Check if the event was triggered by clicking the close button
                if (event && event.target ) {
                    removeClosedNotification(invoiceId);
                }
            },
        })
    }

    const removeClosedNotification = (event) => {
        let index = notification.findIndex(noti => { return noti.rc_invoice_id === event });
        notification.splice(index, 1)
        dispatch({
            type: 'SET_RC_INVOICE_NOTIFICATION',
            payload: {
                data: notification
            }
        });
        setNotifyTrigger(true);
        if (event) {

            setTimeout(() => {
                let element = document.getElementById("rc_" + event).parentNode;
                if (typeof (element) != 'undefined' && element != null) {
                    element.parentElement.parentElement.remove()
                }
            }, 50);
        }

    }


    useEffect(() => {
        if (error) {
            if (error.statusCode === 422) {
                let errors = error?.data;
                if (error.componentName == 'RcInvoiceTrigger' && !tilledBlockMsg.includes(routeName) ) {
                    toast.error(errors);
                }
            }
        }
        setTimeout(() => {
            dispatch({ type: 'CLEAR_VALIDATION_ERROR' });
        }, 100);

    }, [error]);

    useEffect(() => {
        if (alert && alert !== null) {
            if (alert.variant === "danger") {

                if ((alert.componentName == 'TilledPay' || alert.componentName=='RcInvoiceCardpointePayment' && alert?.isFromGlobal ) ) {
                    toast.error(alert.message);
                }
               
            } else {
             
                if (alert.componentName == 'RcInvoiceTrigger' && alert?.isFromGlobal) {
                    let msg = (alert.data?.data?.payType == 'CC') ? localised.tilledCCSuccess : localised.tilledAchSuccessPmInvoice;
                    toast.success(msg);
                    removeClosedNotification(alert?.invoice_id)
                    dispatch({ type: 'GET_LOGGED_USER_DETAILS' });
                }
                if ((alert.componentName == 'TilledPay' || alert.componentName=='RcInvoiceCardpointePayment') && alert?.isFromGlobal ) {
                   toast.success(alert?.message);
                    removeClosedNotification(alert?.invoice_id)
                    dispatch({ type: 'GET_LOGGED_USER_DETAILS' });
                 }
                
                setShowGlobalTilledPayModal(false);
                setShowGlobalPropelrPayModal(false);
                setIsLoader(false);
               
            }
            setTimeout(() => {
                dispatch({ type: "CLEAR_ALERT" });
            }, 1000);
        }
    }, [alert]);

    const emptyFunction = () => void 0;


    useEffect(() => {

        if (notification?.length > 0 && !isCalled && notifyTrigger) {
            notification.map((not) => {
                let message = "";
                let canClose = true;
                let invoiceNumber = not?.invoice_number;
                let invoiceId = not?.rc_invoice_id;
                if (not?.thirty_days_mail) {
                    message = localised.thirtyDaysMessage + convertToDecimal(not?.amount_balance) + '. ' + localised.ifRecievedMessageByMistake + localised.clickHereToMakePaymentNow;
                    canClose = false;
                } else if (not?.twentify_days_mail) {
                    message = localised.youHaveResortCleaningInvoiceDueOf + convertToDecimal(not?.amount_balance) + '. ' + localised.pleaseMakeAPaymentNowToAvoidAccountSuspension + localised.clickHereToMakePaymentNow;
                    canClose = false;
                } else if (not?.twenty_days_mail || not?.ten_days_mail) {
                    message = localised.youHaveResortCleaningInvoiceDueOf + convertToDecimal(not?.amount_balance) + '. ' + localised.clickHereToMakePaymentNow;
                    canClose = true;
                }
                let Msg = () => (
                    <div id={'rc_'+invoiceId}>
                        <p className='mb-1'><strong>{localised.invoiceNo}: {invoiceNumber}</strong></p>
                        <p>
                            {message}
                        </p>
                         {Object.keys(companyWalletList).length >0  && companyWalletList.autopay=='Yes' &&  
                         <>
                         <Buttons variant="primary" title={localised.triggerPayment} onClick={(e) => togglePaymentTriggerModal(not)} className="btn-sm" />
                          <span> </span>
                          </>
                         } 
                            <Buttons variant="primary" title={localised.payNow} onClick={(e) => payNowModalFn(not)} className="btn-sm" />
                      
                    </div>
                );
                setTimeout(() => {
                  //  paymentAlert1(Msg, canClose, invoiceId);
                }, 500);
            });
            setIsCalled(true);
            setNotifyTrigger(false);
        }
    }, [companyWalletList, notification,isProccess]);

    useEffect(() => {

        if (companyWalletData?.data) {
            setNotifyTrigger(true);
            setCompanyWalletList(companyWalletData.data)
        };

    }, [companyWalletData]);

    useEffect(() => {
        let gtwType =0;
        if (invoicesNotification?.length > 0) {
            setNotification(invoicesNotification);
            gtwType = invoicesNotification[0]?.rc_payment_gateway;
            setPaymentGtwType(gtwType);
        }
        let paymentGateway = (gtwType==RC_INVOICE_TILLED_GATEWAY ) ? 'tilled' : 'cardpointe';
        if(gtwType){ dispatch(getCompanyWallet({paymentGateway})) };
       
            const script = document.createElement('script');
            script.src = "https://js.tilled.com/v2";
            script.async = true;
            document.body.appendChild(script);
            return () => {
                document.body.removeChild(script);
            };
        
        
    }, [invoicesNotification]);

    const togglePaymentTriggerModal = (data) => {
     
        if (Number(data.amount_balance) && Number(data.amount_balance) > 0) {
            setInvoiceId(data?.rc_invoice_id)
            setShowPaymentTriggerModal(!showPaymentTriggerModal);

        } else {
            toast.error(localised.invoiceAmtZero);
        }

    }

    const closePaymentTrigger = () => {
        setInvoiceId('')
        setShowPaymentTriggerModal(!showPaymentTriggerModal);
    }

    const [showGlobalPropelrPayModal, setShowGlobalPropelrPayModal] = useState(false);

    const [cardpointePayMethod,setCardpointePayMethod] =useState([]) ;
    const toggleGlobalPropelrPayModal = () => setShowGlobalPropelrPayModal(!showGlobalPropelrPayModal);
    const [paymentGtwType,setPaymentGtwType]=useState(RC_INVOICE_TILLED_GATEWAY);
    const { 
      radioHandlerCardpointeAch, 
      radioHandlerCardpointeCc,
      cardpointePayloadObj,
      setCardpointePayloadObj,
      setPaymentType,
      paymentType,
      setCardpointeFormError,
      cardpointeFormError,
      payMethod,
      setCardpointeSelectedState,
      setCardpointeSelectedCoutry,
      cardpointeSelectedCoutry,
      cardpointeSelectedState,
      clearCardpoint,
      setIsInitialized,
      cardpointeCountryWiseStates,
      getCardpointeCountryWiseStates
     } = useCardpointeModalUtils();
  
     async function handleCardpointeSubmit(isUpdate=false) {
    
        let isError = false;
        let cardpointeFormErrors = cardpointeFormValidation(
          cardpointePayloadObj,
          true
        );
        for (const key in cardpointeFormErrors) {
          if (cardpointeFormErrors[key]) {
            isError = true;
          }
        }
        setCardpointeFormError(cardpointeFormErrors);
        if (isError) {
          return;
        }
        dispatch(apiLoader(true));
        let paymentTokenPayload = setTokenPayload(cardpointePayloadObj);
       
        getPaymentToken(paymentTokenPayload).then((res) => {
          if (res?.data?.errorcode == 0) {
            let invoiceFrm ={...invoicePayForm,payment_method:payMethod}
            let basicFormData ={
              account: res?.data?.token,
              type:payMethod == WALLET_TILLED_CC ? TILLED_CC_LABEL: TILLED_ACH_LABEL,
              address: cardpointePayloadObj?.billing_details?.address?.street,
              city: cardpointePayloadObj?.billing_details?.address?.city,
              state: cardpointePayloadObj?.billing_details?.address?.state,
              country: cardpointePayloadObj?.billing_details?.address?.country,
              postal: cardpointePayloadObj?.billing_details?.address?.zip,
              name: cardpointePayloadObj?.billing_details?.name,
              email: cardpointePayloadObj?.billing_details?.email,
            };
            let formData = {...invoiceFrm,...basicFormData};
            if (payMethod == WALLET_TILLED_ACH) {
              formData = {
                ...formData,
                accttype: cardpointePayloadObj.ach_debit.account_type,
              };
            } else if (payMethod == WALLET_TILLED_CC ) {
              formData = {
                ...formData,
                expiry:
                  cardpointePayloadObj.card.exp_month+''+cardpointePayloadObj.card.exp_year,
              };
            }
            // return
            if(invoicePayForm?.invoice_id){
             dispatch(invoicePaymentViaCardpointe({
              data:formData,
              origin:'global',
              invoice_id:invoicePayForm?.invoice_id,
              message:(payMethod == WALLET_TILLED_CC) ? localised.tilledCCSuccess : localised.tilledAchSuccessPmInvoice,
              failedMsg:localised.tilledPaymentFailed})) 
            }

            if(saveCard){
                addWalletDetails(basicFormData);
            }
            
           
          }
        },
        (error)=>{
          dispatch(apiLoader(false));
        }
        );
      }
    
      const addWalletDetails = (basicFormData) => {
        let formData = {};
        if (payMethod == WALLET_TILLED_ACH) {
          formData = {
            ...basicFormData,
            accttype: cardpointePayloadObj.ach_debit.account_type,
          };
        } else if (payMethod == WALLET_TILLED_CC) {
          formData = {
            ...basicFormData,
            expiry:
              cardpointePayloadObj.card.exp_month +
              "" +
              cardpointePayloadObj.card.exp_year,
          };
        }
        dispatch(
          saveCardpointeRcPaymentRequest({
            data: formData,
            successMsg: "",
            failedMsg: "",
          })
        );
      };
    return (
        <>
            {showGlobalTilledPayModal &&
                <CustomModal
                    title={localised.paymentMethod}
                    useHandleSave={true}
                    photoPoup={false}
                    show={showGlobalTilledPayModal}
                    handleShow={toggleGlobalTilledPayModal}
                    handleClose={toggleGlobalTilledPayModal}
                >
                    {invoicePayForm.existing_pay_now &&
                        <PayNowModal
                            invoicePayForm={invoicePayForm}
                            addNewMethod={addNewMethod}
                            triggerInvoicePayment={triggerInvoicePayment}
                            isLoading={isLoader}
                            setIsLoading={setIsLoader}
                        />
                    }
                    {invoicePayForm.new_method_pay_now &&
                        <TilledPayment
                            showTilledPayModal={showGlobalTilledPayModal}
                            setShowTilledPayModal={setShowGlobalTilledPayModal}
                            tilledPayloadObj={tilledPayloadObj}
                            setTilledPayloadObj={setTilledPayloadObj}
                            payForm={payForm}
                            setPayForm={setPayForm}
                            getCountryWiseStates={getCountryWiseStates}
                            countryWiseStates={countryWiseStates}
                            handleValidations={handleValidations}
                            formError={formError}
                            setFormError={setFormError}
                            country={country}
                            selectedCountry={selectedCountry}
                            state={state}
                            selectedState={selectedState}
                            invoicePayForm={invoicePayForm}
                            setInvoicePayForm={setInvoicePayForm}
                            isLoading={isLoader}
                            setIsLoading={setIsLoader}
                            toggleRefreshComponent={emptyFunction}
                            refreshComponent={false}
                            accountId={TILLEDACCID}
                            isAdminIvoice={true}
                            setSaveCard={setSaveCard}
                            saveCard={saveCard}
                            setSaveMethod={setSaveMethod}
                            tilledType={tilledType}
                            setTilledType={setTilledType}
                            setExpiryDate={setExpiryDate}
                            saveCardShow={false}
                            backDatedInvoice={{}}
                            tilledCCId={WALLET_TILLED_CC}
                            tilledACHId={WALLET_TILLED_ACH}
                        />
                    }
                </CustomModal>
            }

            {showPaymentTriggerModal &&
                <ConfirmModal
                    message={localised.payInvoiceConfimation}
                    onSubmit={tiggerPayments}
                    buttontext={localised.yes}
                    showconfirm={showPaymentTriggerModal}
                    handlecloseconfirm={closePaymentTrigger}

                />
            }

              {/* for propelr */}

    {showGlobalPropelrPayModal && (
        <CustomModal
        title={localised.paymentMethod}
          useHandleSave={false}
          photoPoup={!invoicePayForm.existing_pay_now }
          errorReset={clearCardpoint}
          handleSubmit={handleCardpointeSubmit}
          show={showGlobalPropelrPayModal}
          handleShow={toggleGlobalPropelrPayModal}
          handleClose={toggleGlobalPropelrPayModal}
        >
             {invoicePayForm.existing_pay_now == true && (
            <PropelrPayNowModal
              invoicePayForm={invoicePayForm}
              addNewMethod={addNewMethod}
              paymentGtwType={paymentGtwType}
              triggerInvoicePayment={triggerInvoicePayment}
              isLoading={isLoader}
              setIsLoading={setIsLoader}
              cardpointePayMethod={cardpointePayMethod}
              backDatedInvoice={''}
            />
          )}
          {invoicePayForm.new_method_pay_now && (
            <CardpointeWallet
              cardpointePayloadObj={cardpointePayloadObj}
              setCardpointePayloadObj={setCardpointePayloadObj}
              paymentType={paymentType}
              setPaymentType={setPaymentType}
              getCountryWiseStates={getCardpointeCountryWiseStates}
              countryWiseStates={cardpointeCountryWiseStates}
              cardpointeFormError={cardpointeFormError}
              setCardpointeFormError={setCardpointeFormError}
              cardpointeSelectedCountry={cardpointeSelectedCoutry}
              cardpointeSelectedState={cardpointeSelectedState}
              setCardpointeSelectedCountry={setCardpointeSelectedCoutry}
              setCardpointeSelectedState={setCardpointeSelectedState}
              radioHandlerCardpointeCc={radioHandlerCardpointeCc}
              radioHandlerCardpointeAch={radioHandlerCardpointeAch}
              active={payMethod}
              setExpiryDate={setExpiryDate}
              expiryDate={expiryDate}
              saveCardShow={true}
              btnLabel={localised.payNow}
              backDatedInvoice={''}
              invoicePayForm={invoicePayForm}
              setInvoicePayForm={setInvoicePayForm}
              isLoading={isLoader}
              setIsLoading={setIsLoader}
              accountId={TILLEDACCID}
              isAdminIvoice={true}
              isAutoPayRequest={true}
              setSaveCard={setSaveCard}
              saveCard={saveCard}
            ></CardpointeWallet>
          )}
        </CustomModal>
      )}

        </>
    );
};
export default Invoicenotification;
