const initState = {
    property:[],
    regionWiseArea:[],
    regionlist:[],
    timezones:[],
    propertyTypes:[],
    propertyNotes:[],
    note:{},
    propertyService:[],
    propertyConfigList:[],
    PropertyServicePriceConfig:[],
    apptProperty:[],
    apptPropertiesData:[],
    apptPropertiesInternalData:[],
    propertyOwnerList: [],
    inventorySmartTemplate:[],
    propertyIntegrationsData: {},
    propertyDropdown:[],
    PropertyBillingAmount : [],
    propertyWorkorderDropdown: [],
    defaultTimeData : []
  };
  
  const property = (state = initState, action) => {

    switch (action.type) {

      case "SET_DROPDOWN_REGION_LIST": {
        return {
          ...state,
          regionlist: action.payload,
        }
      }

      case "SET_DROPDOWN_TIMEZONES_LIST": {
        return {
          ...state,
          timezonesList: action.payload,
        }
      }

      case "SET_DROPDOWN_PROPERTYTYPES_LIST": {
        return {
          ...state,
          propertyTypesList: action.payload,
        }
      }

      case "SET_DROPDOWN_AREA_LIST": {
        return {
          ...state,
          regionWiseArea: action.payload,
        }
      }

      case 'CLEAR_REGION_AREA': {
        return {
          ...state,
          regionWiseArea : null
        };
      }

      case 'CLEAR_REGION': {
        return {
          ...state,
          regionlist : null
        };
      }
      case 'SET_REGION_AREA_ACCESS': {
        return {
          ...state,
          regionAreaAccess: action.payload.data
        };
      }
      case "SET_DROPDOWN_PROPERTY_ENTRY_METHOD_LIST": {
        return {
          ...state,
          propertyEntryMethodList: action.payload,
        }
      }

      case "SET_DROPDOWN_PROPERTY_SQUARE_FEET_LIST": {
        return {
          ...state,
          propertySquareFeetList: action.payload,
        }
      }


      case "SET_DROPDOWN_PROPERTIES_LIST": {
        return {
          ...state,
          property: action.payload,
        }
      }
      case 'CLEAR_PROPERTIES': {
        return {
          ...state,
          property : []
        };
      }

      case 'CLEAR_REGION_AREA_ACCESS': {
        return {
          ...state,
          regionAreaAccess: null
        };
      }

      case 'SET_PROPERTY_NOTES':{
        return{
          ...state,
          propertyNotes: action.payload,
        }
      }

      case 'CLEAR_PROPERTY_NOTES': {
        return {
          ...state,
          propertyNotes: []
        };
      }

      case 'SET_SINGLE_PROPERTY_NOTES':{
        return{
          ...state,
          note: action.payload.data
        }
      }

      case 'CLEAR_SINGLE_PROPERTY_NOTES':{
        return{
          ...state,
          note:{}
        }
      }

      case 'SET_PROPERTY_PHOTOS':{
        return{
          ...state,
          propertyPhotos: action.payload,
        }
      }

      case 'CLEAR_PROPERTY_PHOTOS': {
        return {
          ...state,
          propertyPhotos: []
        };
      }
      case "SET_DROPDOWN_MANAGER_WISE_LIST": {
        return {
          ...state,
          managerRegionlist: action.payload,
        }
      }
       case "SET_PROPERTY_CONFIG_LIST": {
        return {
          ...state,
          propertyConfigList: action.payload,
        }
      }
      case "SET_PROPERTY_SERVICE": {
        return {
          ...state,
          propertyService: action.payload,
        }
      }
      case "CLEAR_PROPERTY_SERVICE": {
        return {
          ...state,
          propertyService: [],
        }
      }
      case "SET_TEMP_PROPERTY_DATA": {
        return {
          ...state,
          PropertyUploads: action.payload.data,
        }
      }
      case "CLEAR_TEMP_PROPERTY_DATA": {
        return {
          ...state,
          PropertyUploads: [],
        }
      }

      case "SET_PROPERTY_SERVICE_CONFIG": {
        return {
          ...state,
          PropertyServicePriceConfig:action.payload.data,
        }
      }

      case "CLEAR_PROPERTY_SERVICE_CONFIG": {
        return {
          ...state,
          PropertyServicePriceConfig:[],
        }
      }

      case "SET_PROPERTY_DATA_APPT":{
        return {
          ...state,
          apptProperty:action.payload.data,
        }
      }
      case "CLEAR_PROPERTY_DATA_APPT":{
        return {
          ...state,
          apptProperty:[],
        }
      }
      
      case "SET_DROPDOWN_INSPECTION_LIST" : {
        return {
          ...state,
          regionAreaWiseTemplate : action.payload
        }
      }

      case "SET_PROPERTIES_DETAILS_APPT" : {
        return {
          ...state,
          apptPropertiesData:action.payload.data,
        }
      }

      case "SET_PROPERTY_INTERNAL" : {
        return {
          ...state,
          apptPropertiesInternalData:action.payload.data,
        }
      }
      case "CLEAR_PROPERTY_INTERNAL" : {
        return {
          ...state,
          apptPropertiesInternalData:{},
        }
      }
      case "SET_PROPERTY_INTERNAL_SERVICE": {
        return {
          ...state,
          propertyInternalService: action.payload,
        }
      }
      case "CLEAR_PROPERTY_INTERNAL_SERVICE": {
        return {
          ...state,
          propertyInternalService: [],
        }
      }
      case "SET_DEFAULT_PROPERTY_CLEANER": {
        return {
          ...state,
          propertyCleanerDefault: action.payload,
        }
      }
      case "CLEAR_DEFAULT_PROPERTY_CLEANER": {
        return {
          ...state,
          propertyCleanerDefault: [],
        }
      }
      
      case "SET_DROPDOWN_PROPERTY_OWNER_LIST": {
        return {
          ...state,
          propertyOwnerList: action.payload,
        }
      }

      case "SET_PROPERTY_OWNER_DETAILS": {
        return {
          ...state,
          propertyOwnerDetails: action.payload,
        }
      }

      case "CLEAR_PROPERTY_OWNER_DETAILS": {
        return {
          ...state,
          propertyOwnerDetails: [],
        }
      }

      case "SET_DROPDOWN_PROPERTY_CLEANERS": {
        return {
          ...state,
          propertyCleanersDropdown: action.payload,
        };
      }

      case "CLEAR_DROPDOWN_PROPERTY_CLEANERS": {
        return {
          ...state,
          propertyCleanersDropdown: null,
        };
      }

      case 'SET_INVENTORY_SMART_TEMPLATE':{
        return{
          ...state,
          inventorySmartTemplate: action.payload,
        }
      }
      
      case "CLEAR_INVENTORY_SMART_TEMPLATE": {
        return {
          ...state,
          inventorySmartTemplate: null,
        };
      }

      case "SET_PROPERTY_INTEGRATIONS": {
        return {
          ...state,
          propertyIntegrationsData: action.payload,
        };
      }
      
      case "CLEAR_DROPDOWN_MANAGER_WISE_LIST": {
        return {
          ...state,
          managerRegionlist: null,
        }
      }

      case "SET_PROPERTIES_DROPDOWN_LIST": {
        return {
          ...state,
          propertyDropdown: action.payload,
        }
      }
      case "SET_TEMP_PROPERTY_SERVICE": {
        return {
          ...state,
          PropertyServiceUploads: action.payload.data,
        }
      }
      case "CLEAR_TEMP_PROPERTY_SERVICE": {
        return {
          ...state,
          PropertyServiceUploads: null,
        }
      }

      case "SET_PROPERTY_BILLING_AMOUNT" : {
        return {
          ...state,
          PropertyBillingAmount:action.payload.data,
        }
      }

      case "CLEAR_PROPERTY_BILLING_AMOUNT" : {
        return {
          ...state,
          PropertyBillingAmount: [],
        }
      }
      case "SET_PARENT_CHILD_PROPERTIES_DROPDOWN_LIST": {
        return {
          ...state,
          parentChildPropertyDropdown: action.payload,
        }
      }
      case "CLEAR_PARENT_CHILD_PROPERTIES_DROPDOWN_LIST": {
        return {
          ...state,
          parentChildPropertyDropdown: [],
        }
      }
      case "SET_PROPERTIES_WORKORDER_DROPDOWN_LIST": {
        return {
          ...state,
          propertyWorkorderDropdown: action.payload,
        }
      }
      case "CLEAR_PROPERTIES_WORKORDER_DROPDOWN_LIST": {
        return {
          ...state,
          propertyWorkorderDropdown: [],
        }
      }
      case "SET_DEFAULT_TIME": {
        return {
          ...state,
          defaultTimeData: action.payload,
        };
      }
      case "CLEAR_DEFAULT_TIME": {
        return {
          ...state,
          defaultTimeData: action.payload,
        };
      }
      
      case 'SET_PROPERTY_ASSIGNED_INVENTORY_LIST': {
        return {
          ...state,
          propertyInventoryList : action.payload,
        };
      }
      
      case 'CLEAR_PROPERTY_ASSIGNED_INVENTORY_LIST': {
        return {
          ...state,
          propertyInventoryList:[],
        };
      }

      case 'SET_PROPERTY_MANAGER_ASSIGNED_INVENTORY_LIST': {
        return {
          ...state,
          propertyManagerNewInventories : action.payload,
        };
      }

      case 'CLEAR_PROPERTY_MANAGER_ASSIGNED_INVENTORY_LIST': {
        return {
          ...state,
          propertyManagerNewInventories:[],
        };
      }

      default:
        return state;
  }
};

export default property;
