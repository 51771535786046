import { combineReducers } from "redux";
import authentication from "./authentication/reducer";
import global from "./global/reducer";
import admin from "./admin/reducer";
import form from "./form/reducer";
import billing from "./Billing/reducer";
import property from "./properties/reducer";
import company from "./company/reducer";
import user from "./user/reducer";
import inventory from "./Inventory/reducer";
import service from "./service/reducer";
import inspection from "./inspection/reducer";
import appointment from "./appointment/reducer";
import workorder from "./workorder/reducer";
import dashboard from "./dashboard/reducer";
import InvoicePayroll from "./InvoicePayroll/reducer";
import MessageCenter from "./MessageCenter/reducer";
import InventorySmart from "./inventorySmart/reducer";
import CommonArea from "./CommonArea/reducer";
import DailyCloseout from "./DailyCloseout/reducer";
import UnitStatusView from "./UnitStatusView/reducer";
import InventoryTemplate from "./InventoryTemplate/reducer";

const appReducer = combineReducers({
  global,
  authentication,
  admin,
  form,
  billing,
  property,
  company,
  user,
  inventory,
  service,
  inspection,
  appointment,
  workorder,
  dashboard,
  InvoicePayroll,
  MessageCenter,
  InventorySmart,
  CommonArea,
  DailyCloseout,
  UnitStatusView,
  InventoryTemplate
});

const rootReducer = (state, action) => {
  if (action.type === "LOG_OUT") {
    localStorage.removeItem("persist:root");
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
