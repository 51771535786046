import React, { useState, useContext, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row, Form, Tab, Nav, Alert, Table } from "react-bootstrap";
import { LanguageContext } from "../../../containers/Language";
import Buttons from "../../../atoms/Buttons";
import { getAssignmentProgress } from "../../../../redux/company/action";
import { isEmptyNullUndefined, prepareFilterUrl, timeConvert } from "../../../../utils/utils";
import { getGlobalAreaRegionValues } from "../../../../utils/staffFilter";
import moment from "moment";

const defaultFilter = {
  date: moment().format("YYYY/MM/DD"),
}

let searchValue = {
  date: defaultFilter.date
}

const AssignmentProgress = (props) => {
  let today = moment().format("YYYY/MM/DD");

  const { localised } = useContext(LanguageContext);
  const [showHide, setHideShow] = useState(false);

  const dispatch = useDispatch();

  const loggedInUser = useSelector((state) => state.authentication?.profile) || "";
  const globalRegionAreaFilter = useSelector((state) => state.global.globalRegionAreaFilter) || {};

  const hkProgress = useSelector((state) => state.company.hk_progress) || null;
  const haProgress = useSelector((state) => state.company.ha_progress) || null;
  const inspProgress = useSelector((state) => state.company.insp_progress) || null;

  const hkTarget = useSelector((state) => state.company.hk_target) || 0;
  const haTarget = useSelector((state) => state.company.ha_target) || 0;
  const inspTarget = useSelector((state) => state.company.insp_target) || 0;

  let HaAdditionalFeature = loggedInUser.additional_feature_access.some((item) => item.additional_features_id === 3);
  let InspectionAdditionalFeature = loggedInUser.additional_feature_access.some((item) => item.additional_features_id === 1); 

  const [hkProgressList,setHkProgressList] = useState([]);
  const [haProgressList,setHaProgressList] = useState([]);
  const [inspProgressList,setInspProgressList] = useState([]);

  const loadAllStaffProgress = () => {
    if(props?.date) {
      let newDate = props.date.split('-');
      searchValue = {...searchValue, date:newDate[0]}
    }
    
    if (globalRegionAreaFilter && Object.keys(globalRegionAreaFilter).length > 0) {
      let [area, region] = getGlobalAreaRegionValues(globalRegionAreaFilter.area_id, globalRegionAreaFilter.region_id);
      searchValue['region_id'] = region;
      searchValue['area_id'] = area;
    }
    
    dispatch(getAssignmentProgress({ type: "hk", filters: prepareFilterUrl(searchValue), action_name: "SET_HK_PROGRESS", showLoader:2 }))
    HaAdditionalFeature && dispatch(getAssignmentProgress({ type: "ha", filters: prepareFilterUrl(searchValue),action_name: "SET_HA_PROGRESS", showLoader:2 }))
    InspectionAdditionalFeature && dispatch(getAssignmentProgress({ type: "inspector", filters: prepareFilterUrl(searchValue), action_name: "SET_INSP_PROGRESS", showLoader:2 }))
  }

  const onRefreshHandler = () => {
    loadAllStaffProgress()
  }

  const remainingIndicator = (total, remaining ) => {
    let diff = total - remaining;
    return diff==0 ? "yellow" : diff<0 ? "red" : "blue"
  }

  useEffect(() => {
    loadAllStaffProgress()
    return () => {
        dispatch({type: "CLEAR_HK_PROGRESS"})
        dispatch({type: "CLEAR_HA_PROGRESS"})
        dispatch({type: "CLEAR_INSP_PROGRESS"})
    };
  }, [])

  useEffect(() => {
    if(Array.isArray(hkProgress)) {
      setHkProgressList(hkProgress)
    }
  }, [hkProgress])

  useEffect(() => {
    if(Array.isArray(haProgress)) {
      setHaProgressList(haProgress)
    }
  }, [haProgress])

  useEffect(() => {
    if(Array.isArray(inspProgress)) {
      setInspProgressList(inspProgress)
    }
  }, [inspProgress])

  useEffect(() => {
    if(props?.date) {
      loadAllStaffProgress();
    }
  }, [props?.date])

  useEffect(() => {
    if (globalRegionAreaFilter && Object.keys(globalRegionAreaFilter).length > 0) {
      loadAllStaffProgress();
    }
  }, [globalRegionAreaFilter]);

  return (
    <div className="assignment-outer-wrap">
      <h5 className="card-title-indi mb-2">{localised.assignmentProgress}
        <Buttons
          variant="primary"
          className="ms-3 btn-sm"
          title={localised.refresh}
          onClick={onRefreshHandler}
        />

      </h5>

      <div className="assignment-progress-wrapper">
        {HaAdditionalFeature &&
        <div className="search-container" id="no-more-tables">
          <div className="card">
            <div className="card-header d-flex">
              <h3 className="card-title">{localised.houseAttendants}</h3>
              <span className="text-small ms-auto">Target {`[${haTarget} units]`}</span>
            </div>
            <div className="card-body">
              <div className="assignment-progress">
                <Table bordered>
                  <thead>
                    <tr>
                      <th>{localised.name} </th>
                      <th>{localised.total}</th>
                      <th>{localised.assigned}</th>
                      <th>{localised.remaining}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      haProgressList.map((item) => (
                        <tr key={item.user_id}>
                          <td data-title={localised.name}>
                            {item.user}
                          </td>
                          <td data-title={localised.total}>{item.total}</td>
                          <td data-title={localised.assigned}>
                            {item.assigned}
                          </td>
                          <td className={`${remainingIndicator(item.total, item.assigned)}`} data-title={localised.remaining}>{item.total - item.assigned}</td>
                        </tr>
                      ))
                    }
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>}
        <div className="search-container" id="no-more-tables">
          <div className="card">
            <div className="card-header d-flex">
              <h3 className="card-title">{localised.housekeepers}</h3>
              <span className="text-small ms-auto">
                Target {`[${timeConvert(hkTarget)} hrs (${hkTarget} min)]`}
              </span>
            </div>
            <div className="card-body">
              <div className="assignment-progress">
                <Table bordered>
                  <thead>
                    <tr>
                      <th>{localised.name} </th>
                      <th>{localised.total}</th>
                      <th>{localised.assigned}</th>
                      <th>{localised.remaining}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                        hkProgressList.map((item) => (
                          <tr key={item.user_id}>
                            <td data-title={localised.name}>
                              {item.user}
                            </td>
                            <td data-title={localised.total}>
                            {`${item.total} min (${timeConvert(item.total)} hrs)`}
                            </td>
                            <td data-title={localised.assigned}>
                              {`${item.assigned} min (${timeConvert(item.assigned)} hrs)`}
                            </td>
                            <td className={`${remainingIndicator(item.total,item.assigned)}`} data-title={localised.remaining}>
                              {`${item.total - item.assigned} min (${timeConvert(item.total - item.assigned)} hrs)`}
                            </td>
                          </tr>
                        ))
                    }
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
        {InspectionAdditionalFeature && 
        <div className="search-container" id="no-more-tables">
          <div className="card">
            <div className="card-header d-flex">
              <h3 className="card-title">{localised.inspectors}</h3>
              <span className="text-small ms-auto">Target {`[${inspTarget} units]`}</span>
            </div>
            <div className="card-body">
              <div className="assignment-progress">
                <Table bordered>
                  <thead>
                    <tr>
                      <th>{localised.name} </th>
                      <th>{localised.total}</th>
                      <th>{localised.assigned}</th>
                      <th>{localised.remaining}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      inspProgressList.map((item) => (
                        <tr key={item.user_id}>
                          <td data-title={localised.name}>
                            {item.user}
                          </td>
                          <td data-title={localised.total}>{item.total}</td>
                          <td data-title={localised.assigned}>
                            {item.assigned}
                          </td>
                          <td className={`${remainingIndicator(item.total, item.assigned)}`} data-title={localised.remaining}>{item.total - item.assigned}</td>
                        </tr>
                      ))
                    }
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>}
      </div>
    </div>
  );
};

export default AssignmentProgress;
