import React, { useState, useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { Col, Row, Form, Tab, Nav, Table, Button, Spinner, Alert } from 'react-bootstrap';
import { LanguageContext } from "../../containers/Language";


const TilledDetails = React.forwardRef((props, ref) => {
    const { localised } = useContext(LanguageContext);


    return (
        <>
            <div className="modal-heading">
                <h3>  <Form.Label>{localised.existingDetails}</Form.Label></h3>


            </div>

            {props.backDatedInvoice?.is_back_dated &&
                <Alert key={'warning'} variant={'warning'}>
                    {localised.rcInvoicePayFirst + ' ' + props.backDatedInvoice?.old_invoice?.invoice_number}
                </Alert>
            }
            <div>

                <Row>
                    {

                        props.tilledPayMethod?.data?.bank?.type == 'card' &&
                        <Col md="10">
                            <p>{localised.cardNo}  : {'******************' + props.tilledPayMethod?.data?.bank?.card?.last4} </p>
                            <p>{localised.expDate} : {props.tilledPayMethod?.data?.bank?.card?.exp_month + '/' + props.tilledPayMethod?.data?.bank?.card?.exp_year} </p>
                            <p>{localised.cardHolderName} : {props.tilledPayMethod?.data?.bank?.card?.holder_name} </p>
                            <p>{localised.bilingEmail} : {props.tilledPayMethod?.data?.bank?.billing_details?.email} </p>

                        </Col>
                    }
                    {
                        props.tilledPayMethod?.data?.bank?.type == 'ach_debit' &&
                        <Col md="10">

                            <p>{localised.accountNo} :{"********************" + props.tilledPayMethod?.data?.bank?.ach_debit?.last2} </p>
                            <p>{localised.routingNo} :{props.tilledPayMethod?.data?.bank?.ach_debit?.routing_number} </p>
                            <p>{localised.accountType} :{props.tilledPayMethod?.data?.bank?.ach_debit?.account_type} </p>
                        </Col>
                    }
                </Row>
            </div>
        </>
    )

})
export default TilledDetails;
