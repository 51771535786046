import React, { useState, useContext } from "react";
import Select from 'react-select';
import { PropTypes } from 'prop-types';
import { Text, LanguageContext } from '../../containers/Language';

const CustomSelect = React.forwardRef((props, ref) => {
  const { localised } = useContext(LanguageContext);
  const customStyles = {
    // control: () => ({
    //   // none of react-select's styles are passed to <Control />
    //   minHeight: 36,
    //   borderRadius: 4,
    //   borderWidth: 1,
    //   borderStyle: 'solid',
    //   borderColor: '#ededf5',     
    //   color: '#4d5875',
    //   fontSize: 14,
    //   fontWeight: 500,
    //   display:'flex',
    // }),

    control: (styles, { isDisabled}) => {
      
      return {
        ...styles,
        minHeight: 36,
        borderRadius: 4,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: '#ededf5',  
        fontSize: 14,
        fontWeight: 500,
        display:'flex',
        cursor: isDisabled ? 'not-allowed' : 'default',
        // This is an example: backgroundColor: isDisabled ? 'rgba(206, 217, 224, 0.5)' : 'white'
        color: isDisabled ? '#aaa' : '#4d5875'
      }
    },
    Input: () => ({
      color: '#4d5875',

    }),
    // singleValue: () => ({
    //   color: '#4d5875',
    //   fontSize: 14,
    //   fontWeight: 500,
    //   lineHeight: 22,
    //   paddingTop:4,
    //   paddingBottom:4,
    //   paddingLeft:4,
    //   paddingRight:4,
    // }),
    // input:() => ({
    //   color: '#4d5875',
    //   fontSize: 14,
    //   fontWeight: 500, 
    //   position:'absolute'
    // }),
    menuList: () => ({
      color: '#4d5875',
      fontSize: 14,
      fontWeight: 500, 
      maxHeight: 300,
      overflowY:'auto',
    }),
  };
  return (
    <Select
      id={props.id}
      name={props.name}
      value={props.value}
      label={props.label}
      options={props.options}
      className={props.className}
      styles={customStyles} 
      CommonProps={props.isMulti.boolean}
      onChange={props.onChange}
      isDisabled={props.isDisabled}
      isOptionDisabled={(option) => option.isdisabled}
      isClearable={true}
      
      classNamePrefix="select"
      {...props}  
      placeholder={props?.placeholder ?? localised.select}
    />
  );
});
CustomSelect.propTypes = {
  label: PropTypes.string,
  options: PropTypes.object,
  className: PropTypes.string, 
  onChange: PropTypes.func,
  isDisabled: PropTypes.boolean
};
export default CustomSelect;
