import React, { useState, useContext, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row, Form, Tab, Nav, Alert, Table } from "react-bootstrap";
import { LanguageContext } from "../../../containers/Language";
import Buttons from "../../../atoms/Buttons";
import { getAssignmentProgress } from "../../../../redux/company/action";
import { isArray, prepareFilterUrl, timeConvert } from "../../../../utils/utils";
import moment from "moment";
import { setGridFilter } from "../../../../utils/gridUtil";
import WorkorderAssignmentTracking from "../../WorkorderAssignmentTracking";

const AssignmentProgress = (props) => {

  let today = moment().format("YYYY/MM/DD");

  const { localised } = useContext(LanguageContext);
  const [showHide, setHideShow] = useState(false);

  const dispatch = useDispatch();

  const loggedInUser = useSelector((state) => state.authentication?.profile) || "";

  const hkProgress = useSelector((state) => state.global.hk_progress) || null;
  const haProgress = useSelector((state) => state.global.ha_progress) || null;
  const inspProgress = useSelector((state) => state.global.insp_progress) || null;

  const hkTarget = useSelector((state) => state.global.hk_target) || 0;
  const haTarget = useSelector((state) => state.global.ha_target) || 0;
  const inspTarget = useSelector((state) => state.global.insp_target) || 0;

  let HaAdditionalFeature = loggedInUser.additional_feature_access.some((item) => item.additional_features_id === 3);
  let InspectionAdditionalFeature = loggedInUser.additional_feature_access.some((item) => item.additional_features_id === 1); 
  let hkTimeAnalysisAdditionalFeature = loggedInUser.additional_feature_access.some((item) => item.additional_features_id === 5); 
  let workorderAdditionalFeature = loggedInUser.additional_feature_access.some((item) => item.additional_features_id === 2);  

  const globalRegionAreaFilter = useSelector((state) => state.global.globalRegionAreaFilter) || {};

  const [hkProgressList,setHkProgressList] = useState([]);
  const [haProgressList,setHaProgressList] = useState([]);
  const [inspProgressList,setInspProgressList] = useState([]);
  const dateFormat = useSelector((state) => state.authentication.profile.date_format);

  const loadAllStaffProgress = () => {
    let globalfilters = ""
    if (globalRegionAreaFilter && Object.keys(globalRegionAreaFilter).length > 0) {
      globalfilters = setGridFilter('', {
        'region_id': globalRegionAreaFilter.region_id,
        'area_id': globalRegionAreaFilter.area_id
      })
      globalfilters = globalfilters.replace("?","");
    }
    hkTimeAnalysisAdditionalFeature && dispatch(getAssignmentProgress({ type: "hk", action_name: "SET_GLOBAL_HK_PROGRESS", filters: globalfilters }))
    HaAdditionalFeature && dispatch(getAssignmentProgress({ type: "ha", action_name: "SET_GLOBAL_HA_PROGRESS", filters: globalfilters}))
    InspectionAdditionalFeature && dispatch(getAssignmentProgress({ type: "inspector", action_name: "SET_GLOBAL_INSP_PROGRESS", filters: globalfilters}))
  }

  const onRefreshHandler = () => {
    loadAllStaffProgress()
  }

  const remainingIndicator = (total, remaining ) => {
    let diff = total - remaining;
    return diff==0 ? "yellow" : diff<0 ? "red" : "blue"
  }

  useEffect(() => {
    loadAllStaffProgress()
    return () => {
        dispatch({type: "CLEAR_GLOBAL_HK_PROGRESS"})
        dispatch({type: "CLEAR_GLOBAL_HA_PROGRESS"})
        dispatch({type: "CLEAR_GLOBAL_INSP_PROGRESS"})
    };
  }, [])

  useEffect(() => {
    if(Array.isArray(hkProgress)) {
      setHkProgressList(hkProgress)
    }
  }, [hkProgress])

  useEffect(() => {
    if(Array.isArray(haProgress)) {
      setHaProgressList(haProgress)
    }
  }, [haProgress])

  useEffect(() => {
    if(Array.isArray(inspProgress)) {
      setInspProgressList(inspProgress)
    }
  }, [inspProgress])

  return (
    <>
      <div className="assignment-outer-wrap">
        <h5 className="card-title-indi mb-2">{localised.assignmentProgress} - {moment().format(dateFormat)}
          <Buttons
            variant="primary"
            className="ms-3 btn-sm"
            title={localised.refresh}
            onClick={onRefreshHandler}
          />

        </h5>

        <div className="assignment-progress-wrapper">
          {HaAdditionalFeature &&
          <div className="search-container" id="no-more-tables">
            <div className="card">
              <div className="card-header d-flex">
                <h3 className="card-title">{localised.houseAttendants}</h3>
                <span className="text-small ms-auto">{localised.target} {`[${haTarget} units]`}</span>
              </div>
              <div className="card-body">
                <div className="assignment-progress">
                  <Table bordered>
                    <thead>
                      <tr>
                        <th>{localised.name} </th>
                        <th>{localised.total}</th>
                        <th>{localised.assigned}</th>
                        <th>{localised.remaining}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        isArray(haProgressList) ? haProgressList.map((item) => (
                          <tr key={item.user_id}>
                            <td data-title={localised.name}>
                              {item.user}
                            </td>
                            <td data-title={localised.total}>{item.total}</td>
                            <td data-title={localised.assigned}>
                              {item.assigned}
                            </td>
                            <td className={`${remainingIndicator(item.total, item.assigned)}`} data-title={localised.remaining}>{item.total - item.assigned}</td>
                          </tr>
                        ))
                        : (
                          <tr align="center" key={'noRecordsFoundHa'}>
                            <td colspan="4">{ localised.noRecordsFound }</td> 
                          </tr>
                        )
                      }
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>}
          {hkTimeAnalysisAdditionalFeature &&
          <div className="search-container" id="no-more-tables">
            <div className="card">
              <div className="card-header d-flex">
                <h3 className="card-title">{localised.housekeepers}</h3>
                <span className="text-small ms-auto">
                  {localised.target} {`[${timeConvert(hkTarget)} hrs (${hkTarget} min)]`}
                </span>
              </div>
              <div className="card-body">
                <div className="assignment-progress">
                  <Table bordered>
                    <thead>
                      <tr>
                        <th>{localised.name} </th>
                        <th>{localised.total}</th>
                        <th>{localised.assigned}</th>
                        <th>{localised.remaining}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                          isArray(hkProgressList) ? hkProgressList.map((item) => (
                            <tr key={item.user_id}>
                              <td data-title={localised.name}>
                                {item.user}
                              </td>
                              <td data-title={localised.total}>
                              {`${item.total} min (${timeConvert(item.total)} hrs)`}
                              </td>
                              <td data-title={localised.assigned}>
                                {`${item.assigned} min (${timeConvert(item.assigned)} hrs)`}
                              </td>
                              <td className={`${remainingIndicator(item.total,item.assigned)}`} data-title={localised.remaining}>
                                {`${item.total - item.assigned} min (${timeConvert(item.total - item.assigned)} hrs)`}
                              </td>
                            </tr>
                          ))
                          : (
                            <tr align="center" key={'noRecordsFoundHk'}>
                              <td colspan="4">{ localised.noRecordsFound }</td> 
                            </tr>
                          )
                      }
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>}
          {InspectionAdditionalFeature && 
          <div className="search-container" id="no-more-tables">
            <div className="card">
              <div className="card-header d-flex">
                <h3 className="card-title">{localised.inspectors}</h3>
                <span className="text-small ms-auto">{localised.target} {`[${inspTarget} units]`}</span>
              </div>
              <div className="card-body">
                <div className="assignment-progress">
                  <Table bordered>
                    <thead>
                      <tr>
                        <th>{localised.name} </th>
                        <th>{localised.total}</th>
                        <th>{localised.assigned}</th>
                        <th>{localised.remaining}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        isArray(inspProgressList) ? inspProgressList.map((item) => (
                          <tr key={item.user_id}>
                            <td data-title={localised.name}>
                              {item.user}
                            </td>
                            <td data-title={localised.total}>{item.total}</td>
                            <td data-title={localised.assigned}>
                              {item.assigned}
                            </td>
                            <td className={`${remainingIndicator(item.total, item.assigned)}`} data-title={localised.remaining}>{item.total - item.assigned}</td>
                          </tr>
                        ))
                        : (
                          <tr align="center" key={'noRecordsFoundInsp'}>
                            <td colspan="4">{ localised.noRecordsFound }</td> 
                          </tr>
                        )
                      }
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>}
        </div>
      </div>

      {workorderAdditionalFeature && (
        <div className="assignment-outer-wrap">
          <WorkorderAssignmentTracking 
            date = {moment().format('YYYY/MM/DD')}
          />
        </div>
      )}
    </>
  );
};

export default AssignmentProgress;
