const initState = {
  loading: false,
}

export default (state = initState, action) => {
  switch (action.type) {
    case "SHOW_ALERT": {
      return {
        ...state,
        alert: action.payload,
      }
    }

    case "CLEAR_ALERT": {
      return {
        ...state,
        alert: null,
      }
    }

    case "IS_LOADER": {
      return {
        ...state,
        loading: action.payload,
      }
    }

    case "SET_COUNTRIES": {
      return {
        ...state,
        countries: action.payload,
      }
    }

    case "SET_STATES": {
      return {
        ...state,
        states: action.payload,
      }
    }

    case "SET_COUNTRY_WISE_STATES": {
      return {
        ...state,
        country_wise_states: action.payload,
      }
    }

    case "SET_STATUSES": {
      return {
        ...state,
        statuses: action.payload,
      }
    }
    case "SET_PROFESSION_TYPES": {
      return {
        ...state,
        professionTypes: action.payload,
      }
    }
    case 'GLOBAL_REGION_AREA_FILTER':{
      return {
        ...state,
        globalRegionAreaFilter : action.payload,
      };
    }
    case "SET_APPOINTMENT_STATUSES": {
      return {
        ...state,
        appStatuses: action.payload,
      }
    }
    case "SET_APPOINTMENT_STATUSES": {
      return {
        ...state,
        appointmentTaskStatuses: action.payload,
      }
    }

    case "SET_APPOINTMENT_REQUEST_TYPE":{
      return {
        ...state,
        appointmentRequestType: action.payload,
      }
    }

    case "SET_BILLING_STATUSES":{
      return {
        ...state,
        billingStatuses: action.payload,
      }
    }

    case "SET_GLOBAL_HK_PROGRESS": {
      return {
        ...state,
        hk_progress: action.payload.data,
        hk_target: action.payload.target
      }
    }

    case "CLEAR_GLOBAL_HK_PROGRESS": {
      return {
        ...state,
        hk_progress: []
      }
    }

    case "SET_GLOBAL_HA_PROGRESS": {
      return {
        ...state,
        ha_progress: action.payload.data,
        ha_target: action.payload.target
      }
    }

    case "CLEAR_GLOBAL_HA_PROGRESS": {
      return {
        ...state,
        ha_progress: []
      }
    }

    case "SET_GLOBAL_INSP_PROGRESS": {
      return {
        ...state,
        insp_progress: action.payload.data,
        insp_target: action.payload.target
      }
    }

    case "CLEAR_GLOBAL_INSP_PROGRESS": {
      return {
        ...state,
        insp_progress: []
      }
    }

    case "SET_CURRENCY": {
      return {
        ...state,
        currency: action.payload,
      }
    }
    
    case "CLEAR_CURRENCY": {
      let {currency, ...newState} = state;
      return {
        ...newState
      }
    }

    case "SET_WIZARD_STATUS": {
      return {
        ...state,
        wizard_status: action.payload,
      }
    }

    case "CLEAR_WIZARD_STATUS": {
      return {
        ...state,
        wizard_status: null,
      }
    }

    default:
      return state
  }
}