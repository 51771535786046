import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "react-bootstrap";
import { LanguageContext } from "../../containers/Language";
import Buttons from "../../atoms/Buttons";
import moment from "moment";
import { setGridFilter } from "../../../utils/gridUtil";
import { getWorkorderAssignmentTrackingHkData } from "../../../redux/workorder/action";
import { isEmpty, isEmptyNullUndefined } from "../../../utils/utils";

const WorkorderAssignmentTracking = ({
    date,
    woQuickAssignCountData,
    woQuickAssignSelectedHousekeepers,
}) => {

    const { localised } = useContext(LanguageContext);

    const dispatch = useDispatch();

    const dateFormat = useSelector((state) => state.authentication.profile.date_format);
    const globalRegionAreaFilter = useSelector((state) => state.global.globalRegionAreaFilter) || {};
    const workorderAssignmentTrackingHkData = useSelector((state) => state?.workorder?.workorderAssignmentTrackingHkData) || [];
    const workorderAssignmentTrackingHkTarget = useSelector((state) => state?.company?.workorderAssignmentTrackingHkTarget) || 0;

    const [hkData, setHkData] = useState([]);

    useEffect(() => {
        setHkData(workorderAssignmentTrackingHkData);
    }, [workorderAssignmentTrackingHkData]);

    useEffect(() => {
        calculateHkQuickAssignCount();
    }, [woQuickAssignCountData, woQuickAssignSelectedHousekeepers, date]);

    const calculateHkQuickAssignCount = () => {
        let _hkAssignCountData = JSON.parse(JSON.stringify(workorderAssignmentTrackingHkData));
        setHkData({ ..._hkAssignCountData });

        if (woQuickAssignCountData && woQuickAssignCountData?.length) {
            woQuickAssignCountData?.forEach((item) => {
                let index = _hkAssignCountData?.data?.findIndex((hkItem) => hkItem.user_id == item.user_id);
                if (index > -1) {
                    _hkAssignCountData.data[index].assigned += item.count;
                } else {
                    _hkAssignCountData?.data?.push({
                        user_id: item.user_id,
                        user: item.user || '--',
                        total: workorderAssignmentTrackingHkTarget,
                        assigned: item.count,
                    });
                }
            });
            setHkData({ ..._hkAssignCountData });
        }

        if (isEmptyNullUndefined(woQuickAssignSelectedHousekeepers) || isEmpty(woQuickAssignSelectedHousekeepers)) {
            fetchWorkorderAssignmentTrackingData();
        }
    }

    const fetchWorkorderAssignmentTrackingData = () => {
        let filters = setGridFilter('', {
            'date': date,
        })
        if (globalRegionAreaFilter && Object.keys(globalRegionAreaFilter).length > 0) {
            filters = setGridFilter('', {
                'date': date,
                'region_id': globalRegionAreaFilter.region_id,
                'area_id': globalRegionAreaFilter.area_id,
            })
        }
        filters = filters.replace("?", "");
        dispatch(getWorkorderAssignmentTrackingHkData({ filters }));
    }

    const onRefreshHandler = () => {
        fetchWorkorderAssignmentTrackingData();
    }

    const remainingIndicator = (total, remaining) => {
        let diff = total - remaining;
        return diff == 0 ? "yellow" : diff < 0 ? "red" : "blue"
    }

    return (
        <>
            <h5 className="card-title-indi mb-2">{localised.workorder + ' ' + localised.assignmentProgress} - {moment(date).format(dateFormat)}
                <Buttons
                    variant="primary"
                    className="ms-3 btn-sm"
                    title={localised.refresh}
                    onClick={onRefreshHandler}
                />
            </h5>

            {
                <div className="assignment-progress-wrapper">
                    <div className="search-container" id="no-more-tables">
                        <div className="card">
                            <div className="card-header d-flex">
                                <h3 className="card-title">{localised.housekeepers}</h3>
                                <span className="text-small ms-auto">
                                    {localised.target} {`[${hkData?.target || 0} units]`}
                                </span>
                            </div>
                            <div className="card-body">
                                <div className="assignment-progress">
                                    <Table bordered>
                                        <thead>
                                            <tr>
                                                <th>{localised.name} </th>
                                                <th>{localised.total}</th>
                                                <th>{localised.assigned}</th>
                                                <th>{localised.remaining}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {hkData?.data && hkData?.data?.length > 0
                                                ? (
                                                    hkData?.data?.map((item, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td data-title={localised.name}>{item.user}</td>
                                                                <td data-title={localised.total}>{item.total}</td>
                                                                <td data-title={localised.assigned}>{item.assigned}</td>
                                                                <td className={`${remainingIndicator(item.total, item.assigned)}`} data-title={localised.remaining}>{item.total - item.assigned}</td>
                                                            </tr>
                                                        )
                                                    })
                                                )
                                                : (
                                                    <tr align="center" key={'noRecordsFoundHk'}>
                                                        <td colspan="4">{localised.noRecordsFound}</td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );

}

export default WorkorderAssignmentTracking;