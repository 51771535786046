import React, { useState, useContext } from "react";
import { FiArrowUp } from "react-icons/fi";
import { Button } from "react-bootstrap";
import AssignmentProgress from "../../molecules/Appointment/AssignmentProgress";
import AssignmentProgressGlobal from "../../molecules/Appointment/AssignmentProgressGlobal";
import CustomModal from "../../molecules/CustomModal";
import { LanguageContext } from "../../containers/Language";
import Zendesk from "react-zendesk";
import { useSelector } from "react-redux";

const ZENDESK_KEY = "e8ec65d7-b495-4d43-96b9-339a332a4c7e";
const setting = {
  color: {
    theme: "#293072",
  },
  webWidget: {
    position: { horizontal: "right", vertical: "bottom" },
  },
};

const ScrollButton = () => {
  const { localised } = useContext(LanguageContext);
  const [visible, setVisible] = useState(false);

  const loggedInUser = useSelector((state) => state.authentication?.profile) || "";
  const profileRole = useSelector((state) => state?.authentication?.profile?.role);
  const staffProfileRole = useSelector((state) => state?.authentication?.profile?.companyData?.name);
  const isCleaner = (profileRole === "CL" || profileRole === "ST" && staffProfileRole ==='Cleaner');
  let AssignmentTrackingAdditionalFeature = loggedInUser?.additional_feature_access?.some((item) => item.additional_features_id === 22); 

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 100) {
      setVisible(true);
    } else if (scrolled <= 100) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };

  window.addEventListener("scroll", toggleVisible);

  const [showModal, setShowModal] = useState(false);
  const toggleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <>
      <Button
        className="scroll-btn"
        onClick={scrollToTop}
        style={{ display: visible ? "inline" : "none" }}
      >
        <FiArrowUp />
      </Button>
      <Zendesk defer zendeskKey={ZENDESK_KEY} {...setting} />
      {isCleaner && AssignmentTrackingAdditionalFeature && <Button
        variant="primary"
        className="btn-sm assignment-progress-btn"
        onClick={toggleModal}
      >
        {localised.assignmentProgress}
      </Button>}

      {showModal && <CustomModal
        title={localised.assignmentProgress}
        show={showModal}
        handleShow={toggleModal}
        handleClose={toggleModal}
        className="without-footer max-width-modal"
      >
        <AssignmentProgressGlobal />
      </CustomModal>}
    </>
  );
};

export default ScrollButton;
