import axios from "axios";
import { logout } from "./authentication/action";
import { store } from "./store";
import axiosRetry from 'axios-retry';
import { retryUrls } from '../utils/constUtil';
import { getObjectFromArrayByKeyValue } from "../utils/utils";

let checkAuthError = true;
let retries = 1;

function createHttp(){
  let tokenData       = localStorage.getItem('persist:root');
  if(tokenData){
    let tokenObj        = JSON.parse(tokenData);
    let authentication  = JSON.parse(tokenObj.authentication);
    
    const axiosInstance =  axios.create({
      baseURL: process.env.REACT_APP_API,
      headers: {
        'Authorization': `Bearer ${authentication?.keycloak?.token}`,
      }
    });
    
    axiosRetry(axiosInstance, {retries:retries});

    axiosInstance.interceptors.response.use((response) => {
      return response;
    }, (error) => {
      if(error && error.response){

        const { config, message } = error;

        if (error.response.status === 401 || error.response.data.code === "token_not_valid" || error.response.statusText === "Unauthorized")  {
          store.dispatch(logout());
          return Promise.reject(error);
        }
       
        if(retryUrls.some(item => config.url === item.url)) {
          let retryUrl = getObjectFromArrayByKeyValue('url',config.url,retryUrls);
          store.dispatch({
            type: 'SHOW_ALERT',
            payload: {
              variant: 'danger',
              message: retryUrl.errorMessage,
              componentName: retryUrl.componentName
            }
          });
        }

        return Promise.reject(error)
        
      }
    });
    return axiosInstance;
  }
}

export default {
  get(url, params) {
      return createHttp().get(url, {params});
  },

  post(url, data) {
    return createHttp().post(url, data);
  },

  put(url, data, key=null) {
    let createHttpInstance = createHttp();
    if(key!=null) {
      createHttpInstance.defaults.headers['Authorization'] = `Bearer ${key}`;
    }
    return createHttpInstance.put(url, data);
  },

  patch(url, data) {
    return createHttp().patch(url, data);
  },
  
  delete(url, params) {
    return createHttp().delete(url, {params});
  },
  postMultipartData(url, data, config={}) {
    let createHttpInstance = createHttp();
    createHttpInstance.defaults.headers.common['Content-Type'] = 'multipart/form-data';
    return createHttpInstance.post(url, data, config);
  },
  signup(url,data){
    let createHttpInstance = createHttp();
    createHttpInstance.defaults.headers = {};
    return createHttpInstance.post(url, data);
  },
  getBlobDataWithParams(url, data) {
    let createHttpInstance = createHttp();
    createHttpInstance.defaults['responseType'] = 'blob';
    return createHttpInstance.get(url, data);
  },
  getBlobDataForMultipleRecord(url, data) {
    let createHttpInstance = createHttp();
    createHttpInstance.defaults['responseType'] = 'blob';
    return createHttpInstance.post(url, data);
  },
  getBlobDataForMultipleRecordPut(url, data) {
    let createHttpInstance = createHttp();
    createHttpInstance.defaults['responseType'] = 'blob';
    return createHttpInstance.put(url, data);
  },
  withoutKeycloakTokenPost(url, data) {
    let createHttpInstance = createHttp();
    delete createHttpInstance.defaults.headers['Authorization'];
   return createHttpInstance.post(url, data);
  },
  withoutKeycloakTokenGet(url, data) {
    let createHttpInstance = createHttp();
    delete createHttpInstance.defaults.headers['Authorization'];
    return createHttpInstance.get(url, data);
  },
  deleteWithPayload(url, data) {
    let createHttpInstance =  createHttp();
    return createHttpInstance.delete(url, { data: data });
  }
};


