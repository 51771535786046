import React from 'react';
import FormControl from 'react-bootstrap/FormControl';
import PropTypes from 'prop-types';


const InputText = React.forwardRef((props, ref) => {
  return (
    <FormControl
      id={props.id}
      name={props.name}
      value={props.value}
      type={props.type}
      placeholder={props.placeholder}
      required={props.required}
      disabled={props.isDisabled}
      readOnly={props.isReadOnly}
      onChange={props.onChange}
      {...props}
    />
  );
});

InputText.propTypes = {
  onClick: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onKeyUp: PropTypes.func,
  onKeyDown: PropTypes.func,
  id: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.string,
  value:PropTypes.string,
};
export default InputText;
