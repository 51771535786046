import React from 'react';
import Button from 'react-bootstrap/Button';
import { PropTypes } from 'prop-types';
import Style from './styles.scss';

const Buttons = React.forwardRef((props, ref) => {
  return (
    <Button
      variant={props.variant ?? 'primary'}
      onClick={props.onClick}
      disabled={props.disabled}
      size={props.size}
      type={props.type ?? "button"}
      className={props.className}
      {...props}
    >
      {props.children ?? props.title}
    </Button>
  );
});

Buttons.propTypes = {
  variant: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.string,
  type:PropTypes.string,
  className:PropTypes.string,
};
export default Buttons;
