const initState = {
    workorderNotes: [],
    workorderPhotos:[],
    workorderItems: [],
    subtaskList:[],
    costPerHour : [],
    workorderTotal : [],
    integrationTrackWorkorder : [],
    departmentList : []
};
  
const workorder = (state = initState, action) => {
    switch (action.type) {

        case "SET_WORKORDER_PHOTOS": {
            return{
                ...state,
                workorderPhotos:action.payload.data
            }
        }

        case "CLEAR_WORKORDER_PHOTOS": {
            return {
                ...state,
                workorderPhotos: [],
            };
        }

        case "SET_WORKORDER_ITEMS": {
            return {
              ...state,
              workorderItems: action.payload.data,
            }
        }

        case 'CLEAR_WORKORDER_ITEMS': {
            return {
              ...state,
              workorderItems : []
            };
        }

        case 'SET_WORKORDER_NOTES': {
            return {
              ...state,
              workorderNotes : action.payload.data,
            };
        }

        case 'CLEAR_WORKORDER_NOTES': {
            return {
              ...state,
              workorderNotes : []
            }
        }

        case "SET_WORKORDER_SUBTASKS_LIST":{
            return {
              ...state,
              subtaskList : action.payload.data,
            };
        }

        case "CLEAR_WORKORDER_SUBTASKS_LIST":{
            return {
              ...state,
              subtaskList : [],
            };
        }

        case "SET_WORKORDER_LIST":
      return {
        ...state,
        workorders: action.payload,
      };

    case "CLEAR_WORKORDER_LIST":
      return {
        ...state,
        workorders: null,
      };
      
    case "SET_UNIQUE_WORKORDER_ITEMS": {
        return {
          ...state,
          uniqueWorkorderItems: action.payload.data,
        }
    }

    case 'CLEAR_UNIQUE_WORKORDER_ITEMS': {
        return {
          ...state,
          uniqueWorkorderItems : []
        };
    }
    case "SET_WORKORDER":
      return {
        ...state,
        workorderSingle: action.payload,
      };

    case "CLEAR_WORKORDER":
      return {
        ...state,
        workorderSingle: null,
      };
       case "SET_COST_PER_HOUR":
      return {
        ...state,
        costPerHour: action.payload,
      };

    case "CLEAR_COST_PER_HOUR":
      return {
        ...state,
        costPerHour: [],
      };
      
      case "SET_WORKORDER_TOTAL":
          return {
            ...state,
            workorderTotal : action.payload.data,
          }
  
      case "CLEAR_WORKORDER_TOTAL":
        return {
          ...state,
          workorderTotal: [],
        }
      
        case "SET_WORKORDER_LOGS": {
          return {
            ...state,
            woLogList: action.payload,
          };
        }
    
        case "CLEAR_WORKORDER_LOGS": {
          return {
            ...state,
            woLogList: [],
          };
        }
        
    case "SET_INTEGRATION_TRACK_WORKORDER":
      return {
        ...state,
        integrationTrackWorkorder : action.payload.data,
      }

    case "CLEAR_INTEGRATION_TRACK_WORKORDER":
      return {
        ...state,
        integrationTrackWorkorder: [],
      } 
      
    case "SET_WO_ANALYTICS_COUNTS": {
      return {
        ...state,
        woAnalyticsCounts: action.payload.data,
      }
    }

       
    case "CLEAR_WO_ANALYTICS_COUNTS": {
      return {
        ...state,
        woAnalyticsCounts: null,
      }
    }


    case "SET_WO_ANALYTICS_COUNT_CHARTS": {
      return {
        ...state,
        woAnalyticsCountChart: action.payload.data,
      }
    }


    case "SET_WO_ANALYTICS_REVENUE_CHARTS": {
      return {
        ...state,
        woAnalyticsRevenueChart: action.payload.data,
      }
    }


    case "SET_WO_ANALYTICS_TIME_CHARTS": {
      return {
        ...state,
        woAnalyticsTimeChart: action.payload.data,
      }
    }

    case "SET_WORKORDER_ASSIGNMENT_TRACKING_HK_DATA": {
      return {
        ...state,
        workorderAssignmentTrackingHkData: action.payload.data,
      }
    }

    case "CLEAR_WORKORDER_ASSIGNMENT_TRACKING_HK_DATA": {
      return {
        ...state,
        workorderAssignmentTrackingHkData: null,
      }
    }

    case "SET_WO_QUICK_ASSIGN_TRACKING_COUNT": {
      return {
        ...state,
        woQuickAssignTrackingCountData: action.payload.data,
      }
    }

    case "CLEAR_WO_QUICK_ASSIGN_TRACKING_COUNT": {
      return {
        ...state,
        woQuickAssignTrackingCountData: null,
      }
    }

    case "SET_WORKORDER_DEPARTMENT": {
      return {
        ...state,
        departmentList: action.payload.data,
      }
    }

    case "CLEAR_WORKORDER_DEPARTMENT": {
      return {
        ...state,
        departmentList: [],
      }
    }
    
        default:
            return state;
    }
};
  
  export default workorder;
  