const initState = {
  invoicesNotification: [],
  payrollTotal : [],
  clInvoiceTotal : [],
  internalInvoiceTotal : [],
  rcInvoiceTotal : []
};

const InvoicePayroll = (state = initState, action) => {
  switch (action.type) {
    case 'SET_RC_INVOICE_NOTIFICATION':
      return {
        ...state,
        invoicesNotification: action.payload.data
      };

    case 'CLEAR_RC_INVOICE_NOTIFICATION':
      return {
        ...state,
        invoicesNotification: []
      };
    case "SET_PAYROLL_TOTAL":
      return {
        ...state,
        payrollTotal: action.payload.data,
      }

    case "CLEAR_PAYROLL_TOTAL":
      return {
        ...state,
        payrollTotal: [],
      }
    case "SET_CL_INVOICE_TOTAL":
      return {
        ...state,
        clInvoiceTotal: action.payload.data,
      }

    case "CLEAR_CL_INVOICE_TOTAL":
      return {
        ...state,
        clInvoiceTotal: [],
    }
    case "SET_INTERNAL_INVOICE_TOTAL":
      return {
        ...state,
        internalInvoiceTotal: action.payload.data,
      }

    case "CLEAR_INTERNAL_INVOICE_TOTAL":
      return {
        ...state,
        internalInvoiceTotal: [],
    }
    case "SET_RC_INVOICE_TOTAL":
      return {
        ...state,
        rcInvoiceTotal: action.payload.data,
      }

    case "CLEAR_RC_INVOICE_TOTAL":
      return {
        ...state,
        rcInvoiceTotal: [],
    }
    case "SET_QUICKBOOK_INVOICE":
      return {
        ...state,
        quickBookInvoice: action.payload.data,
    }
    case "CLEAR_QUICKBOOK_INVOICE":
      return {
        ...state,
        quickBookInvoice: [],
    }
      default:
        return state;
  }
};

export default InvoicePayroll;
