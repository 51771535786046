import APIservice from "../apiService";
import { isLoader } from "../global/action";
import { setError } from "../form/action";

/**
 * ApiMiddleware can be used to call any User related API services
 * @param {*} store 
 * @returns 
 */
const UnitStatusViewMiddleware = (store) => (next) => async (action) => {
    const result = next(action);

    switch (action.type) {
        case "GET_UNIT_STATUS_VIEW_LIST":
            store.dispatch(isLoader(true));
            APIservice
            .get(action?.payload?.endpoint)
            .then((response)=>{
                store.dispatch({ 
                    type: 'SET_UNIT_STATUS_VIEW_LIST', 
                    payload: {
                      data:response?.data
                      }
                    });
            })
            .catch((error)=>{
                if (error.response.status !== 422) {
                store.dispatch({ 
                    type: 'CLEAR_UNIT_STATUS_VIEW_LIST', 
                    payload: {
                        data:[]
                        }
                });
                } else {
                    let err = {componentName:'UnitStatusViewList',statusCode: error.response.status,data: error.response.data};
                    store.dispatch(setError(err));
                }
            })
            .finally(()=>{
                store.dispatch(isLoader(false));
            })
        break;

        case "GET_DAY_WISE_COUNT_FOR_UNIT_STATUS_VIEW":
            // store.dispatch(isLoader(true));
            APIservice
            .get(action?.payload?.countendpoint)
            .then((response)=>{
                store.dispatch({ 
                    type: 'SET_DAY_WISE_COUNT_FOR_UNIT_STATUS_VIEW', 
                    payload: {
                      data:response?.data
                      }
                    });
            })
            .catch((error)=>{
                if (error.response.status !== 422) {
                store.dispatch({ 
                    type: 'CLEAR_DAY_WISE_COUNT_FOR_UNIT_STATUS_VIEW', 
                    payload: {
                        data:[]
                        }
                });
                } else {
                    let err = {componentName:'UnitStatusViewList',statusCode: error.response.status,data: error.response.data};
                    store.dispatch(setError(err));
                }
            })
            .finally(()=>{
                store.dispatch(isLoader(false));
            })
        break;
        default:
            break;
    }
    return result;
};

export default UnitStatusViewMiddleware;
