export const getInventorySmartCredential = (data) => {
    return {
        type: 'GET_INVENTORY_SMART_CREDENTIAL',
        payload: data
    };
}

export const replaceStockroomItem = (data) => {
    return {
        type: 'REPLACE_STOCKROOM_ITEM',
        payload: data
    };
}

export const inventorySmartRequestActions = (data) =>{
    return {
        type: 'INVENTORY_SMART_REQUEST_ACTIONS',
        payload: data
    };
}

export const viewItemsInStockRoom = (data) =>{
    return {
        type: 'VIEW_ITEMS_IN_STOCKROOM',
        payload: data
    };
}