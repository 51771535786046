import pt from './pt.json';
import en from './en.json';
import es from './es.json';
import el from './el.json';
import de from './de.json';
import fr from './fr.json';

export const localisedList = { en, es, el, pt, fr, de };

export const languageOptions = {
  en: 'English',
  es: 'Spanish',
  pt: 'Portuguese',
  el: 'Greek',
  de: 'German',
  fr: 'French',
  // tr: 'Türkçe',
  // de: 'Deutsch'
};

// export const languageOptions = [
//   {
//     label:"English",
//     src: require("https://reactjsexample.com/assets/favicon.png"),
//     value:"en"
//   },
//   {
//     label:"Spanish",
//     src: require("https://reactjsexample.com/assets/favicon.png"),
//     value:"es"
//   }
// ]