import APIservice from "../apiService";
import { getDropdowns, isLoader } from "../global/action";
import { microServices } from "../../config/global";
import { setError } from "../form/action";
import { useEffect } from "react";
import { logout } from "../authentication/action";
import { fetchLeftSidebarCount, fetchMessageCount, getCompanySetting, getInvoiceSetting } from "../company/action";
import { getInventorySmartCredential } from "../inventorySmart/action";
import { getCallbackInspectionCount } from "../inspection/action";
import { getQuickBook } from "../Billing/action";
import { getHHSIds } from "../../utils/utils";
import { prepareStaffsList } from "../../utils/staffUtil";
/**
 * UserMiddleware can be used to call any User related API services
 * @param {*} store 
 * @returns 
 */
const UserMiddleware = (store) => (next) => async (action) => {
  // Our middleware
  const result = next(action);
  let endpoint = '';

  switch (action.type) {
    case "GET_LOGGED_USER_DETAILS":
      store.dispatch(isLoader(true));
      APIservice.get("me")
        .then((response) => {
          if (response && response.data) {
            let HHS_IDS = getHHSIds();
            let basic_info = response.data.data;
            let userDetails = {
              email: basic_info.email,
              first_name: basic_info.first_name,
              last_name: basic_info.last_name,
              name: basic_info.first_name + ' ' + (basic_info.last_name ? basic_info.last_name : ''),
              keycloak_id: basic_info.keycloak_id,
              company_id: basic_info.company_id,
              role: basic_info.role,
              realm_access: basic_info.realm_access,
              companyData: basic_info.company,
              resource_access: basic_info.resource_access,
              is_owner: basic_info.is_owner,
              user_id: basic_info.user_id,
              additional_feature_access: basic_info.additional_feature_access,
              appointment_attribute_access: basic_info?.appointment_attribute_access,
              inspection_attribute_access: basic_info?.inspection_attribute_access,
              userInfo:basic_info?.userInfo,
              status_id : basic_info?.status,
              owner_status_id : (basic_info?.owner) ? basic_info?.owner?.status_id : null,
              userProfession : basic_info?.userProfession,
              workorder_attribute_access: basic_info?.workorder_attribute_access,
              property_attribute_access: basic_info?.property_attribute_access,
              has_slow_network: basic_info?.has_slow_network ? basic_info?.has_slow_network : false,
              date_format:basic_info?.date_format,
              has_hhs_features_access: HHS_IDS?.find((el, index) => el == basic_info.company_id) != undefined || false,
              is_hhs_manager:(basic_info.company?.parent_company_id != 0) ?true : false,
              currency : basic_info?.currency,
              date_format:basic_info?.date_format,
              has_autopay_enable : basic_info.company_settings?.has_autopay_enable,
              is_autopay_set : basic_info.company_settings?.is_autopay_set,
              has_outside_service : basic_info.company_settings?.has_outside_service ,
              service_mode : basic_info.company_settings?.service_mode,
              is_billing_eligible :basic_info.company_settings?.is_billing_eligible,
              can_deactivate_user : (basic_info?.is_owner === 1) ? true : basic_info?.userInfo?.can_deactivate_user,
              owner_user_id : (basic_info?.is_owner === 1) ? basic_info?.user_id : basic_info?.owner?.user_id,
            }
            store.dispatch({ type: 'SAVE_PROFILE', payload: userDetails });

            store.dispatch(
              getDropdowns({
                keyword: 'countries'
              })
            );
      
            store.dispatch(
              getDropdowns({
                keyword: 'states'
              })
            );
      
            store.dispatch(
              getDropdowns({
                keyword: 'status'
              })
            );
      
            store.dispatch(
              getDropdowns({
                  keyword: 'appointmentStatus'
              })
            );

            store.dispatch(
              getDropdowns({
                  keyword: 'appointmentTaskStatus'
              })
            );

            store.dispatch(
              getDropdowns({
                  keyword: 'appointmentTaskRequestType'
              })
            );

            store.dispatch(
              getDropdowns({
                  keyword: 'billingStatus'
              })
            );

            const companySettting_id = basic_info?.company_id;
            store.dispatch(getCompanySetting({companySettting_id}));

            if(basic_info.role === "CL" || basic_info.role === 'MN' || basic_info.role === 'ST')
            {
              store.dispatch(
                {type:"CLEAR_LEFT_SIDEBAR_COUNT"}
              );
              store.dispatch(
                {type:"CLEAR_HEADER_MESSAGE_COUNT"}
              );
              store.dispatch(
                {type:"CLEAR_INVENTORY_ITEMS_IN_STOCKROOM"}
              );
              store.dispatch(
                fetchLeftSidebarCount()
              );
              store.dispatch(
                fetchMessageCount()
              );
              store.dispatch(
                getInventorySmartCredential()
              );
              store.dispatch(
                getQuickBook({ reqtype: 'companyInfo' })
              );
              store.dispatch({ type: "CLEAR_ALL_STAFF_WITH_REGION_AREA" });
              
              if(basic_info.role === "CL" || (basic_info.role === "ST" && basic_info?.company?.name === 'Cleaner')) {
                store.dispatch(getInvoiceSetting({company_id: companySettting_id}));
                let inspectionAdditionalFeature = basic_info.additional_feature_access.some((item) => item.additional_features_id === 1); 
                if (inspectionAdditionalFeature) {
                    store.dispatch(
                    getCallbackInspectionCount()
                  )
                }
              }
            }
            if(basic_info.role === "SA" || basic_info.role === "AD") {
              store.dispatch(
                fetchLeftSidebarCount()
              );
              store.dispatch(
                {type:"CLEAR_HEADER_MESSAGE_COUNT"}
              );
              store.dispatch(
                fetchMessageCount()
              );
            }
          }
        })
        .catch((error) => {
          // if (error.response.status == 500) {
          //   store.dispatch(logout());
          // }
        }).finally(() => {
          store.dispatch(isLoader(false));
        });
      break;

    case "USERS_SIGNUP":
      endpoint = microServices.signup + '/' + action.userType + '?api-key=' + process.env.REACT_APP_SIGNUP_API_KEY;
      store.dispatch(isLoader(true));
      APIservice
        .signup(endpoint, action.payload)
        .then((response) => {
          store.dispatch({
            type: 'SHOW_ALERT',
            payload: {
              variant: 'success',
              message: 'Signup successfully!',
            }
          });
        })
        .catch((error) => {
          if (error.response.status !== 422) {
            store.dispatch({
              type: 'SHOW_ALERT',
              payload: {
                variant: 'danger',
                message: 'Unable to save user information!'
              }
            });
          } else {
            let err = { componentName: 'Signup', statusCode: error.response.status, data: error.response.data };
            store.dispatch(setError(err));
          }
        })
        .finally(() => {
          store.dispatch(isLoader(false));
        })
      break;
    case "CREATE_STAFF":
      endpoint = microServices.base + action.endpoint + action.userType;
      store.dispatch(isLoader(true));
      APIservice
        .postMultipartData(endpoint, action.payload.formData)
        .then((response) => {
          if(action.isProfTypeSUP && action.isAccess){
            store.dispatch({
              type: 'ASSIGN_ACCESS_TO_STAFF',
              payload:
              {
                "accessRoles": action.payload.formData,
                "userId": response.data.data.user_id
              }
            });
          }
         
          store.dispatch({
            type: 'ASSIGN_REGION_AREA',
            payload: {
              'assignData': action.payload.formData,
              'staffId': response.data.data.user_id,
              'moduleType': 'staff',
            }
          });
          store.dispatch({
            type: 'SHOW_ALERT',
            payload: {
              variant: 'success',
              message: action.message,
              componentName: action.componentName
            }
          });
        })
        .catch((error) => {
          if (error.response.status !== 422) {
            store.dispatch({
              type: 'SHOW_ALERT',
              payload: {
                variant: 'danger',
                message: 'Unable to save staff information!'
              }
            });
          } else {
            let err = { componentName: action.componentName, statusCode: error.response.status, data: error.response.data };
            store.dispatch(setError(err));
          }
        }).finally(() => {
          store.dispatch(isLoader(false));
        })
      break;
    case "ACTIVE_INACTIVE_STAFF":
      store.dispatch(isLoader(true));
      endpoint = microServices.base + `${action.endpoint}/${action.payload.apiName}/${action.userType}/${action.payload.id}`;
      APIservice
        .put(endpoint)
        .then((response) => {
          store.dispatch({
            type: 'SHOW_ALERT',
            payload: {
              variant: 'success',
              message: `Staff ${(action.payload.apiName === 'inactivate') ? 'deactivated' : 'activated'} successfully!`,
              componentName: action.componentName
            }
          });
        })
        .catch((error) => {
          if (error.response.status !== 422) {
            store.dispatch({
              type: 'SHOW_ALERT',
              payload: {
                variant: 'danger',
                message: `Unable to ${action.payload.apiName} staff!`
              }
            })
            .catch((error)=>{
              let err = {componentName:'Get_client',statusCode: error.response.status,data: error.response.data};
              store.dispatch(setError(err));
            })
          }
        })
        .finally(()=>{
          store.dispatch(isLoader(false));
        })  
        break;
        case "FETCH_STAFF":
          store.dispatch(isLoader(true));
          APIservice
      .get(microServices.base+action.endpoint+'/'+action.userType+'/'+action.payload.user_id)
      .then((response)=>{
        if(response && response.data){
          store.dispatch({ 
          type: 'SET_DATA_FOR_EDIT', 
          payload: {
            data:response.data.data
            }
          });
        }
      })
      .catch((error)=>{
        let err = {componentName:action.componentName,statusCode: error.response.status,data: error.response.data};
        store.dispatch(setError(err));
      })
      .finally(()=>{
        store.dispatch(isLoader(false));
      })
  break; 
      case "UPDATE_STAFF":
      store.dispatch(isLoader(true));
    
      APIservice
        .post(microServices.base + action.endpoint + '/' + action.userType + '/' + action.payload.user_id + '?_method=PUT', action.payload.formData)
        .then((response) => {


        if(action.isProfTypeSUP && action.isAccess){
          store.dispatch({
            type: 'UPDATE_ACCESS_OF_STAFF',
            payload: {
              "accessRoles": action.payload.formData,
              "userId": action.payload.user_id
            }
          });
        }
          store.dispatch({
            type: 'UPDATE_REGION_AREA',
            payload: {
              'updateRegionAreaData': action.payload.formData,
              'staffId': action.payload.user_id,
              'moduleType': 'staff',
            }
          });

          store.dispatch({
            type: 'SHOW_ALERT',
            payload: {
              variant: 'success',
              message: 'Staff updated successfully!',
              componentName: action.componentName
            }
          });
        })
        .catch((error) => {
          if (error.response.status !== 422) {
            store.dispatch({
              type: 'SHOW_ALERT',
              payload: {
                variant: 'danger',
                message: 'Unable to save staff information!',
              }
            });
          } else {
            let err = { componentName: action.componentName, statusCode: error.response.status, data: error.response.data };
            store.dispatch(setError(err));
          }
        })
        .finally(() => {
          store.dispatch(isLoader(false));
        })
      break;

    case "CLEANER_MANAGER_RELATION":
      endpoint = microServices.base + '/v1/company/' + action.userType + '/associates/' + action.rType;
      store.dispatch(isLoader(true));
      
      APIservice
        .post(endpoint, action.payload)
        .then((response) => {
          store.dispatch({
            type: 'ASSIGN_REGION_AREA',
            payload: {
              'assignData': action.payload,
              'staffId': response.data.data.manager_id,
              'moduleType': 'manager/associates',
            }
          });
          store.dispatch({
            type: 'SHOW_ALERT',
            payload: {
              variant: 'success',
              message: response?.data?.message,
            }
          });
        })
        .catch((error) => {
          if (error.response.status !== 422) {
            store.dispatch({
              type: 'SHOW_ALERT',
              payload: {
                variant: 'danger',
                message: 'Unable to save user information!'
              }
            });
          } else {
            let err = { componentName: 'AddClient', statusCode: error.response.status, data: error.response.data };
            store.dispatch(setError(err));
          }
        })
        .finally(() => {
          store.dispatch(isLoader(false));
        })
      break;
    case "DROPDOWN_GROUP_LIST":
      let filter = '';
      if (action.payload.filter) {
        filter = '&' + prepareFilterUrl(action.payload.filter);
      }
      endpoint = microServices.base + '/v1/company/staff/group?filter[profession_type_id]=1&per_page=0' + filter;
      APIservice
        .get(endpoint)
        .then((response) => {
          let groupList = prepareGroupList(response);
          store.dispatch({
            type: 'SET_DROPDOWN_GROUP_LIST',
            payload: groupList,
          });
        })
        .catch((error) => {
          if (error.response.status !== 422) {
            store.dispatch({
              type: 'SET_DROPDOWN_GROUP_LIST',
              payload: [],
            });
          }
        })
      break;
    case "HOUSEKEEPER_DROPDOWN":
      let perPage = '';
      if(action.payload.hasOwnProperty('perPage')){
        perPage = '&per_page='+ action.payload.perPage;
      }
      else{
        perPage = '&per_page=0'
      }
      endpoint = microServices.base + '/v1/company/staff/cl?filter[profession_type_id]=1&filter[status_id]=1' + perPage;
      
      APIservice
        .get(endpoint)
        .then((response) => {
          let regionList = prepareStaffs(response);
          store.dispatch({
            type: 'SET_HOUSEKEEPER_DROPDOWN',
            payload: regionList,
          });
        })
        .catch((error) => {
          if (error.response.status !== 422) {
            store.dispatch({
              type: 'SET_HOUSEKEEPER_DROPDOWN',
              payload: [],
            });
          }
        })
      break;
    case "CREATE_HOUSEKEEPER_GROUP":
      endpoint = microServices.base + '/v1/company/staff/group';
      store.dispatch(isLoader(true));
      APIservice
        .post(endpoint, action.payload)
        .then((response) => {
          store.dispatch({
            type: 'ASSIGN_REGION_AREA',
            payload: {
              'assignData': action.payload,
              'staffId': response.data.data.group_id,
              'moduleType': 'group',
            }
          });
          store.dispatch({
            type: 'SHOW_ALERT',
            payload: {
              variant: 'success',
              message: 'Housekeeper Group has been added successfully.',
              componentName: 'AddHousekeeperGroup'
            }
          });
        })
        .catch((error) => {
          if (error.response.status !== 422) {
            store.dispatch({
              type: 'SHOW_ALERT',
              payload: {
                variant: 'danger',
                message: 'Unable to save housekeeper group information!'
              }
            });
          } else {
            let err = { componentName: 'AddHousekeeperGroup', statusCode: error.response.status, data: error.response.data };
            store.dispatch(setError(err));
          }
        })
        .finally(() => {
          store.dispatch(isLoader(false));
        })
      break;
    case "ACTIVE_INACTIVE_GROUP":
      endpoint = microServices.base + '/v1/company/staff/group';
      store.dispatch(isLoader(true));
      APIservice
        .put(`${endpoint}/${action.payload.apiName}/${action.payload.id}`)
        .then((response) => {
          store.dispatch({
            type: 'SHOW_ALERT',
            payload: {
              variant: 'success',
              message: `Housekeeper Group has been ${action.payload.message}d successfully.`,
              componentName: 'ActiveInactiveGroup'
            }
          });
        })
        .catch((error) => {
          if (error.response.status !== 422) {
            store.dispatch({
              type: 'SHOW_ALERT',
              payload: {
                variant: 'danger',
                message: `Unable to ${action.payload.message} group information!`
              }
            });
          } else {
            let err = { componentName: 'ActiveInactiveGroup', statusCode: error.response.status, data: error.response.data };
            store.dispatch(setError(err));
          }
        })
        .finally(() => {
          store.dispatch(isLoader(false));
        })
      break;
    case "FETCH_GROUP":
      endpoint = microServices.base + '/v1/company/staff/group';
      store.dispatch(isLoader(true));
      APIservice
        .get(endpoint + '/' + action.payload.id)
        .then((response) => {
          if (response && response.data) {
            store.dispatch({
              type: 'SET_DATA_FOR_EDIT',
              payload: {
                data: response.data.data
              }
            });
          }
        })
        .catch((error) => {
          let err = { componentName: 'Group', statusCode: error.response.status, data: error.response.data };
          store.dispatch(setError(err));
        })
        .finally(() => {
          store.dispatch(isLoader(false));
        })
      break;
    case "UPDATE_GROUP":
      endpoint = microServices.base + '/v1/company/staff/group';
      store.dispatch(isLoader(true));
      APIservice
        .post(endpoint + '/' + action.payload.id + '?_method=PUT', action.payload.formData)
        .then((response) => {
          store.dispatch({
            type: 'UPDATE_REGION_AREA',
            payload: {
              'updateRegionAreaData': action.payload.formData,
              'staffId': action.payload.id,
              'moduleType': 'group',
            }
          });
          store.dispatch({
            type: 'SHOW_ALERT',
            payload: {
              variant: 'success',
              message: 'Group updated successfully!',
              componentName: 'EditGroup'
            }
          });
        })
        .catch((error) => {
          if (error.response.status !== 422) {
            store.dispatch({
              type: 'SHOW_ALERT',
              payload: {
                variant: 'danger',
                message: 'Unable to save group information!',
              }
            });
          } else {
            let err = { componentName: 'EditGroup', statusCode: error.response.status, data: error.response.data };
            store.dispatch(setError(err));
          }
        })
        .finally(() => {
          store.dispatch(isLoader(false));
        })
      break;
    case "UPDATE_CLEANER_MANAGER_RELATION":
    // 
      endpoint =microServices.base +'/v1/company/' + action.userType + '/associates/' + action.id + '?_method=PUT';
      store.dispatch(isLoader(true));
      APIservice
        .post(endpoint, action.payload)
        .then((response) => {
          store.dispatch({
            type: 'UPDATE_REGION_AREA',
            payload: {
              'updateRegionAreaData': action.payload,
              'staffId': response.data.data.company_id,
              'moduleType': 'manager/associates',
            }
          });
          store.dispatch({
            type: 'SHOW_ALERT',
            payload: {
              variant: 'success',
              message: action?.successMessage,
            }
          });
        })
        .catch((error) => {
          if (error.response.status !== 422) {
            store.dispatch({
              type: 'SHOW_ALERT',
              payload: {
                variant: 'danger',
                message: error.response.data.message.includes("Vacasa") ? error.response.data.message : 'Unable to save user information!'
              }
            });
          } else {
            let err = { componentName: 'EditClient', statusCode: error.response.status, data: error.response.data };
            store.dispatch(setError(err));
          }
        })
        .finally(() => {
          store.dispatch(isLoader(false));
        })
      break;

    case "GET_CLEANER_MANAGER_RELATION":
      store.dispatch(isLoader(true));
      endpoint = microServices.base + '/v1/company/' + action.payload.userType + '/associates/' + action.payload.user_id;
      APIservice
        .get(endpoint)
        .then((response) => {
          if (response && response.data) {
            store.dispatch({
              type: 'SET_DATA_FOR_EDIT',
              payload: {
                data: response.data.data
              }
            });
          }
        })
        .catch((error) => {
          let err = { componentName: 'Get_client', statusCode: error.response.status, data: error.response.data };
          store.dispatch(setError(err));
        })
        .finally(() => {
          store.dispatch(isLoader(false));
        })
      break;
            case "ASSIGN_REGION_AREA":
              endpoint  = microServices.base+'/v1/company/'+action.payload.moduleType+'/region-area/assign/'+action.payload.staffId;
              store.dispatch(isLoader(true));
              APIservice
              .post(endpoint, action.payload.assignData)
              .then((response)=>{
                    store.dispatch({ 
                    type: 'SHOW_ALERT', 
                    payload: {
                        variant:'success',
                        message: action.message,
                        componentName:action.componentName
                    }
                  });
              })
              .catch((error)=>{
                  let err = {componentName:action.componentName,statusCode: error.response.status,data: error.response.data};
                  store.dispatch(setError(err));
              }).finally(()=>{
                  store.dispatch(isLoader(false));
                })
            break;
            case "GET_USER_ACCESS_DETAILS":
              let userId = (action.payload.user_id) ? '/'+action.payload.user_id :'';
             endpoint = microServices.base+'/v1/company/staff/access-role'+userId;
              APIservice
              .get(endpoint)
              .then((response)=>{
                if(response && response.data){
                  store.dispatch({ 
                  type: 'SET_USER_ACCESS_DETAILS', 
                  payload:response.data.data
                  });
                }
               
              })
              .catch((error)=>{
                if (error.response.status !== 422) {
                    store.dispatch({ 
                      type: 'SET_USER_ACCESS_DETAILS', 
                      payload: [],
                    });
                } 
              })            
            break;
            case "UPDATE_REGION_AREA":
              endpoint  = microServices.base+'/v1/company/'+action.payload.moduleType+'/region-area/update/'+action.payload.staffId;
              store.dispatch(isLoader(true));
              APIservice
              .post(endpoint, action.payload.updateRegionAreaData)
              .then((response)=>{
                    store.dispatch({ 
                    type: 'SHOW_ALERT', 
                    payload: {
                        variant:'success',
                        message: action.message,
                        componentName:action.componentName
                    }
                  });
              })
              .catch((error)=>{
                  let err = {componentName:action.componentName,statusCode: error.response.status,data: error.response.data};
                  store.dispatch(setError(err));
              }).finally(()=>{
                  store.dispatch(isLoader(false));
                })
              break;
              case "ASSIGN_ACCESS_TO_STAFF":
             
                endpoint  = microServices.base+'/v1/company/staff/access-role/'+action.payload.userId;
                store.dispatch(isLoader(true));
            
                  APIservice
                    .post(endpoint, action.payload.accessRoles)
                    .then((response)=>{
                      store.dispatch({ 
                        type: 'SHOW_ALERT', 
                        payload: {
                          variant:'success',
                         
                          componentName:'AccescRight'
                        }
                      });
                    })
                    .catch((error)=>{
                      if (error.response.status !== 422) {
                        store.dispatch({ 
                          type: 'SHOW_ALERT', 
                          payload: {
                            variant:'danger',
                            message: 'Unable to Assign Access role!',
                          }
                        });
                      } else {
                        let err = {componentName:'AccessRight',statusCode: error.response.status,data: error.response.data};
                        store.dispatch(setError(err));
                      }
                    })
                    .finally(()=>{
                      store.dispatch(isLoader(false));
                    })
                break;
                case "UPDATE_ACCESS_OF_STAFF":
             
                endpoint  = microServices.base+'/v1/company/staff/access-role/'+action.payload.userId+'?_method=PUT';
                store.dispatch(isLoader(true));
              
                  APIservice
                    .post(endpoint, action.payload.accessRoles)
                    .then((response)=>{
                      store.dispatch({ 
                        type: 'SHOW_ALERT', 
                        payload: {
                          variant:'success',
                         
                          componentName:'AccescRight'
                        }
                      });
                    })
                    .catch((error)=>{
                      if (error.response.status !== 422) {
                        store.dispatch({ 
                          type: 'SHOW_ALERT', 
                          payload: {
                            variant:'danger',
                            message: 'Unable to Assign Access role!',
                          }
                        });
                      } else {
                        let err = {componentName:'AccessRight',statusCode: error.response.status,data: error.response.data};
                        store.dispatch(setError(err));
                      }
                    })
                    .finally(()=>{
                      store.dispatch(isLoader(false));
                    })
                break;
                case "FETCH_USER":
              APIservice
              .get(microServices.base+'/v1/user/'+action.payload.id)
              .then((response)=>{
                if(response && response.data){
                  store.dispatch({ 
                  type: 'SET_USER_DETAILS', 
                  payload:response.data.data
                  });
                }
               
              })
              .catch((error)=>{
                if (error.response.status !== 422) {
                    store.dispatch({ 
                      type: 'SET_USER_DETAILS', 
                      payload: [],
                    });
                } 
              })            
            break;
            
            case "FETCH_COMPANY":
              APIservice
              .get(microServices.base+'/v1/company/'+action.payload.company_id)
              .then((response)=>{
                if(response && response.data){
                  store.dispatch({ 
                  type: 'SET_COMPANY_DETAILS', 
                  payload:response.data.data
                  });
                }
               
              })
              .catch((error)=>{
                if (error.response.status !== 422) {
                    store.dispatch({ 
                      type: 'SET_COMPANY_DETAILS', 
                      payload: [],
                    });
                } 
              })            
            break;
            case "UPDATE_USER":
            store.dispatch(isLoader(true));
            APIservice
            .put(microServices.base+action.endpoint+action.payload.id, action.payload.formData)
              .then((response)=>{   
                if(action.payload.formCompanyData)
                {
                  store.dispatch({ 
                    type: 'UPDATE_COMPANY', 
                    componentName : 'EditCompany',
                    payload: { 
                            "company":action.payload.formCompanyData,
                            "company_id":action.payload.company_id }
                    });
                }
                else{
                  store.dispatch({ 
                    type: 'SHOW_ALERT', 
                    payload: {
                      variant:'success',
                      message: 'Personal Information updated successfully!',
                      componentName:action.componentName
                    }
                  });
                }
              })
              .catch((error)=>{
                if (error.response.status !== 422) {
                  store.dispatch({ 
                    type: 'SHOW_ALERT', 
                    payload: {
                      variant:'danger',
                      message: 'Unable to save Personal information!',
                    }
                  });
                } else {
                  let err = {componentName:action.componentName,statusCode: error.response.status,data: error.response.data};
                  store.dispatch(setError(err));
                }
              })
              .finally(()=>{
                store.dispatch(isLoader(false));
              })
              break;  
              case "UPDATE_COMPANY":
             
                endpoint  = microServices.base+'/v1/company/'+action.payload.company_id+'?_method=PUT';
                store.dispatch(isLoader(true));
              
                  APIservice
                    .post(endpoint, action.payload.company)
                    .then((response)=>{
                      store.dispatch({ 
                        type: 'SHOW_ALERT', 
                        payload: {
                          variant:'success',
                          message: 'Personal Information updated successfully!',
                          componentName:action.componentName
                        }
                      });
                    })
                    .catch((error)=>{
                      if (error.response.status !== 422) {
                        store.dispatch({ 
                          type: 'SHOW_ALERT', 
                          payload: {
                            variant:'danger',
                            message: 'Unable to Save Personal Information',
                          }
                        });
                      } else {
                        let err = {componentName:'UpdateCompany',statusCode: error.response.status,data: error.response.data};
                        store.dispatch(setError(err));
                      }
                    })
                    .finally(()=>{
                      store.dispatch(isLoader(false));
                    })
                break;
                case "HOUSEKEEPER_DROPDOWN_WITH_REGION_AREA":
                  endpoint = microServices.base+'/v1/company/cleaner/staff/region-area?per_page=0&filter[profession_type_id]=1&filter[status_id]=1&per_page=0';
                  APIservice
                  .get(endpoint)
                  .then((response)=>{
                   
                    store.dispatch({ 
                      type: 'SET_HOUSEKEEPER_DATA', 
                      payload: response.data.data,
                    });
                  })
                  .catch((error)=>{
                    if (error.response.status !== 422) {
                      store.dispatch({ 
                        type: 'SET_HOUSEKEEPER_DATA', 
                        payload: [],
                      });
                    } 
                  })
                break; 

    
                case "INITIALIZE_PROPERTY_MANAGER_UPLOAD":
                  endpoint = microServices.base+'/v1/company/manager/associates/upload';
                  store.dispatch(isLoader(true));
                  APIservice
                  .post(endpoint, action.payload.formData)
                    .then((response)=>{
                      store.dispatch({ 
                        type: 'UPLOAD_PROPERTY_MANAGER',
                        payload: { 
                          'uploadUrl':response.data.data.upload,
                          'uploadFiledata':action.payload.formData
                        }
                        });
                    })
                    .catch((error)=>{
                      let err = {componentName:'Upload_PM',statusCode: error.response.status,data: error.response.data};
                      store.dispatch(setError(err));
                      store.dispatch(isLoader(false));
                    });
            break;
            case "UPLOAD_PROPERTY_MANAGER":
              endpoint = action.payload.uploadUrl;
              store.dispatch(isLoader(true));
              APIservice
              .post(endpoint,action.payload.uploadFiledata)
              .then((response)=>{
                store.dispatch({ 
                  type: 'SHOW_ALERT', 
                  payload: {
                    variant:'success',
                    message: 'File Uploded successfully.',
                    componentName:'UploadPropertyManager',
                    data : response
                  }
                });
               })
              .catch((error)=>{
                  let err = {componentName:action.componentName,statusCode: error.response.status,data: error.response.data};
                  store.dispatch(setError(err));
              }).finally(()=>{
                  store.dispatch(isLoader(false));
              })
              break;
            
              case "SEND_WELCOME_EMAIL":
                endpoint = microServices.base+'/v1/user/verify/'+action.payload.user_id;
                store.dispatch(isLoader(true));
                APIservice
                .put(endpoint,action.payload)
                .then(()=>{
                  store.dispatch({ 
                    type: 'SHOW_ALERT', 
                    payload: {
                      variant:'success',
                      message: 'Email has been sent successfully.',
                      componentName:'welcome_mail',
                    }
                  });
                 })
                .catch((error)=>{
                    let err = {componentName:action.componentName,statusCode: error.response.status,data: error.response.data};
                    store.dispatch(setError(err));
                }).finally(()=>{
                    store.dispatch(isLoader(false));
                })
                break;
              case "SEND_VERIFICATION_SMS":
                endpoint = microServices.base+'/v1/user/verify-mobile/'+action.payload.user_id;
                store.dispatch(isLoader(true));
                APIservice
                .put(endpoint,action.payload)
                .then((response)=>{
                  store.dispatch({ 
                    type: 'SHOW_ALERT', 
                    payload: {
                      variant:'success',
                      message: 'Verification SMS has been sent successfully.',
                    }
                  });
                  if(typeof action.callback === 'function'){
                    action.callback(response);
                  }
                 })
                .catch((error)=>{
                  store.dispatch({
                    type: 'SHOW_ALERT',
                    payload: {
                      variant:'danger',
                      message: error.response.data.message,
                    }
                  });
                }).finally(()=>{
                    store.dispatch(isLoader(false));
                })
                break;
              case "ALL_STAFF_DROPDOWN_WITH_REGION_AREA":
                endpoint = microServices.base+'/v1/company/cleaner/staff/region-area?per_page=0';
                  if (action.payload.customFilterModel) {
                    endpoint += '&';
                    let filterKeys = Object.keys(action.payload.customFilterModel);
                    filterKeys.forEach((filter) => {
                        endpoint += `filter[${filter}]=${action.payload.customFilterModel[filter]}&`;
                    });
                }
                APIservice
                .get(endpoint)
                .then((response)=>{
                  if(action.payload?.customFilterModel['profession_type_id']==2 || action.payload?.customFilterModel['profession_type_id']==7){
                    store.dispatch({ 
                      type: 'SET_INSPECTOR_DATA', 
                      payload: response.data.data,
                    });
                  }
                  else if(action.payload?.customFilterModel['profession_type_id']==4){
                    store.dispatch({ 
                      type: 'SET_HOUSE_ATTENDANT_DATA', 
                      payload: response.data.data,
                    });
                  }
                  else if(action.payload?.customFilterModel['profession_type_id']==5){
                    store.dispatch({ 
                      type: 'SET_TEAM_MEMBER_DATA', 
                      payload: response.data.data,
                    });
                  } else {
                    store.dispatch({ 
                      type: 'SET_ALL_STAFF_WITH_REGION_AREA', 
                      payload: response.data.data,
                    });
                  }
                })
                .catch((error)=>{
                  if (error.response.status !== 422) {
                    store.dispatch({ 
                      type: 'SET_INSPECTOR_DATA', 
                      payload: [],
                    });
                  } 
                })
              break; 
  case "COMPANY_STAFF_DROPDOWN":
      let filterUrl = '';
      if (action.payload) {
        filterUrl = prepareFilterUrl(action.payload.filter);
      }

      endpoint = microServices.base + '/v1/company/staff/' + action.payload.userType + '?' + filterUrl+'per_page=0';
      APIservice
        .get(endpoint)
        .then((response) => {
          let staffList;
          if(action.payload?.from === 'payroll'){
            staffList =  prepareGroupStaffs(response);
          } else if(action?.payload?.from === 'timesheetReport') {
            staffList =  prepareTimeSheetStaff(response);
          } else {
            staffList = prepareStaffs(response);
          }
          
          setDispatchForStaff(action.payload.staffType, staffList, store);
        })

        .catch((error) => {
          if (error.response?.status !== 422) {
            store.dispatch({
              type: 'SET_STAFF_DROPDOWN',
              payload: [],
            });
          }
        })
      break;
  
    case "HOUSEKEEPER_STAFF_DROPDOWN":
      let userType='cl';
  
      const endpoint1 = microServices.base + '/v1/company/staff/group' + checkFilterFields(action.payload.hkGroup.filter) + '&per_page=0';
      const endpoint2 = microServices.base + '/v1/company/staff/' + userType  + checkFilterFields(action.payload.hk.filter) + '&per_page=0';

      Promise.all([APIservice.get(endpoint1), APIservice.get(endpoint2)]).then((response) => {
        const [result1, result2] = response;
        let hkStaffList = []
        hkStaffList.push(prepareHouseKeeperList(result2,"hk"));
        hkStaffList.push(prepareHouseKeeperList(result1,"hkGroup"));
        if(action.payload.bulk) {
          store.dispatch({
            type: 'SET_HKBULK_DROPDOWN',
            payload: hkStaffList
          })
        } else {
          store.dispatch({
            type: 'SET_HK_STAFF_DROPDOWN',
            payload: hkStaffList
          })
        }
      });

      break;

      case "INSPECTORS_DROPDOWN":
          let inspfilterUrl = '';
          if (action.payload) {
            inspfilterUrl = prepareFilterUrl(action.payload.filter);
          }
          endpoint = microServices.base+'/v1/company/staff/dropdown/'+action.payload.userType + '?' + inspfilterUrl;
           
          if(action?.payload?.perPage === 0 || action?.payload?.perPage)
          {
            endpoint += 'per_page=' + action?.payload?.perPage;
          }
          APIservice
            .get(endpoint)
            .then((response)=>{
              // let inspectorsList = prepareInspectorList(response);
              let inspList = prepareStaffsList(response,'staff','insp');
              let inspectorsList = [{label: "Active Inspector", options: inspList?.options}];
              store.dispatch({ 
                type: 'SET_INSP_LIST_DROPDOWN', 
                payload: inspectorsList,
              });
            })
            .catch((error)=>{
              if (error.response.status !== 422) {
              store.dispatch({ 
                type: 'SET_INSP_LIST_DROPDOWN', 
                payload: [],
              });
            } 
          })
        break; 
    
        case "USER_IMPERSONATE":
          endpoint = microServices.base+'/v1/user/'+action.payload.id + '/impersonate';
          let token = store.getState()?.authentication?.keycloak?.token;
          APIservice.put(endpoint,{},action?.payload?.key)
            .then((response)=>{
              if(response.data.data.isNewTab){
                if(!action.payload.sameTab){
                  window.open(process.env.REACT_APP_WEB_URL+'/impersonate?data='+btoa(token)+'&id='+btoa(action.payload.id),'_blank');
                }  
                else {
                  store.dispatch({type:'USER_IMPERSONATE_DATA',payload:response.data.data});
                }
              } else {
                  store.dispatch(isLoader(true));
                  let reduxState = store.getState();
                  reduxState.authentication.keycloak.token = response.data.data.access_token;
                  reduxState.authentication.keycloak.refreshToken = response.data.data.refresh_token;
                  store.dispatch({ type: 'MODIFY_AUTHENTICATION', payload:  reduxState.authentication.keycloak});
                  setTimeout(() => {
                    store.dispatch({ type: 'GET_LOGGED_USER_DETAILS'});
                    store.dispatch(isLoader(false));
                  }, 150);
              }
            })
            .catch((error)=>{
              if (error.response.status !== 422) {

              } 
          });
        break; 

        case "CHANGE_PASSWORD":
          store.dispatch(isLoader(true));
          APIservice.post('/me/update-password',action.payload)
            .then((response)=>{
              store.dispatch({ 
                type: 'SHOW_ALERT', 
                payload: {
                  variant:'success',
                  message: 'Password changed Successfully.',
                  componentName:'changePassword',
                }
              });
            })
            .catch((error)=>{
              if (error.response.status == 422) {
                let err = {componentName:'changePassword',statusCode: error.response.status,data: error.response.data.message};
                store.dispatch(setError(err));
              } 
          }).finally(() => {
            store.dispatch(isLoader(false));
          });
        break; 

        case "STAFF_CHANGE_PASSWORD":
          endpoint = `${microServices.base}/v1/staff/update-password/`;
          store.dispatch(isLoader(true));
          APIservice.post(endpoint+action.payload.userId,action.payload.formData)
            .then((response)=>{
              store.dispatch({ 
                type: 'SHOW_ALERT', 
                payload: {
                  variant:'success',
                  message: action.payload.successMessage,
                  componentName:'staffChangePassword',
                }
              });
            })
            .catch((error)=>{
              if (error.response.status == 422) {
                let err = {componentName:'staffChangePassword',statusCode: error.response.status,data: error.response.data.message};
                store.dispatch(setError(err));
              } 
          }).finally(() => {
            store.dispatch(isLoader(false));
          });
        break; 

        case "EXPORT_STAFF":
          let api = action.payload.exportApi;
          store.dispatch(isLoader(true));
          APIservice.
          getBlobDataWithParams(api,"")
          .then((response)=>{
              const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = blobUrl;
              link.setAttribute('download', `${(action.payload?.fileName) ? action.payload.fileName : "Report"}_${Date.now()}.xlsx`);
              document.body.appendChild(link);
              link.click(); 
              link.parentNode.removeChild(link);
          })
          .catch((error)=>{
            if (error.response.status !== 422) {
                store.dispatch({ 
                    type: 'SHOW_ALERT', 
                    payload: {
                        variant:'danger',
                        message: 'Unable to Download Report!',
                    }
                });
            } else {
                let err = {componentName:'DownloadStaffXlsx',statusCode: error.response.status,data: error.response.data};
                store.dispatch(setError(err));
            }
          })
          .finally(()=>{
              store.dispatch(isLoader(false));
          });
      break;

      case "PRINT_PDF_REPORT":
            let printApi = action.payload.printApi;
            store.dispatch(isLoader(true));
            APIservice.
            getBlobDataWithParams(printApi,"")
            .then((response)=>{
                const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
                   const link = document.createElement('a');
                   link.href = blobUrl;
                   link.setAttribute('download', `${(action.payload?.fileName) ? action.payload.fileName : "Document"}_${Date.now()}.pdf`);
                   document.body.appendChild(link);
                   link.click(); 
                   link.parentNode.removeChild(link);
            })
            .catch((error)=>{
                if (error.response.status !== 422) {
                    store.dispatch({ 
                        type: 'SHOW_ALERT', 
                        payload: {
                            variant:'danger',
                            message: 'Unable to Download Report!',
                        }
                    });
                } else {
                    let err = {componentName:'DownloadReportPdf',statusCode: error.response.status,data: error.response.data};
                    store.dispatch(setError(err));
                }
            })
            .finally(()=>{
                store.dispatch(isLoader(false));
            });
            break;

            case "EXPORT_CLIENTS":
              let exportApi = action.payload.exportApi;
              store.dispatch(isLoader(true));
              APIservice.
              getBlobDataWithParams(exportApi,"")
              .then((response)=>{
                  const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
                  const link = document.createElement('a');
                  link.href = blobUrl;
                  link.setAttribute('download', `${(action.payload?.fileName) ? action.payload.fileName : "Report"}_${Date.now()}.xlsx`);
                  document.body.appendChild(link);
                  link.click(); 
                  link.parentNode.removeChild(link);
              })
              .catch((error)=>{
                  if (error.response.status !== 422) {
                      store.dispatch({ 
                          type: 'SHOW_ALERT', 
                          payload: {
                              variant:'danger',
                              message: 'Unable to Export Client Report!',
                          }
                      });
                  } else {
                      let err = {componentName:'ExportReport',statusCode: error.response.status,data: error.response.data};
                      store.dispatch(setError(err));
                  }
              })
              .finally(()=>{
                  store.dispatch(isLoader(false));
              });
              break;
              case "ADD_REGION_AREA_CONFIG":
                if(action.payload.action === 'inspection-template'){
                  endpoint = `${microServices.inspection}/v1/region-area-config/${action.payload.action}`;
                }
                else{
                  endpoint = `${microServices.base}/v1/region-area-config/${action.payload.action}`;
                }
                store.dispatch(isLoader(true));
                APIservice
                .put(endpoint, action.payload)
                .then((response) => {
                  store.dispatch({
                    type: 'SHOW_ALERT',
                    payload: {
                      variant: 'success',
                      message: action.payload.action !== 'group' ? 'Property Area has been assigned successfully.' 
                      : 'Property Area has been assigned successfully. Please confirm these group members are available in the selected Region.',
                      componentName: 'RegionAreaConfig'
                    }
                  });
                })
                .catch((error) => {
                  if (error.response.status !== 422) {
                    store.dispatch({
                      type: 'SHOW_ALERT',
                      payload: {
                        variant: 'danger',
                        message: 'Unable to save region area information!',
                      }
                    });
                  } else {
                    let err = { componentName: 'RegionAreaConfig', statusCode: error.response.status, data: error.response.data };
                    store.dispatch(setError(err));
                  }
                })
                .finally(() => {
                  store.dispatch(isLoader(false));
                })
              break;
              case "GET_QUICKBOOK_VENDOR":
              let endpointUrl = microServices.base+'/v1/payroll/qb/quickbookMap/vendor';
              APIservice
                .get(endpointUrl)
                .then((response)=>{
                  store.dispatch({ 
                    type: 'SET_QUICKBOOK_VENDOR', 
                    payload: response.data,
                  });
                })
                .catch((error)=>{
                  if (error.response.status !== 422) {
                  store.dispatch({ 
                    type: 'SET_QUICKBOOK_VENDOR', 
                    payload: [],
                  });
                } 
              })
            break;
            case "STORE_QUICKBOOK_VENDOR": {

              let endUrl =  microServices.base + "/v1/payroll/qb/vendor/";
              APIservice.post(endUrl+action.payload.userId,{qb_user_id :action.payload.selectedId})
                .then((response) => {
                  if (response ) {
                    store.dispatch({
                      type: 'SHOW_ALERT',
                      payload: {
                        variant: 'success',
                        message: "Staff mapped Successfully!",
                        rowIndex:action.payload.rowIndex,
                        componentName: 'MappedQuickbookVendor',
                      }
                    });
                  }
                })
                .catch((error) => {
                  let err = {
                    componentName: "MappedQuickbookVendor",
                    statusCode: error.response.status,
                    data: error.response.data,
                    
                  };
                  store.dispatch(setError(err));
                })
                .finally(() => {
                  store.dispatch(isLoader(false));
                });
                break;
              }
              
              case "GET_WO_HOUSEKEEPERS_WITH_ASSIGNED_COUNT": {
                let hkFilters = checkFilterFields(action.payload.HkDropdownObj.hk.filter)?.replace("?", "");
                let hkGrpFilters = checkFilterFields(action.payload.HkDropdownObj.hkGroup.filter)?.replace("?", "");
                const endpoint1 = `${microServices.base}/v1/company/staff/group?${hkGrpFilters}&per_page=0`;
                const endpoint2 = `${microServices.base}/v1/company/staff/productive-time?${hkFilters}&per_page=0&model=workorder&date=${action.payload.date}`;
                
                store.dispatch(isLoader(true));
                Promise.all([APIservice.get(endpoint1), APIservice.get(endpoint2)])
                    .then((response) => {
                        const [result1, result2] = response;
                        let hkStaffList = []
                        hkStaffList.push(prepareHouseKeeperWithAssignedCountList(result2, "hk"));
                        hkStaffList.push(prepareHouseKeeperWithAssignedCountList(result1, "hkGroup"));
                        store.dispatch({
                            type: "SET_WO_HOUSEKEEPERS_WITH_ASSIGNED_COUNT",
                            payload: {
                                data: hkStaffList,
                            },
                        });
                    })
                    .catch((error) => {
                      let err = {
                          componentName: "WoHousekeepersWithAssignedCount",
                          statusCode: error.response.status,
                          data: error.response.data,
                      };
                      store.dispatch(setError(err));
                  })
                  .finally(() => {
                      store.dispatch(isLoader(false));
                  })
              ;
              break;
            }
            case "SEND_APP_CREDENTIALS":{
              store.dispatch(isLoader(true));
              endpoint = microServices.base +'/v1/user/send-credentilals/'+action.payload.id;
              APIservice
                .put(endpoint)
                .then((response) => {
                  if(response?.data?.data?.status==true){
                    store.dispatch({
                      type: 'SHOW_ALERT',
                      payload: {
                        variant: 'success',
                        message: action.payload?.successMessage,
                        componentName: action.payload?.componentName,
                        userId: action.payload.id
                      }
                    });
                  }else{
                    store.dispatch({
                      type: 'SHOW_ALERT',
                      payload: {
                        variant: 'danger',
                        message: action.payload?.failedMessage,
                        componentName: action.payload?.componentName
                      }
                    })
                  }
                  
                })
              .catch((error) => {
                if (error.response.status !== 422) {
                  store.dispatch({
                    type: 'SHOW_ALERT',
                    payload: {
                      variant: 'danger',
                      message:action.payload?.successMessage,
                    }
                  })
                  .catch((error)=>{
                    let err = {componentName:action.payload?.componentName,statusCode: error.response.status,data: error.response.data};
                    store.dispatch(setError(err));
                  })
                }
              })
              .finally(()=>{
                store.dispatch(isLoader(false));
              })  
            }

            case "UPDATE_USER_PREFERENCES":{
              store.dispatch(isLoader(true));
              endpoint = microServices.base +'/v1/user/preference/'+action.payload.apiName;
              APIservice
                .post(endpoint,action?.payload?.formData)
                .then((response) => {
                  if(response?.status == 200){
                    store.dispatch({
                      type: 'SHOW_ALERT',
                      payload: {
                        variant: 'success',
                        message: action.payload?.successMessage,
                        componentName: 'updateUserPreferences'
                      }
                    });
                  }else{
                    store.dispatch({
                      type: 'SHOW_ALERT',
                      payload: {
                        variant: 'danger',
                        message: action.payload?.failMessage,
                        componentName: "updateUserPreferences"
                      }
                    })
                  }
                  
                })
              .catch((error) => {
                if (error.response.status !== 422) {
                  store.dispatch({
                    type: 'SHOW_ALERT',
                    payload: {
                      variant: 'danger',
                      message:action.payload?.successMessage,
                    }
                  })
                } else {
                  let err = {componentName:action.payload?.componentName,statusCode: error.response.status,data: error.response.data};
                  store.dispatch(setError(err));
                }
              })
              .finally(()=>{
                store.dispatch(isLoader(false));
              })  
              break;
            }

        default:
            break;
    }
    return result;
};

const prepareGroupList = (response) => {
  let ActiveGroupList = [];
  let InactiveGroupList = [];

  let GroupList = [{
    label: "Active Group",
    options: ActiveGroupList,
  },
  {
    label: "Inactive Group",
    options: InactiveGroupList,
  },
  ];
  response.data.data.map((group) => {
    if (group.status_id == '1') {
      ActiveGroupList.push({
        value: group.group_id,
        label: group.group_name,
        regionAreas:group.regionAreas,
        key : "group",
        members: group.Members
      });
    }
    else if (group.status_id == '2') {
      InactiveGroupList.push({
        value: group.group_id,
        label: group.group_name,
        regionAreas:group.regionAreas,
        key : "group",
        members: group.Members
      });
    }
  });
  return GroupList;
}

const prepareGroupStaffs = (response) => {
  let ActiveGroupList = [];
  let InactiveGroupList = [];

  let GroupList = [{
    label: "Active",
    options: ActiveGroupList,
  },
  {
    label: "Inactive",
    options: InactiveGroupList,
  },
  ]; 
  response.data.data.map((group) => {
    let proffession = group.professions;
    let staffData = [];
    if (group.Status.status_id === 1) {
      
      proffession.forEach((item) => {
           
        staffData.push(item.professions_type)
      });
      ActiveGroupList.push({
            value: group.user_id,
            label: group.name+' '+ '[ '+staffData.join(' , ')+' ]'
          });

    } else if (group.Status.status_id === 2) {
      proffession.forEach((item) => {
           
        staffData.push(item.professions_type)
      });
      InactiveGroupList.push({
            value: group.user_id,
            label: group.name+' '+ '[ '+staffData.join(' , ')+' ]'
          });
    }
  });
  
  return GroupList;
}

const prepareTimeSheetStaff = (response) => {
  let ActiveGroupList = [];
  let InactiveGroupList = [];

  let GroupList = [{
    label: "Active",
    options: ActiveGroupList,
  },
  {
    label: "Inactive",
    options: InactiveGroupList,
  },
  ]; 
  response.data.data.map((group) => {
    let proffession = group.professions;
    let staffData = [];
    if (group.Status.status_id === 1) {
      
      proffession.forEach((item) => {
           
        staffData.push(item.professions_type)
      });
      ActiveGroupList.push({
            value: group.user_id,
            label: group.name
          });

    } else if (group.Status.status_id === 2) {
      proffession.forEach((item) => {
           
        staffData.push(item.professions_type)
      });
      InactiveGroupList.push({
            value: group.user_id,
            label: group.name
          });
    }
  });
  
  return GroupList;
}

const prepareInspectorList = (response) => {
  let ActiveInspectorList = [];
  let InactiveInspectorList = [];
  let ActiveSupervisorList = [];
  let InactiveSupervisorList = [];

  let GroupList = [{
    label: "Active Inspector",
    options: ActiveInspectorList,
  },
  {
    label: "Inactive Inspector",
    options: InactiveInspectorList,
  },
  {
    label: "Active Supervisor",
    options: ActiveSupervisorList,
  },
  {
    label: "Inactive Supervisor",
    options: InactiveSupervisorList,
  }
  ];
  response.data.data.map((group) => {
        group.professions.forEach((prof) => {
          if(prof.profession_type_id == 2 || prof.profession_type_id == 7) {
            if (group.Status.status_id == '1') {
              ActiveInspectorList.push({
                value: group.user_id,
                label: group.name,
                key : 'inspector'
              })
            }
            if (group.Status.status_id == '2') {
              InactiveInspectorList.push({
                value: group.user_id,
                label: group.name,
                key : 'inspector'
              })
            }
          } 

          if(prof.profession_type_id === 3 || prof.profession_type_id === 8) {
            if (group.Status.status_id == '1') {
              ActiveSupervisorList.push({
                value: group.user_id,
                label: group.name,
                key : 'supervisor'
              })
            }
            if (group.Status.status_id == '2') {
              InactiveSupervisorList.push({
                value: group.user_id,
                label: group.name,
                key : 'supervisor'
              })
            }
          }
        })
  });
 
  return GroupList;
}

const prepareStaffs = (response) => {
  let staffList = [];
  response.data.data.map((staff) => {
    staffList.push({
      value: staff.user_id,
      label: `${staff.first_name} ${staff.last_name}`,
    });
  });
  return staffList;
}

const checkFilterFields = (filter) => {
  let staffFilter = prepareFilterUrl(filter);
  return staffFilter ? `?${staffFilter}` : '';
}

const prepareHouseKeeperList = (response,staffType) => {
  let hkGroupList = [];
  let hkList = [];

  let GroupList = {
    label: "Groups",
    options: hkGroupList,
  }
  
  let HousekeeperList = {
    label: "Housekeepers",
    options: hkList,
  }

  response.data.data.map((group) => {
    if(staffType==="hkGroup") {
      if(group.status_id === 1){
        hkGroupList.push({
          value: group.group_id,
          label: group.group_name,
          type: 'group',
          key:"hkGroup",
          members: group.Members
        });
      }
    }
    else if (staffType==="hk") {
      hkList.push({
        value: group.user_id,
        label: group.name,
        type: 'hk',
        key:"hk"
      });
    }
  });

  if(staffType==="hkGroup") return GroupList;
  if(staffType==="hk") return HousekeeperList;
  
}


const prepareFilterUrl = (filters) => {
  let searchUrl = '';

  for (let [key, value] of Object.entries(filters)) {
    if (value) {
      searchUrl += `filter[${key}]=${value}&`
    }


  }
  return searchUrl;
}

const setDispatchForStaff = (role, staffList, store) => {

  switch (role) {
    case 'HK':
      store.dispatch({
        type: 'SET_CL_HK_DROPDOWN',
        payload: staffList,
      });

      break;
    case 'INSP':
      store.dispatch({
        type: 'SET_CL_INSP_DROPDOWN',
        payload: staffList,
      });

      break;
    case 'SUP':
      store.dispatch({
        type: 'SET_SUP_DROPDOWN',
        payload: staffList,
      });
      break;
    case 'TM':
        store.dispatch({
          type: 'SET_TM_DROPDOWN',
          payload: staffList,
        });
        break;
    case 'INSPBULK':
      store.dispatch({
        type: 'SET_INSPBULK_DROPDOWN',
        payload: staffList,
      });
      break;

    case 'HA':
      store.dispatch({
        type: 'SET_HA_DROPDOWN',
        payload: staffList,
      });
    break;
    
    case 'TEAMBULK':
      store.dispatch({
        type: 'SET_TEAMBULK_DROPDOWN',
        payload: staffList,
      });
    break;

    case 'HABULK':
      store.dispatch({
        type: 'SET_HABULK_DROPDOWN',
        payload: staffList,
      });
    break;

    case 'WEE_SUP':
      store.dispatch({
        type: 'SET_WEESUP_DROPDOWN',
        payload: staffList,
      });
    break;
    
    default:
      store.dispatch({
        type: 'SET_ALLSTAFF_DROPDOWN',
        payload: staffList,
      });
      break;
  }
}

const prepareHouseKeeperWithAssignedCountList = (response,staffType) => {
  let hkGroupList = [];
  let hkList = [];

  let GroupList = {
    label: "Groups",
    options: hkGroupList,
  }
  
  let HousekeeperList = {
    label: "Housekeepers",
    options: hkList,
  }

  response.data.data.map((group) => {
    if(staffType==="hkGroup") {
      if(group.status_id === 1){
        hkGroupList.push({
          value: group.group_id,
          label: group.group_name,
          type: 'group',
          key:"hkGroup",
          members: group.Members,
          name: group.group_name,
        });
      }
    }
    else if (staffType==="hk") {
      hkList.push({
        value: group.user_id,
        label: group.name + ((group?.assigned_count || typeof group?.assigned_count === 'number') ? ` (${group.assigned_count})` : ''),
        type: 'hk',
        key:"hk",
        name: group.name,
      });
    }
  });

  if(staffType==="hkGroup") return GroupList;
  if(staffType==="hk") return HousekeeperList;
  
}


export default UserMiddleware;
