import { isRegionExists, isAreaExists, isValue, isArray } from "./utils";

export const filterHkAndGroupByRegionAreas = (
  housekeepersData,
  hkGroup,
  regionId,
  areaId
) => {
  let finalHkGroupList = [];
  if (hkGroup) {
    hkGroup[0]?.options.map((value, index) => {
      if (value.regionAreas) {
        if (
          isRegionExists(value.regionAreas, regionId) &&
          isAreaExists(value.regionAreas, areaId)
        ) {
          finalHkGroupList.push({
            value: value.value,
            label: value.label,
            key: "group",
            members: value.members
          });
        } else if (
          isRegionExists(value.regionAreas, regionId) &&
          !isValue(areaId)
        ) {
          finalHkGroupList.push({
            value: value.value,
            label: value.label,
            key: "group",
            members: value.members
          });
        } else if (
          isAreaExists(value.regionAreas, areaId) &&
          !isValue(regionId)
        ) {
          finalHkGroupList.push({
            value: value.value,
            label: value.label,
            key: "group",
            members: value.members
          });
        }
      }
    });
  }
  const finalHkList = filterStaffByRegionAreas(
    housekeepersData,
    regionId,
    areaId
  );
  let finalHkAndGroupList = [
    {
      label: "Housekeeper",
      options: finalHkList,
    },
    {
      label: "Group",
      options: finalHkGroupList,
    }
  ];
  return finalHkAndGroupList;
};

export const filterStaffByRegionAreas = (staffList, regionId, areaId) => {
  let finalStaffList = [];
  if (staffList) {
    staffList.map((value, index) => {
      if (value.regionAreas) {
        if (
          isRegionExists(value.regionAreas, regionId) &&
          isAreaExists(value.regionAreas, areaId)
        ) {
          finalStaffList.push({ value: value.user_id, label: value.name, key: "hk" });
        } else if (
          isRegionExists(value.regionAreas, regionId) &&
          !isValue(areaId)
        ) {
          finalStaffList.push({ value: value.user_id, label: value.name, key: "hk"  });
        } else if (
          isAreaExists(value.regionAreas, areaId) &&
          !isValue(regionId)
        ) {
          finalStaffList.push({ value: value.user_id, label: value.name, key: "hk"  });
        }
      }
    });
  }
  return finalStaffList;
};

export const getGlobalAreaRegionValues = (areas,regions) => {
  let area_list = [];
  let region_list = [];
  if(areas.length>0) {
      let allAreas = [...areas];
      allAreas.forEach(area => area_list.push(area.value))
  }
  if(regions.length>0) {
      let allRegions = [...regions];
      allRegions.forEach(region => region_list.push(region.value))
  }

  return [area_list.join(","),region_list.join(",")]
}

export const filterOnDutyStaff = (staffList, regionId, areaId, serviceDate) => {
  let newStaffList = staffList ? JSON.parse(JSON.stringify(staffList)) : []
  let finalOnDutyStaffList = [];
  staffList && staffList.map((value, index) => {
    if(value.label == "On Duty") {
      if(!isArray(value.options)) {
        finalOnDutyStaffList.push({label: "Not Available", value: '', disabled: true})
      } else {
        value.options.map(staff => {
          if(serviceDate==staff.scheduleDate && staff.userRegion) {
            if (
              isRegionExists(staff.userRegion, regionId) &&
              isAreaExists(staff.userRegion, areaId)
            ) {
              finalOnDutyStaffList.push({ value: staff.value, label: staff.label });
            } else if (
              isRegionExists(staff.userRegion, regionId) &&
              !isValue(areaId)
            ) {
              finalOnDutyStaffList.push({ value: staff.value, label: staff.label });
            } else if (
              isAreaExists(staff.userRegion, areaId) &&
              !isValue(regionId)
            ) {
              finalOnDutyStaffList.push({ value: staff.value, label: staff.label });
            }
          }
        })
      }
    }
  })
  !isArray(finalOnDutyStaffList) && finalOnDutyStaffList.push({label: "Not Available", value: '', disabled: true})
  if(isArray(newStaffList)) newStaffList[0]['options'] = [...finalOnDutyStaffList]
  return newStaffList
}

export const filterOnDutyStaffByRegionAreas = (staffList, regionId, areaId, serviceDate) => {
  let newStaffList = JSON.parse(JSON.stringify(staffList))
  let finalOnDutyStaffList = [];
  let finalStaffList = [];
  if (newStaffList) {
    newStaffList?.map((value, index) => {
      if(value.label == "On Duty") {
        if(!isArray(value.options)) {
          finalOnDutyStaffList.push({label: "Not Available", value: '', disabled: true})
        } else {
          value.options.map(staff => {
            if(serviceDate==staff.scheduleDate && staff.userRegion) {
              if (
                isRegionExists(staff.userRegion, regionId) &&
                isAreaExists(staff.userRegion, areaId)
              ) {
                finalOnDutyStaffList.push({ value: staff.value, label: staff.label });
              } else if (
                isRegionExists(staff.userRegion, regionId) &&
                !isValue(areaId)
              ) {
                finalOnDutyStaffList.push({ value: staff.value, label: staff.label });
              } else if (
                isAreaExists(staff.userRegion, areaId) &&
                !isValue(regionId)
              ) {
                finalOnDutyStaffList.push({ value: staff.value, label: staff.label });
              }
            }
          })
        }

      } else {
        value?.options?.map(staff => {
          
          if (staff.regionAreas) {
            if (
              isRegionExists(staff.regionAreas, regionId) &&
              isAreaExists(staff.regionAreas, areaId)
            ) {
              finalStaffList.push({ value: staff.user_id, label: staff.name });
            } else if (
              isRegionExists(staff.regionAreas, regionId) &&
              !isValue(areaId)
            ) {
              finalStaffList.push({ value: staff.user_id, label: staff.name });
            } else if (
              isAreaExists(staff.regionAreas, areaId) &&
              !isValue(regionId)
            ) {
              finalStaffList.push({ value: staff.user_id, label: staff.name });
            }
          }
        })
      }
    });
    !isArray(finalOnDutyStaffList) && finalOnDutyStaffList.push({label: "Not Available", value: '', disabled: true})
    if(isArray(newStaffList)){
      newStaffList[0]['options'] = [...finalOnDutyStaffList]
      newStaffList[1]['options'] = [...finalStaffList]
    }
    return newStaffList
  }
 
};

export const filterOnDutyStaffByDate = (staffList, serviceDate) => {
  let newStaffList = JSON.parse(JSON.stringify(staffList))
  let finalOnDutyStaffList = [];
  let finalStaffList = [];

  if (newStaffList) {
    newStaffList.map((value, index) => {
      if(value.label == "On Duty") {
        if(!isArray(value.options)) {
          finalOnDutyStaffList.push({label: "Not Available", value: '', disabled: true})
        } else {
          value.options.map(staff => {
            if(serviceDate == staff.scheduleDate) {
              finalOnDutyStaffList.push({ value: staff.value, label: staff.label });
            }
          })
        }
      } else {
        finalStaffList = [...value.options];
      }
    });
    !isArray(finalOnDutyStaffList) && finalOnDutyStaffList.push({label: "Not Available", value: '', disabled: true})
    if(isArray(newStaffList)) {
      newStaffList[0]['options'] = [...finalOnDutyStaffList]
      newStaffList[1]['options'] = [...finalStaffList]
    }
    return newStaffList;
  }
};
