import APIservice from "../apiService";
import { isLoader } from "../global/action";
import { microServices } from "../../config/global";
import { setError } from "../form/action";
/**
 * ApiMiddleware can be used to call any Service related API services
 * @param {*} store 
 * @returns 
 */
const ServiceMiddleware = (store) => (next) => async (action) => {
    const result    = next(action);
    const endpoint  = microServices.base+'/v1/company/service';
    const checklistEndpoint = microServices.inspection+'/v1/service-check-list';
    let per_page_condition = '';
    switch (action.type) {
        case "CREATE_SERVICE":
            store.dispatch(isLoader(true));
              APIservice
                .post(endpoint+'/'+action.payload.s_type, action.payload.formData)
                .then((response)=>{
                  store.dispatch({ 
                    type: 'SHOW_ALERT', 
                    payload: {
                      variant:'success',
                      message: action.payload.successMessage,
                      componentName:'AddService'
                    }
                  });
                })
                .catch((error)=>{
                  if (error.response.status !== 422) {
                    store.dispatch({ 
                      type: 'SHOW_ALERT', 
                      payload: {
                        variant:'danger',
                        message: action.payload.failMessage
                      }
                    });
                  } else {
                    let err = {componentName:'AddService',statusCode: error.response.status,data: error.response.data};
                    store.dispatch(setError(err));
                  }
                })
                .finally(()=>{
                  store.dispatch(isLoader(false));
                })
            break;
          case "FETCH_SERVICE":
          store.dispatch(isLoader(true));
          APIservice
            .get(endpoint+'/'+action.payload.id)
            .then((response)=>{
              if(response && response.data){
                store.dispatch({ 
                type: 'SET_DATA_FOR_EDIT', 
                payload: {
                  data:response.data.data
                  }
                });
              }
            })
            .catch((error)=>{
              let err = {componentName:'Service',statusCode: error.response.status,data: error.response.data};
              store.dispatch(setError(err));
            })
            .finally(()=>{
              store.dispatch(isLoader(false));
            })
        break;
        case "UPDATE_SERVICE":
          store.dispatch(isLoader(true));
          APIservice
            .put(endpoint+'/'+action.payload.s_type+'/'+action.payload.id, action.payload.formData)
            .then((response)=>{
              store.dispatch({ 
                type: 'SHOW_ALERT', 
                payload: {
                  variant:'success',
                  message: action.payload.successMessage,
                  componentName:'EditService'
                }
              });
            })
            .catch((error)=>{
              if (error.response.status !== 422) {
                store.dispatch({ 
                  type: 'SHOW_ALERT', 
                  payload: {
                    variant:'danger',
                    message: action.payload.failMessage,
                  }
                });
              } else {
                let err = {componentName:'EditService',statusCode: error.response.status,data: error.response.data};
                store.dispatch(setError(err));
              }
            })
            .finally(()=>{
              store.dispatch(isLoader(false));
            })
            break;

            case "ACTIVE_INACTIVE_SERVICE":
          store.dispatch(isLoader(true));
          APIservice
            .put(`${endpoint}/${action.payload.apiName}/${action.payload.id}`)
            .then((response)=>{
              store.dispatch({ 
                type: 'SHOW_ALERT', 
                payload: {
                  variant:'success',
                  message: action.payload.successMessage,
                  componentName:'ActiveInactiveService'
                }
              });
            })
            .catch((error)=>{
              if (error.response.status !== 422) {
                store.dispatch({ 
                  type: 'SHOW_ALERT', 
                  payload: {
                    variant:'danger',
                    message: action.payload.failMessage,
                  }
                });
              } else {
                  let err = {componentName:'ActiveInactiveService',statusCode: error.response.status,data: error.response.data};
                  store.dispatch(setError(err));
                }
            })
            .finally(()=>{
              store.dispatch(isLoader(false));
            })
        break;
        case "FETCH_ACTIVE_SERVICES":
          let serviceEndpoint  = microServices.base+'/v1/company/services?per_page=0&filter[status_id]=1';
          if (action.payload?.customFilterModel) {
            let filterKeys = Object.keys(action.payload?.customFilterModel);
            filterKeys.forEach((filter) => {
              serviceEndpoint += `&filter[${filter}]=${action.payload?.customFilterModel[filter]}&`;
            });
          }
          if(action?.payload?.sort){
            serviceEndpoint += '&'+ action.payload.sort;
          }
          store.dispatch(isLoader(true));
          APIservice
            .get(serviceEndpoint)
            .then((response)=>{
              if(response && response.data){
                let serviceList = [];
                response.data.data.forEach((service) => {
                  serviceList.push({
                    value: service.id,
                    label: service.name,
                    ha_task : service?.tasks?.length,
                    created_by_com : service?.created_by_company,
                    created_by_company: service?.createdByCompany?.id,
                    is_access : !service?.is_access,
                    company_id: service?.company?.id
                  });
                });
                
                if(action.payload?.customFilterModel && action.payload?.customFilterModel['is_automation']){
                  store.dispatch({ 
                    type: 'SET_AUTOMATION_SERVICE_LIST', 
                    payload: {
                      data:serviceList
                      }
                    });
                }
                else{
                  store.dispatch({ 
                  type: 'SET_SERVICE_LIST', 
                  payload: {
                    data:serviceList
                    }
                  });

                }
              }
            })
            .catch((error)=>{
              let err = {componentName:'Service',statusCode: error.response?.status,data: error.response?.data};
              store.dispatch(setError(err));
            })
            .finally(()=>{
              store.dispatch(isLoader(false));
            })
            break;

            case "CHECKLIST_DROPDOWN_WITH_REGION_AREA":
              let endpointNew = microServices.inspection+'/v1/dropdown/service-check-lists?per_page=0';              
              if (action.payload.customFilterModel) {
                endpointNew += '&';
                let filterKeys = Object.keys(action.payload.customFilterModel);
                filterKeys.forEach((filter) => {
                  endpointNew += `filter[${filter}]=${action.payload.customFilterModel[filter]}&`;
                });
              }
              APIservice
              .get(endpointNew)
              .then((response)=>{
                  store.dispatch({ 
                    type: 'SET_CHECKLIST_DATA', 
                    payload: response.data.data,
                  });
              })
              .catch((error)=>{
                if (error.response.status !== 422) {
                  store.dispatch({ 
                    type: 'SET_CHECKLIST_DATA', 
                    payload: [],
                  });
                } 
              })
            break; 
            case "ACTIVE_INACTIVE_SERVICE_CHECKLIST_TEMPLATE":
              store.dispatch(isLoader(true));
              const activeInactiveEndpoint = microServices.inspection+`${action.endpoint}/${action.payload.apiName}/${action.payload.id}`;
              APIservice
              .put(activeInactiveEndpoint)
              .then((response)=>{
                store.dispatch({ 
                  type: 'SHOW_ALERT', 
                  payload: {
                  variant:'success',
                  message: `Template ${(action.payload.apiName === 'inactivate')?'deactivated':'activated'} successfully!`,
                  componentName:action.componentName
                  }
                });
              })
              .catch((error)=>{
                if (error.response.status !== 422) {
                    store.dispatch({ 
                    type: 'SHOW_ALERT', 
                    payload: {
                        variant:'danger',
                        message: `Unable to ${action.payload.apiName} template!`
                    }
                    });
                } else {
                    let err = {componentName:action.componentName,statusCode: error.response.status,data: error.response.data};
                    store.dispatch(setError(err));
                    }
              })
              .finally(()=>{
                store.dispatch(isLoader(false));
              })
            break;
            case "CREATE_SERVICE_CHECKLIST_TEMPLATE":
              const createEndpoint  = microServices.inspection + action.endpoint;
              store.dispatch(isLoader(true));
              APIservice
              .postMultipartData(createEndpoint, action.payload.formData)
              .then((response)=>{
                if(response.data.data.checklist_template_id) {
                  if(action.payload.alertName === 'upload'){
                    store.dispatch({ 
                      type: 'ASSIGN_CHECKLIST_TEMPLATE_REGION_AREA', 
                      payload: { 
                          'formData':action.payload.formData,
                          'checklist_template_id':response.data.data.checklist_template_id,
                      }
                    });
                    store.dispatch({ 
                      type: 'INITIALIZE_CHECKLIST_TEMPLATE_UPLOAD', 
                      payload: {
                          'checklist_template_id': response.data.data.checklist_template_id,
                          'assignData': action.payload.formData
                      }
                    });
                  }
                  store.dispatch({ 
                    type: 'FETCH_SERVICE_CHECKLIST_TEMPLATE_DETAIL', 
                    payload: {
                      checklist_template_id:response.data.data.checklist_template_id,
                    }
                  });
                  store.dispatch({ 
                    type: 'SHOW_ALERT', 
                    payload: {
                      componentName: (action.payload.alertName === 'upload') ?  'UploadServiceChecklistTemplate' : 'AddServiceChecklistTemplate',
                      variant:'success',
                      message: `Service Checklist Template ${action.payload.alertName === 'upload' ? 'Uploaded' : 'Added'} Successfully!`
                    }
                  });
                }
              })
              .catch((error)=>{
                if (error.response.status !== 422) {
                  store.dispatch({ 
                    type: 'SHOW_ALERT', 
                    payload: {
                      variant:'danger',
                      message: 'Unable to save staff information!'
                    }
                  });
                } else {
                  let err = {componentName:action.componentName,statusCode: error.response.status,data: error.response.data};
                  store.dispatch(setError(err));
                }
              })
              .finally(()=>{
                store.dispatch(isLoader(false));
              })
            break;
            case "FETCH_SERVICE_CHECKLIST_TEMPLATE_DETAIL":
              store.dispatch(isLoader(true));
              APIservice
              .get(checklistEndpoint+'/'+action.payload.checklist_template_id)
              .then((response)=>{
                if(response && response.data){
                    store.dispatch({ 
                        type: 'SET_CHECKLIST_TEMPLATE_DETAILS', 
                        payload: {
                            data:response.data.data
                        }
                    });
                }
              })
              .catch((error)=>{
                  let err = {componentName:'ViewServiceChekclistTemplate',statusCode: error.response.status,data: error.response.data};
                  store.dispatch(setError(err));
              })
              .finally(()=>{
                  store.dispatch(isLoader(false));
              })
            break;
            case "UPDATE_SERVICE_CHECKLIST_TEMPLATE":
              store.dispatch(isLoader(true));
              APIservice
              .put(checklistEndpoint+'/'+action.payload.checklist_template_id, action.payload.formData)
              .then((response)=>{
                store.dispatch({ 
                    type: 'SHOW_ALERT', 
                    payload: {
                        variant:'success',
                        message: 'Template updated successfully!',
                        componentName:'EditServiceChekclistTemplate'
                    }
                });
                store.dispatch({ 
                    type: 'SET_DATA_FOR_EDIT', 
                    payload: {
                        data:response.data.data,
                    }
                });
              })
              .catch((error)=>{
                if (error.response.status !== 422) {
                    store.dispatch({ 
                        type: 'SHOW_ALERT', 
                        payload: {
                            variant:'danger',
                            message: 'Unable to save template information!',
                        }
                    });
                } else {
                    let err = {componentName:'EditServiceChekclistTemplate',statusCode: error.response.status,data: error.response.data};
                    store.dispatch(setError(err));
                }
              })
              .finally(()=>{
                store.dispatch(isLoader(false));
              })
            break;
            case "FETCH_SERVICE_CHECKLIST_TEMPLATE_ITEMS":
              per_page_condition = action.payload.per_page ? '?per_page='+action.payload.per_page : "";
              store.dispatch(isLoader(true));
              APIservice
              .get(checklistEndpoint+'/'+action.payload.checklist_template_id+'/items'+per_page_condition)
              .then((response)=>{
                if(response && response.data){
                    store.dispatch({ 
                        type: 'SET_SERVICE_CHECKLIST_TEMPLATE_ITEMS', 
                        payload: {
                            data:response.data.data
                        }
                    });
                }
              })
              .catch((error)=>{
                let err = {componentName:'ChecklistTemplateItems',statusCode: error.response.status,data: error.response.data};
                store.dispatch(setError(err));
              })
              .finally(()=>{
                store.dispatch(isLoader(false));
              })
            break;
            case "CREATE_SERVICE_CHECKLIST_TEMPLATE_ITEM":
              store.dispatch(isLoader(true));
              APIservice
              .post(checklistEndpoint+'/'+action.payload.checklist_template_id+'/item',
              action.payload.formData)
              .then((response)=>{
                store.dispatch({ 
                    type: 'SHOW_ALERT', 
                    payload: {
                        variant:'success',
                        message: 'Item created successfully!',
                        componentName:'AddChecklistTemplateItem'
                    }
                });
              })
              .catch((error)=>{
                if (error.response.status !== 422) {
                    store.dispatch({ 
                        type: 'SHOW_ALERT', 
                        payload: {
                            variant:'danger',
                            message: 'Unable to save item information!',
                        }
                    });
                } else {
                    let err = {componentName:'AddChecklistTemplateItem',statusCode: error.response.status,data: error.response.data};
                    store.dispatch(setError(err));
                }
              })
              .finally(()=>{
                store.dispatch(isLoader(false));
              })
            break;
            case "UPDATE_SERVICE_CHECKLIST_TEMPLATE_ITEM":
              store.dispatch(isLoader(true));
              APIservice
              .put(checklistEndpoint+'/'+action.payload.checklist_template_id+'/item/'+action.payload.checklist_item_id, 
              action.payload.formData)
              .then((response)=>{
                store.dispatch({ 
                    type: 'SHOW_ALERT', 
                    payload: {
                        variant:'success',
                        message: 'Item updated successfully!',
                        componentName:'EditChecklistTemplateItem'
                    }
                });
              })
              .catch((error)=>{
                if (error.response.status !== 422) {
                    store.dispatch({ 
                        type: 'SHOW_ALERT', 
                        payload: {
                            variant:'danger',
                            message: 'Unable to save item information!',
                        }
                    });
                } else {
                    let err = {componentName:'EditChecklistTemplateItem',statusCode: error.response.status,data: error.response.data};
                    store.dispatch(setError(err));
                }
              })
              .finally(()=>{
                store.dispatch(isLoader(false));
              })
            break;
            case "DELETE_SERVICE_CHECKLIST_TEMPLATE_ITEM":
              store.dispatch(isLoader(true));
              APIservice
              .delete(checklistEndpoint+'/item/'+action.payload.checklist_item_id)
              .then((response)=>{
                store.dispatch({ 
                  type: 'SHOW_ALERT', 
                  payload: {
                    variant:'success',
                    message: 'Item deleted successfully.',
                    componentName:'DeleteChecklistTemplateItem'
                  }
                });
              })
              .catch((error)=>{
                if (error.response.status !== 422) {
                  store.dispatch({
                    type: 'SHOW_ALERT', 
                    payload: {
                      variant:'danger',
                      message: 'Unable to delete Item!'
                    }
                  });
                } else {
                  let err = {componentName:'DeleteChecklistTemplateItem',statusCode: error.response.status,data: error.response.data};
                  store.dispatch(setError(err));
                }
              })
              .finally(()=>{
                store.dispatch(isLoader(false));
              })
            break;
            case "UPDATE_CHECKLIST_TEMPLATE_ITEM_DISPLAY_ORDER":
              store.dispatch(isLoader(true));
              APIservice
              .post(checklistEndpoint+'/'+action.payload.checklist_template_id+'/item/display-order', 
              action.payload.formData)
              .then((response)=>{
                store.dispatch({ 
                    type: 'SHOW_ALERT', 
                    payload: {
                        variant:'success',
                        message: 'Item Display Order updated successfully!',
                        componentName:'UpdateChecklistTemplateItemDisplayOrder'
                    }
                });
              })
              .catch((error)=>{
                if (error.response.status !== 422) {
                    store.dispatch({ 
                        type: 'SHOW_ALERT', 
                        payload: {
                            variant:'danger',
                            message: 'Unable to save item display order information!',
                        }
                    });
                } else {
                    let err = {componentName:'UpdateChecklistTemplateItemDisplayOrder',statusCode: error.response.status,data: error.response.data};
                    store.dispatch(setError(err));
                }
              })
              .finally(()=>{
                store.dispatch(isLoader(false));
              })
            break;
            case "INITIALIZE_CHECKLIST_ITEM_PHOTOS":
              store.dispatch(isLoader(true));
              APIservice
              .post(checklistEndpoint+'/item/image', action.payload)
                .then((response)=>{
                  store.dispatch({ 
                    type: 'UPLOAD_CHECKLIST_ITEM_PHOTOS',
                    payload: { 
                      'uploadUrl':response.data.data.upload,
                      'uploadImagedata':action.payload
                    }
                    });
                })
                .catch((error)=>{
                  let err = {componentName:'ChecklistItemPhotos',statusCode: error.response.status,data: error.response.data};
                  store.dispatch(setError(err));
                })
                .finally(()=>{
                  store.dispatch(isLoader(false));
                })
            break;
            case "UPLOAD_CHECKLIST_ITEM_PHOTOS":
              store.dispatch(isLoader(true));
              APIservice
              .post(action.payload.uploadUrl,action.payload.uploadImagedata)
              .then((response)=>{
                store.dispatch({ 
                    type: 'SHOW_ALERT', 
                    payload: {
                      variant:'success',
                      message: 'Photo uploaded successfully.',
                      componentName:'UploadChecklistItemPhotos'
                    } 
                });
              })
              .catch((error)=>{
                if (error.response.status !== 422) {
                  store.dispatch({ 
                    type: 'SHOW_ALERT', 
                    payload: {
                      variant:'danger',
                      message: 'Unable to Add Checklist Item Photo!'
                    }
                  });
                } else {
                  let err = {componentName:action.componentName,statusCode: error.response.status,data: error.response.data};
                  store.dispatch(setError(err));
                }
              }).finally(()=>{
                  store.dispatch(isLoader(false));
              })
            break;
            case "DELETE_CHECKLIST_ITEM_IMAGE":
              store.dispatch(isLoader(true));
              APIservice
              .delete(checklistEndpoint+'/item/image/'+action.payload.checklist_item_id)
              .then((response)=>{
                store.dispatch({ 
                  type: 'SHOW_ALERT', 
                  payload: {
                    variant:'success',
                    message: 'Photo deleted successfully.',
                    componentName:'DeleteChecklistItemImage'
                  }
                });
              })
              .catch((error)=>{
                if (error.response.status !== 422) {
                  store.dispatch({ 
                    type: 'SHOW_ALERT', 
                    payload: {
                      variant:'danger',
                      message: 'Unable to delete item image!'
                    }
                  });
                } else {
                  let err = {componentName:'DeleteChecklistItemImage',statusCode: error.response.status,data: error.response.data};
                  store.dispatch(setError(err));
                }
              })
              .finally(()=>{
                store.dispatch(isLoader(false));
              })
            break;
            case "VIEW_CHECKLIST_TEMPLATE_REGION_AREA_ACCESS":
              store.dispatch(isLoader(true));
              APIservice
              .get(checklistEndpoint+'/region-area/'+action.payload.checklist_template_id)
              .then((response)=>{
                if(response && response.data){
                    store.dispatch({ 
                        type: 'SET_TEMPLATE_REGION_AREA_ACCESS', 
                        payload: {
                            data:response.data
                        }
                    });
                }
              })
              .catch((error)=>{
                let err = {componentName:"ChecklistTemplateRegionArea",statusCode: error.response.status,data: error.response.data};
                store.dispatch(setError(err));
              })
              .finally(()=>{
                store.dispatch(isLoader(false));
              })
            break;
            case "UPDATE_CHECKLIST_TEMPLATE_REGION_AREA":
              store.dispatch(isLoader(true));
              APIservice
              .post(checklistEndpoint+'/region-area/update/'+action.payload.checklist_template_id, action.payload.formData)
              .then((response)=>{
                store.dispatch({ 
                    type: 'SHOW_ALERT', 
                    payload: {
                        variant:'success',
                        message: "Region Area updated successfully",
                        componentName:"UpdateChecklistTemplateRegionArea"
                    }
                });
              })
              .catch((error)=>{
                let err = {componentName:"UpdateChecklistTemplateRegionArea",statusCode: error.response.status,data: error.response.data};
                store.dispatch(setError(err));
              }).finally(()=>{
                store.dispatch(isLoader(false));
              })
            break;
            case "ASSIGN_CHECKLIST_TEMPLATE_REGION_AREA":
              store.dispatch(isLoader(true));
              APIservice
              .post(checklistEndpoint+'/region-area/assign/'+action.payload.checklist_template_id, action.payload.formData)
              .then((response)=>{
                store.dispatch({ 
                type: 'SHOW_ALERT', 
                payload: {
                    variant:'success',
                    message: action.message,
                    componentName:action.componentName
                }
                });
              })
              .catch((error)=>{
                let err = {componentName:action.componentName,statusCode: error.response.status,data: error.response.data};
                store.dispatch(setError(err));
              }).finally(()=>{
                store.dispatch(isLoader(false));
              })
            break;
            case "INITIALIZE_CHECKLIST_TEMPLATE_UPLOAD":
              store.dispatch(isLoader(true));
              APIservice
              .postMultipartData(`${checklistEndpoint}/upload/${action.payload.checklist_template_id}`, action.payload.assignData)
              .then((response)=>{
                  store.dispatch({ 
                    type: 'UPLOAD_TEMPLATE',
                    payload: { 
                      'uploadUrl':response.data.data.upload,
                      'uploadFiledata':action.payload.assignData
                    }
                  });
              })
              .catch((error)=>{
                  let err = {componentName:'Upload_Template',statusCode: error.response.status,data: error.response.data};
                  store.dispatch(setError(err));
                  store.dispatch(isLoader(false));
              });
            break;
            case "UPLOAD_CHECKLIST_TEMPLATE_ITEM":
              store.dispatch(isLoader(true));
              APIservice
              .postMultipartData(action.payload.uploadUrl,action.payload.uploadFiledata)
              .catch((error)=>{
                let err = {componentName:action.componentName,statusCode: error.response.status,data: error.response.data};
                store.dispatch(setError(err));
              }).finally(()=>{
                store.dispatch(isLoader(false));
              })
            break;     
            case "FETCH_MANAGER_SERVICES":
              let mangerEndpoint  = microServices.base+'/v1/company/services/manager-wise?per_page=0';
              if (action.payload?.customFilterModel) {
                let filterKeys = Object.keys(action.payload?.customFilterModel);
                mangerEndpoint += '&';
                filterKeys.forEach((filter) => {
                  mangerEndpoint += `filter[${filter}]=${action.payload?.customFilterModel[filter]}&`;
                });
              }
              if(action?.payload?.sort){
                mangerEndpoint += '&'+ action.payload.sort;
              }
              store.dispatch(isLoader(true));
              APIservice
                .get(mangerEndpoint)
                .then((response)=>{
                  if(response && response.data){
                    let serviceList = [];
                    response.data.data.forEach((service) => {
                      if(service?.name != 'Client Service'){
                        serviceList.push({
                          value: service.id,
                          label: service.name,
                          cleaner_id:service?.company?.id,
                          created_by_company:service?.createdByCompany?.id
                          
                
                         });
                      }
                      
                    });
                    if(action.payload?.customFilterModel['is_automation']){
                      store.dispatch({ 
                        type: 'SET_AUTOMATION_MANAGER_SERVICE_LIST', 
                        payload: {
                          data:serviceList
                          }
                        });
                    }
                    else{
                      store.dispatch({ 
                      type: 'SET_SERVICE_MANAGER_LIST', 
                      payload: {
                        data:serviceList
                        }
                      });
    
                    }
                  }
                })
                .catch((error)=>{
                  let err = {componentName:'Service',statusCode: error.response.status,data: error.response.data};
                  store.dispatch(setError(err));
                })
                .finally(()=>{
                  store.dispatch(isLoader(false));
                })
              break;
              case "PROPERTY_SERVICE_EXPORT":
                store.dispatch(isLoader(true));
                    APIservice.
                    getBlobDataWithParams(action.payload.exportApi)
                    .then((response)=>{
                      store.dispatch(isLoader(false));
                        const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
                          const link = document.createElement('a');
                          link.href = blobUrl;
                          link.setAttribute('download', `${(action.payload?.fileName) ? action.payload.fileName : "propertyService"}_${Date.now()}.xlsx`);
                          document.body.appendChild(link);
                          link.click(); 
                          link.parentNode.removeChild(link);
                    })
                    .catch((error)=>{
                      store.dispatch(isLoader(false));
                        if (error.response.status !== 422) {
                            store.dispatch({ 
                                type: 'SHOW_ALERT', 
                                payload: {
                                    variant:'danger',
                                    message: 'Unable to Download Report!',
                                }
                            });
                        } else {
                            let err = {componentName:'ExportPropertyService',statusCode: error.response.status,data: error.response.data};
                            store.dispatch(setError(err));
                        }
                    })
                    .finally(()=>{
                    });
                    break;
                    case "SKIP_PROPERTY_SERVICE_EXPORT":
                      store.dispatch(isLoader(true));
                      APIservice.
                      getBlobDataWithParams(action.payload)
                      .then((response)=>{
                        store.dispatch(isLoader(false));
                          const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
                            const link = document.createElement('a');
                            link.href = blobUrl;
                            link.setAttribute('download', `${(action.payload?.fileName) ? action.payload.fileName : "skipedPropertyService"}_${Date.now()}.xlsx`);
                            document.body.appendChild(link);
                            link.click(); 
                            link.parentNode.removeChild(link);
                      })
                      .catch((error)=>{
                        store.dispatch(isLoader(false));
                          if (error.response.status !== 422) {
                              store.dispatch({ 
                                  type: 'SHOW_ALERT', 
                                  payload: {
                                      variant:'danger',
                                      message: 'Unable to Download Report!',
                                  }
                              });
                          } else {
                              let err = {componentName:'ExportSkipPropertyService',statusCode: error.response.status,data: error.response.data};
                              store.dispatch(setError(err));
                          }
                      })
                      .finally(()=>{
                      });
                      break;
                      case "CLONE_SERVICE_TEMPLATE":
                        store.dispatch(isLoader(true));
                        APIservice
                          .post(checklistEndpoint+'/'+action.payload.id+'/clone',action.payload.formData)
                          .then((response)=>{
                            store.dispatch({ 
                              type: 'SHOW_ALERT', 
                              payload: { 
                                variant:'success',
                                message: action.message,
                                componentName:action.componentName
                              }
                              });
                          })
                          .catch((error)=>{
                              if (error.response.status !== 422) {
                              store.dispatch({ 
                                  type: 'SHOW_ALERT', 
                                  payload: {
                                  variant:'danger',
                                  message: 'Unable to clone Template!'
                                  }
                              });
                              } else {
                              let err = {componentName:action.componentName,statusCode: error.response.status,data: error.response.data};
                              store.dispatch(setError(err));
                              }
                          }).finally(()=>{
                              store.dispatch(isLoader(false));
                          })
                    break;
                    case "FETCH_HHS_MANAGERS":
                      let api  = microServices.base+'/v1/dropdown/hhs-manager?per_page=0';
                      store.dispatch(isLoader(true));
                      APIservice
                        .get(api)
                        .then((response)=>{
                          if(response && response.data){
                            let hhsManagerList = [];
            
                            response.data.data.forEach((list) => {  
                              hhsManagerList.push({
                                value: list?.customer_id,
                                label: list?.company_name,
                                company_id : list?.company_id
                              });
                            });
                              store.dispatch({ 
                              type: 'SET_HHS_MANAGERS', 
                              payload:hhsManagerList
                            });
                          }
                        })
                        .catch((error)=>{
                          let err = {componentName:'ServiceHhsmanagerList',statusCode: error.response?.status,data: error.response?.data};
                          store.dispatch(setError(err));
                        })
                        .finally(()=>{
                          store.dispatch(isLoader(false));
                        })
                        break;
                        case "EXPORT_SERVICE_TRACKER_REPORT":
                          let serviceTrackerapi = action.payload.exportApi;
                          store.dispatch(isLoader(true));
                          APIservice.
                          getBlobDataWithParams(serviceTrackerapi,"")
                          .then((response)=>{
                              const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
                              const link = document.createElement('a');
                              link.href = blobUrl;
                              link.setAttribute('download', `${(action.payload?.fileName) ? action.payload.fileName : `Services_Report_${Date.now()}`}.csv`);
                              document.body.appendChild(link);
                              link.click(); 
                              link.parentNode.removeChild(link);
                              store.dispatch({ 
                                type: 'SHOW_ALERT', 
                                payload: { 
                                  variant:'success',
                                  message: 'Service Tracker Report downloaded successfully.',
                                  componentName:"ServiceTrackerReport"
                                }
                              });
                          })
                          .catch((error)=>{
                            if (error.response.status !== 422) {
                                store.dispatch({ 
                                    type: 'SHOW_ALERT', 
                                    payload: {
                                        variant:'danger',
                                        message: 'Unable to Download Report!',
                                    }
                                });
                            } else {
                                let err = {componentName:'ServiceTrackerReport',statusCode: error.response.status,data: error.response.data};
                                store.dispatch(setError(err));
                            }
                          })
                          .finally(()=>{
                              store.dispatch(isLoader(false));
                          });
                        break;
            default:
            break;
    }
    return result;
};

export default ServiceMiddleware;