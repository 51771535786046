import { microServices } from "../config/global";
import apiService from "../redux/apiService";


export async function verifyInvoiceToken(endpoint, formData) {
    return await apiService.withoutKeycloakTokenPost(endpoint, formData).then((response) => {
        return response;
    })
}

export async function verifyInvoiceOtp(endpoint, formData) {
    return await apiService.withoutKeycloakTokenPost(endpoint, formData).then((response) => {
        return response;
    })
}

export function checkIsInvoicePayable(endpoint) {
    return apiService.get(endpoint);
}

export async function getServiceWithoutAuth(endpoint, formData) {
    return await apiService.withoutKeycloakTokenGet(endpoint, formData).then((response) => {
        return response;
    })
}
