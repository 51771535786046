import React,{useContext, useEffect, useState} from 'react';
import Loader from '../atoms/Loader';
import { BrowserRouter, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import Style from './App.scss';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import keycloak from '../../keycloak';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ScrollButton from '../atoms/ScrollButton';
import { LanguageProvider } from '../containers/Language';
import { AppRouter } from './AppRouter';
import { Text, LanguageContext } from '../containers/Language';
import Invoicenotification from '../molecules/InvoiceNotification';
import { browserName, browserVersion, isSafari} from "react-device-detect";
import { fetchLeftSidebarCount } from '../../redux/company/action';
import OneSignal from 'react-onesignal';
import { getInventorySmartCredential } from "../../redux/inventorySmart/action";
import { getCompanyWallet, getQuickBook } from '../../redux/Billing/action';
import { getCallbackInspectionCount } from '../../redux/inspection/action';
import { isLoader, updateGlobalFilter } from '../../redux/global/action';
import { logout } from '../../redux/authentication/action';

let initialValue = 1;

function App(props) {

  const dispatch = useDispatch();
  const auth = useSelector(state => state.authentication) || '';
  const impersonateData = useSelector(state => state.user.imperData) || '';
  const [initialRender,setInitialRender] = useState(true);
  const loggedInUser = useSelector((state) => state.authentication?.profile) || "";
  const alert = useSelector((state) => state.global.alert);
  
  const currentURL = window.location.href;
  const { localised } = useContext(LanguageContext);
  
  const tokenLogger = (token) => {
    
    if (token.token && (impersonateData == '' || impersonateData == undefined)) {
      dispatch({
        type: "LOGIN",
        payload: {
          authenticated: true,
          keycloak: keycloak,
          expireTime: keycloak.tokenParsed.exp,
        }
      });

      setTimeout(() => {
        if(initialValue) {
          dispatch({ type: 'GET_LOGGED_USER_DETAILS' });
          initialValue = 0;
        }
      }, 150);
    } 
  };

  useEffect(() => {
    if(currentURL.includes('impersonate') && (impersonateData=='' || impersonateData==undefined)) {
      const params = new URLSearchParams(window.location.search);
      let id = atob(params.get('id'));
      let key = atob(params.get('data'));

      dispatch({type: "SAVE_PROFILE", payload: null});
      dispatch({type: "SET_IS_PROXY", payload: true});
      dispatch({
        type: "LOGIN",
        payload: {
          authenticated: true,
          keycloak: keycloak
        }
      });
      dispatch({type:'USER_IMPERSONATE', payload:{id:id,sameTab : 1,key: key}});
      params.delete('data');
      params.delete('id');
      dispatch(updateGlobalFilter(null));  
    }
  }, []);

  useEffect(() => {
    if (impersonateData && Object.keys(impersonateData).length > 0) {
      dispatch({ type: 'MODIFY_AUTHENTICATION', payload: {...auth.keycloak, authenticated:true, token:impersonateData.access_token, refreshToken:impersonateData.refresh_token}});
      setTimeout(() => {
        dispatch({ type: 'GET_LOGGED_USER_DETAILS' });
        dispatch({ type: 'FETCH_RC_INVOICE_NOTIFICATION' });
        dispatch({ type: 'CLEAR_USER_IMPERSONATE_DATA' });
      }, 150);
    }
  }, [impersonateData]);

  useEffect(() => {
    if(!keycloak.authenticated) {
      return;
    }

    keycloak.onInitError = () => {
      console.log('inside onInitError');
    }

    keycloak.onAuthError = () => {
      console.log("error in token api");
    };

    keycloak.onAuthRefreshError = () => {
      console.log("inside onAuthRefreshError");
    };

    keycloak.onAuthLogout = () => {
      console.log("inside onAuthLogout");
      // dispatch(logout());
    }

    keycloak.onTokenExpired = () => {
        keycloak.updateToken(50).then((resp) => {
          console.log("Token refreshed");
        }).catch((error) => {
          console.log("token error");
          dispatch(logout());
        });
    };

}, [keycloak.authenticated]);

useEffect(() => {
  keycloak?.isLoadingCheck ? dispatch(isLoader(true)) : dispatch(isLoader(false));;
}, [keycloak?.isLoadingCheck]);

useEffect(()=>{
  if(keycloak && initialRender){

    if(loggedInUser.role === "CL" || loggedInUser.role === 'MN' || loggedInUser.role === 'ST')
    {
      dispatch(fetchLeftSidebarCount());
      dispatch(getInventorySmartCredential());
      dispatch(getQuickBook({ reqtype: 'companyInfo' })); 
      
      if (loggedInUser.role === "CL" || (loggedInUser.role === 'ST' && loggedInUser?.companyData?.name === 'Cleaner')) {
        dispatch(getCallbackInspectionCount())
      }
      setInitialRender(false);
    }
  }
 
 },[initialRender]);

 useEffect(() => {
   if (loggedInUser && initialValue) {
     OneSignal.init({ appId: process.env.REACT_APP_ONESIGNAL_APPTID}).then(() => {
       OneSignal.showSlidedownPrompt().then(() => {
         console.log("control here...");
       });

       OneSignal.setExternalUserId(String(loggedInUser.user_id));

     });
     //dispatch(getCompanyWallet());
    dispatch({ type: 'FETCH_RC_INVOICE_NOTIFICATION' });
   }
 },[loggedInUser]);

 useEffect(() => {
  if (alert && alert !== null) {
    if (alert.variant === "danger" && alert.componentName=="LoginError") {
      dispatch(isLoader(true));
      toast.error(alert.message);
      setTimeout(() => {
        dispatch(logout());
      }, 1000);
    } 
  }
}, [alert]);

const isNotEmailOtpMode = () => {
  return (localStorage.getItem('invoice_token')) ? false :true;
}
  return (

    <ReactKeycloakProvider authClient={keycloak} onTokens={tokenLogger} 
      LoadingComponent={<Loader isLoading={true}/>} >
      <LanguageProvider>

        <div className={ isSafari ? 'main-container is-safari' : 'main-container'}>
        <Loader />
          <AppRouter />
        </div>
        <ToastContainer
          enableMultiContainer
          closeButton
          containerId={'B'}
          position="top-right"
          autoClose={false}
          newestOnTop = {false}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable = {false}
          theme="colored"
          className='custom-toast-conatiner'
        />
        <ToastContainer enableMultiContainer limit={1} autoClose={1000} theme="colored" />
        { loggedInUser && <ScrollButton />}
        { loggedInUser && isNotEmailOtpMode() &&
        <Invoicenotification/>
        }
        
      
      </LanguageProvider>
    </ReactKeycloakProvider>
  );
}

export default App;