import APIservice from "../apiService";
import { isLoader } from "../global/action";
import { microServices } from "../../config/global";
import { setError } from "../form/action";
/**
 * ApiMiddleware can be used to call any Service related API services
 * ApiMiddleware can be used to call any Inventory Template related API Template.
 * @param {*} store 
 * @returns 
 */
const InventoryTemplateMiddleware = (store) => (next) => async (action) => {
    const result = next(action);
    let endpoint = microServices.base + '/v2/inventory/';
    let per_page_condition = 0;
    switch (action.type) {
        case "CREATE_INVENTORY_TEMPLATE":
            endpoint += "templates/" + action.payload.action_type;
            store.dispatch(isLoader(true));
            APIservice
                .postMultipartData(endpoint, action.payload.formData)
                .then((response) => {
                    if (response.data.inventory_template_id) {
                        store.dispatch({
                            type: 'FETCH_INVENTORY_TEMPLATE_DETAIL',
                            payload: {
                                'inventory_template_id': response.data.inventory_template_id
                            }
                        });
                        store.dispatch({
                            type: 'FETCH_INVENTORY_TEMPLATE_CONTAINERS',
                            payload: {
                                'inventory_template_id': response.data.inventory_template_id
                            }
                        });
                        store.dispatch({
                            type: 'SHOW_ALERT',
                            payload: {
                                componentName: (action.payload.alertName == "add") ? 'AddInventoryTemplate' : 'UploadInventoryTemplate',
                                variant: 'success',
                                message: 'Inventory Template Added Successfully!'
                            }
                        });
                    }
                })
                .catch((error) => {
                    if (error.response.status !== 422) {
                        store.dispatch({
                            type: 'SHOW_ALERT',
                            payload: {
                                variant: 'danger',
                                message: 'Unable to Create Template!'
                            }
                        });
                    } else {
                        let err = { componentName: action.componentName, statusCode: error.response.status, data: error.response.data };
                        store.dispatch(setError(err));
                    }
                })
                .finally(() => {
                    store.dispatch(isLoader(false));
                })
            break;

        case "FETCH_INVENTORY_TEMPLATE_DETAIL":
            endpoint += 'templates';
            store.dispatch(isLoader(true));
            APIservice
                .get(endpoint + '/' + action.payload.inventory_template_id)
                .then((response) => {
                    if (response && response.data) {
                        store.dispatch({
                            type: 'SET_INVENTORY_TEMPLATE_DETAIL',
                            payload: {
                                data: response.data.data
                            }
                        });
                    }
                })
                .catch((error) => {
                    let err = { componentName: 'EditInventoryTemplate', statusCode: error.response.status, data: error.response.data };
                    store.dispatch(setError(err));
                })
                .finally(() => {
                    store.dispatch(isLoader(false));
                })
            break;

        case "FETCH_INVENTORY_TEMPLATE_CONTAINERS":
            per_page_condition = (action.payload.per_page || action.payload.per_page === 0) ? '?per_page=' + action.payload.per_page : "";
            endpoint += 'templates';
            store.dispatch(isLoader(true));
            APIservice
                .get(endpoint + '/' + action.payload.inventory_template_id + '/containers' + per_page_condition)
                .then((response) => {
                    if (response && response.data) {
                        store.dispatch({
                            type: 'SET_TEMPLATE_INVENTORY_CONTAINERS',
                            payload: {
                                data: response.data
                            }
                        });
                    }
                })
                .catch((error) => {
                    let err = { componentName: 'TemplateCantainers', statusCode: error.response.status, data: error.response.data };
                    store.dispatch(setError(err));
                })
                .finally(() => {
                    store.dispatch(isLoader(false));
                })
            break;

        case "UPLOAD_INVENTORY_TEMPLATE":
            endpoint += "templates/" + action.payload.action_type;
            store.dispatch(isLoader(true));
            APIservice
                .postMultipartData(endpoint, action.payload.formData)
                .then((response) => {
                    if (response.data.inventory_template_id) {
                        store.dispatch({
                            type: 'INITIALIZE_INVENTORY_TEMPLATE_UPLOAD',
                            payload: {
                                'inventory_template_id': response.data.inventory_template_id,
                                'assignData': action.payload.formData,
                                'alertName': action.payload.alertName
                            }
                        });
                    }
                })
                .catch((error) => {
                    if (error.response.status !== 422) {
                        store.dispatch({
                            type: 'SHOW_ALERT',
                            payload: {
                                variant: 'danger',
                                message: 'Unable to save staff information!'
                            }
                        });
                    } else {
                        let err = { componentName: action.componentName, statusCode: error.response.status, data: error.response.data };
                        store.dispatch(setError(err));
                    }
                })
                .finally(() => {
                    store.dispatch(isLoader(false));
                })
            break;

        case "INITIALIZE_INVENTORY_TEMPLATE_UPLOAD":
            const uploadEndpoint = microServices.base + '/v2/inventory/templates/upload/' + action.payload.inventory_template_id;
            store.dispatch(isLoader(true));
            APIservice
                .postMultipartData(uploadEndpoint, action.payload.assignData)
                .then((response) => {
                    store.dispatch({
                        type: 'UPLOAD_TEMPLATE_INVENTORY',
                        payload: {
                            'uploadUrl': response.data.data.upload,
                            'uploadFiledata': action.payload.assignData,
                            'inventory_template_id': action.payload.inventory_template_id,
                            'alertName': action.payload.alertName
                        }
                    });
                })
                .catch((error) => {
                    let err = { componentName: 'Upload_Inventory_Template', statusCode: error.response.status, data: error.response.data };
                    store.dispatch(setError(err));
                    store.dispatch(isLoader(false));
                });
            break;

        case "UPLOAD_TEMPLATE_INVENTORY":
            const uploadTokenEndpoint = action.payload.uploadUrl;
            store.dispatch(isLoader(true));
            APIservice
                .postMultipartData(uploadTokenEndpoint, action.payload.uploadFiledata)
                .then((response) => {
                    if (response.status == 200) {
                        store.dispatch({
                            type: 'FETCH_INVENTORY_TEMPLATE_DETAIL',
                            payload: {
                                'inventory_template_id': action.payload.inventory_template_id,
                            }
                        });
                        store.dispatch({
                            type: 'FETCH_INVENTORY_TEMPLATE_CONTAINERS',
                            payload: {
                                'inventory_template_id': action.payload.inventory_template_id,
                            }
                        });

                        store.dispatch({
                            type: 'SHOW_ALERT',
                            payload: {
                                componentName: (action.payload.alertName == "add") ? 'AddInventoryTemplate' : 'UploadInventoryTemplate',
                                variant: 'success',
                                message: 'Inventory Template Added Successfully!'
                            }
                        });
                    }
                })
                .catch((error) => {
                    let err = { componentName: action.componentName, statusCode: error.response.status, data: error.response.data };
                    store.dispatch(setError(err));
                }).finally(() => {
                    store.dispatch(isLoader(false));
                })
            break;

        case "ADD_TEMPLATE_CONTAINER":
            endpoint += 'templates';
            store.dispatch(isLoader(true));
            APIservice
                .post(endpoint + '/' + action.payload.inventory_template_id + '/container',
                    action.payload.formData)
                .then((response) => {
                    store.dispatch({
                        type: 'SHOW_ALERT',
                        payload: {
                            variant: 'success',
                            message: 'Container created successfully!',
                            componentName: 'AddTemplateContainer'
                        }
                    });
                })
                .catch((error) => {
                    if (error.response.status !== 422) {
                        store.dispatch({
                            type: 'SHOW_ALERT',
                            payload: {
                                variant: 'danger',
                                message: 'Unable to save container information!',
                            }
                        });
                    } else {
                        let err = { componentName: 'AddTemplateContainer', statusCode: error.response.status, data: error.response.data };
                        store.dispatch(setError(err));
                    }
                })
                .finally(() => {
                    store.dispatch(isLoader(false));
                })
            break;

        
        case 'ACTIVE_INACTIVE_INVENTORY_TEMPLATE':
                const invTemplateEndpoint = endpoint + `/templates`;
                store.dispatch(isLoader(true));
                APIservice.put(`${invTemplateEndpoint}/${action.payload.id}/${action.payload.apiName}`, action.payload.data)
                  .then(response => {
                    store.dispatch({
                      type: 'SHOW_ALERT',
                      payload: {
                        variant: 'success',
                        message: action.payload.successMessage,
                        componentName: 'ActiveInactiveInventoryTemplate',
                      },
                    });
                  })
                  .catch(error => {
                    if (error.response && error.response.status !== 422) {
                      store.dispatch({
                        type: 'SHOW_ALERT',
                        payload: {
                          variant: 'danger',
                          message: action.payload.errorMessage,
                        },
                      });
                    } else {
                      let err = {
                        componentName: 'ActiveInactiveInventoryTemplate',
                        statusCode: error.response ? error.response.status : 'Network Error',
                        data: error.response ? error.response.data : error.message,
                      };
                      store.dispatch(setError(err));
                    }
                  })
                  .finally(() => {
                    store.dispatch(isLoader(false));
                  });
                break;

        case 'CLONE_INVENTORY_TEMPLATE':
                const cloneEndpoint = endpoint + `/templates`;
                store.dispatch(isLoader(true));

                APIservice.post(`${cloneEndpoint}/${action.payload.id}/clone`, action.payload.formData)
                    .then(response => {
                    store.dispatch({
                        type: 'SHOW_ALERT',
                        payload: {
                        variant: 'success',
                        message: action.payload.successMessage,
                        componentName: 'CloneInventoryTemplate',
                        },
                    });
                    })
                    .catch(error => {
                    if (error.response && error.response.status !== 422) {
                        store.dispatch({
                        type: 'SHOW_ALERT',
                        payload: {
                            variant: 'danger',
                            message: action.payload.errorMessage,
                        },
                        });
                    } else {
                        let err = {
                        componentName: 'CloneInventoryTemplate',
                        statusCode: error.response ? error.response.status : 'Network Error',
                        data: error.response ? error.response.data : error.message,
                        };
                        store.dispatch(setError(err));
                    }
                    })
                    .finally(() => {
                    store.dispatch(isLoader(false));
                    });
                break;      
                
                case "UPDATE_INVENTORY_TEMPLATE":
                endpoint += 'templates';
                store.dispatch(isLoader(true));
                APIservice
                .put(endpoint+'/'+action.payload.inventory_template_id, action.payload.formData)
                .then((response)=>{
                    store.dispatch({ 
                        type: 'SHOW_ALERT', 
                        payload: {
                            variant:'success',
                            message: 'Template updated successfully!',
                            componentName:'EditInventoryTemplate'
                        }
                    });
                    store.dispatch({ 
                        type: 'SET_DATA_FOR_EDIT', 
                        payload: {
                            data:response.data.data,
                        }
                    });
                })
                .catch((error)=>{
                    if (error.response.status !== 422) {
                        store.dispatch({ 
                            type: 'SHOW_ALERT', 
                            payload: {
                                variant:'danger',
                                message: 'Unable to save template information!',
                            }
                        });
                    } else {
                        let err = {componentName:'EditInventoryTemplate',statusCode: error.response.status,data: error.response.data};
                        store.dispatch(setError(err));
                    }
                })
                .finally(()=>{
                    store.dispatch(isLoader(false));
                })
            break;
            case "GET_INVENTORY_TEMPLATE_CONTAINER" :
                endpoint += 'templates';
                store.dispatch(isLoader(true));
                APIservice
                .get(endpoint+'/'+action.payload.inventory_template_id+'/container/'+action.payload.inventory_container_id)
                .then((response)=>{
                    if(response && response.data){
                        store.dispatch({ 
                            type: 'SET_TEMPLATE_CONTAINER', 
                            payload: {
                                data:response.data.data
                            }
                        });
                    }
                })
                .catch((error)=>{
                    let err = {componentName:"InventoryTemplateContainer",statusCode: error.response.status,data: error.response.data};
                    store.dispatch(setError(err));
                })
                .finally(()=>{
                    store.dispatch(isLoader(false));
                })
            break;
            case "UPDATE_INVENTORY_TEMPLATE_CONTAINER":
                endpoint += 'templates';
                store.dispatch(isLoader(true));
                APIservice
                .put(endpoint+'/'+action.payload.inventory_template_id+'/container/'+action.payload.inventory_template_container_id, 
                action.payload.formData)
                .then((response)=>{
                    store.dispatch({ 
                        type: 'SHOW_ALERT', 
                        payload: {
                            variant:'success',
                            message: 'Container updated successfully!',
                            componentName:'EditTemplateContainer'
                        }
                    });
                })
                .catch((error)=>{
                    if (error.response.status !== 422) {
                        store.dispatch({ 
                            type: 'SHOW_ALERT', 
                            payload: {
                                variant:'danger',
                                message: 'Unable to save container information!',
                            }
                        });
                    } else {
                        let err = {componentName:'EditTemplateContainer',statusCode: error.response.status,data: error.response.data};
                        store.dispatch(setError(err));
                    }
                })
                .finally(()=>{
                    store.dispatch(isLoader(false));
                })
            break;
            case 'DELETE_INVENTORY_TEMPLATE_CONTAINER':
                endpoint += 'templates';
                store.dispatch(isLoader(true));
                APIservice
                .delete(endpoint+'/container/'+action.payload.inventory_template_container_id)
                .then((response)=>{
                store.dispatch({ 
                    type: 'SHOW_ALERT', 
                    payload: {
                    variant:'success',
                    message: 'Container deleted successfully.',
                    componentName:'DeleteTemplateContainer'
                    }
                });
                })
                .catch((error)=>{
                if (error.response.status !== 422) {
                    store.dispatch({
                    type: 'SHOW_ALERT', 
                    payload: {
                        variant:'danger',
                        message: 'Unable to delete Conatiner!'
                    }
                    });
                } else {
                    let err = {componentName:'DeleteTemplateContainer',statusCode: error.response.status,data: error.response.data};
                    store.dispatch(setError(err));
                }
                })
                .finally(()=>{
                store.dispatch(isLoader(false));
                })
            break;
            case "UPDATE_TEMPLATE_CONTAINER_DISPLAY_ORDER":
                endpoint += 'templates';
                store.dispatch(isLoader(true));
                APIservice
                .post(endpoint+'/'+action.payload.inventory_template_id+'/container/display-order', 
                action.payload.formData)
                .then((response)=>{
                    store.dispatch({ 
                        type: 'SHOW_ALERT', 
                        payload: {
                            variant:'success',
                            message: 'Container Display Order updated successfully!',
                            componentName:'UpdateTemplateContainerDisplayOrder'
                        }
                    });
                })
                .catch((error)=>{
                    if (error.response.status !== 422) {
                        store.dispatch({ 
                            type: 'SHOW_ALERT', 
                            payload: {
                                variant:'danger',
                                message: 'Unable to save item display order information!',
                            }
                        });
                    } else {
                        let err = {componentName:'UpdateTemplateContainerDisplayOrder',statusCode: error.response.status,data: error.response.data};
                        store.dispatch(setError(err));
                    }
                })
                .finally(()=>{
                    store.dispatch(isLoader(false));
                })
            break;
            case "FETCH_INVENTORY_TEMPLATE_ITEMS" :
            per_page_condition = (action.payload.per_page || action.payload.per_page === 0)  ? '?per_page='+action.payload.per_page : "";
            endpoint += 'templates';
            store.dispatch(isLoader(true));
            APIservice
            .get(endpoint+'/container/'+action.payload.inventory_template_container_id+'/items'+per_page_condition)
            .then((response)=>{
                if(response && response.data){
                    store.dispatch({ 
                        type: 'SET_INVENTORY_TEMPLATE_ITEMS', 
                        payload: {
                            data:response.data.data
                        }
                    });
                }
            })
            .catch((error)=>{
                let err = {componentName:'InventoryTemplateItems',statusCode: error.response.status,data: error.response.data};
                store.dispatch(setError(err));
            })
            .finally(()=>{
                store.dispatch(isLoader(false));
            })
        break;
        case 'UPDATE_INVENTORY_TEMPLATE_ITEM':
            endpoint += 'templates';
            store.dispatch(isLoader(true));
            APIservice
            .put(endpoint+'/'+action.payload.inventory_template_id+'/container/'+action.payload.inventory_template_container_id+'/item/'+action.payload.inventory_template_item_id, 
            action.payload.formData)
            .then((response)=>{
                store.dispatch({ 
                    type: 'SHOW_ALERT', 
                    payload: {
                        variant:'success',
                        message: 'Item updated successfully!',
                        componentName:'EditInventoryTemplateItem'
                    }
                });
            })
            .catch((error)=>{
                if (error.response.status !== 422) {
                    store.dispatch({ 
                        type: 'SHOW_ALERT', 
                        payload: {
                            variant:'danger',
                            message: 'Unable to save item information!',
                        }
                    });
                } else {
                    let err = {componentName:'EditInventoryTemplateItem',statusCode: error.response.status,data: error.response.data};
                    store.dispatch(setError(err));
                }
            })
            .finally(()=>{
                store.dispatch(isLoader(false));
            })
        break;
        case "ADD_INVENTORY_TEMPLATE_ITEM":
            endpoint += 'templates';
            store.dispatch(isLoader(true));
            APIservice
            .post(endpoint+'/'+action.payload.inventory_template_id+'/container/'+action.payload.inventory_template_container_id+'/item',
            action.payload.formData)
            .then((response)=>{
                store.dispatch({ 
                    type: 'SHOW_ALERT', 
                    payload: {
                        variant:'success',
                        message: 'Item created successfully!',
                        componentName:'AddInventoryTemplateItem'
                    }
                });
            })
            .catch((error)=>{
                if (error.response.status !== 422) {
                    store.dispatch({ 
                        type: 'SHOW_ALERT', 
                        payload: {
                            variant:'danger',
                            message: 'Unable to save item information!',
                        }
                    });
                } else {
                    let err = {componentName:'AddInventoryTemplateItem',statusCode: error.response.status,data: error.response.data};
                    store.dispatch(setError(err));
                }
            })
            .finally(()=>{
                store.dispatch(isLoader(false));
            })
        break;

        case "DELETE_INVENTORY_TEMPLATE_ITEM":
            endpoint += 'templates';
            store.dispatch(isLoader(true));
            APIservice
            .delete(endpoint+'/item/'+action.payload.inventory_template_item_id)
            .then((response)=>{
              store.dispatch({ 
                type: 'SHOW_ALERT', 
                payload: {
                  variant:'success',
                  message: 'Item deleted successfully.',
                  componentName:'DeleteInventoryTemplateItem'
                }
              });
            })
            .catch((error)=>{
              if (error.response.status !== 422) {
                store.dispatch({
                  type: 'SHOW_ALERT', 
                  payload: {
                    variant:'danger',
                    message: 'Unable to delete Item!'
                  }
                });
              } else {
                let err = {componentName:'DeleteInventoryTemplateItem',statusCode: error.response.status,data: error.response.data};
                store.dispatch(setError(err));
              }
            })
            .finally(()=>{
              store.dispatch(isLoader(false));
            })
        break;
        case "UPDATE_INVENTORY_TEMPLATE_ITEM_DISPLAY_ORDER":
            endpoint += 'templates';
            store.dispatch(isLoader(true));
            APIservice
            .post(endpoint+'/'+action.payload.inventory_template_id+'/container/item/display-order', 
            action.payload.formData)
            .then((response)=>{
                store.dispatch({ 
                    type: 'SHOW_ALERT', 
                    payload: {
                        variant:'success',
                        message: 'Item Display Order updated successfully!',
                        componentName:'UpdateInventoryTemplateItemDisplayOrder'
                    }
                });
            })
            .catch((error)=>{
                if (error.response.status !== 422) {
                    store.dispatch({ 
                        type: 'SHOW_ALERT', 
                        payload: {
                            variant:'danger',
                            message: 'Unable to save item display order information!',
                        }
                    });
                } else {
                    let err = {componentName:'UpdateInventoryTemplateItemDisplayOrder',statusCode: error.response.status,data: error.response.data};
                    store.dispatch(setError(err));
                }
            })
            .finally(()=>{
                store.dispatch(isLoader(false));
            })
        break;

        case "DELETE_INVENTORY_ITEM_IMAGE" : 
       
          endpoint += 'templates';
          store.dispatch(isLoader(true));
          APIservice
          .delete(endpoint+'/item/image/'+action.payload.inventory_template_item_id)
          .then((response)=>{
            store.dispatch({ 
              type: 'SHOW_ALERT', 
              payload: {
                variant:'success',
                message: 'Photo deleted successfully.',
                componentName:'DeleteInventoryItemPhoto'
              }
            });
          })
          .catch((error)=>{
            if (error.response.status !== 422) {
              store.dispatch({ 
                type: 'SHOW_ALERT', 
                payload: {
                  variant:'danger',
                  message: 'Unable to delete item image!'
                }
              });
            } else {
              let err = {componentName:'DeleteInventoryItemPhoto',statusCode: error.response.status,data: error.response.data};
              store.dispatch(setError(err));
            }
          })
          .finally(()=>{
            store.dispatch(isLoader(false));
          })
        break;

        default:
            break;
    }
    return result;
};

export default InventoryTemplateMiddleware;