// import { useSSRSafeId } from "@restart/ui/esm/ssr"

export const webKeyclockConfig = {
    "realm": "rc",
    "url": process.env.REACT_APP_AUTH_URL,
    "ssl-required": "external",
    "resource": process.env.REACT_APP_AUTH_USER_CLIENT,
    "credentials": {
      "secret": process.env.REACT_APP_AUTH_CLIENT_SECRATE
    },
    "confidential-port": 0,
    "clientId": process.env.REACT_APP_AUTH_USER_CLIENT,
  }

  
export const adminKeyclockConfig = {
    "realm": "rc",
    "url": process.env.REACT_APP_AUTH_URL,
    "ssl-required": "external",
    "resource": process.env.REACT_APP_AUTH_ADMIN_CLIENT,
    "credentials": {
      "secret": process.env.REACT_APP_AUTH_CLIENT_SECRATE
    },
    "confidential-port": 0,
    "clientId": process.env.REACT_APP_AUTH_ADMIN_CLIENT,
  }
  
export const roles = {
  'SA': 'Super Admin',
  'AD': 'Admin',
  'OW' : 'Owner',
  'MN': 'Manager',
  'CL': 'Cleaner',
  'STF': 'Staff'
}

export const microServices = {
  'base' : 'base',
  'session' : 'session',
  'signup' : 'signup',
  'inspection' : 'inspection',
  'notification' :'notification',
  'integration' : 'integration',
  'commonarea' : 'commonarea'
}

export const webCompanyTilledConfig = {
  "tilled_onboard_url":process.env.REACT_APP_TILLED_ONBOARD_URL,
  "tilled_account":process.env.REACT_APP_TILLED_ADMIN_ACCOUNT,
  "tilled_public_key":process.env.REACT_APP_TILLED_PUBLIC_KEY,
  "tilled_sandbox":process.env.REACT_APP_TILLED_SANDBOX_MODE,
 
}

export const webCompanyStripConfig = {
  "client_secret": process.env.REACT_APP_STRIP_CLIENT_SECRET_KEY,
  "grant_type" : "authorization_code",
  "client_id" :  process.env.REACT_APP_STRIP_CLIENT_ID,
  "strip_aut_url" : process.env.REACT_APP_STRIP_AUTH_URL,
  "redirectUrl" :  process.env.REACT_APP_WEB_URL,
  "client_public_key": process.env.REACT_APP_STRIP_CLIENT_PUBLIC_KEY,
}

export const integrationConfig = {
  "webrez_pro_url": process.env.REACT_APP_WEBREZPRO_CALLBACK_URL,
  "staycloud_url": process.env.REACT_APP_STAYCLOUD_CALLBACK_URL
}


export const servicesUrl ={
  "base_service":process.env.REACT_APP_BASE_SERVICE_URL,
  "base_cardpoint":process.env.REACT_APP_CARDPOINT_BASE_URL,
}