const initState = {
    appointmentStats:[],
    propertyRevenue:[],
  };
  
  const dashboard = (state = initState, action) => {
    switch (action.type) {

        case "SET_DASHBOARD_APPOINTMENT_STATS":
          return {
            ...state,
            appointmentStats:action.payload
          }
        case "CLEAR_DASHBOARD_APPOINTMENT_STATS":
          return {
            ...state,
            appointmentStats: null,
          };
        case "SET_PROPERTY_REVENUE_LIST":
            return {
                ...state,
                propertyRevenue: action.payload,
            };
        case "CLEAR_PROPERTY_REVENUE_LIST":
            return {
              ...state,
              propertyRevenue: null,
            };
        case "SET_PROPERTY_READINESS_LIST":
          return {
              ...state,
              propertyReadiness: action.payload,
          };    
        case "SET_DASHBOARD_CLEANER_GRAPH":
          return {
              ...state,
              cleanerGraph: action.payload,
          };    

        case "SET_DASHBOARD_PM_GRAPH":
          return {
              ...state,
              propertyManagerGraph: action.payload,
          };  

        case "SET_DASHBOARD_STAFF_GRAPH":
          return {
              ...state,
              staffGraph: action.payload,
          };  

        case "SET_DASHBOARD_APPMT_GRAPH":
          return {
              ...state,
              appmtGraph: action.payload,
          };  

        case "SET_DASHBOARD_ANALYTICS_COUNT":
          return {
              ...state,
              analyticsChartCount: action.payload,
          };   
          
        case "SET_DASHBOARD_INTEGRATION_LIST":
          return {
              ...state,
              integrationList: action.payload,
          };

        case "SET_DASHBOARD_PREV_YEAR_APPMT_GRAPH":
          return {
              ...state,
              prevYearApptGraph: action.payload,
          };   
          

        case "SET_BROADCAST_ALERT_LIST":
          return {
              ...state,
              broadCastAlert: action.payload,
          };   
            
      default:
        return state;
    }
  };
  
  export default dashboard;  