const initState = {
  inventoryTemplateList: [],
  containers:[],
  container:[],
  itemCount:'',
  inventorytemplateitems:[]
};

const InventoryTemplate = (state = initState, action) => {

  switch (action.type) {
    case 'SET_INVENTORY_TEMPLATE_DETAIL': {
      return {
        ...state,
        inventoryTemplateList: action.payload.data
      };
    }
    case 'CLEAR_INVENTORY_TEMPLATE_DETAIL': {
      return {
        ...state,
        inventoryTemplateList: []
      };
    }

    case 'SET_TEMPLATE_INVENTORY_CONTAINERS': {
      return {
        ...state,
        containers: action.payload.data.data,
        itemCount: action.payload.data.meta

      }
    }

    case 'CLEAR_TEMPLATE_INVENTORY_CONTAINERS': {
      return {
        ...state,
        containers: [],
        itemCount: []
      }
    }

    case 'SET_TEMPLATE_CONTAINER': {
      return {
        ...state,
        container: action.payload.data
      }
    }

    case 'CLEAR_TEMPLATE_CONTAINER': {
      return {
        ...state,
        container: []
      }
    }

    case 'CLEAR_INVENTORY_TEMPLATE_ITEMS':{
      return{
        ...state,
        inventorytemplateitems:[]
      }
    }

    case 'SET_INVENTORY_TEMPLATE_ITEMS':{
      return{
        ...state,
        inventorytemplateitems: action.payload.data
      }
    }

    default:
      return state;
  }
};

export default InventoryTemplate;