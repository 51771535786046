const initState = {
  property: [],
  stockRoomItems:[],
  inventorySmartCredential:{}
};

const inventorySmart = (state = initState, action) => {

  switch (action.type) {
    case "SET_INVENTORY_SMART_CREDENTIAL":
      return {
        ...state,
        inventorySmartCredential: action.payload
      };
    case "CLEAR_INVENTORY_SMART_CREDENTIAL":
      return {
        ...state,
        inventorySmartCredential: action.payload
      };
    case "SET_INVENTORY_ITEMS_IN_STOCKROOM":
      return {
        ...state,
        stockRoomItems: action.payload
      };
      case 'CLEAR_INVENTORY_ITEMS_IN_STOCKROOM':
      return {
        ...state,
        stockRoomItems: null
      };

    default:
      return state;
  }
};

export default inventorySmart;
