import React, { useState, createContext, useContext } from 'react';

import { languageOptions, localisedList } from '../../languages';

// create the language context with default selected language
export const LanguageContext = createContext({
  userLanguage: 'en',
  localised: localisedList.en,
  isLanguageChanged: () => {},
});

// it provides the language context to app
export function LanguageProvider({ children }) {
  const defaultLanguage = window.localStorage.getItem('rcml-lang');
  const [userLanguage, setUserLanguage] = useState(defaultLanguage || 'en');
  const [isLanguageChanged, setIsLanguageChanged] = useState('');

  const provider = {
    userLanguage,
    localised: localisedList[userLanguage],
    isLanguageChanged,
    userLanguageChange: selected => {
      const newLanguage = languageOptions[selected] ? selected : 'en'
      setUserLanguage(newLanguage);
      window.localStorage.setItem('rcml-lang', newLanguage);
    },
    setLanguageChanged: (changed) => { 
      setIsLanguageChanged(changed)
    },
  };

  return (
    <LanguageContext.Provider value={provider}>
      {children}
    </LanguageContext.Provider>
  );
};

// get text according to id & current language
export function Text({ tid }) {
  const languageContext = useContext(LanguageContext);

  return languageContext.localised[tid] || tid;
};
