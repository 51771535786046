const initState = {
  messageData: [],
};

const MessageCenter = (state = initState, action) => {
  switch (action.type) {
    case 'SET_MESSAGE_DATA_FOR_EDIT':
      return {
        ...state,
        messageData: action.payload.data
      };

    case 'CLEAR_MESSAGE_DATA_FOR_EDIT':
      return {
        ...state,
        messageData: []
      };
      default:
        return state;
  }
};

export default MessageCenter;
