import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import style from './style.scss';

const Loader = (props) => {
  const loading = useSelector((state) => state.global.loading);

  return (
    <>
      {(loading || props.isLoading) && (
        <div className={'loaderBackground d-flex justify-content-center'}>
          <Spinner className={'spinner'} animation="grow" variant={'info'} />
        </div>
      )}
    </>
  );
};

Loader.defaultProps = {
  isLoading: false
}
export default Loader;
