const initState = {
  groupList:[],
  housekeeperlist:[],
  housekeepersData:[],
  hkGroups:[],
  hkStaff:[],
  inspClStaff:null,
  teamStaff:null,
  inspectorList:[],
  hkStaffdropdownList:null,
  haList:null,
  inspBulkDropdown:[],
  hkBulkDropdown:[],
  tmBulkDropdown:[],
  haBulkDropdown:[],
  boardAssignment:{},
  allStaffWithRegionArea:[],
  resetAppCredentials:[]
};

const user = (state = initState, action) => {

  switch (action.type) {
    case "SET_DROPDOWN_GROUP_LIST": {
      return {
        ...state,
        groupList: action.payload,
      }
    }

    case 'CLEAR_GROUP': {
      return {
        ...state,
        groupList : []
      };
    }

    case "SET_HOUSEKEEPER_DROPDOWN": {
      return {
        ...state,
        housekeeperlist: action.payload,
      }
    }
    case 'CLEAR_HOUSEKEEPER_DROPDOWN': {
      return {
        ...state,
        housekeeperlist : []
      };
    }
    case "SET_USER_ACCESS_DETAILS": {
      return {
        ...state,
        userAccessDetails: action.payload,
      }
    }
    case 'CLEAR_USER_ACCESS_DETAILS': {
      return {
        ...state,
        userAccessDetails : []
      };
    }
    case "SET_COMPANY_DETAILS": {
      return {
        ...state,
        company: action.payload,
      }
    }

    case "SET_USER_DETAILS": {
      return {
        ...state,
        user: action.payload,
      }
    }
    case 'SET_HOUSEKEEPER_DATA': {
      return {
        ...state,
        housekeepersData : action.payload,
        hkResponseRecieved: true
      };
    }
    case 'CLEAR_HOUSEKEEPER_DATA': {
      const {hkResponseRecieved, ...newState} = state;
      return {
        ...newState,
        housekeepersData:[],
        hkResponseRecieved: false
      };
    }
    case 'SET_INSPECTOR_DATA': {
      return {
        ...state,
        inspectorsData : action.payload,
        inspResponseRecieved: true
      };
    }
    case 'CLEAR_INSPECTOR_DATA': {
      const {inspectorsData,...newState} = state;
      return {
        ...newState,
        inspResponseRecieved: false
      };
    }

    case 'HK_GROUPS_DROPDOWN': {
      return {
        ...state,
        hkGroups : action.payload,
      };
    }
    
    case 'CLEAR_HK_GROUPS_DROPDOWN': {
      return {
        ...state,
        hkGroups : [],
      };
    }
    case 'SET_CL_HK_DROPDOWN': {
      return {
        ...state,
        hkStaff : action.payload,
      };
    }
    case 'CLEAR_CL_HK_DROPDOWN': {
      return {
        ...state,
        hkStaff : [],
      };
    }

    case 'SET_CL_INSP_DROPDOWN': {
      return {
        ...state,
        inspClStaff : action.payload,
      };
    }
    case 'CLEAR_CL_INSP_DROPDOWN': {
      return {
        ...state,
        inspClStaff : null,
      };
    }

    case 'SET_INSP_LIST_DROPDOWN': {
      return {
        ...state,
        inspectorList : action.payload,
      };
    }
    case 'CLEAR_INSP_LIST_DROPDOWN': {
      return {
        ...state,
        inspectorList : [],
      };
    }

    case 'SET_HK_STAFF_DROPDOWN': {
      return {
        ...state,
        hkStaffdropdownList : action.payload,
      };
    }
    case 'CLEAR_HK_STAFF_DROPDOWN': {
      return {
        ...state,
        hkStaffdropdownList : null,
      };
    }

    case 'SET_HK_ASSIGN_STAFF_DROPDOWN': {
      return {
        ...state,
        hkAssignStaffdropdownList : action.payload,
      };
    }
    case 'CLEAR_HK_ASSIGN_STAFF_DROPDOWN': {
      const {hkAssignStaffdropdownList, ...newState} = state;
      return {
        ...newState,
      };
    }

    case 'SET_SUP_DROPDOWN': {
      return {
        ...state,
        supStaff : action.payload,
      };
    }

    case 'CLEAR_SUP_DROPDOWN': {
      return {
        ...state,
        supStaff : [],
      };
    }

    case 'SET_HOUSE_ATTENDANT_DATA': {
      return {
        ...state,
        haList : action.payload,
        haResponseRecieved: true
      };
    }
    case 'CLEAR_HOUSE_ATTENDANT_DATA': {
      return {
        ...state,
        haList : null,
        haResponseRecieved: false
      };
    }
    
    case 'SET_TEAM_MEMBER_DATA': {
      return {
        ...state,
        tmList : action.payload,
        tmResponseRecieved: true
      };
    }
    case 'CLEAR_TEAM_MEMBER_DATA': {
      const {tmList, ...newState} = state;
      return {
        ...newState,
        tmResponseRecieved:false
      };
    }

    case 'SET_TM_DROPDOWN': {
      return {
        ...state,
        teamStaff : action.payload,
      };
    }

    case 'CLEAR_TM_DROPDOWN': {
      return {
        ...state,
        teamStaff : null,
      };
    }

    case 'SET_INSPBULK_DROPDOWN': {
      return {
        ...state,
        inspBulkDropdown : action.payload,
      };
    }

    case 'CLEAR_INSPBULK_DROPDOWN': {
      return {
        ...state,
        inspBulkDropdown : [],
      };
    }

    case 'SET_HKBULK_DROPDOWN': {
      return {
        ...state,
        hkBulkDropdown : action.payload,
      };
    }

    case 'CLEAR_HKBULK_DROPDOWN': {
      return {
        ...state,
        hkBulkDropdown : [],
      };
    }

    case 'SET_HA_DROPDOWN': {
      return {
        ...state,
        haStaff : action.payload,
      };
    }

    case 'CLEAR_HA_DROPDOWN': {
      return {
        ...state,
        haStaff : null,
      };
    }

    case 'SET_HA_ASSIGN_DROPDOWN': {
      return {
        ...state,
        haAssignStaff : action.payload,
      };
    }

    case 'CLEAR_HA_ASSIGN_DROPDOWN': {
      const {haAssignStaff, ...newState} = state;
      return {
        ...newState,
      };
    }

    case 'USER_IMPERSONATE_DATA': {
      return {
          ...state,
          imperData : action.payload
      };
    }

    case 'CLEAR_USER_IMPERSONATE_DATA': {
      return {
          ...state,
          imperData : null
      };
    }

    case 'SET_TEAMBULK_DROPDOWN': {
      return {
        ...state,
        tmBulkDropdown : action.payload,
      };
    }

    case 'CLEAR_TEAMBULK_DROPDOWN': {
      return {
        ...state,
        tmBulkDropdown : [],
      };
    }
    
    case 'SET_ALLSTAFF_DROPDOWN': {
      return {
        ...state,
        allStaffDropdown : action.payload,
      };
    }

    case "CLEAR_USER_DETAILS": {
      return {
        ...state,
        user: [],
      }
    }

    case 'SET_HABULK_DROPDOWN': {
      return {
        ...state,
        haBulkDropdown : action.payload,
      };
    }

    case 'CLEAR_HABULK_DROPDOWN': {
      return {
        ...state,
        haBulkDropdown : [],
      };
    }

    case "CLEAR_COMPANY_DETAILS": {
      return {
        ...state,
        company: [],
      }
    }

    case 'SET_BOARD_ASSIGNMENT_DATA': {
      return {
        ...state,
        boardAssignment : action.payload,
      };
    }

    case "CLEAR_BOARD_ASSIGNMENT_DATA": {
      return {
        ...state,
        boardAssignment: {},
      }
    }
       
    
    case 'SET_ALL_STAFF_WITH_REGION_AREA':{
      return {
        ...state,
        allStaffWithRegionArea: action.payload,
      }
    }

    case 'CLEAR_ALL_STAFF_WITH_REGION_AREA':{
      return {
        ...state,
        allStaffWithRegionArea: [],
      }
    }
    case 'SET_QUICKBOOK_VENDOR':{
      return {
        ...state,
        quickBookVendors: action.payload,
      }
    }

    case 'CLEAR_QUICKBOOK_VENDOR':{
      return {
        ...state,
        quickBookVendors: [],
      }
    }

    case 'SET_WEESUP_DROPDOWN': {
      return {
        ...state,
        weeSupDropdown : action.payload,
      };
    }

    case 'CLEAR_WEESUP_DROPDOWN': {
      return {
        ...state,
        weeSupDropdown : [],
      };
    }
    
    case "SET_WO_HOUSEKEEPERS_WITH_ASSIGNED_COUNT": {
      return {
        ...state,
        woHousekeepersWithAssignedCount: action.payload.data,
      }
    }

    case "CLEAR_WO_HOUSEKEEPERS_WITH_ASSIGNED_COUNT": {
      return {
        ...state,
        woHousekeepersWithAssignedCount: [],
      }
    }

    case "SET_APP_CREDENTIALS": {
      return {
        ...state,
        resetAppCredentials: action.payload,
      }
    }
    case "CLEAR_APP_CREDENTIALS": {
      return {
        ...state,
        resetAppCredentials: [],
      }
    }

    default:
      return state;
  }
};

export default user;
