export const createWorkorderItem = (data) => {
    return {
        type: 'CREATE_WORKORDER_ITEM',
        payload: data,
        componentName:'AddWorkOrderItem',
        message: 'Maintenance Inventory has been Added Successfully!',
    };
}

export const getWorkorderItem = (data) => {
    return {
        type: 'FETCH_WORKORDER_ITEM',
        payload: data
    };
}

export const activeInactiveWorkorderItem = (data) => {
    return {
        type: 'ACTIVE_INACTIVE_WORKORDER_ITEM',
        payload: data
    };
}

export const updateWorkorderItem = (data) => {
    return {
      type: 'UPDATE_WORKORDER_ITEM',
      payload: data
    };
}

export const initializeWorkorderPhotos = (data) =>{
    return {
      type: 'INITIALIZE_WORKORDER_PHOTOS',
      payload: data
    };
}
  
export const getWorkorderPhotos  = (data) => {
    return {
        type: 'FETCH_WORKORDER_PHOTOS',
        payload:data
    };
}
  
export const shareWorkorderPhoto= (data) => {
    return {
        type: 'SHARE_WORKORDER_PHOTO',
        payload: data
    }
}
  
export const deleteWorkorderPhoto = (data) => {
    return {
        type: 'DELETE_WORKORDER_PHOTO',
        payload: data,
        workorderId: data?.workorderId
    }
}
  
export const rotateWorkorderPhotos = (data) => {
    return {
        type: 'ROTATE_WORKORDER_PHOTO',
        payload: data
    }
} 
export const getWorkorderItems = (data = {}) => {
    return {
        type: 'FETCH_WORKORDER_ITEMS',
        payload: data
    };
}

export const cancelCompleteWorkorder = (data) => {
    return {
      type: 'CANCEL_COMPLETE_WORKORDER',
      payload: data
    };
}

export const getWorkorderNotes = (data = {}) => {
    return {
        type: "FETCH_WORKORDER_NOTES",
        payload: data,
    }
}

export const createWorkorderNote = (data) => {
    return {
        type: "CREATE_WORKORDER_NOTE",
        payload: data,
        componentName:'AddWorkOrderNote',
        message: 'Note added successfully!',
        workorderId: data?.workorderId
    }
}

export const deleteWorkorderNote = (data) => {
    return {
        type: "DELETE_WORKORDER_NOTE",
        payload: data,
        componentName:'DeleteWorkOrderNote',
        message: 'Note deleted successfully!',
        workorderId: data?.workorderId
    }
}

export const shareUnshareWorkorderNote = (data) => {
    return {
        type: "SHARE_UNSHARE_WORKORDER_NOTE",
        payload: data,
        componentName:'ShareUnshareWorkOrderNote',
        message: 'Note shared / unshared successfully!',
    }
}

export const updateWorkorderNote = (data) => {
    return {
        type: "UPDATE_WORKORDER_NOTE",
        payload: data,
        componentName:'EditWorkOrderNote',
        message: 'Note updated successfully!',
    }
}

export const updateWorkorderIconData = (data) => {
    return {
      type: "UPDATE_WORKORDER_ICON_DATA",
      payload: data,
    };
  };

export const assignSubtasksToWorkorder = (data) =>{
    return {
        type: "CREATE_WORKORDER_ASSIGN_SUBTASKS",
        payload: data
    };
}

export const getWorkorderList = (data) =>{
    return {
        type: "FETCH_WORKORDER_LIST",
        payload: data
    };
}

export const getWorkorder = (data) =>{
    return {
        type: "FETCH_WORKORDER",
        payload: data
    };
}

export const updateWorkorderAttribute = (data) => {
    return {
      type: "UPDATE_WORKORDER_ATTRIBUTES",
      payload: data,
    };
  };

export const updateWorkorder = (data) => {
    return {
        type: "UPDATE_WORKORDER",
        payload: data,
    };
};
  
export const getTimePerHour = (data) => {
    return {
        type: "FETCH_UPDATE_TIME_PER_HOUR",
        payload: data,
    };
};

export const GetWorkorderTotal =  (data) => {
    return {
      type: "GET_WORKORDER_TOTAL",
      payload: data,
    };
  };

export const getWorkOrderLogList  = (data) => {
    return {
        type: 'FETCH_WORKORDER_LOGS',
        payload:data
    };
}

export const quickAssignWoStaff = (data) => {
    return {
      type: "QUICK_ASSIGN_WO_STAFF",
      payload: data,
    };
};

export const bulkConfirmWorkorder = (data) => {
    return {
      type: 'BULK_CONFIRM_WORKORDER',
      payload: data
    };
}

export const getIntegrationTrackWorkorder = (data) => {
    return {
      type: 'FETCH_INTEGRATION_TRACK_WORKORDER',
      payload: data
    };
}

export const getWoAnalyticsCounts = (data) => {
    return {
      type: "GET_WO_ANALYTICS_COUNTS",
      payload: data,
    };
};


export const getWoAnalyticsCharts = (data) => {
    return {
      type: "GET_WO_ANALYTICS_CHARTS",
      payload: data,
    };
};

export const getWorkorderAssignmentTrackingHkData = (data) => {
    return { 
      type: 'GET_WORKORDER_ASSIGNMENT_TRACKING_HK_DATA',
      payload: data
    };
}

export const woQuickAssignTrackingCount = (data) => {
    return {
      type: "WO_QUICK_ASSIGN_TRACKING_COUNT",
      payload: data,
    };
}

export const activeInactiveWorkorderDepartment = (data) => {
    return {
        type: 'ACTIVE_INACTIVE_WORKORDER_DEPARTMENT',
        payload: data
    };
}


export const getWorkorderDepartment = (data) => {
    return {
        type: 'FETCH_WORKORDER_DEPARTMENT',
        payload: data
    };
}
export const updateWorkorderDeparment = (data) => {
    return {
      type: 'UPDATE_WORKORDER_DEPARTMENT',
      payload: data
    };
}
export const createWorkorderDepartment = (data) => {
    return {
        type: 'CREATE_WORKORDER_DEPARTMENT',
        payload: data,
        componentName:'AddWorkOrderDepartment',
        message: 'Department name has been Added Successfully!',
    };
}

export const workorderDepartmentList = (data) => {
    return {
        type: 'WORKORDER_DEPARTMENT_LIST',
        payload: data,
    };
}

export const pushIndividualItemToOwnerRez = (data) => {
    return {
        type:"PUSH_INDIVIDUAL_ITEM_TO_OWNERREZ",
        payload : data
    }
}

export const pushWorkorderToIntegration = (data) => {
    return {
        type:"PUSH_WORKORDER_TO_INTEGRATION",
        payload : data
    }
}