const initState = {
    commonAreaDropdown: [],
  };
  
  const CommonArea = (state = initState, action) => {
    switch (action.type) {
      case 'SET_COMMONAREA_DROPDOWN_LIST':
        return {
          ...state,
          commonAreaDropdown: action.payload
        };
  
      case 'CLEAR_COMMONAREA_DROPDOWN_LIST':
        return {
          ...state,
          commonAreaDropdown: []
        };

        case 'SET_COMMONAREA_TEMPLATE':
          return {
            ...state,
            singleCaTemplate: action.payload
          };
    
        case 'CLEAR_COMMONAREA_TEMPLATE':
          return {
            ...state,
            singleCaTemplate: null
          };

        case 'SET_CA_TEMPLATE_AREA_BY_TEMPLATE':
          return {
            ...state,
            CaTemplateArea: action.payload
          };
    
        case 'CLEAR_CA_TEMPLATE_AREA_BY_TEMPLATE':
          return {
            ...state,
            CaTemplateArea: null
          };

        case 'SET_CA_TEMPLATE_ITEM_BY_AREA':
          return {
            ...state,
            CaTemplateItem: action.payload
          };
    
        case 'CLEAR_CA_TEMPLATE_ITEM_BY_AREA':
          return {
            ...state,
            CaTemplateItem: []
          };
      
        case 'SET_CA_TEMPLATE_AREA_ITEMS':
          return {
            ...state,
            CaTemplateAreaItem: action.payload
          };
    
        case 'CLEAR_CA_TEMPLATE_AREA_ITEMS':
          return {
            ...state,
            CaTemplateAreaItem: []
          };

        case "SET_COMMONAREA_TEMPLATES_DROPDOWN_LIST": {
          return {
            ...state,
            commonAreaTemplatesDropdown: action.payload
          }
        }
        
        case 'SET_CL_CA_DROPDOWN': {
          return {
            ...state,
            caStaff : action.payload,
          };
        }

        case 'SET_COMMONAREA_APPOINTMENT':
          return {
            ...state,
            singleCaAppoinment: action.payload
          };
    
        case 'CLEAR_COMMONAREA_APPOINTMENT':
          return {
            ...state,
            singleCaAppoinment: null
          };

        case 'SET_COMMON_AREA_APPOINTMENTS': {
          return {
            ...state,
            caAppoinments: action.payload
          };
        }

        case 'CLEAR_COMMON_AREA_APPOINTMENTS': {
          return {
            ...state,
            caAppoinments: []
          };
        }
  
        default:
          return state;
    }
  };
  
  export default CommonArea;
  