const initState = {
  reportData: []
}

const DailyCloseout = (state = initState, action) => {
  switch (action.type) {
    case "SET_DROPDOWN_DCP_PM": {
      return {
        ...state,
        pmDropdown: action.payload,
      }
    }

    case "CLEAR_DROPDOWN_DCP_PM": {
      return {
        ...state,
        pmDropdown: [],
      }
    }

    case "SET_DCP_OVERDUE_NOT_COMPLETED_APPOINTMENTS": {
      return {
        ...state,
        dcpOverdueNotCompletedAppts: action.payload.data,
      }
    }

    case "SET_DCP_TODAY_NOT_COMPLETED_APPOINTMENTS": {
      return {
        ...state,
        dcpTodayNotCompletedAppts: action.payload.data,
      }
    }

    case "SET_DCP_DND_OVERDUE_NOT_COMPLETED_APPOINTMENTS": {
      return {
        ...state,
        dcpDndOverdueNotCompletedAppts: action.payload.data,
      }
    }

    case "SET_DCP_DND_TODAY_NOT_COMPLETED_APPOINTMENTS": {
      return {
        ...state,
        dcpDndTodayNotCompletedAppts: action.payload.data,
      }
    }

    case "CLEAR_DCP_OVERDUE_NOT_COMPLETED_APPOINTMENTS": {
      return {
        ...state,
        dcpOverdueNotCompletedAppts: [],
      }
    }

    case "CLEAR_DCP_TODAY_NOT_COMPLETED_APPOINTMENTS": {
      return {
        ...state,
        dcpTodayNotCompletedAppts: [],
      }
    }

    case "CLEAR_DCP_DND_OVERDUE_NOT_COMPLETED_APPOINTMENTS": {
      return {
        ...state,
        dcpDndOverdueNotCompletedAppts: [],
      }
    }

    case "CLEAR_DCP_DND_TODAY_NOT_COMPLETED_APPOINTMENTS": {
      return {
        ...state,
        dcpDndTodayNotCompletedAppts: [],
      }
    }

    case "SET_DROPDOWN_DCP_PM_LIST": {
      return {
        ...state,
        pmDropdownOptions: action.payload,
      }
    }

    case "CLEAR_DROPDOWN_DCP_PM_LIST": {
      return {
        ...state,
        pmDropdownOptions: [],
      }
    }

    case "SET_DCP_BASELINE_ROLES": {
      return {
        ...state,
        dcpBaselineRoles: action.payload.data,
      }
    }

    case "CLEAR_DCP_BASELINE_ROLES": {
      return {
        ...state,
        dcpBaselineRoles: [],
      }
    }

    case "SET_DCP_CONTRACT_POSITIONS": {
      return {
        ...state,
        dcpContractPositions: action.payload.data,
      }
    }

    case "CLEAR_DCP_CONTRACT_POSITIONS": {
      return {
        ...state,
        dcpContractPositions: [],
      }
    }

    case "SET_DCP_COMPLETED_APPOINTMENTS": {
      return {
        ...state,
        dcpCompletedAppts: action.payload.data,
      }
    }

    case "CLEAR_DCP_COMPLETED_APPOINTMENTS": {
      return {
        ...state,
        dcpCompletedAppts: [],
      }
    }

    case "SET_DCP_DND_COMPLETED_APPOINTMENTS": {
      return {
        ...state,
        dcpDndCompletedAppts: action.payload.data,
      }
    }

    case "CLEAR_DCP_DND_COMPLETED_APPOINTMENTS": {
      return {
        ...state,
        dcpDndCompletedAppts: [],
      }
    }

    case "SET_DCP_OVERDUE_COMPLETED_APPOINTMENTS": {
      return {
        ...state,
        dcpOverdueCompletedAppts: action.payload.data,
      }
    }

    case "CLEAR_DCP_OVERDUE_COMPLETED_APPOINTMENTS": {
      return {
        ...state,
        dcpOverdueCompletedAppts: [],
      }
    }

    case "SET_DCP_DND_OVERDUE_COMPLETED_APPOINTMENTS": {
      return {
        ...state,
        dcpDndOverdueCompletedAppts: action.payload.data,
      }
    }

    case "CLEAR_DCP_DND_OVERDUE_COMPLETED_APPOINTMENTS": {
      return {
        ...state,
        dcpDndOverdueCompletedAppts: [],
      }
    }

    case "SET_DCP_REPORT_BASELINE_ROLES": {
      return {
        ...state,
        dcpReportBaselineRoles: action.payload.data,
      }
    }

    case "CLEAR_DCP_REPORT_BASELINE_ROLES": {
      return {
        ...state,
        dcpReportBaselineRoles: [],
      }
    }

    case "SET_DCP_REPORT_SUPPLEMENTAL_HRS": {
      return {
        ...state,
        dcpReportSupplementalHrs: action.payload.data,
      }
    }

    case "CLEAR_DCP_REPORT_SUPPLEMENTAL_HRS": {
      return {
        ...state,
        dcpReportSupplementalHrs: [],
      }
    }

    case "SET_DCP_REPORT_CONTRACT_POSITIONS": {
      return {
        ...state,
        dcpReportContractPositions: action.payload.data,
      }
    }

    case "CLEAR_DCP_REPORT_CONTRACT_POSITIONS": {
      return {
        ...state,
        dcpReportContractPositions: [],
      }
    }

    default: {
      return state;
    }

  }
};

export default DailyCloseout;
