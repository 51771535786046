
export const prepareInventoryTemplatesDropdownOptions = (inventoryTemplates) => {
  let finalMasterInventoryTemplateList = [];
  inventoryTemplates.forEach((template) => {
    if (template) {
      finalMasterInventoryTemplateList.push({
        value: template.inventory_template_id,
        label: template.inventory_template_title
      });
    }
  });
  return finalMasterInventoryTemplateList;
};
export const prepareNewInventoryByContainerWise = (inventoryData) => {
  const groupedData = inventoryData.reduce((acc, item) => {
    const { container_name, item_name, quantity, item_image } = item;
    if (!acc[container_name]) {
      acc[container_name] = {
        items: []
      };
    }
    acc[container_name].items.push({ item_name, quantity, item_image });
    return acc;
  }, {});
  return groupedData;
};


export const prepareNewInventoryByContainerWiseForAppointment = (inventoryData) => {
  const groupedData = inventoryData.reduce((acc, item) => {
    const { container_name, item_name, quantity, item_image,inventory_template_item_id } = item;
    if (!acc[container_name]) {
      acc[container_name] = [];
    }
    acc[container_name].push({ item_name, quantity, item_image, container_name,inventory_template_item_id });
    return acc;
  }, {});
  return groupedData;
};
