export const toggleModal = (showModal) => async (dispatch,getState) =>{
    dispatch({
        type:"TOGGLE_MODAL",
        payload:showModal
    })
}


export const toggleAlert = (showAlert) => async (dispatch,getState) =>{
    dispatch({
        type:"TOGGLE_ALERT",
        payload:showAlert
    })
}

export const isLoader = (isLoader) => async (dispatch,getState) =>{
    dispatch({
        type:"IS_LOADER",
        payload:isLoader
    });
}

export const getDropdowns = (data) => async (dispatch,getState) =>{
    dispatch({
        type:"GET_DROPDOWN",
        payload:data
    });
}
export const getProfessionType = (data) => async (dispatch,getState) =>{
    dispatch({
        type:"GET_PROFESSION_TYPE",
        payload:data
    });
}

export const activeDeactive = (data) => async (dispatch, getState) => {
//{id:'', apiName:'' , endpoint:'' , componentName:'' }
    dispatch({
        type: 'ACTIVE_INACTIVE',
        payload: data
    });
}
export const updateGlobalFilter = (data) => {
    return {
        type: 'UPDATE_GLOBAL_REGION_AREA_FILTER',
        payload: data
    };
  }

export const pdfReportDownload = (data) => {
    return {
      type: "PDF_REPORT_DOWNLOAD",
      payload: data,
    }
};

export const excelReportDownload = (data) => {
    return {
      type: "EXCEL_REPORT_DOWNLOAD",
      payload: data,
    }
};

export const downloadReports = (data) => {
    return {
      type: "REPORT_DOWNLOAD",
      payload: data,
    }
};