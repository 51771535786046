import APIservice from "../apiService";
import { isLoader } from "../global/action";
import { microServices } from "../../config/global";
import { setError } from "../form/action";
import { setGridFilter } from "../../utils/gridUtil";
import React, { useState, useContext, useEffect } from "react";
import { preparePropertyInventoryByContainerWise } from "../../utils/propertyUtil";


/**
 * ApiMiddleware can be used to call any User related API services
 * @param {*} store 
 * @returns 
 */
const PropertyMiddleware = (store) => (next) => async (action) => {
    const result    = next(action);
    let endpoint  = '';
    let per_page_cond= '';
    switch (action.type) {
        case "CREATE_REGION":
          endpoint = microServices.base+'/v1/company/region';
            store.dispatch(isLoader(true));
              APIservice
                .post(endpoint, action.payload)
                .then((response)=>{
                  store.dispatch({ 
                    type: 'SHOW_ALERT', 
                    payload: {
                      variant:'success',
                      message: 'Property Region has been successfully added.',
                      componentName:'AddRegion'
                    }
                  });
                })
                .catch((error)=>{
                  if (error.response.status !== 422) {
                    store.dispatch({ 
                      type: 'SHOW_ALERT', 
                      payload: {
                        variant:'danger',
                        message: 'Unable to save region information!'
                      }
                    });
                  } else {
                    let err = {componentName:'AddRegion',statusCode: error.response.status,data: error.response.data};
                    store.dispatch(setError(err));
                  }
                })
                .finally(()=>{
                  store.dispatch(isLoader(false));
                })
            break;
            case "DROPDOWN_REGION_LIST":
              endpoint = microServices.base+'/v1/company/region?per_page=0';
              
              let mang_filter = (action.payload?.manager_id) ? '&filter[manager_id]='+action.payload?.manager_id : ''; 
              endpoint = endpoint+mang_filter;
                APIservice
                  .get(endpoint)
                  .then((response)=>{
                    let regionList = prepareRegionList(response);
                    if(action.payload?.manager_id){
                      store.dispatch({ 
                        type: 'SET_DROPDOWN_MANAGER_WISE_LIST', 
                        payload: regionList,
                      });
                    }
                    else{
                      store.dispatch({ 
                        type: 'SET_DROPDOWN_REGION_LIST', 
                        payload: regionList,
                      });
                    }
                  })
                  .catch((error)=>{
                    if (error.response.status !== 422) {
                      if(action.payload?.manager_id){
                        store.dispatch({ 
                          type: 'SET_DROPDOWN_MANAGER_WISE_LIST', 
                          payload: [],
                        });
                      }
                      else{
                        store.dispatch({ 
                          type: 'SET_DROPDOWN_REGION_LIST', 
                          payload: [],
                        });
                      }
                    } 
                  })
                  
                break;    

          case "DROPDOWN_TIMEZONES_LIST":
                  endpoint = microServices.base+'/v1/dropdown/timezones';
                      APIservice
                        .get(endpoint)
                        .then((response)=>{
                          let timezonesList = prepareTimezonesList(response);
                          store.dispatch({ 
                            type: 'SET_DROPDOWN_TIMEZONES_LIST', 
                            payload: timezonesList,
                          });
                        })
                        .catch((error)=>{
                          if (error.response.status !== 422) {
                            store.dispatch({ 
                              type: 'SET_DROPDOWN_TIMEZONES_LIST', 
                              payload: [],
                            });
                          } 
                        })
                        
                    break;
                    
                    case "DROPDOWN_PROPERTYTYPES_LIST":
                      endpoint = microServices.base+'/v1/dropdown/propertyTypes';
                          APIservice
                            .get(endpoint)
                            .then((response)=>{
                              let propertyTypesList = preparePropertyTypesList(response.data.data);
                              store.dispatch({ 
                                type: 'SET_DROPDOWN_PROPERTYTYPES_LIST', 
                                payload: propertyTypesList,
                              });
                            })
                            .catch((error)=>{
                              if (error.response.status !== 422) {
                                store.dispatch({ 
                                  type: 'SET_DROPDOWN_PROPERTYTYPES_LIST', 
                                  payload: [],
                                });
                              } 
                            })
                            
                        break;
        case "DROPDOWN_REGION_WISE_AREA_LIST":
          endpoint = microServices.base+'/v1/company/regional-area?show_region=1&filter[status_id]=1&filter[region_area_id]='+action.payload.region_id;
          let manager_filter = (action.payload?.manager_id) ? '&filter[manager_id]='+action.payload?.manager_id : ''; 
          endpoint = endpoint+manager_filter+'&per_page=0';
        
          APIservice
            .get(endpoint)
            .then((response)=>{
              let areaList = prepareAreaList(response);
              store.dispatch({ 
                type: 'SET_DROPDOWN_AREA_LIST', 
                payload: areaList,
              });
            })
            .catch((error)=>{
              if (error.response.status !== 422) {
              store.dispatch({ 
                type: 'SET_DROPDOWN_AREA_LIST', 
                payload: [],
              });
            } 
          })
        break;
        case "FETCH_REGION":
          endpoint = microServices.base+'/v1/company/region';
          store.dispatch(isLoader(true));
          APIservice
            .get(endpoint+'/'+action.payload.id)
            .then((response)=>{
              if(response && response.data){
                store.dispatch({ 
                type: 'SET_DATA_FOR_EDIT', 
                payload: {
                  data:response.data.data
                  }
                });
              }
            })
            .catch((error)=>{
              let err = {componentName:'Region',statusCode: error.response.status,data: error.response.data};
              store.dispatch(setError(err));
            })
            .finally(()=>{
              store.dispatch(isLoader(false));
            })
        break;
        case "FETCH_AREA":
          endpoint = microServices.base+'/v1/company/regional-area';
          store.dispatch(isLoader(true));
          APIservice
            .get(endpoint+'/'+action.payload.id)
            .then((response)=>{
              if(response && response.data){
                store.dispatch({ 
                type: 'SET_DATA_FOR_EDIT', 
                payload: {
                  data:response.data.data
                  }
                });
              }
            })
            .catch((error)=>{
              let err = {componentName:'Area',statusCode: error.response.status,data: error.response.data};
              store.dispatch(setError(err));
            })
            .finally(()=>{
              store.dispatch(isLoader(false));
            })
        break;

        case "UPDATE_REGION":
          endpoint = microServices.base+'/v1/company/region';
          store.dispatch(isLoader(true));
          APIservice
            .put(endpoint+'/'+action.payload.id, action.payload.formData)
            .then((response)=>{
              store.dispatch({ 
                type: 'SHOW_ALERT', 
                payload: {
                  variant:'success',
                  message: 'Property Region has been updated successfully.',
                  componentName:'EditRegion'
                }
              });
            })
            .catch((error)=>{
              if (error.response.status !== 422) {
                store.dispatch({ 
                  type: 'SHOW_ALERT', 
                  payload: {
                    variant:'danger',
                    message: 'Unable to save region information!',
                  }
                });
              } else {
                let err = {componentName:'EditRegion',statusCode: error.response.status,data: error.response.data};
                store.dispatch(setError(err));
              }
            })
            .finally(()=>{
              store.dispatch(isLoader(false));
            })
          break;
          case "UPDATE_AREA":
          endpoint = microServices.base+'/v1/company/regional-area';
          store.dispatch(isLoader(true));
          APIservice
            .put(endpoint+'/'+action.payload.id, action.payload.formData)
            .then((response)=>{
              store.dispatch({ 
                type: 'SHOW_ALERT', 
                payload: {
                  variant:'success',
                  message: 'Area updated successfully!',
                  componentName:'EditArea'
                }
              });
            })
            .catch((error)=>{
              if (error.response.status !== 422) {
                store.dispatch({ 
                  type: 'SHOW_ALERT', 
                  payload: {
                    variant:'danger',
                    message: 'Unable to save area information!',
                  }
                });
              } else {
                let err = {componentName:'EditArea',statusCode: error.response.status,data: error.response.data};
                store.dispatch(setError(err));
              }
            })
            .finally(()=>{
              store.dispatch(isLoader(false));
            })
          break;
          

        case "ACTIVE_INACTIVE_REGION":
          endpoint = microServices.base+'/v1/company/region';
          store.dispatch(isLoader(true));
          APIservice
            .put(`${endpoint}/${action.payload.apiName}/${action.payload.id}`)
            .then((response)=>{
              store.dispatch({ 
                type: 'SHOW_ALERT', 
                payload: {
                  variant:'success',
                  message: `Property Region has been ${action.payload.message}d successfully.`,
                  componentName:'ActiveInactiveRegion'
                }
              });
            })
            .catch((error)=>{
              if (error.response.status !== 422) {
                store.dispatch({ 
                  type: 'SHOW_ALERT', 
                  payload: {
                    variant:'danger',
                    message: `Unable to ${action.payload.message} region information!`
                  }
                });
              } else {
                  let err = {componentName:'ActiveInactiveRegion',statusCode: error.response.status,data: error.response.data};
                  store.dispatch(setError(err));
                }
            })
            .finally(()=>{
              store.dispatch(isLoader(false));
            })
        break;
        case "CREATE_AREA":
          endpoint = microServices.base+'/v1/company/regional-area';
            store.dispatch(isLoader(true));
              APIservice
                .post(endpoint, action.payload)
                .then((response)=>{
                  store.dispatch({ 
                    type: 'SHOW_ALERT', 
                    payload: {
                      variant:'success',
                      message: '"Property Area has been successfully added.',
                      componentName:'AddArea'
                    }
                  });
                })
                .catch((error)=>{
                  if (error.response.status !== 422) {
                    store.dispatch({ 
                      type: 'SHOW_ALERT', 
                      payload: {
                        variant:'danger',
                        message: 'Unable to save area information!'
                      }
                    });
                  } else {
                    let err = {componentName:'AddArea',statusCode: error.response.status,data: error.response.data};
                    store.dispatch(setError(err));
                  }
                })
                .finally(()=>{
                  store.dispatch(isLoader(false));
                })
            break;
        case "ACTIVE_INACTIVE_AREA":
          endpoint = microServices.base+'/v1/company/regional-area';
          store.dispatch(isLoader(true));
          APIservice
            .put(`${endpoint}/${action.payload.apiName}/${action.payload.id}`)
            .then((response)=>{
              store.dispatch({ 
                type: 'SHOW_ALERT', 
                payload: {
                  variant:'success',
                  message: `Property Area ${action.payload.apiName}d successfully!`,
                  componentName:'activeInactiveArea'
                }
              });
            })
            .catch((error)=>{
              if (error.response.status !== 422) {
                store.dispatch({ 
                  type: 'SHOW_ALERT', 
                  payload: {
                    variant:'danger',
                    message: `Unable to ${action.payload.apiName} area information!`
                  }
                });
              } else {
                  let err = {componentName:'activeInactiveArea',statusCode: error.response.status,data: error.response.data};
                  store.dispatch(setError(err));
                }
            })
            .finally(()=>{
              store.dispatch(isLoader(false));
            })
        break;
        case "REGION_AREA_ACCESS":
          endpoint = microServices.base+action.endpoint;
            store.dispatch(isLoader(true));
            APIservice
        .get(endpoint)
        .then((response)=>{
          if(response && response.data){
            store.dispatch({ 
            type: 'SET_REGION_AREA_ACCESS', 
            payload: {
              data:response.data.data
              }
            });
          }
        })
        .catch((error)=>{
          let err = {componentName:action.componentName,statusCode: error.response.status,data: error.response.data};
          store.dispatch(setError(err));
        })
        .finally(()=>{
          store.dispatch(isLoader(false));
        })
    break; 
    case "DROPDOWN_PROPERTY_ENTRY_METHOD_LIST":
      endpoint = microServices.base+'/v1/dropdown/propertyEntryMethod';
          APIservice
            .get(endpoint)
            .then((response)=>{
              let entryMethodList = preparePropertyEntryMethodList(response);
              store.dispatch({ 
                type: 'SET_DROPDOWN_PROPERTY_ENTRY_METHOD_LIST', 
                payload: entryMethodList,
              });
            })
            .catch((error)=>{
              if (error.response.status !== 422) {
                store.dispatch({ 
                  type: 'SET_DROPDOWN_PROPERTY_ENTRY_METHOD_LIST', 
                  payload: [],
                });
              } 
            })
            
        break;

        case "DROPDOWN_PROPERTY_SQUARE_FEET_LIST":
      endpoint = microServices.base+'/v1/dropdown/propertySquareFeet';
          APIservice
            .get(endpoint)
            .then((response)=>{
              let squareFeetList = preparePropertySquareFeetList(response);
              store.dispatch({ 
                type: 'SET_DROPDOWN_PROPERTY_SQUARE_FEET_LIST', 
                payload: squareFeetList,
              });
            })
            .catch((error)=>{
              if (error.response.status !== 422) {
                store.dispatch({ 
                  type: 'SET_DROPDOWN_PROPERTY_SQUARE_FEET_LIST', 
                  payload: [],
                });
              } 
            })
            
        break;
        case "PROPERTIES_DROPDOWN":
          
          let params = {};
          endpoint = `${microServices.base}/v1/property/${action.payload.type}`;
          if (action.payload.hasOwnProperty('params')) {
            endpoint = setGridFilter(endpoint, action.payload.params);
          } 
          params['sort'] = 'property_name';
          
          if (action.payload.hasOwnProperty('perPage')) {
            params['per_page'] = action.payload.perPage;
          }else{
            params['per_page'] = 0;
          }
          APIservice
            .get(endpoint, params)
            .then((response)=>{
            let propertiesList = preparePropertiesList(response,action.payload.type);
            
              store.dispatch({ 
                type: 'SET_DROPDOWN_PROPERTIES_LIST', 
                payload: propertiesList,
              });
           })
            .catch((error)=>{
              if (error.response.status !== 422) {
              store.dispatch({ 
                type: 'SET_DROPDOWN_PROPERTIES_LIST', 
                payload: [],
              });
            } 
        })
        break; 
        case "FETCH_PROPERTIES":
          let propParams = {};      
          if (action.payload.hasOwnProperty('perPage')) {
            propParams['per_page'] = action.payload.perPage;
          }else{
            propParams['per_page'] = 0;
          }
          
          endpoint = microServices.base+'/v1/property';
          endpoint = (action?.userType) ? endpoint+'/'+action.userType  :endpoint;
          let dispatchType = '';
          endpoint = (action.payload?.id) ? endpoint+'/'+action.payload.id :endpoint;
          endpoint =(action.payload?.apptPropCond) ? endpoint+'?'+action.payload.apptPropCond :endpoint;
          if(action.payload?.dispatchType){
            dispatchType = action.payload.dispatchType;
          }
          store.dispatch(isLoader(true));
          APIservice
            .get(endpoint,propParams)
            .then((response)=>{
              if(response && response.data){
                if(dispatchType==='appt'){
                 
                  store.dispatch({ 
                    type: 'SET_PROPERTY_DATA_APPT', 
                    payload: {
                      data:response.data.data
                      }
                    });
                }else{
                
                  store.dispatch({ 
                    type: 'SET_DATA_FOR_EDIT', 
                    payload: {
                      data:response.data.data
                      }
                    });
                }
               
              }
            })
            .catch((error)=>{
              let err = {componentName:'Property',statusCode: error.response.status,data: error.response.data};
              store.dispatch(setError(err));
            })
            .finally(()=>{
              store.dispatch(isLoader(false));
            })
        break;
        case "CREATE_PROPERTY":   
                endpoint  = microServices.base+action.endpoint+action.userType;
                store.dispatch(isLoader(true));
                APIservice
                .post(endpoint, action.payload.formData)
                .then((response)=>{
                    store.dispatch({ 
                      type: 'SHOW_ALERT', 
                      payload: {
                          variant:'success',
                          message: action.payload.successMessage,
                          componentName:action.componentName
                      }
                      });
                })
                .catch((error)=>{
                    if (error.response.status !== 422) {
                    store.dispatch({ 
                        type: 'SHOW_ALERT', 
                        payload: {
                        variant:'danger',
                        message: action.payload.failMessage
                        }
                    });
                    } else {
                    let err = {componentName:action.componentName,statusCode: error.response.status,data: error.response.data};
                    store.dispatch(setError(err));
                    }
                }).finally(()=>{
                    store.dispatch(isLoader(false));
                  })
            break;

        case "EDIT_REGION_AREA_ACCESS":
          endpoint = microServices.base+action.endpoint+action.payload.id;
          store.dispatch(isLoader(true));
            APIservice
        .get(endpoint)
        .then((response)=>{
          if(response && response.data){
            store.dispatch({ 
            type: 'SET_REGION_AREA_ACCESS', 
            payload: {
              data:response.data.data
              }
            });
          }
        })
        .catch((error)=>{
          let err = {componentName:action.componentName,statusCode: error.response.status,data: error.response.data};
          store.dispatch(setError(err));
        })
        .finally(()=>{
          store.dispatch(isLoader(false));
        })
    break;
    case "UPDATE_PROPERTY":
          endpoint  = microServices.base+action.endpoint+action.userType;
          store.dispatch(isLoader(true));
          APIservice
            .put(endpoint+'/'+action.payload.id,action.payload.formData)
            .then((response)=>{
              store.dispatch({ 
                type: 'SHOW_ALERT', 
                payload: { 
                  variant:'success',
                  message: action.payload.successMessage,
                  componentName:action.componentName
                }
                });
          })
          .catch((error)=>{            
              if (error.response.status !== 422 || action.componentName=='EditPropertyConfig') {
                    store.dispatch({ 
                        type: 'SHOW_ALERT', 
                        payload: {
                        variant:'danger',
                        message: action.payload.failMessage,
                        componentName:action.componentName
                        }
                    });
              } else {
              let err = {componentName:action.componentName,statusCode: error.response.status,data: error.response.data};
              store.dispatch(setError(err));
              }
          }).finally(()=>{
              store.dispatch(isLoader(false));
            })
      break;
      case "CREATE_PROPERTY_NOTES":
        endpoint = microServices.base+'/v1/property/notes';
          store.dispatch(isLoader(true));
          APIservice
          .post(endpoint, action.payload)
          .then((response)=>{
            store.dispatch({ 
              type: 'SHOW_ALERT', 
              payload: {
                variant:'success',
                message: 'Note added successfully.',
                componentName:'AddPropertyNotes'
              }
            });
          })
          .catch((error)=>{
            if (error.response.status !== 422) {
              store.dispatch({ 
                type: 'SHOW_ALERT', 
                payload: {
                  variant:'danger',
                  message: 'Unable to save notes information!'
                }
              });
            } else {
              let err = {componentName:'AddPropertyNotes',statusCode: error.response.status,data: error.response.data};
              store.dispatch(setError(err));
            }
          })
          .finally(()=>{
            store.dispatch(isLoader(false));
          })
      break;
      case "ACTIVE_INACTIVE_PROPERTIES":
          endpoint = microServices.base+'/v1/property';
          store.dispatch(isLoader(true));
          APIservice
            .put(`${endpoint}/${action.payload.apiName}/${action.payload.id}`)
            .then((response)=>{
              store.dispatch({ 
                type: 'SHOW_ALERT', 
                payload: {
                  variant:'success',
                  message: `Property has been ${action.payload.message}d successfully.`,
                  componentName:'ActiveInactiveProperties'
                }
              });
            })
            .catch((error)=>{
              if (error.response.status !== 422) {
                store.dispatch({ 
                  type: 'SHOW_ALERT', 
                  payload: {
                    variant:'danger',
                    message: `Unable to ${action.payload.message} Property information!`
                  }
                });
              } else {
                  let err = {componentName:'ActiveInactiveProperties',statusCode: error.response.status,data: error.response.data};
                  store.dispatch(setError(err));
                }
            })
            .finally(()=>{
              store.dispatch(isLoader(false));
            })
        break;
        case "FETCH_PROPERTY_NOTES":
          endpoint = microServices.base+'/v1/property';
          store.dispatch(isLoader(true));
          APIservice
            .get(endpoint+'/'+action.payload.id+'/notes')
            .then((response)=>{
              if(response && response.data){
                store.dispatch({ 
                  type: 'SET_PROPERTY_NOTES', 
                  payload: {
                    data:response.data.data,
                    no_of_notes:response.data.meta.total
                  }
                });
              }
            })
            .catch((error)=>{
              let err = {componentName:'PropertyNotes',statusCode: error.response.status,data: error.response.data};
              store.dispatch(setError(err));
            })
            .finally(()=>{
              store.dispatch(isLoader(false));
            })
        break;

        case "ASSIGN_UNASSIGN_CLEANER_TO_PROPERTIES":      
        endpoint  = microServices.base+'/v1/property/cleaner/'+action.payload.cleaner_id+'/properties/'+action.payload.userAction;
        store.dispatch(isLoader(true));
        APIservice
        .post(endpoint, action.payload.formData)
        .then((response)=>{
            store.dispatch({ 
            type: 'SHOW_ALERT', 
            payload: {
                variant:'success',
                message: (action.payload.userAction=='assign' ) ? 'Cleaner Assigned successfully!' :  'Cleaner Unassigned successfully!',
                componentName:'AssignUnassignCleanerToProperty'
            }
            });
        })
        .catch((error)=>{
            if (error.response.status !== 422) {
            store.dispatch({ 
                type: 'SHOW_ALERT', 
                payload: {
                variant:'danger',
                message: (action.payload.userAction=='assign' ) ? 'Unable to assign cleaner' :  'Unable to unassign cleaner',
                }
            });
            } else {
            let err = {componentName:'AssignUnassignCleanerToProperty',statusCode: error.response.status,data: error.response.data};
            store.dispatch(setError(err));
            }
        }).finally(()=>{
            store.dispatch(isLoader(false));
          })
    break;

        case "CLONE_PROPERTY":
          endpoint  = microServices.base+action.endpoint;
          store.dispatch(isLoader(true));
          APIservice
            .post(endpoint+action.payload.id+'/clone',action.payload.formData)
            .then((response)=>{
              store.dispatch({ 
                type: 'SHOW_ALERT', 
                payload: { 
                  variant:'success',
                  message: action.message,
                  componentName:action.componentName
                }
                });
          })
          .catch((error)=>{
              if (error.response.status !== 422) {
              store.dispatch({ 
                  type: 'SHOW_ALERT', 
                  payload: {
                  variant:'danger',
                  message: 'Unable to create property Clone!'
                  }
              });
              } else {
              let err = {componentName:action.componentName,statusCode: error.response.status,data: error.response.data};
              store.dispatch(setError(err));
              }
          }).finally(()=>{
              store.dispatch(isLoader(false));
            })
      break;
      case "FETCH_SINGLE_PROPERTY_NOTE":
              endpoint = microServices.base+'/v1/property/notes';
              store.dispatch(isLoader(true));
              APIservice
              .get(endpoint+'/'+action.payload.id)
              .then((response)=>{
                if(response && response.data){
                  store.dispatch({ 
                  type: 'SET_SINGLE_PROPERTY_NOTES', 
                  payload: {
                    data:response.data.data
                    }
                  });
                }
              })
              .catch((error)=>{
                let err = {componentName:'PropertyNotes',statusCode: error.response.status,data: error.response.data};
                store.dispatch(setError(err));
              })
              .finally(()=>{
                store.dispatch(isLoader(false));
              })
      break;
      case "UPDATE_PROPERTY_NOTE":
        endpoint = microServices.base+'/v1/property/notes';
          store.dispatch(isLoader(true));
          APIservice
            .put(endpoint+'/'+action.payload.property_note_id, action.payload)
            .then((response)=>{
              store.dispatch({ 
                type: 'SHOW_ALERT', 
                payload: {
                  variant:'success',
                  message: 'Note Updated Successfully.',
                  componentName:'EditNote'
                }
              });
            })
            .catch((error)=>{
              if (error.response.status !== 422) {
                store.dispatch({ 
                  type: 'SHOW_ALERT', 
                  payload: {
                    variant:'danger',
                    message: 'Unable to save note information!',
                  }
                });
              } else {
                let err = {componentName:'EditNote',statusCode: error.response.status,data: error.response.data};
                store.dispatch(setError(err));
              }
            })
            .finally(()=>{
              store.dispatch(isLoader(false));
            })
      break;
      case "DELETE_PROPERTY_NOTE":
          endpoint = microServices.base+'/v1/property/notes/'+action.payload.id;
            store.dispatch(isLoader(true));
            APIservice
            .delete(endpoint)
            .then((response)=>{
              store.dispatch({ 
                type: 'SHOW_ALERT', 
                payload: {
                  variant:'success',
                  message: 'Note deleted successfully.',
                  componentName:'DeletePropertyNote'
                }
              });
            })
            .catch((error)=>{
              if (error.response.status !== 422) {
                store.dispatch({ 
                  type: 'SHOW_ALERT', 
                  payload: {
                    variant:'danger',
                    message: 'Unable to delete Property Note!'
                  }
                });
              } else {
                let err = {componentName:'EditProperty',statusCode: error.response.status,data: error.response.data};
                store.dispatch(setError(err));
              }
            })
            .finally(()=>{
              store.dispatch(isLoader(false));
            })
        break;
        case "SHARE_PROPERTY_NOTE":
          endpoint = microServices.base+'/v1/property/notes/share/'+action.payload.id;
            store.dispatch(isLoader(true));
            APIservice
            .put(endpoint)
            .then((response)=>{
              store.dispatch({ 
                type: 'SHOW_ALERT', 
                payload: {
                  variant:'success',
                  message: `Note ${action.payload.isShare ? 'unshared' : 'shared'} successfully.`,
                  componentName:'SharedPropertyNote'
                }
              });
            })
            .catch((error)=>{
              if (error.response.status !== 422) {
                store.dispatch({ 
                  type: 'SHOW_ALERT', 
                  payload: {
                    variant:'danger',
                    message: 'Unable to share Property Note!'
                  }
                });
              } else {
                let err = {componentName:'EditProperty',statusCode: error.response.status,data: error.response.data};
                store.dispatch(setError(err));
              }
            })
            .finally(()=>{
              store.dispatch(isLoader(false));
            })
        break;
        case "FETCH_PROPERTY_PHOTO":
          endpoint = microServices.base+'/v1/property';
          store.dispatch(isLoader(true));
          APIservice
            .get(endpoint+'/'+action.payload.id+'/photos?per_page=0')
            .then((response)=>{
              if(response && response.data){
                store.dispatch({ 
                  type: 'SET_PROPERTY_PHOTOS', 
                  payload: {
                    data:response.data.data,
                    no_of_photos:response.data.meta.total
                  }
                });
              }
            })
            .catch((error)=>{
              let err = {componentName:'PropertyPhotos',statusCode: error.response.status,data: error.response.data};
              store.dispatch(setError(err));
            })
            .finally(()=>{
              store.dispatch(isLoader(false));
            })
        break;
        case "SHARE_PROPERTY_PHOTO" :
            endpoint = microServices.base+'/v1/property/photos/share/'+action.payload.id;
            store.dispatch(isLoader(true));
            APIservice
            .put(endpoint)
            .then((response)=>{
              store.dispatch({ 
                type: 'SHOW_ALERT', 
                payload: {
                  variant:'success',
                  message: `Photo ${action.payload.msg} successfully.`,
                  componentName:'SharedPropertyPhoto'
                }
              });
            })
            .catch((error)=>{
              if (error.response.status !== 422) {
                store.dispatch({ 
                  type: 'SHOW_ALERT', 
                  payload: {
                    variant:'danger',
                    message: 'Unable to share Property Photo!'
                  }
                });
              } else {
                let err = {componentName:'EditProperty',statusCode: error.response.status,data: error.response.data};
                store.dispatch(setError(err));
              }
            })
            .finally(()=>{
              store.dispatch(isLoader(false));
            })
        break;
        case "SHARE_ALL_PROPERTY_PHOTO":
          endpoint = microServices.base+'/v1/property/'+action.payload.id+'/photos/share-all';
          store.dispatch(isLoader(true));
          APIservice
          .put(endpoint)
          .then((response)=>{
            store.dispatch({ 
              type: 'SHOW_ALERT', 
              payload: {
                variant:'success',
                message: 'Photos shared successfully.',
                componentName:'SharedPropertyPhoto'
              }
            });
          })
          .catch((error)=>{
            if (error.response.status !== 422) {
              store.dispatch({ 
                type: 'SHOW_ALERT', 
                payload: {
                  variant:'danger',
                  message: 'Unable to share Property Photo!'
                }
              });
            } else {
              let err = {componentName:'EditProperty',statusCode: error.response.status,data: error.response.data};
              store.dispatch(setError(err));
            }
          })
          .finally(()=>{
            store.dispatch(isLoader(false));
          })
        break;
        case "ROTATE_PROPERTY_PHOTO":
          endpoint = microServices.base+'/v1/property/photos/rotate/'+action.payload.direction+'/'+action.payload.id;
          store.dispatch(isLoader(true));
          APIservice
          .patch(endpoint)
          .then((response)=>{
            if(response && response.data){
              store.dispatch({ 
                type: 'SHOW_ALERT', 
                payload: {
                  variant:'success',
                  message: 'Photo rotated successfully.',
                  componentName:'RotatePropertyPhoto'
                }
              });
            }
          })
          .catch((error)=>{
            if (error.response.status !== 422) {
              store.dispatch({ 
                type: 'SHOW_ALERT', 
                payload: {
                  variant:'danger',
                  message: 'Unable to rotate Property Photo!'
                }
              });
            } else {
              let err = {componentName:'EditProperty',statusCode: error.response.status,data: error.response.data};
              store.dispatch(setError(err));
            }
          })
          .finally(()=>{
            store.dispatch(isLoader(false));
          })
        break;
        case "DELETE_PROPERTY_PHOTO" : 
          endpoint = microServices.base+'/v1/property/photos/'+action.payload.id;
          store.dispatch(isLoader(true));
          APIservice
          .delete(endpoint)
          .then((response)=>{
            store.dispatch({ 
              type: 'SHOW_ALERT', 
              payload: {
                variant:'success',
                message: 'Photo deleted successfully.',
                componentName:'DeletePropertyPhoto'
              }
            });
          })
          .catch((error)=>{
            if (error.response.status !== 422) {
              store.dispatch({ 
                type: 'SHOW_ALERT', 
                payload: {
                  variant:'danger',
                  message: 'Unable to delete Property Photo!'
                }
              });
            } else {
              let err = {componentName:'EditProperty',statusCode: error.response.status,data: error.response.data};
              store.dispatch(setError(err));
            }
          })
          .finally(()=>{
            store.dispatch(isLoader(false));
          })
        break;
          case 'PROPERTY_CONFIG_LIST':
               let baseUrl = microServices.base+'/v1/property/'+action.payload.userType;               
                endpoint=setGridFilter(baseUrl,action.payload.filter);
               store.dispatch(isLoader(true));
               APIservice
                 .get(endpoint)
                 .then((response)=>{
                      
                  if(response && response.data){
                      store.dispatch({ 
                      type: 'SET_PROPERTY_CONFIG_LIST', 
                      payload: {
                        data:response.data.data
                        }
                      });
                  }
                 })
                 .catch((error)=>{
                   let err = {componentName:'propertyConfig',statusCode: error.response.status,data: error.response.data};
                   store.dispatch(setError(err));
                 })
                 .finally(()=>{
                   store.dispatch(isLoader(false));
                 })
          break;
          case "UPDATE_PROPERTY_SERVICE":
            endpoint = microServices.base+action.payload.url;
          
            APIservice
              .put(endpoint+'/'+action.payload.propertyId+'/'+action.payload.serviceId, action.payload.formData)
              .then((response)=>{
                if(action.payload?.types=='internal'){
                  store.dispatch({ 
                    type: 'SET_PROPERTY_INTERNAL_SERVICE', 
                    payload: {
                      data:response.data.data
                      }
                  });
                }
                else{
                  store.dispatch({ 
                    type: 'SET_PROPERTY_SERVICE', 
                    payload: {
                      data:response.data.data
                      }
                  });
                }
              })
              .catch((error)=>{
                if (error.response.status !== 422) {
                  store.dispatch({ 
                    type: 'SET_PROPERTY_SERVICE', 
                    payload: {
                      data:[]
                      }
                  });
                  store.dispatch({ 
                    type: 'SET_PROPERTY_INTERNAL_SERVICE', 
                    payload: {
                      data:[]
                    }
                  });
                } else {
                  let err = {componentName:'Property Price config',statusCode: error.response.status,data: {status:'false'}};
                  store.dispatch(setError(err));
                }
              })
              .finally(()=>{
                store.dispatch(isLoader(false));
              })
            break;
            case "CREATE_PROPERTY_SERVICE":
              if(action.payload?.url){
                endpoint =  microServices.base+action.payload?.url;
              }
              else{
                endpoint = microServices.base+'/v1/property/service/price-config';
              }
              APIservice
                .post(endpoint, action.payload.formData)
                .then((response)=>{
                  if(action.payload?.types=='internal'){
                    store.dispatch({ 
                      type: 'SET_PROPERTY_INTERNAL_SERVICE', 
                      payload: {
                        data:response.data.data
                        }
                    });
                  }
                  else{
                    store.dispatch({ 
                      type: 'SET_PROPERTY_SERVICE', 
                      payload: {
                        data:response.data.data
                      }
                    });
                  }
                })
                .catch((error)=>{
                  if (error.response.status !== 422) {
                    store.dispatch({ 
                      type: 'SET_PROPERTY_SERVICE', 
                      payload: {
                        data:[]
                        }
                    });
                    store.dispatch({ 
                      type: 'SET_PROPERTY_INTERNAL_SERVICE', 
                      payload: {
                        data:[]
                        }
                    });
                  } else {
                    let err = {componentName:'PropertyPriceConfig',statusCode: error.response.status,data: {status:'false'}};
                    store.dispatch(setError(err));
                    store.dispatch({ 
                      type: 'SET_PROPERTY_SERVICE', 
                      payload: {
                        data:[]
                        }
                    });
                    store.dispatch({ 
                      type: 'SET_PROPERTY_INTERNAL_SERVICE', 
                      payload: {
                        data:[]
                      }
                    });
                  }
                })
                .finally(()=>{
                  store.dispatch(isLoader(false));
                })
              break;
              case "DELETE_PROPERTY_SERVICE":
                store.dispatch(isLoader(true));
                endpoint = microServices.base+'/v1/property/service/price-config/'+action.payload.serviceId;
                  APIservice
                    .delete(endpoint)
                    .then((response)=>{
                     
                        store.dispatch({ 
                          type: 'SHOW_ALERT', 
                          payload: {
                            variant:'success',
                            message: 'Property service deleted successfully!',
                            componentName : 'delete'
                          }
                        });
                      
                    })
                    .catch((error)=>{
                        if (error.response.status !== 422) {
                          store.dispatch({ 
                            type: 'SHOW_ALERT', 
                            payload: {
                              variant:'danger',
                              message: 'Unable to Delete property service!',
                            }
                          })}
                          else {
                            let err = {componentName:'deletePropertyService',statusCode: error.response.status,data: error.response.data};
                            store.dispatch(setError(err));
                          }
                        })
                    .finally(()=>{
                      store.dispatch(isLoader(false));
                    });
                break;

        case "INITIALIZE_UPLOAD_TEMP_PROPERTY":
            endpoint = microServices.base+'/v1/property/uploads/initilize';
            store.dispatch(isLoader(true));
            APIservice
            .post(endpoint, action.payload.formData)
              .then((response)=>{
                store.dispatch({ 
                  type: 'UPLOAD_TEMP_PROPERTY',
                  payload: { 
                    'uploadUrl':response.data.data.upload,
                    'uploadFiledata':action.payload.formData
                  }
                  });
              })
              .catch((error)=>{
                let err = {componentName:'Upload_PM',statusCode: error.response.status,data: error.response.data};
                store.dispatch(setError(err));
                store.dispatch(isLoader(false));
              });
      break;
      case "UPLOAD_TEMP_PROPERTY":
        endpoint = action.payload.uploadUrl;
        store.dispatch(isLoader(true));
        APIservice
        .post(endpoint,action.payload.uploadFiledata)
        .then((response)=>{
          store.dispatch({ 
            type: 'SHOW_ALERT', 
            payload: {
              variant:'success',
              componentName:'UploadTempProperty',
              data : response
            }
          });
         })
        .catch((error)=>{
            let err = {componentName:action.componentName,statusCode: error.response.status,data: error.response.data};
            store.dispatch(setError(err));
        }).finally(()=>{
            store.dispatch(isLoader(false));
        })
        break;
        case "FETCH_TEMP_PROPERTY":
          endpoint = microServices.base+'/v1/property/uploads';
          store.dispatch(isLoader(true));
          APIservice
            .get(endpoint+'/'+action.payload.session_id)
            .then((response)=>{
              if(response && response.data){
                store.dispatch({ 
                type: 'SET_TEMP_PROPERTY_DATA', 
                payload: {
                  data:response.data
                  }
                });
              }
            })
            .catch((error)=>{
              let err = {componentName:'PropertyUploads',statusCode: error.response.status,data: error.response.data};
              store.dispatch(setError(err));
            })
            .finally(()=>{
              store.dispatch(isLoader(false));
            })
        break;
        case 'FETCH_PROPERTY_SERVICE_PRICE_CONFIG':
          endpoint = microServices.base+'/v1/property/service/price-config';

          if(action.payload?.active) {
            endpoint += '/active';
          }
          endpoint+='?filter[properties_id]='+action.payload.propertyId;
          per_page_cond = (action.payload.hasOwnProperty('per_page')) ? '&per_page='+action.payload.per_page : "";
          endpoint += per_page_cond;

         APIservice
            .get(endpoint)
            .then((response)=>{
 
              store.dispatch({ 
                type: 'SET_PROPERTY_SERVICE_CONFIG', 
                payload: response.data,
              });
            })
            .catch((error)=>{
              if (error.response.status !== 422) {
              store.dispatch({ 
                type: 'SET_PROPERTY_SERVICE_CONFIG', 
                payload: [],
              });
            } 
          })
        break;

        case "UPLOAD_ACTUAL_PROPERTY":
          endpoint = microServices.base+'/v1/property/upload/'+action.payload.session_id;
          store.dispatch(isLoader(true));
          APIservice
          .post(endpoint,action.payload)
          .then((response)=>{
            store.dispatch({ 
              type: 'SHOW_ALERT', 
              payload: {
                variant:'success',
                componentName:'UploadActualProperty',
                data : response,
                message: 'Uploaded successfully..'
              }
            });
           })
          .catch((error)=>{
              let err = {statusCode: error.response.status,data: error.response.data};
              store.dispatch(setError(err));
          }).finally(()=>{
              store.dispatch(isLoader(false));
          })
        break;
        case "FETCH_INSPECTION_TEMPLATE":
          endpoint = microServices.inspection+'/v1/templates?filter[type]=2';
          let Region_Filter = (action.payload?.region_id) ? '&filter[region_id]='+action.payload?.region_id: ''; 
          let Area_Filter = (action.payload?.area_id) ? '&filter[area_id]='+action.payload?.area_id: ''; 
          let Status_Filter = (action.payload?.status) ? '&filter[status]='+action.payload?.status: ''; 

          endpoint = endpoint+Region_Filter+Area_Filter+Status_Filter;
          APIservice
            .get(endpoint)
            .then((response)=>{
              let templateList = prepareInspectionTemplateList(response);
              store.dispatch({ 
                type: 'SET_DROPDOWN_INSPECTION_LIST', 
                payload: templateList,
              });
            })
            .catch((error)=>{
              if (error.response.status !== 422) {
              store.dispatch({ 
                type: 'SET_DROPDOWN_AREA_LIST', 
                payload: [],
              });
            } 
          })
        break;

        case 'FETCH_PM_PROPERTY_SERVICE_PRICE_CONFIG':
      
          endpoint = microServices.base+'/v1/property/pm/service/price-config';

          if(action.payload?.active) {
            endpoint += '/active';
          }
          
          endpoint+='?filter[properties_id]='+action.payload.propertyId;
          if(action.payload?.cleaner_status) {
            endpoint += '&filter[cleaner_status]=1';
          }
          per_page_cond = (action.payload.hasOwnProperty('per_page')) ? '&per_page='+action.payload.per_page : "";
          endpoint+=per_page_cond;
          
          APIservice
            .get(endpoint)
            .then((response)=>{
            
              store.dispatch({ 
                type: 'SET_PROPERTY_SERVICE_CONFIG', 
                payload: response.data,
              });
            })
            .catch((error)=>{
              if (error.response.status !== 422) {
              store.dispatch({ 
                type: 'SET_PROPERTY_SERVICE_CONFIG', 
                payload: [],
              });
            } 
          })
        break;

        case "PROPERTIES_INSPECTION_TEMPLATE_DROPDOWN":
          let inspParams = {};
          let regionArea='';
          if(action.payload.region_id) {
            regionArea += '&filter[region_id]='+action.payload.region_id
            regionArea += action.payload.area_id ? '&filter[area_id]='+action.payload.area_id : ''
          }
          if (action.payload.hasOwnProperty('perPage')) {
            inspParams['per_page'] = action.payload.perPage;
          }else{
            inspParams['per_page'] = 0;
          }
          endpoint = microServices.base+'/v1/property/'+action.payload.type+'?filter[status_id]=1&filter[hasInspection]=true&sort=property_name'+regionArea;
          APIservice
            .get(endpoint,inspParams)
            .then((response)=>{
              store.dispatch({ 
                type: 'SET_DROPDOWN_PROPERTIES_LIST', 
                payload: response.data.data,
              });
            })
            .catch((error)=>{
              if (error.response.status !== 422) {
              store.dispatch({ 
                type: 'SET_DROPDOWN_PROPERTIES_LIST', 
                payload: [],
              });
            } 
          })
        break; 
        
        case "CREATE_PROPERTY_SERVICE_AUTOMATION":
          if(action.payload?.url){
            endpoint = microServices.base+action.payload?.url;
          }
          else{
            endpoint = microServices.base+'/v1/property/service/price-config/automation';
          }
          APIservice
            .post(endpoint, action.payload.formData)
            .then((response)=>{
              if(action.payload?.types=='internal'){
                store.dispatch({ 
                  type: 'SET_PROPERTY_INTERNAL_SERVICE', 
                  payload: {
                    data:{automations : response.data.data}
                    }
                });
              }
              else{
                store.dispatch({ 
                  type: 'SET_PROPERTY_SERVICE', 
                  payload: {
                    data:{automations : response.data.data}
                    }
                });
              }
            })
            .catch((error)=>{
              if (error.response.status !== 422) {
                store.dispatch({ 
                  type: 'SET_PROPERTY_SERVICE', 
                  payload: {
                    data:[]
                    }
                });
              } else {
                let err = {componentName:'PropertyPriceConfig',statusCode: error.response.status,data: {status:'false'}};
                store.dispatch(setError(err));
                store.dispatch({ 
                  type: 'SET_PROPERTY_SERVICE', 
                  payload: {
                    data:[]
                    }
                });
              }
            })
            .finally(()=>{
              store.dispatch(isLoader(false));
            })
          break;
      
          case "DECLINE_UPLOAD_PROPERTY":
          endpoint = microServices.base+'/v1/property/uploads/delete';
          APIservice
            .delete(endpoint, action.payload.formData)
            .then((response)=>{
                     
              store.dispatch({ 
                type: 'SHOW_ALERT', 
                payload: {
                  variant:'success',
                  message: 'Property Declined successfully!',
                  componentName : 'PropertyDecline'
                }
              });
            
          })
          .catch((error)=>{
              if (error.response.status !== 422) {
                store.dispatch({ 
                  type: 'SHOW_ALERT', 
                  payload: {
                    variant:'danger',
                    message: 'Unable to Decline property!',
                  }
                })}
                else {
                  let err = {componentName:'PropertyDecline',statusCode: error.response.status,data: error.response.data};
                  store.dispatch(setError(err));
                }
              })
          .finally(()=>{
            store.dispatch(isLoader(false));
          });
          break;

          case "FETCH_INTERNAL_PROPERTIES":
          endpoint = microServices.base+'/v1/property/pm/service/internals/price-config';
          
          if(action.payload?.active) {
            endpoint += '/active';
          }

          let internalParams = {};
      
          if (action.payload.hasOwnProperty('perPage')) {
            internalParams['per_page'] = action.payload.perPage;
          }else{
            internalParams['per_page'] = 0;
          }
         
          let  internalCondi = (action.payload?.apptPropCond) ? '?'+action.payload?.apptPropCond :'';
          store.dispatch(isLoader(true));
          APIservice
            .get(endpoint+internalCondi,internalParams)
            .then((response)=>{
              if(response && response.data){
                  store.dispatch({ 
              
                    type: 'SET_PROPERTY_INTERNAL_SERVICE', 
                    payload: {
                      data:response.data.data
                      }
                    });
              }
            })
            .catch((error)=>{
              let err = {componentName:'Property',statusCode: error.response.status,data: error.response.data};
              store.dispatch(setError(err));
            })
            .finally(()=>{
              store.dispatch(isLoader(false));
            })
        break;

        case "DROPDOWN_PROPERTY_OWNER_LIST": {
          endpoint = microServices.base+'/v1/dropdown/propertyOwner/'+(action.payload.params.company_id);
              APIservice.get(endpoint)
                .then((response) => {
                  let propertyOwnerList = preparePropertyOwnerList(response.data);
                  store.dispatch({ 
                    type: 'SET_DROPDOWN_PROPERTY_OWNER_LIST',
                    payload: propertyOwnerList,
                  });
                })
                .catch((error)=>{
                  if (error.response.status !== 422) {
                    store.dispatch({ 
                      type: 'SET_DROPDOWN_PROPERTY_OWNER_LIST', 
                      payload: [],
                    });
                  } 
                })
          break;
        }

        case "ADD_EXISTING_PROPERTY_OWNER": {
          endpoint = microServices.base+'/v1/property/owner/add-existing';
          store.dispatch(isLoader(true));
          APIservice.put(endpoint, action.payload.data)
            .then((response) => {
              if(response && response.data) {
                store.dispatch({
                    type: 'SHOW_ALERT', 
                    payload: {
                        variant: "success",
                        message: action.message,
                        componentName: action.componentName,                            
                    }
                });
              }                
            })
            .catch((error) => {
              if (error.response.status !== 422) {
                  store.dispatch({
                      type: "SHOW_ALERT",
                      payload: {
                          variant: "danger",
                          message: "Unable to add owner!",
                    },
                  });
              } else {
                  let err = {
                      componentName: action.componentName,
                      statusCode: error.response.status,
                      data: error.response.data,
                  };
                  store.dispatch(setError(err));
              }
          })
          .finally(() => {
              store.dispatch(isLoader(false));
          })
          break;
        }

        case "CREATE_PROPERTY_OWNER": {
          endpoint = microServices.base+'/v1/property/owner';
          store.dispatch(isLoader(true));
          APIservice.post(endpoint, action.payload.data)
            .then((response) => {
              if(response && response.data) {
                let disableAlert = (action.payload.data && action.payload.data.hasOwnProperty('disableAlert')) ? true : false;
                if(!disableAlert){
                  store.dispatch({
                      type: 'SHOW_ALERT', 
                      payload: {
                          variant: "success",
                          message: action.message,
                          componentName: action.componentName,                            
                      }
                  });
                }
              }                
            })
            .catch((error) => {
              if (error.response.status !== 422) {
                  store.dispatch({
                      type: "SHOW_ALERT",
                      payload: {
                          variant: "danger",
                          message: "Unable to add owner!",
                    },
                  });
              } else {
                  let err = {
                      componentName: action.componentName,
                      statusCode: error.response.status,
                      data: error.response.data,
                  };
                  store.dispatch(setError(err));
              }
          })
          .finally(() => {
              store.dispatch(isLoader(false));
          })
          break;
        }

        case 'FETCH_PROPERTY_OWNER_DETAILS': {
          endpoint = microServices.base+'/v1/property/owner/'+action.payload.params.propertyOwnerId;
          store.dispatch(isLoader(true));
          APIservice.get(endpoint)
            .then((response) => {
              store.dispatch({ 
                type: 'SET_PROPERTY_OWNER_DETAILS', 
                payload: response.data,
              });
            })
            .catch((error) => {
              if (error.response.status !== 422) {
              store.dispatch({ 
                type: 'SET_PROPERTY_OWNER_DETAILS', 
                payload: [],
              });
            }
          })
          .finally(() => {
            store.dispatch(isLoader(false));
          })
          break;
        }

        case "UPDATE_PROPERTY_OWNER_DETAILS": {
          endpoint = microServices.base+'/v1/property/owner/'+action.payload.params.propertyOwnerId;
          store.dispatch(isLoader(true));
          APIservice.put(endpoint, action.payload.data)
            .then((response) => {
              if(response && response.data) {
                let disableAlert = (action.payload.data && action.payload.data.hasOwnProperty('disableAlert')) ? true : false;
                if(!disableAlert){
                  store.dispatch({
                    type: 'SHOW_ALERT', 
                    payload: {
                        variant: "success",
                        message: action.message,
                        componentName: action.componentName,                            
                    }
                  });
                }
              }                
            })
            .catch((error) => {
              if (error.response.status !== 422) {
                  store.dispatch({
                      type: "SHOW_ALERT",
                      payload: {
                          variant: "danger",
                          message: "Unable to update owner!",
                    },
                  });
              } else {
                  let err = {
                      componentName: action.componentName,
                      statusCode: error.response.status,
                      data: error.response.data,
                  };
                  store.dispatch(setError(err));
              }
          })
          .finally(() => {
              store.dispatch(isLoader(false));
          })
          break;
        }

        case "UPDATE_PROPERTY_INTEGRATION":
          endpoint = microServices.base+'/v1/property/integrations';
          APIservice
            .post(endpoint+'/'+action.payload.id, action.payload.formData)
            .then((response)=>{
              store.dispatch({ 
                type: 'SHOW_ALERT', 
                payload: {
                  variant:'success',
                  message: 'Automation Link has been updated successfully.',
                  componentName:'UpdatePropertyIntegration'
                }
              });
            })
            .catch((error)=>{
              if (error.response.status !== 422) {
                store.dispatch({ 
                  type: 'SHOW_ALERT', 
                  payload: {
                    variant:'danger',
                    message: 'Unable to update automation link',
                    componentName:'UpdatePropertyIntegration'
                  }
                });
              } else {
                let err = {componentName:'UpdatePropertyIntegration',statusCode: error.response.status,data: error.response.data};
                store.dispatch(setError(err));
              }
            })
            .finally(()=>{
              store.dispatch(isLoader(false));
            })
        break;

        case "UPDATE_CL_PROPERTY_INTEGRATION":
          endpoint = microServices.base+'/v1/property/cleanerintegrations';
          APIservice
            .post(endpoint+'/'+action.payload.id, action.payload.formData)
            .then((response)=>{
              store.dispatch({ 
                type: 'SHOW_ALERT', 
                payload: {
                  variant:'success',
                  message: 'Automation Link has been updated successfully.',
                  componentName:'UpdatePropertyIntegration'
                }
              });
            })
            .catch((error)=>{
              if (error.response.status !== 422) {
                store.dispatch({ 
                  type: 'SHOW_ALERT', 
                  payload: {
                    variant:'danger',
                    message: 'Unable to update automation link',
                    componentName:'UpdatePropertyIntegration'
                  }
                });
              } else {
                let err = {componentName:'UpdatePropertyIntegration',statusCode: error.response.status,data: error.response.data};
                store.dispatch(setError(err));
              }
            })
            .finally(()=>{
              store.dispatch(isLoader(false));
            })
        break;
      
        case "UPDATE_DEFAULT_PROPERTY_CLEANER":
          endpoint = microServices.base+'/v1/property/pm/service/defaultcleaner';
            store.dispatch(isLoader(true));
            APIservice
              .put(endpoint+'/'+action.payload.property_cleaner_id, action.payload.formData)
              .then((response)=>{
                store.dispatch({ 
                  type: 'SET_DEFAULT_PROPERTY_CLEANER', 
                  payload: {
                    data:response.data.data
                    }
                });
              })
              .catch((error)=>{
                if (error.response.status !== 422) {
                  store.dispatch({ 
                    type: 'SHOW_ALERT', 
                    payload: {
                      variant:'danger',
                      message: 'Unable to save note information!',
                    }
                  });
                } else {
                  let err = {componentName:'EditDefaultCleaner',statusCode: error.response.status,data: error.response.data};
                  store.dispatch(setError(err));
                }
              })
              .finally(()=>{
                store.dispatch(isLoader(false));
              })
        break;

        case 'GET_ALL_PROPERTY_PRICE_CONFIG':
          endpoint = microServices.base+action.payload.url;
            
          if(action?.payload?.url?.includes('?')) {
            endpoint += '&per_page=0';
          } else {
            endpoint += '?per_page=0';
          }
          APIservice
            .get(endpoint)
            .then((response)=>{
              store.dispatch({ 
                type: 'SET_PROPERTY_SERVICE_CONFIG', 
                payload: response.data,
              });
            })
            .catch((error)=>{
              if (error.response.status !== 422) {
              store.dispatch({ 
                type: 'SET_PROPERTY_SERVICE_CONFIG', 
                payload: [],
              });
            } 
          });
        break;

        case "DROPDOWN_PROPERTY_CLEANERS": {
          endpoint = `${microServices.base}/v1/dropdown/property-cleaners/${action.payload.params.properties_id}`;
          APIservice.get(endpoint)
            .then((response) => {
              let propertyCleanersDropdown = preparePropertyCleanersDropdown(response.data);
              store.dispatch({ 
                type: 'SET_DROPDOWN_PROPERTY_CLEANERS',
                payload: propertyCleanersDropdown,
              });
            })
            .catch((error)=>{
              if (error.response.status !== 422) {
                store.dispatch({ 
                  type: 'SET_DROPDOWN_PROPERTY_CLEANERS', 
                  payload: [],
                });
              } 
            })
          break;
        }

        case "EXPORT_PROPERTY":
          let api = action.payload.exportApi;
          store.dispatch(isLoader(true));
          APIservice.
          getBlobDataWithParams(api,"")
          .then((response)=>{
              const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = blobUrl;
              link.setAttribute('download', `${(action.payload?.fileName) ? action.payload.fileName : "Report"}_${Date.now()}.xlsx`);
              document.body.appendChild(link);
              link.click(); 
              link.parentNode.removeChild(link);
          })
          .catch((error)=>{
            if (error.response.status !== 422) {
                store.dispatch({ 
                    type: 'SHOW_ALERT', 
                    payload: {
                        variant:'danger',
                        message: 'Unable to Download Report!',
                    }
                });
            } else {
                let err = {componentName:'DownloadPropertyXlsx',statusCode: error.response.status,data: error.response.data};
                store.dispatch(setError(err));
            }
          })
          .finally(()=>{
              store.dispatch(isLoader(false));
          });
      break;
      case "PRINT_PROPERTY":
            let printApi = action.payload.printApi;
            store.dispatch(isLoader(true));
            APIservice.
            getBlobDataWithParams(printApi,"")
            .then((response)=>{
                const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
                   const link = document.createElement('a');
                   link.href = blobUrl;
                   link.setAttribute('download', `${(action.payload?.fileName) ? action.payload.fileName : "Document"}_${Date.now()}.pdf`);
                   document.body.appendChild(link);
                   link.click(); 
                   link.parentNode.removeChild(link);
            })
            .catch((error)=>{
                if (error.response.status !== 422) {
                    store.dispatch({ 
                        type: 'SHOW_ALERT', 
                        payload: {
                            variant:'danger',
                            message: 'Unable to Download Report!',
                        }
                    });
                } else {
                    let err = {componentName:'DownloadReportPdf',statusCode: error.response.status,data: error.response.data};
                    store.dispatch(setError(err));
                }
            })
            .finally(()=>{
                store.dispatch(isLoader(false));
            });
            break;
      case "FETCH_INVENTORY_SMART_TEMPLATE":
        endpoint = `${microServices.base}/v1/property/${action.payload.properties_id}/inventory-smart/${action.payload.inventory_smart_id}/template`;
        store.dispatch(isLoader(true));
        APIservice
        .get(endpoint)
        .then((response)=>{
          if(response && response.data){
            store.dispatch({ 
              type: 'SET_INVENTORY_SMART_TEMPLATE', 
              payload:response.data.data,
            });
          }
        })
        .catch((error)=>{
          let err = {componentName:'PropertyInventorySmart',statusCode: error.response.status,data: error.response.data};
          store.dispatch(setError(err));
        })
        .finally(()=>{
          store.dispatch(isLoader(false));
        })
      break;
      case "UPDATE_INVENTORY_SMART_DEPLETE":
        endpoint = `${microServices.base}/v1/property/${action.payload.properties_id}/inventory-smart/template`;
        store.dispatch(isLoader(true));
        APIservice
          .put(endpoint, action.payload.formData)
          .then((response)=>{
            store.dispatch({ 
              type: 'SHOW_ALERT', 
              payload: {
                variant:'success',
                message: 'Inventory Smart Item has been updated successfully.',
                componentName:'EditInventorySmart'
              }
            });
          })
          .catch((error)=>{
            if (error.response.status !== 422) {
              store.dispatch({ 
                type: 'SHOW_ALERT', 
                payload: {
                  variant:'danger',
                  message: 'Unable to save Inventory Smart information!',
                }
              });
            } else {
              let err = {componentName:'EditInventorySmart',statusCode: error.response.status,data: error.response.data};
              store.dispatch(setError(err));
            }
          })
          .finally(()=>{
            store.dispatch(isLoader(false));
          })
      break;

      case "FETCH_PROPERTY_INTEGRATIONS":
        endpoint = `${microServices.base}/v1/property/integrations?filter[status_id]=1&per_page=20&page=1&filter[properties_id]=${action.payload.id}`;
        APIservice
        .get(endpoint)
        .then((response)=>{
          if(response && response.data){
            const [data] = response.data.data;
            store.dispatch({ 
              type: 'SET_PROPERTY_INTEGRATIONS',
              payload: data,
            });
          }
        })
        .catch((error)=>{
          let err = {componentName:'PropertyIntegration',statusCode: error.response.status,data: error.response.data};
          store.dispatch(setError(err));
        })
        .finally(()=>{
          store.dispatch(isLoader(false));
        })
      break;
      
      case "UPDATE_PROPERTY_GUESTY_INTEGRATIONS":
        endpoint = `${microServices.base}/v1/property/integrations/guesty/${action.payload.id}`;
        store.dispatch(isLoader(true));
        APIservice
          .put(endpoint, action.payload.formData)
          .then((response)=>{
            store.dispatch({ 
              type: 'SHOW_ALERT', 
              payload: {
                variant:'success',
                message: 'Property Integrations updated successfully.',
                componentName:'EditPropertyGuestyIntegrations'
              }
            });
          })
          .catch((error)=>{
            if (error.response.status !== 422) {
              store.dispatch({ 
                type: 'SHOW_ALERT', 
                payload: {
                  variant:'danger',
                  message: 'Unable to save Property Integrations!',
                }
              });
            } else {
              let err = {componentName:'EditPropertyGuestyIntegrations',statusCode: error.response.status,data: error.response.data};
              store.dispatch(setError(err));
            }
          })
          .finally(()=>{
            store.dispatch(isLoader(false));
          })
      break;
      case "UPDATE_PROPERTY_INTEGRATIONS":
        endpoint = `${microServices.base}/v1/property/integrations/${action.payload.id}`;
        store.dispatch(isLoader(true));
        APIservice
          .put(endpoint, action.payload.data)
          .then((response)=>{
            store.dispatch({ 
              type: 'SHOW_ALERT', 
              payload: {
                variant:'success',
                message: 'Property Integrations updated successfully.',
                componentName:'EditPropertyIntegrations'
              }
            });
          })
          .catch((error)=>{
            if (error.response.status !== 422) {
              store.dispatch({ 
                type: 'SHOW_ALERT', 
                payload: {
                  variant:'danger',
                  message: 'Unable to save Property Integrations!',
                }
              });
            } else {
              let err = {componentName:'EditPropertyIntegrations',statusCode: error.response.status,data: error.response.data};
              store.dispatch(setError(err));
            }
          })
          .finally(()=>{
            store.dispatch(isLoader(false));
          })
          
      break;
      case "MOVE_PROPERTY_TO_MANAGER":
          endpoint  = `${microServices.base}/v1/property/${action.payload.id}/move`;
          store.dispatch(isLoader(true));
          APIservice
            .put(endpoint,action.payload.formData)
            .then((response)=>{
              store.dispatch({ 
                type: 'SHOW_ALERT', 
                payload: { 
                  variant:'success',
                  message: 'Property has been moved successfully',
                  componentName:"MoveProperty"
                }
                });
          })
          .catch((error)=>{            
              if (error.response.status !== 422) {
                    store.dispatch({ 
                        type: 'SHOW_ALERT', 
                        payload: {
                          variant:'danger',
                          message: 'Unable to move property!',
                          componentName:"MoveProperty"
                        }
                    });
              } else {
                let err = {componentName:"MoveProperty",statusCode: error.response.status,data: error.response.data};
                store.dispatch(setError(err));
              }
          }).finally(()=>{
              store.dispatch(isLoader(false));
          })
      break;
      case "UPDATE_PROPERTY_INSPECTION_TOGGLE":
        endpoint = `${microServices.base}/v1/property/inspectionToggle/${action.payload.id}`;
        APIservice
          .post(endpoint, action.payload.formData)
          .then((response)=>{
          })
          .catch((error)=>{
            if (error?.response?.status !== 422) {
              store.dispatch({ 
                type: 'SHOW_ALERT', 
                payload: {
                  variant:'danger',
                  message: 'Unable to save Property Toggle!',
                }
              });
            } else {
              let err = {componentName:'EditPropertyToggle',statusCode: error.response.status,data: error.response.data};
              store.dispatch(setError(err));
            }
          })
          .finally(()=>{
          })
          
      break;

      case "FETCH_PROPERTIES_DROPDOWN":
        let queryParams = {};
        endpoint = `${microServices.base}/v1/dropdown/property/${action.payload.type}`;
        if (action.payload.hasOwnProperty('params')) {
          endpoint = setGridFilter(endpoint, action.payload.params);
        } 
        queryParams['sort'] = 'property_name,unit_no';
        
        if (action.payload.hasOwnProperty('perPage')) {
          queryParams['per_page'] = action.payload.perPage;
        }else{
          queryParams['per_page'] = 0;
        }
        APIservice
          .get(endpoint, queryParams)
          .then((response)=>{
            let propertiesList = preparePropertyDropdown(response,action.payload.type);
            store.dispatch({ 
              type: 'SET_PROPERTIES_DROPDOWN_LIST', 
              payload: propertiesList,
            });
          })
          .catch((error)=>{
            if (error.response.status !== 422) {
              store.dispatch({ 
                type: 'SET_PROPERTIES_DROPDOWN_LIST', 
                payload: [],
              });
            } 
          })

        break;
      
        case "UPDATE_PROPERTY_ATTRIBUTES":
          endpoint = microServices.base + "/v1/user/additional-property-attributes";
          store.dispatch(isLoader(true));
          APIservice.post(endpoint, action.payload.formData)
            .then((response) => {
              store.dispatch({ type: "GET_LOGGED_USER_DETAILS" });
              store.dispatch({
                type: "SHOW_ALERT",
                payload: {
                  variant: "success",
                  message: "Column updated successfully!",
                  componentName: "EditPropertyColumn",
                },
              });
            })
            .catch((error) => {
              let err = {
                componentName: "EditPropertyColumn",
                statusCode: error.response.status,
                data: error.response.data,
              };
              store.dispatch(setError(err));
            })
            .finally(() => {
              store.dispatch(isLoader(false));
            });
        break;
          case "INITIALIZE_UPLOAD_TEMP_PROPERTY_SERVICE":
            endpoint = microServices.base+'/v1/property/temp-property-service/cleaner/uploads/initilize';
            store.dispatch(isLoader(true));
            APIservice
            .post(endpoint, action.payload.formData)
              .then((response)=>{
                store.dispatch({ 
                  type: 'UPLOAD_TEMP_PROPERTY_SERVICE',
                  payload: { 
                    'uploadUrl':response.data.data.upload,
                    'uploadFiledata':action.payload.formData,
                    'failedMessage' : action.payload.failedMessage
                  }
                  });
              })
              .catch((error)=>{
                let err = {componentName:'UploadPropertyService',statusCode: error.response.status,data: error.response.data,message:'Something went wrong try again!'};
                store.dispatch(setError(err));
                store.dispatch(isLoader(false));
              });
      break;
      case "UPLOAD_TEMP_PROPERTY_SERVICE":
        endpoint = action.payload.uploadUrl;
        store.dispatch(isLoader(true));
        APIservice
        .post(endpoint,action.payload.uploadFiledata)
        .then((response)=>{
          if(response?.data?.invalid_format === true) {
            store.dispatch(setError({ message : action.payload.failedMessage, componentName: "UploadTempPropertyService"}))
            // store.dispatch({ 
            //   type: 'SHOW_ALERT', 
            //   payload: {
            //     variant:'danger',
            //     componentName:'UploadTempPropertyService',
            //     message : action.payload.failedMessage
            //   }
            // });
          } else {
            store.dispatch({ 
              type: 'SHOW_ALERT', 
              payload: {
                variant:'success',
                componentName:'UploadTempPropertyService',
                data : response
              }
            });
          }
         })
        .catch((error)=>{
            let err = {componentName:action.componentName,statusCode: error.response.status,data: error.response.data};
            store.dispatch(setError(err));
        }).finally(()=>{
            store.dispatch(isLoader(false));
        })
        break;
        case "FETCH_TEMP_PROPERTY_SERVICE":
          
          endpoint = microServices.base+'/v1/property/temp-property-service/cleaner/view';
          store.dispatch(isLoader(true));
          APIservice
            .get(endpoint+'/'+action.payload.session_id)
            .then((response)=>{
              if(response && response.data){
                store.dispatch({ 
                type: 'SET_TEMP_PROPERTY_SERVICE', 
                payload: {
                  data:response.data
                  }
                });
              }
            })
            .catch((error)=>{
              let err = {componentName:'PropertyServiceUploads',statusCode: error.response.status,data: error.response.data};
              store.dispatch(setError(err));
            })
            .finally(()=>{
              store.dispatch(isLoader(false));
            })
        break;
        case "DELETE_TEMP_PROPERTY_SERVICE":
        endpoint = `${microServices.base}/v1/property/temp-property-service/cleaner/delete`;
        store.dispatch(isLoader(true));
        APIservice
          .post(endpoint, action.payload.formData)
          .then((response)=>{
            store.dispatch({ 
              type: 'SHOW_ALERT', 
              payload: {
                variant:'success',
                componentName:'deleteTempPropertyService',
                message: 'Property service decline succesfully!',
                data : response
              }
            });
          })
          .catch((error)=>{
            if (error?.response?.status !== 422) {
              store.dispatch({ 
                type: 'SHOW_ALERT', 
                payload: {
                  variant:'danger',
                  message: 'Unable to delete Property Service!',
                }
              });
            } else {
              let err = {componentName:'deleteTempPropertyService',statusCode: error.response.status,data: error.response.data};
              store.dispatch(setError(err));
            }
          })
          .finally(()=>{
            store.dispatch(isLoader(false));
          })
          
      break;
      case "SAVE_TEMP_PROPERTY_SERVICE":
        store.dispatch(isLoader(true));
        endpoint = `${microServices.base}/v1/property/temp-property-service/cleaner/store`;
        APIservice
          .post(endpoint, action.payload.formData)
          .then((response)=>{
            store.dispatch({ 
              type: 'SHOW_ALERT', 
              payload: {
                variant:'success',
                componentName:'SaveTempPropertyService',
                message: 'Property service added succesfully!',
                data : response
              }
            });
          })
          .catch((error)=>{
            if (error?.response?.status !== 422) {
              store.dispatch({ 
                type: 'SHOW_ALERT', 
                payload: {
                  variant:'danger',
                  message: 'Unable to save Property Service!',
                }
              });
            } else {
              let err = {componentName:'SaveTempPropertyService',statusCode: error.response.status,data: error.response.data};
              store.dispatch(setError(err));
            }
          })
          .finally(()=>{
            store.dispatch(isLoader(false));
          })
          
      break;
      case "CREATE_SALESFORCE_PROPERTY":
        endpoint = `${microServices.base}/v1/property/temp-saleforce-property/store`;
        APIservice
          .post(endpoint, action.payload.formData)
          .then((response)=>{
            store.dispatch({ 
              type: 'SHOW_ALERT', 
              payload: {
                variant:'success',
                componentName:'createSalesforceProperty',
                message: 'Property created succesfully!',
                data : response
              }
            });
          })
          .catch((error)=>{
            if (error?.response?.status !== 422) {
              store.dispatch({ 
                type: 'SHOW_ALERT', 
                payload: {
                  variant:'danger',
                  message: 'Unable to create Property!',
                }
              });
            } else {
              let err = {componentName:'createSalesforceProperty',statusCode: error.response.status,data: error.response.data};
              store.dispatch(setError(err));
            }
          })
          .finally(()=>{
          })
          
      break;
      case "DELETE_SALESFORCE_PROPERTY":
        endpoint = `${microServices.base}/v1/property/temp-saleforce-property/delete`;
        APIservice
          .post(endpoint, action.payload.formData)
          .then((response)=>{
            store.dispatch({ 
              type: 'SHOW_ALERT', 
              payload: {
                variant:'success',
                componentName:'DeleteTempSalesforcProperty',
                message: 'Property deleted !',
                data : response
              }
            });
          })
          .catch((error)=>{
            if (error?.response?.status !== 422) {
              store.dispatch({ 
                type: 'SHOW_ALERT', 
                payload: {
                  variant:'danger',
                  message: 'Unable to delete Property!',
                }
              });
            } else {
              let err = {componentName:'DeleteTempSalesforcProperty',statusCode: error.response.status,data: error.response.data};
              store.dispatch(setError(err));
            }
          })
          .finally(()=>{
          })
          
      break;
      case "GET_PROPERTY_BILLING_AMOUNT":
          
      endpoint = microServices.base+'/v1/property/deactivate/billing/';
      store.dispatch(isLoader(true));
      APIservice
        .get(endpoint+'/'+action.payload.property_id)
        .then((response)=>{
          if(response && response.data){
            store.dispatch({ 
            type: 'SET_PROPERTY_BILLING_AMOUNT', 
            payload: {
              data:response.data
              }
            });
          }
        })
        .catch((error)=>{
          let err = {componentName:'PropertyBillingAmount',statusCode: error.response.status,data: error.response.data};
          store.dispatch(setError(err));
        })
        .finally(()=>{
          store.dispatch(isLoader(false));
        })
    break;

      case "FETCH_PARENT_CHILD_PROPERTIES_DROPDOWN":
        let queryParam = {};
        endpoint = `${microServices.base}/v1/dropdown/${action.payload.propType}/${action.payload.type}`;
        if (action.payload.hasOwnProperty('params')) {
          endpoint = setGridFilter(endpoint, action.payload.params);
        } 
        queryParam['sort'] = 'property_name';
        
        if (action.payload.hasOwnProperty('perPage')) {
          queryParam['per_page'] = action.payload.perPage;
        }else{
          queryParam['per_page'] = 0;
        }
        APIservice
          .get(endpoint, queryParam)
          .then((response)=>{
            let propertiesData = getPropertyDropdown(response);
            store.dispatch({ 
              type: 'SET_PARENT_CHILD_PROPERTIES_DROPDOWN_LIST', 
              payload: propertiesData,
            });
          })
          .catch((error)=>{
            if (error.response.status !== 422) {
              store.dispatch({ 
                type: 'SET_PARENT_CHILD_PROPERTIES_DROPDOWN_LIST', 
                payload: [],
              });
            } 
          })

        break;
        case "FETCH_PROPERTY_DEFAULT_TIME":
          endpoint = `${microServices.base}/v1/property/getTime/${action.payload.id}`;
          APIservice
          .get(endpoint)
          .then((response)=>{
            if(response && response.data){
              const data = response.data.data;
              store.dispatch({ 
                type: 'SET_DEFAULT_TIME',
                payload: data,
              });
            }
          })
          .catch((error)=>{
            let err = {componentName:'PropertyDefaultTime',statusCode: error.response.status,data: error.response.data};
            store.dispatch(setError(err));
          })
          .finally(()=>{
            store.dispatch(isLoader(false));
          })
        break;
        case "UPDATE_PROPERTY_DEFAULT_TIME":
          endpoint = `${microServices.base}/v1/property/updateTime/${action.payload.id}`;
          store.dispatch(isLoader(true));
          APIservice
            .post(endpoint, action.payload.data)
            .then((response)=>{
              store.dispatch({ 
                type: 'SHOW_ALERT', 
                payload: {
                  variant:'success',
                  message: action?.payload?.successMessage,
                  componentName:'EditdefaultTime'
                }
              });
            })
            .catch((error)=>{
              if (error.response.status !== 422) {
                store.dispatch({ 
                  type: 'SHOW_ALERT', 
                  payload: {
                    variant:'danger',
                    message: action?.payload?.failMessage
                  }
                });
              } else {
                let err = {componentName:'EditdefaultTime',statusCode: error.response.status,data: error.response.data};
                store.dispatch(setError(err));
              }
            })
            .finally(()=>{
              store.dispatch(isLoader(false));
            })
            
        break;
        case "ALLOW_CL_TO_UPDATE_OWNER_DETAILS":
          endpoint = `${microServices.base}/v1/property/update-multiple/owner_allow_to_cl`
          APIservice
            .post(endpoint, action.payload.formData)
            .then((response)=>{
              store.dispatch({ 
                type: 'SHOW_ALERT', 
                payload: {
                  variant:'success',
                  componentName:'allowClToUpdateOwnerDetails',
                  message: action?.payload?.successMessage,
                  data : response
                }
              });
            })
            .catch((error)=>{
              if (error?.response?.status !== 422) {
                store.dispatch({ 
                  type: 'SHOW_ALERT', 
                  payload: {
                    variant:'danger',
                    message: action?.payload?.failedMessage,
                  }
                });
              } else {
                let err = {componentName:'allowClToUpdateOwnerDetails',statusCode: error.response.status,data: error.response.data};
                store.dispatch(setError(err));
              }
            })
            .finally(()=>{
            })
        
        break;
        
        case "FETCH_WORKORDER_PROPERTIES_DROPDOWN":
          let queryParameter = {};
          endpoint = `${microServices.base}/v1/dropdown/workorder-property/${action.payload.type}`;
          if (action.payload.hasOwnProperty('params')) {
            endpoint = setGridFilter(endpoint, action.payload.params);
          } 
          queryParameter['sort'] = 'property_name,unit_no';
          if (action.payload.hasOwnProperty('perPage')) {
            queryParameter['per_page'] = action.payload.perPage;
          }else{
            queryParameter['per_page'] = 0;
          }
          APIservice
            .get(endpoint, queryParameter)
            .then((response)=>{
              let propertiesList = prepareWorkorderPropertyDropdown(response,action.payload.type);
              store.dispatch({ 
                type: 'SET_PROPERTIES_WORKORDER_DROPDOWN_LIST', 
                payload: propertiesList,
              });
            })
            .catch((error)=>{
              if (error.response.status !== 422) {
                store.dispatch({ 
                  type: 'SET_PROPERTIES_WORKORDER_DROPDOWN_LIST', 
                  payload: [],
                });
              } 
            })
  
          break;
        
          case "DELETE_ALL_PROPERTY_PHOTO" : 
          endpoint = microServices.base+'/v1/property/photos/bulk-delete/'+action.payload.propertyId;
          store.dispatch(isLoader(true));
          APIservice
          .deleteWithPayload(endpoint,action?.payload.formData)
          .then((response)=>{
            store.dispatch({ 
              type: 'SHOW_ALERT', 
              payload: {
                variant:'success',
                message: action?.payload?.successMessage,
                componentName:'DeletePropertyPhoto'
              }
            });
          })
          .catch((error)=>{
            if (error.response.status !== 422) {
              store.dispatch({ 
                type: 'SHOW_ALERT', 
                payload: {
                  variant:'danger',
                  message: action?.payload?.failMessage
                }
              });
            } else {
              let err = {componentName:'DeletePropertyPhoto',statusCode: error.response.status,data: error.response.data};
              store.dispatch(setError(err));
            }
          })
          .finally(()=>{
            store.dispatch(isLoader(false));
          })
        break;
 
        case "ASSIGN_APPT_INSPECTION_TEMPLATE":
          endpoint = `${microServices.base}/v1/property/service/${action.payload.type}`;
          store.dispatch(isLoader(true));
          APIservice
            .put(endpoint, action.payload.formData)
            .then((response)=>{
              store.dispatch({ 
                type: 'SHOW_ALERT', 
                payload: {
                  variant:'success',
                  message: action?.payload?.successMeesage,
                  componentName:'assignApptInsTemplate'
                }
              });
            })
            .catch((error)=>{
              if (error.response.status !== 422) {
                store.dispatch({ 
                  type: 'SHOW_ALERT', 
                  payload: {
                    variant:'danger',
                    message: action?.payload?.failMessage
                  }
                });
              } else {
                let err = {componentName:'assignApptInsTemplate',statusCode: error.response.status,data: error.response.data};
                store.dispatch(setError(err));
              }
            })
            .finally(()=>{
              store.dispatch(isLoader(false));
            })
            
        break;
        
        case "ASSIGN_PROPERTY_INVENTORY_TEMPLATE":
              endpoint  = microServices.base + '/v2/property/assign-inventory-template/' + action.payload.userType + '/' + action.payload.id;
              store.dispatch(isLoader(true));
              APIservice
                .put(endpoint,action.payload.formData)
                .then((response)=>{
                  store.dispatch({ 
                    type: 'SHOW_ALERT', 
                    payload: { 
                      variant:'success',
                      message: action.payload.successMessage,
                      componentName:action.componentName
                    }
                    });
                })
               .catch((error)=>{            
                  store.dispatch({ 
                      type: 'SHOW_ALERT', 
                      payload: {
                      variant:'danger',
                      message: action.payload.failMessage,
                      componentName:action.componentName
                      }
                  });
               }).finally(()=>{
                store.dispatch(isLoader(false));
              })
          break;
        
        case "FETCH_ASSIGN_PROPERTY_INVENTORY_LIST":
            endpoint = `${microServices.base}/v2/property/${action.payload.id}/property-inventory?per_page=0`;
            APIservice
              .get(endpoint)
              .then((response)=>{
                  if(response && response.data){
                    const inventoryResponse = response.data.data;
                    const PropertyInventoryList = preparePropertyInventoryByContainerWise(inventoryResponse);
                    store.dispatch({ 
                      type: 'SET_PROPERTY_ASSIGNED_INVENTORY_LIST',
                      payload: PropertyInventoryList,
                    });
                  }
              })
              .catch((error)=>{
                if (error.response.status !== 422) {
                  store.dispatch({ 
                    type: 'SET_PROPERTY_ASSIGNED_INVENTORY_LIST', 
                    payload: [],
                  });
                } 
              })
              .finally(()=>{
                store.dispatch(isLoader(false));
              })
        break;

        case "FETCH_PROPERTY_MANAGER_ASSIGN_INVENTORY_LIST":
            endpoint = `${microServices.base}/v2/property/${action.payload.id}/manager/property-inventory?per_page=0`;
            APIservice
              .get(endpoint)
              .then((response)=>{
                  if(response && response.data){
                    const inventoryResponse = response.data.data;
                    const PropertyMangerInventoryList = preparePropertyInventoryByContainerWise(inventoryResponse);
                    store.dispatch({ 
                      type: 'SET_PROPERTY_MANAGER_ASSIGNED_INVENTORY_LIST',
                      payload: PropertyMangerInventoryList,
                    });
                  }
              })
              .catch((error)=>{
                if (error.response.status !== 422) {
                  store.dispatch({ 
                    type: 'SET_PROPERTY_MANAGER_ASSIGNED_INVENTORY_LIST', 
                    payload: [],
                  });
                } 
              })
              .finally(()=>{
                store.dispatch(isLoader(false));
              })
        break;

          default:
          break;
    }
    return result;
};

export default PropertyMiddleware;

const prepareRegionList = (response) => {
  let ActiveRegionList = [];
  let InactiveRegionList = [];

  let RegionList = [{
    label: "Active Region",
    options: ActiveRegionList,
  },
  {
    label: "Inactive Region",
    options: InactiveRegionList,
  },
  ];
  response.data.data.map((region) => {
    if (region.status_id == '1') {
      ActiveRegionList.push({
        value: region.region_area_id,
        label: region.name
      });
    }
    else if (region.status_id == '2') {
      InactiveRegionList.push({
        value: region.region_area_id,
        label: region.name
      });
    }
  });
  return RegionList;
}

const prepareTimezonesList = (response) => {
  let TimezonesList = [];
  response.data.data.map((timezones) => {
    TimezonesList.push({
        value: timezones.timezone,
        label: timezones.timezone_display,
      });
  });
  return TimezonesList;
}

const preparePropertyTypesList = (response) => {
  let PropertyTypeList = [];
  Object.keys(response).forEach((key) => {
    PropertyTypeList.push({
        value: response[key].property_type_id,
        label: response[key].name,
      });
  });
  return PropertyTypeList;
}

const prepareAreaList = (response) => {
  let AreaList = [];
  response.data.data.map((region) => {
      AreaList.push({
        value: region.id,
        label: region.tree_path,
        isdisabled: region.type===1 ? true : false
      });
  });
  return AreaList;
}

const prepareInspectionTemplateList = (response) => {
  let InspectionTemplateList = [];
  response.data.data.map((template) => {
    InspectionTemplateList.push({
        value: template.template_id,
        label: template.title
      });
  });
  return InspectionTemplateList;
}

const preparePropertyEntryMethodList = (response) => {
  let PropertyEntryMethodList = [];
  response.data.data.map((entrymethods) => {
    PropertyEntryMethodList.push({
        value: entrymethods.property_entry_method_id,
        label: entrymethods.name,
      });
  });
  return PropertyEntryMethodList;
}
const preparePropertySquareFeetList = (response) => {
  let PropertySquareFeetList = [];
  response.data.data.map((squarefeets) => {
    PropertySquareFeetList.push({
        value: squarefeets.property_square_feet_id,
        label: squarefeets.name,
      });
  });
  return PropertySquareFeetList;
}
const preparePropertiesList = (response, company_type) => {
  let ActivePropertiesList = [];
  let InactivePropertiesList = [];

  let PropertiesList = [{
    label: "Active Properties",
    options: ActivePropertiesList,
  },
  {
    label: "Inactive Properties",
    options: InactivePropertiesList,
  }];
  
  response.data.data.map((properties) => {
      let unit_no = '';
      
        
      
      const property_name = (properties.properties) ? properties.properties.property_name : properties.property_name;
      const properties_id = (properties.properties) ? properties.properties.properties_id : properties.properties_id;
      
      const region_id     = properties.region_id;
      const area_id       = properties.area_id ;
      const owner_id      = properties.owner_id ;
      const manager_id      = properties.Manager?.manager_id ;
      const cleaner_ids      = [] ;
   
      if(properties.Cleaner?.length  > 0){
        properties.Cleaner?.map((cl)=>{
          cleaner_ids.push(cl.cleaner_id);
        })
      }

      if(company_type === 'cleaner') {
        if(properties?.properties?.unit_no){
          unit_no = (properties?.properties?.unit_no !='0') ? ' - ' + properties?.properties?.unit_no :'';
        }
        if (properties.Status.status_id == 1 && properties.properties.status_id == 1) {
          ActivePropertiesList.push({
            value: properties_id,
            label: `${property_name}${unit_no}`,
            region_id: region_id,
            area_id: area_id,
            manager_id:manager_id,
            owner_id
          });
        } else if (properties.Status.status_id == 2 || properties.properties.status_id == 2) {
          InactivePropertiesList.push({
            value: properties_id,
            label: `${property_name}${unit_no}`,
            region_id: region_id,
            area_id: area_id,
            manager_id:manager_id,
            owner_id
          });
        }

      } else if (company_type === 'manager') {
        if(properties?.unit_no){
          unit_no = (properties?.unit_no !='0') ? ' - ' + properties?.unit_no :'';
        }
        if (properties.Status.status_id == 1) {
          ActivePropertiesList.push({
            value: properties_id,
            label: `${property_name}${unit_no}`,
            region_id: region_id,
            area_id: area_id,
            cleaner_ids:cleaner_ids,
            owner_id
          });
        } else if (properties.Status.status_id == 2) {
          InactivePropertiesList.push({
            value: properties_id,
            label: `${property_name}${unit_no}`,
            region_id: region_id,
            area_id: area_id,
            cleaner_ids:cleaner_ids,
            owner_id
          });
        }
      }
  });

  return PropertiesList;
}

const preparePropertyOwnerList = (response) => {
  let propertyOwnerList = [];
  response.data.map((item) => {
    propertyOwnerList.push({
        value: item?.property_owners_details_id,
        label: item?.client_name,
      });
  });
  return propertyOwnerList;
}

const preparePropertyCleanersDropdown = (response) => {
  let preparedDropdown = [];
  response.data.map((item) => {
    preparedDropdown.push({
        value: item?.company_id,
        label: item?.company_name,
        is_default_cleaner: item?.is_default_cleaner,
      });
  });
  return preparedDropdown;
}

const preparePropertyDropdown = (response, company_type) => {
  let ActivePropertiesList = [];
  let InactivePropertiesList = [];

  let PropertiesList = [{
    label: "Active Properties",
    options: ActivePropertiesList,
  },
  {
    label: "Inactive Properties",
    options: InactivePropertiesList,
  }];
  response.data.data.map((properties) => {

      let unit_no ='';
    
      if(properties?.unit_no){
        unit_no = (properties?.unit_no !='0') ? '- '+properties?.unit_no :'';
      }
      
      const property_name = properties.property_name;
      const properties_id = properties.properties_id;
      const region_id     = properties.region_id;
      const area_id       = properties.area_id ;
      const owner_id      = properties.owner_id ;
      const manager_id    = properties.manager_id ;
      const check_in    = properties.default_checkin_time ;
      const check_out    = properties.default_checkout_time ;
      const is_cl_inspection    = properties.is_cl_inspection ;
      const is_pm_inspection    = properties.is_pm_inspection ;

      const cleaner_ids   = properties.cleaner_ids ? properties.cleaner_ids.replace(/[{}]/g, '').split(',') : [];

      if(company_type === 'cleaner') {
        if (properties.status_id == 1 && properties.property_status_id == 1) {
          ActivePropertiesList.push({
            value: properties_id,
            label: `${property_name}${unit_no}`,
            region_id,
            area_id,
            manager_id,
            owner_id,
            check_in,
            check_out,
            is_inspection : is_cl_inspection
          });
        } else if (properties.status_id == 2 || properties.property_status_id == 2) {
          InactivePropertiesList.push({
            value: properties_id,
            label: `${property_name}${unit_no}`,
            region_id,
            area_id,
            manager_id,
            owner_id,
            check_in,
            check_out,
            is_inspection : is_cl_inspection
          });
        }
      } else if (company_type === 'manager') {
        if (properties.status_id == 1) {
          ActivePropertiesList.push({
            value: properties_id,
            label: `${property_name}${unit_no}`,
            region_id: region_id,
            area_id: area_id,
            owner_id,
            cleaner_ids,
            is_inspection : is_pm_inspection
          });
        } else if (properties.status_id == 2) {
          InactivePropertiesList.push({
            value: properties_id,
            label: `${property_name}${unit_no}`,
            region_id: region_id,
            area_id: area_id,
            owner_id,
            cleaner_ids,
            is_inspection : is_pm_inspection
          });
        }
      }
  });
  return PropertiesList;
}

const getPropertyDropdown = (response) => {
  let propertyList = [];
  response.data.data.map((prop) => {
    let unit_no ='';
      if(prop?.unit_no){
        unit_no = (prop?.unit_no !='0') ? '- '+prop?.unit_no :'';
      }
    propertyList.push({
      value: prop.properties_id,
      label: `${prop.property_name}${unit_no}`,
      parent_id: prop.parent_id
      });
  });
  return propertyList;
}

export const prepareWorkorderPropertyDropdown = (response, company_type) => {
  let ActivePropertiesList = [];
  let InactivePropertiesList = [];

  let PropertiesList = [{
    label: "Active Properties",
    options: ActivePropertiesList,
  },
  {
    label: "Inactive Properties",
    options: InactivePropertiesList,
  }];
  response.data.data.map((properties) => {

      let unit_no ='';
    
      if(properties?.unit_no){
        unit_no = (properties?.unit_no !='0') ? '- '+properties?.unit_no :'';
      }
      
      const property_name = properties.property_name;
      const properties_id = properties.properties_id;
      const region_id     = properties.region_id;
      const area_id       = properties.area_id ;
      const owner_id      = properties.owner_id ;
      const manager_id    = properties.manager_id ;
      const check_in    = properties.default_checkin_time ;
      const check_out    = properties.default_checkout_time ;
      const is_cl_inspection    = properties.is_cl_inspection ;
      const is_pm_inspection    = properties.is_pm_inspection ;
      const owner_name    = properties.client_name ;
      const manager_name    = properties.company_name ;
      const allow_update_owner_to_cl    = properties.allow_update_owner_to_cl ;
      const owner_detail_additional_feature    = properties.owner_detail_additional_feature ;

      const cleaner_ids   = properties.cleaner_ids ? properties.cleaner_ids.replace(/[{}]/g, '').split(',') : [];

      if(company_type === 'cleaner') {
        if (properties.status_id == 1 && properties.property_status_id == 1) {
          ActivePropertiesList.push({
            value: properties_id,
            label: `${property_name}${unit_no}`,
            region_id,
            area_id,
            manager_id,
            owner_id,
            check_in,
            check_out,
            is_inspection : is_cl_inspection,
            owner_name,
            manager_name,
            allow_update_owner_to_cl,
            owner_detail_additional_feature,
            group:"active"
          });
        } else if (properties.status_id == 2 || properties.property_status_id == 2) {
          InactivePropertiesList.push({
            value: properties_id,
            label: `${property_name}${unit_no}`,
            region_id,
            area_id,
            manager_id,
            owner_id,
            check_in,
            check_out,
            is_inspection : is_cl_inspection,
            owner_name,
            manager_name,
            allow_update_owner_to_cl,
            owner_detail_additional_feature,
            group:"inactive"
          });
        }
      } else if (company_type === 'manager') {
        if (properties.status_id == 1) {
          ActivePropertiesList.push({
            value: properties_id,
            label: `${property_name}${unit_no}`,
            region_id: region_id,
            area_id: area_id,
            owner_id,
            cleaner_ids,
            is_inspection : is_pm_inspection,
            owner_name,
            allow_update_owner_to_cl,
            group:"active"
          });
        } else if (properties.status_id == 2) {
          InactivePropertiesList.push({
            value: properties_id,
            label: `${property_name}${unit_no}`,
            region_id: region_id,
            area_id: area_id,
            owner_id,
            cleaner_ids,
            is_inspection : is_pm_inspection,
            owner_name,
            allow_update_owner_to_cl,
            group:"inactive"
          });
        }
      }
  });
  return PropertiesList;
}