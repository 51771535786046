import { microServices } from "../../config/global";
import apiService from "../apiService";
import { isLoader } from "../global/action";
import { setError } from "../form/action";

/**
 * Api middleware can be used to call any API services
 * @param {*} store 
 * @returns 
 */

 const ApiMiddleware = (store) => (next) => (action) => {
    const result = next(action);
    const getDropdowns = (keyword, countryCode = false ,userType=false) => {
      
      const endpoint  = 'v1/dropdown';
      let apiUrl      = '';
      let apiTypeUrl      = '';

      apiUrl = `${microServices.base}/${endpoint}/${keyword}`;

      if(countryCode){
        apiUrl = `${microServices.base}/${endpoint}/country/${countryCode}/states`;
      }
      if(userType){
        apiUrl = `${microServices.base}/${endpoint}/ProfessionsType/${userType}`;
      }
      apiService
        .get(apiUrl)
        .then((response) => {
         
          if(response && response.data && response.data.data){
            if(keyword === 'countries'){
              let countries = [];
              response.data.data.map((status) => {
                countries.push({
                  value: status.country_code,
                  label: status.name
                });
              });
              store.dispatch({
                type: 'SET_COUNTRIES',
                payload: countries
              });
            }

            if(keyword === 'states'){
              store.dispatch({
                type: 'SET_STATES',
                payload: response.data.data
              });
            }
            
            if(keyword === 'status'){
              let status_list = [];
              
              response.data.data.map((status) => {
                status_list.push({
                  value: status.status_id,
                  label: status.status
                });
              });
              
              store.dispatch({
                type: 'SET_STATUSES',
                payload: status_list
              });
            }

            if(countryCode){
              store.dispatch({
                type: 'SET_COUNTRY_WISE_STATES',
                payload: response.data.data
              });
            }
            
            if(keyword === 'ProfessionsType'){
              let professionsType_list = []; 
              response.data.data.map((el) => {
                professionsType_list.push({
                  value: el.profession_type_id,
                  label: el.name,
                  type: el.type,
                  shortName: el.short_name,
                  isCheck:false,
                  payrollAmt:0,
                  payrollType:0
                });
              });               
              store.dispatch({
                type: 'SET_PROFESSION_TYPES',
                payload: professionsType_list
              });
            }
            if(keyword === 'appointmentStatus'){
              let app_status_list = [];
              
              response.data.data.map((status) => {
                app_status_list.push({
                  value: status.app_status_id,
                  label: status.status
                });
              });
              
               store.dispatch({
                type: 'SET_APPOINTMENT_STATUSES',
                payload: app_status_list
              });
            }

            if(keyword === 'appointmentTaskStatus'){
              let status_list = [];
              
              response.data.data.map((status) => {
                status_list.push({
                  value: status.status_id,
                  label: status.status
                });
              });
              
              store.dispatch({
                type: 'SET_APPOINTMENT_TASK_STATUSES',
                payload: status_list
              });
            }
            if(keyword === 'appointmentTaskRequestType')
            {
              let request_type_list = [];
              
              response.data.data.map((status) => {
                request_type_list.push({
                  value: status.id,
                  label: status.type
                });
              });

              store.dispatch({
                type: 'SET_APPOINTMENT_REQUEST_TYPE',
                payload:request_type_list
              });
            }
            if(keyword === 'billingStatus'){
              let billing_status_list = [];
              billing_status_list.push({
                value: 0,
                label: 'All'
              });
              
              response.data.data.map((status) => {
                billing_status_list.push({
                  value: status.billing_status_id,
                  label: status.status
                });
              });
              
               store.dispatch({
                type: 'SET_BILLING_STATUSES',
                payload: billing_status_list
              });
            }

            if(keyword === 'currency'){
              let currency_list = [];
              
              response.data.data.map((item) => {
                currency_list.push({
                  value: item.currency_code,
                  label: item.name +' ('+ item.symbol + ')',
                  symbol: item.symbol,
                  supported_at:item?.supported_at
                });
              });

              store.dispatch({
                type: 'SET_CURRENCY',
                payload: currency_list,
              });
            }
          }
  
        }).catch((e)=>{
          if(keyword === 'countries'){
            store.dispatch({
              type: 'SET_COUNTRIES',
              payload: []
            });
          }
          
          if(keyword === 'states'){
            store.dispatch({
              type: 'SET_STATES',
              payload: []
            });
          }
          
          if(countryCode){
            store.dispatch({
              type: 'SET_COUNTRY_WISE_STATES',
              payload:[]
            });
          }         
          if(keyword === 'ProfessionsType'){
            store.dispatch({
              type: 'SET_PROFESSION_TYPES',
              payload:[]
            });
          }
          
          if(keyword === 'appointmentTaskStatus'){
            store.dispatch({
              type: 'SET_APPOINTMENT_TASK_STATUSES',
              payload: []
            });
          }
          
          if(keyword === 'appointmentTaskRequestType'){
            store.dispatch({
              type: 'SET_APPOINTMENT_REQUEST_TYPE',
              payload: []
            });
          }
        });
      }

      switch(action.type){
        case "GET_DROPDOWN" :
          let userType = (action.payload.userType && action.payload.userType !== '')?action.payload.userType:false;
          let countryCode = (action.payload.country_code && action.payload.country_code !== '')?action.payload.country_code:false;
        getDropdowns(action.payload.keyword, countryCode, userType);
      break;
      case "UPDATE_GLOBAL_REGION_AREA_FILTER":                        
            store.dispatch({ 
              type:'GLOBAL_REGION_AREA_FILTER',
              payload: action.payload
            });            
         break;
      case "ACTIVE_INACTIVE":
       let endPoint = action.payload.endpoint;
        store.dispatch(isLoader(true));
        apiService
          .put(`${endPoint}/${action.payload.apiName}/${action.payload.id}`)
          .then((response)=>{
            
            store.dispatch({ 
              type: 'SHOW_ALERT', 
              payload: {
                variant:'success',
                message: response.data.message,
                componentName:action.payload.componentName
              }
            });
          })
          .catch((error)=>{
            if (error.response.status !== 422) {
              store.dispatch({ 
                type: 'SHOW_ALERT', 
                payload: {
                  variant:'danger',
                  message: `Unable to ${action.payload.apiName}`
                }
              });
            } else {
                let err = {componentName:action.payload.componentName,statusCode: error.response.status,data: error.response.data};
                store.dispatch(setError(err));
              }
          })
          .finally(()=>{
            store.dispatch(isLoader(false));
          })
        break;
        case "PDF_REPORT_DOWNLOAD":
            store.dispatch(isLoader(true));
            apiService.
            getBlobDataWithParams(action.payload.endpoint)
            .then((response)=>{
                const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
                   const link = document.createElement('a');
                   link.href = blobUrl;
                   link.setAttribute('download', `${(action.payload?.fileName) ? action.payload.fileName : "Document"}_${Date.now()}.pdf`);
                   document.body.appendChild(link);
                   link.click(); 
                   link.parentNode.removeChild(link);
            })
            .catch((error)=>{
                if (error.response.status !== 422) {
                    store.dispatch({ 
                        type: 'SHOW_ALERT', 
                        payload: {
                            variant:'danger',
                            message: 'Unable to Download Report!',
                        }
                    });
                } else {
                    let err = {componentName:'DownloadReportPdf',statusCode: error.response.status,data: error.response.data};
                    store.dispatch(setError(err));
                }
            })
            .finally(()=>{
                store.dispatch(isLoader(false));
            });
        break;
        case "EXCEL_REPORT_DOWNLOAD":
            store.dispatch(isLoader(true));
            apiService.
            getBlobDataWithParams(action.payload.endpoint)
            .then((response)=>{
                const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = blobUrl;
                link.setAttribute('download', `${(action.payload?.fileName) ? action.payload.fileName : "Report"}_${Date.now()}.xlsx`);
                document.body.appendChild(link);
                link.click(); 
                link.parentNode.removeChild(link);
            })
            .catch((error)=>{
                if (error.response.status !== 422) {
                    store.dispatch({ 
                        type: 'SHOW_ALERT', 
                        payload: {
                            variant:'danger',
                            message: 'Unable to Download Report!',
                        }
                    });
                } else {
                    let err = {componentName:'DownloadReportXlsx',statusCode: error.response.status,data: error.response.data};
                    store.dispatch(setError(err));
                }
            })
            .finally(()=>{
                store.dispatch(isLoader(false));
            });
        break;

        case "REPORT_DOWNLOAD":
            store.dispatch(isLoader(true));
            apiService.
            getBlobDataForMultipleRecord(action.payload.exportEndpoint, action.payload.formData)
            .then((response)=>{
                const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = blobUrl;
                link.setAttribute('download', `${(action.payload?.fileName) ? action.payload.fileName : "Report"}_${Date.now()}.xlsx`);
                document.body.appendChild(link);
                link.click(); 
                link.parentNode.removeChild(link);
            })
            .catch((error)=>{
                if (error.response.status !== 422) {
                    store.dispatch({ 
                        type: 'SHOW_ALERT', 
                        payload: {
                            variant:'danger',
                            message: 'Unable to Download Report!',
                        }
                    });
                } else {
                    let err = {componentName:'DownloadReportXlsx',statusCode: error.response.status,data: error.response.data};
                    store.dispatch(setError(err));
                }
            })
            .finally(()=>{
                store.dispatch(isLoader(false));
            });
        break;
      default :
      return [];
    }
    return result;
  };

  const prepareGlobalRegionAreaList = (response) => {
    let GlobalRegionAreaArr=[];
     GlobalRegionAreaArr.push({
          selectedRegion:response.region_id,
          selectedRegionArea:response.area_id,
      });
    
      return GlobalRegionAreaArr;
  }
  
export default ApiMiddleware;
  
