import React, { useContext, useState } from "react";
import { Col, Row, Form, Button, Spinner, Alert } from "react-bootstrap";
import CustomSelect from "../../../atoms/CustomSelect";
import InputText from "../../../atoms/InputText";
import { LanguageContext } from "../../../containers/Language";
import TilledDetails from "../../TilledDetails";

const PropelrPayNowModal = (props) => {
  const { localised } = useContext(LanguageContext);
  const [status, setStatus] = React.useState(1); // 0: no show, 1: show yes, 2: show no.

  const radioHandler = (status) => {
    setStatus(status);
  };

  return (
    <>
      <div className="modal-content-card pt-2">
        <div className="modal-heading">
          <h3>
            {" "}
            <Form.Label>{localised.existingDetails}</Form.Label>
          </h3>
        </div>

        {props.backDatedInvoice?.is_back_dated && (
          <Alert key={"warning"} variant={"warning"}>
            {localised.rcInvoicePayFirst +
              " " +
              props.backDatedInvoice?.old_invoice?.invoice_number}
          </Alert>
        )}
        <div>
          <Row>
            {props.cardpointePayMethod?.data?.bank?.type == "card" && (
              <Col md="10">
                <p>
                  {localised.cardNo} :{" "}
                  {"******************" +
                    props.cardpointePayMethod?.data?.bank?.card?.card_number}{" "}
                </p>
                <p>
                  {localised.expDate} :{" "}
                  {props.cardpointePayMethod?.data?.bank?.card?.card_exp.substring(0, 2) +
                    "/" + props.cardpointePayMethod?.data?.bank?.card?.card_exp.substring(2)
                    }{" "}
                </p>
              </Col>
            )}
            {props.cardpointePayMethod?.data?.bank?.type == "ach_debit" && (
              <Col md="10">
                <p>{localised.accountHolderName} :{props.cardpointePayMethod?.data?.bank?.ach_debit?.account_holder_name} </p>
                <p>{localised.accountNo} :{"********************" + props.cardpointePayMethod?.data?.bank?.ach_debit?.last4} </p>
              </Col>
            )}
          </Row>
        </div>
        <div>
          <Row>
            <Col md="3" lg="3">
              <Form.Group className="form-group">
                <Form.Label>{localised.amount}</Form.Label>
                <InputText
                  id={"amount"}
                  name={"amount"}
                  type={"text"}
                  value={props.invoicePayForm.amount}
                  placeholder={"$0.00"}
                  required={"required"}
                  readonly={"readonly"}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Group className="form-group">
                <div className="d-flex align-items-center flex-wrap">
                  <div>
                    <Form.Check
                      type="radio"
                      name="radio"
                      id="inline-radio-1"
                      label={"Add new payment method"}
                      checked={props.invoicePayForm.new_method_pay_now}
                      onClick={(e) => props.addNewMethod()}
                    />
                  </div>
                </div>
              </Form.Group>
            </Col>
          </Row>
        </div>
        <div>
          <Row>
            <Col md="12">
              <div className="text-end pb-2 pt-2 edit-appt-btns">
                {/* <Button variant="secondary" form='my-form' type="reset" >
                {localised.reset}
              </Button> */}
                <span> </span>
                <Button
                  disabled={
                    props.isLoading || props.backDatedInvoice?.is_back_dated
                  }
                  variant="primary"
                  form="my-form"
                  type="button"
                  onClick={(e) => {
                    props.triggerInvoicePayment();
                  }}
                >
                  {props.isLoading ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    localised.payNow
                  )}
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
export default PropelrPayNowModal;
