import APIservice from "../apiService";
import { isLoader } from "../global/action";
import { microServices } from "../../config/global";
import { setError } from "../form/action";
/**
 * BillingMiddleware can be used to call Billing related API services
 * @param {*} store 
 * @returns 
 */
const InvoicePayrollMiddleware = (store) => (next) => async (action) => {
  const result = next(action);
  let endpoint = microServices.base + '/v1/';
  switch (action.type) {
    case "GENERATE_INVOICE":
        store.dispatch(isLoader(true));
        APIservice
          .post(action.payload.endpoint , action.payload.formData)
          .then((response) => {
            store.dispatch({
              type: 'SHOW_ALERT',
              payload: {
                variant: 'success',
                message: action.payload.message,
                componentName: 'GenerateInvoice'
              }
            });
          })
          .catch((error) => {
            if (error.response.status !== 422) {
              store.dispatch({
                type: 'SHOW_ALERT',
                payload: {
                  variant: 'danger',
                  message: action.payload.failMessage,
                }
              });
            } else {
              let err = { componentName: 'GenerateInvoice', statusCode: error.response.status, data: error.response.data };
              store.dispatch(setError(err));
            }
          })
          .finally(() => {
            store.dispatch(isLoader(false));
          })
        break;
    case "INVOICES_PDF":
        store.dispatch(isLoader(true));
        let endPoint  = microServices.base+action.payload.url;
        APIservice.
        getBlobDataForMultipleRecord(endPoint, action.payload.invArray)
        .then((response)=>{
          const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
             const link = document.createElement('a');
             link.href = blobUrl;
             link.setAttribute('download', `${(action.payload?.fileName) ? action.payload.fileName : "Invoice"}_${Date.now()}.pdf`);
             document.body.appendChild(link);
             link.click(); 
             link.parentNode.removeChild(link);
        })
        .catch((error)=>{
          let err = {componentName:'InvoicesPDF',statusCode: error.response.status,data: error.response.data};
          store.dispatch(setError(err));
        })
        .finally(()=>{
          store.dispatch(isLoader(false));
        })
      break;
        case "GENERATE_PAYROLL":
          let endpoint = microServices.base + '/v1/payroll';
          store.dispatch(isLoader(true));
          APIservice
            .post(endpoint , action.payload.formData)
            .then((response) => {
              store.dispatch({
                type: 'SHOW_ALERT',
                payload: {
                  variant: 'success',
                  message: "Payroll Generated Successfully!",
                  componentName: 'GeneratePayroll'
                }
              });
            })
            .catch((error) => {
              if (error.response.status !== 422) {
                store.dispatch({
                  type: 'SHOW_ALERT',
                  payload: {
                    variant: 'danger',
                    message: action.payload.failMessage,
                  }
                });
              } else {
                let err = { componentName: 'GeneratePayroll', statusCode: error.response.status, data: error.response.data };
                store.dispatch(setError(err));
              }
            })
            .finally(() => {
              store.dispatch(isLoader(false));
            })
        break;
        case "INVOICES_EXCEL":
          let api =action?.payload?.selectedLanguage 
                  ? microServices.base + '/v1/'+action?.payload?.selectedLanguage+'/invoice/exportInvoice/'+action.payload.type 
                  : microServices.base + '/v1/invoice/exportInvoice/'+action.payload.type;
          store.dispatch(isLoader(true));
          APIservice.
          getBlobDataForMultipleRecord(api, action.payload)
          .then((response)=>{
              const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = blobUrl;
              link.setAttribute('download', `${(action.payload?.fileName) ? action.payload.fileName : "Invoice"}_${Date.now()}.xlsx`);
              document.body.appendChild(link);
              link.click(); 
              link.parentNode.removeChild(link);
          })
          .catch((error)=>{
            if (error.response.status !== 422) {
                store.dispatch({ 
                    type: 'SHOW_ALERT', 
                    payload: {
                        variant:'danger',
                        message: 'Unable to Download Report!',
                    }
                });
            } else {
                let err = {componentName:'DownloadReportXlsx',statusCode: error.response.status,data: error.response.data};
                store.dispatch(setError(err));
            }
          })
          .finally(()=>{
              store.dispatch(isLoader(false));
          });
      break;
      case "INVOICE_TRIGGER_PAYMENT":
          let endpt = microServices.base + '/v1/invoice/payment/';
          store.dispatch(isLoader(true));

          APIservice
            .put(endpt+action.payload.id)
            .then((response) => {
              store.dispatch({
                type: 'SHOW_ALERT',
                payload: {
                  variant: 'success',
                  message: "Invoice payment Success!",
                  componentName: 'InvoiceTrigger'
                }
              });
            })
            .catch((error) => {
             
              if (error.response.status !== 422) {
                store.dispatch({
                  type: 'SHOW_ALERT',
                  payload: {
                    variant: 'danger',
                    message: action.payload.failMessage,
                  }
                });
              } else {
                let err = { componentName: 'InvoiceTrigger', statusCode: error.response.status, data: error.response.data?.message?.error };
                store.dispatch(setError(err));
              }
            })
            .finally(() => {
              store.dispatch(isLoader(false));
            })
        break;
        case "RC_INVOICE_TRIGGER_PAYMENT":
          let isFromGlobal =  (action?.payload?.origin) ? true :false;
          let endt = microServices.base + '/v1/admin/invoice/payment/'+ action?.payload?.paymentGateway + '/trigger/';
          store.dispatch(isLoader(true));
          let invoiceId =action.payload.id;
          let formData1  ={};
          if( action.payload?.isTrigger){
             formData1  ={
              isTrigger:action.payload?.isTrigger
            }
          }
          APIservice
            .put(endt+invoiceId, formData1)
            .then((response) => {
              store.dispatch({
                type: 'SHOW_ALERT',
                payload: {
                  variant: 'success',
                  message: "Invoice payment Success!",
                  invoice_id:invoiceId,
                  componentName: 'RcInvoiceTrigger',
                  data: response?.data?.data,
                  isFromGlobal : isFromGlobal,
                }
              });
            })
            .catch((error) => {
              if (error.response?.status !== 422) {
                store.dispatch({
                  type: 'SHOW_ALERT',
                  payload: {
                    variant: 'danger',
                    message: action.payload.failMessage,
                  }
                });
              } else {
                let err = { componentName: 'RcInvoiceTrigger', statusCode: error.response.status, data: error.response.data?.message?.messages };
                store.dispatch(setError(err));
              }
            })
            .finally(() => {
              store.dispatch(isLoader(false));
            });
            break;

        case "FETCH_RC_INVOICE_NOTIFICATION":
          let endpointNotification = microServices.base + '/v1/admin/invoice/reminder';
          store.dispatch(isLoader(true));
          APIservice
            .get(endpointNotification)
            .then((response)=>{
              if(response && response.data){
                store.dispatch({
                  type: 'SET_RC_INVOICE_NOTIFICATION',
                  payload: {
                    data:response.data
                  }
                });
              }
            })
            .catch((error)=>{
              let err = {componentName:'rcInvoiceNotification',statusCode: error.response.status,data: error.response.data};
              store.dispatch(setError(err));
            })
            .finally(()=>{
              store.dispatch(isLoader(false));
            })
          break;
          case "GET_PAYROLL_TOTAL": {
            let endpoints = action.payload.endpoint + "&per_page=0";
            store.dispatch(isLoader(true));
            APIservice.get(endpoints)
              .then((response) => {
                if (response && response.data) {
                  store.dispatch({
                    type: "SET_PAYROLL_TOTAL",
                    payload: {
                      data: response.data,
                    },
                  });
                }
              })
              .catch((error) => {
                let err = {
                  componentName: "PayrollTotal",
                  statusCode: error.response.status,
                  data: error.response.data,
                };
                store.dispatch(setError(err));
              })
              .finally(() => {
                store.dispatch(isLoader(false));
              });
              break;
            }
              case "GET_INTERNAL_INVOICE_TOTAL": {
                let endpoints = action.payload.endpoint + "&per_page=0";
                store.dispatch(isLoader(true));
                APIservice.get(endpoints)
                  .then((response) => {
                    if (response && response.data) {
                      if(action?.payload?.componentName === 'rcInvoiceTotal')
                      {
                        store.dispatch({
                          type: "SET_RC_INVOICE_TOTAL",
                          payload: {
                            data: response.data,
                          },
                        });
                      } else if(action?.payload?.componentName === 'internalInvoiceTotal') {
                        store.dispatch({
                          type: "SET_INTERNAL_INVOICE_TOTAL",
                          payload: {
                            data: response.data,
                          },
                        });
                      } else if (action?.payload?.componentName === 'clInvoiceTotal'){
                        store.dispatch({
                          type: "SET_CL_INVOICE_TOTAL",
                          payload: {
                            data: response.data,
                          },
                        });
                      }
              }})
                  .catch((error) => {
                    let err = {
                      componentName: "internalInvoiceTotal",
                      statusCode: error.response.status,
                      data: error.response.data,
                    };
                    store.dispatch(setError(err));
                  })
                  .finally(() => {
                    store.dispatch(isLoader(false));
                  });
                  break;
                }
                case "GET_QUICKBOOK_INVOICE": {
                  let endpt =  microServices.base + "/v1/invoice/qb/mapping/invoiceItem";
                  store.dispatch(isLoader(true));
                  APIservice.get(endpt)
                    .then((response) => {
                      if (response && response.data) {
                        store.dispatch({
                          type: "SET_QUICKBOOK_INVOICE",
                          payload: {
                            data: response.data,
                          },
                        });
                      }
                    })
                    .catch((error) => {
                      let err = {
                        componentName: "GetQuickbookInvoiceItem",
                        statusCode: error.response.status,
                        data: error.response.data,
                      };
                      store.dispatch(setError(err));
                    })
                    .finally(() => {
                      store.dispatch(isLoader(false));
                    });
                    break;
                  }
                  case "STORE_QUICKBOOK_INVOICE": {
                    let endUrl =  microServices.base + "/v1/invoice/qb/invoiceItem/";
                   
                    APIservice.post(endUrl+action.payload.serviceId,{qb_item_id :action.payload.selectedId})
                      .then((response) => {
                        if (response ) {
                          store.dispatch({
                            type: 'SHOW_ALERT',
                            payload: {
                              variant: 'success',
                              message: action.payload.successMsg,
                              rowIndex:action.payload.rowIndex,
                              componentName: 'StoreQuickbookInvoiceService',
                            }
                          });
                        }
                      })
                      .catch((error) => {
                        let err = {
                          componentName: "StoreQuickbookInvoiceService",
                          statusCode: error.response.status,
                          data: error.response.data,
                          message: action.payload?.failedMsg,
                        };
                        store.dispatch(setError(err));
                      })
                      .finally(() => {
                        store.dispatch(isLoader(false));
                      });
                      break;
                    }

                    case "STORE_QUICKBOOK_WOITEM": {
                      let endUrl =  microServices.base + "/v1/invoice/qb/woItem/";
                     
                      APIservice.post(endUrl+action.payload.wo_item_id,{qb_wo_item_id :action.payload.selectedId})
                        .then((response) => {
                          if (response ) {
                            store.dispatch({
                              type: 'SHOW_ALERT',
                              payload: {
                                variant: 'success',
                                message: action.payload.successMsg,
                                rowIndex:action.payload.rowIndex,
                                componentName: 'StoreQuickbookInvoiceWoItem',
                              }
                            });
                          }
                        })
                        .catch((error) => {
                          let err = {
                            componentName: "StoreQuickbookInvoiceWoItem",
                            statusCode: error.response.status,
                            data: error.response.data,
                            
                          };
                          store.dispatch(setError(err));
                        })
                        .finally(() => {
                          store.dispatch(isLoader(false));
                        });
                        break;
                      }
                      case "FROG_FUND_INVOICES_PDF":
                        store.dispatch(isLoader(true));
                        let frogFundEndpoint  = microServices.base+action.payload.url;
                        APIservice.
                        getBlobDataForMultipleRecord(frogFundEndpoint, action.payload.invArray)
                        .then((response)=>{
                          const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
                             const link = document.createElement('a');
                             link.href = blobUrl;
                             link.setAttribute('download', `${(action.payload?.fileName) ? action.payload.fileName : "Invoice"}_${Date.now()}.pdf`);
                             document.body.appendChild(link);
                             link.click(); 
                             link.parentNode.removeChild(link);
                        })
                        .catch((error)=>{
                          let err = {componentName:'InvoicesPDF',statusCode: error.response.status,data: error.response.data};
                          store.dispatch(setError(err));
                        })
                        .finally(()=>{
                          store.dispatch(isLoader(false));
                        })
                      break;
                  
default:
      break;
  }
  return result;
};

export default InvoicePayrollMiddleware;