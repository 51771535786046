const initState = {
  error: {},
  data: null
}

export default (state = initState, action) => {
  switch (action.type) {
    
    case "SET_VALIDATION_ERROR": {
      return {
        ...state,
        error: action.payload,
      }
    }

    case "CLEAR_VALIDATION_ERROR": {
      return {
        ...state,
        error: null,
      }
    }

    case 'SET_DATA_FOR_EDIT': {
      return {
        ...state,
        data : action.payload.data
      };
    }

    case 'CLEAR_DATA_STORED_FOR_EDIT': {
      return {
        ...state,
        data : null
      };
    }

    default:
      return state
  }
}
