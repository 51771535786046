// useCardPointeModal.js

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  PAYMENTSUPPORTCOUNTRY,
  WALLET_TILLED_ACH,
  WALLET_TILLED_CC,
  checkCountryOrProvinces,
  checkCountryOrProvincesForString,
  tilledObj,
} from "../../../utils/paymentConstUtil";

import { USA_COUNTRY } from "../../../utils/tilledUtil";
import { tilledTypeCard, tilledTypeAch } from "../../../utils/paymentConstUtil";

import { filterArrayByKeyValue } from "../../../utils/utils";

function useCardpointeModalUtils() {
  const userInformation = useSelector((state) => state.user.user) || [];
  const companyInformation = useSelector((state) => state.user.company) || [];
  const [payMethod, setPayMethod] = useState(false);
  const [cardpointePayloadObj, setCardpointePayloadObj] = useState(tilledObj);
  const [cardpointeSelectedState, setCardpointeSelectedState] = useState("");
  const [cardpointeSelectedCoutry, setCardpointeSelectedCoutry] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const [cardpointeFormError, setCardpointeFormError] = useState("");
  const [cardpointeCountryWiseStates, setCardpointeCountryWiseStates] =
    useState([]);
  const states = useSelector((state) => state.global.states);
  const [isInitialized, setIsInitialized] = useState(false);

  const clearCardpoint = () => {
    
    initCardpointeForm();
  };

  const initCardpointeForm = () => {

    let countryObj1 = (userInformation?.Address?.Country?.country_code  && PAYMENTSUPPORTCOUNTRY.includes(userInformation?.Address?.Country?.country_code))
      ? {
          value: userInformation?.Address?.Country?.country_code,
          label: userInformation?.Address?.Country?.country,
        }
      : USA_COUNTRY;
    let stateCode = userInformation?.state_abbbreaviation
      ? userInformation?.state_abbbreaviation
      : "";
    let tilledObjNew = {
      ...tilledObj,
      billing_details: {
        ...tilledObj.billing_details,
        address: {
          ...tilledObj.billing_details.address,
          street: userInformation?.Address?.address
            ? userInformation?.Address?.address
            : "",
          state: checkCountryOrProvincesForString(
            countryObj1?.value,
            stateCode
          ),
          city: userInformation?.Address?.city
            ? userInformation?.Address?.city
            : "",
          zip: userInformation?.Address?.postal_code
            ? userInformation?.Address?.postal_code
            : "",
          country: userInformation?.Address?.Country?.country_code
            ? userInformation?.Address?.Country?.country_code
            : USA_COUNTRY.value,
        },
        name: userInformation?.name ? userInformation?.name : "",
        email: companyInformation.billingInfo?.billing_email
          ? companyInformation.billingInfo?.billing_email
          : userInformation.email
          ? userInformation.email
          : "",
        phone: userInformation?.phone ? userInformation?.phone : "",
      },
    };

    if (tilledObj.type == "card") {
      tilledObjNew = { ...tilledObjNew, type: "card", ...tilledTypeCard };
    } else if (tilledObj.type == "ach_debit") {
      tilledObjNew = { ...tilledObjNew, type: "ach_debit", ...tilledTypeAch };
    }
    setPayMethod(WALLET_TILLED_CC)
    setExpiryDate("");
    getCardpointeCountryWiseStates(countryObj1);
    setCardpointePayloadObj(tilledObjNew);
    setCardpointeSelectedCoutry(countryObj1);
    let stateObj = userInformation.Address?.State?.state_id
      ? {
          value: userInformation?.state_abbbreaviation,
          label: userInformation.Address?.State?.state_name,
        }
      : "";
    setCardpointeSelectedState(checkCountryOrProvinces(countryObj1, stateObj));
  };

  const radioHandlerCardpointeAch = () => {
    let cardpointeFrm = { ...cardpointePayloadObj };
    delete cardpointeFrm["card"];
    setCardpointePayloadObj({
      ...cardpointeFrm,
      type: "ach_debit",
      ...tilledTypeAch,
    });
    setPayMethod(WALLET_TILLED_ACH);
    setPaymentType("ACH");
  };

  const radioHandlerCardpointeCc = () => {
    let cardpointeFrm = { ...cardpointePayloadObj };
    delete cardpointeFrm["ach_debit"];
    setCardpointePayloadObj({
      ...cardpointeFrm,
      type: "card",
      ...tilledTypeCard,
    });
    setPayMethod(WALLET_TILLED_CC);
    setPaymentType("CC");
  };
  const getCardpointeCountryWiseStates = (selectedValue) => {
    let state_arr = filterArrayByKeyValue(
      "country_code",
      selectedValue.value,
      states
    );
    setCardpointeCountryWiseStates(state_arr);
  };

  useEffect(() => {
    if (userInformation && companyInformation && !isInitialized) {
      initCardpointeForm();
      setIsInitialized(true);
    }
  }, [userInformation, companyInformation]);

  return {
    paymentType,
    setPaymentType,
    cardpointePayloadObj,
    setCardpointePayloadObj,
    setCardpointeFormError,
    cardpointeFormError,
    radioHandlerCardpointeCc,
    radioHandlerCardpointeAch,
    setPayMethod,
    payMethod,
    setCardpointeSelectedState,
    setCardpointeSelectedCoutry,
    cardpointeSelectedCoutry,
    cardpointeSelectedState,
    clearCardpoint,
    setIsInitialized,
    cardpointeCountryWiseStates,
    getCardpointeCountryWiseStates,
  };
}

export default useCardpointeModalUtils;
