const initState = {
  keycloak: null,
  authenticated: false,
  profile: null,
  expireTime:0,
  isProxy: false
};

const authentication = (state = initState, action) => {

  switch (action.type) {

    case 'LOGIN':
      return {
        ...state, 
        keycloak: action.payload.keycloak, 
        authenticated:action.payload.authenticated, 
        expireTime: action.payload.expireTime
      };
    
    case 'SAVE_PROFILE':
      return {
        ...state,
        profile: action.payload,
      };

    case 'MODIFY_AUTHENTICATION' :
      return {
        ...state,
        keycloak: action.payload,
      };

    case 'SET_IS_PROXY' :
      return {
        ...state,
        isProxy: action.payload,
      };
      
    case 'LOG_OUT': {
      return initState;
    }
    default:
      return state;
  }
};

export default authentication
