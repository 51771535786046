import React, { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { Col, Form } from "react-bootstrap";

import { useForm } from "react-hook-form";
import { TilledAchType } from "../../../utils/paymentConstUtil";
import { BANKTRANSFER, buildForm, CREDITCARD, getTilled } from "../../../utils/tilledUtil";
import CustomSelect from "../../atoms/CustomSelect";
import InputText from "../../atoms/InputText";
import { LanguageContext } from "../../containers/Language";

export default function CreditCardFileds(props) {
  const {
    register,
    formState: { errors },
    setValue,
  } = useForm();
  const { localised } = useContext(LanguageContext);


  useEffect(() => {
    setTimeout(() => {
      (async () => {
        CREDITCARD.tilled = getTilled(props.accountId);
        buildForm(CREDITCARD)
      })();

    }, 10);
    return () => {
      if (CREDITCARD.form) {
        CREDITCARD.form.teardown((success) => {
          CREDITCARD.form = undefined;
        });
      };
    }
  }, []);

  return (
    <>
      {props.tilledError ? <p className="text-danger">
        {props.tilledError}  </p> : ''}

      <Col md="6" lg="6">


        <Form.Group className="required form-group">
          <Form.Label>{localised.billingEmail}</Form.Label>
          <InputText
            id={'billingEmail'}
            name={'email'}
            type={'text'}
            placeholder={localised.billingEmail}
            value={props.tilledPayloadObj?.billing_details?.email}
            onChange={(e) => props.handleCcChange(e)}
            onKeyDown={props.whiteSpaceHandler}
            required={'required'}

            isInvalid={(props.formError?.email_error) ? !!props.formError.email_error : false}
          />
          <Form.Control.Feedback type="invalid">
            {props.formError && props.formError.email_error && props.formError.email_error.length > 0 && (
              props.formError.email_error
            )}</Form.Control.Feedback>
        </Form.Group>
      </Col>
      <Col md="6" lg="6">
        <Form.Group className="required form-group">
          <Form.Label>{localised.nameOnCard}</Form.Label>
          <InputText
            id={'nameOnCard'}
            name={'name'}
            type={'text'}
            placeholder={localised.nameOnCard}
            value={props.tilledPayloadObj?.billing_details?.name}
            onChange={(e) => props.handleCcChange(e)}
            required={'required'}

            isInvalid={(props.formError?.name_error) ? !!props.formError.name_error : false}
          />
          <Form.Control.Feedback type="invalid">
            {props.formError && props.formError.name_error && props.formError.name_error.length > 0 && (
              props.formError.name_error
            )}</Form.Control.Feedback>
        </Form.Group>
      </Col>
      <Col md="6" lg="6">
        <Form.Group className="required form-group">
          <Form.Label>{localised.cardNumber}</Form.Label>


          <div id="card-number-element" {...register("cardNumber", { required: true, maxLength: 15 })} />

          {errors.cardNumber?.type === "required" && "Card Number is required"}

        </Form.Group>
      </Col>
      <Col md="6" lg="6">
        <Form.Group className="required form-group">
          <Form.Label>{localised.expiration}</Form.Label>

          <div
            className="tilled-js_input border rounded-md"
            id="card-expiration-element"
            {...register("expiration", { required: true })}
          />
          {errors.expiration?.type === "required" && "Expiration is required"}
        </Form.Group>
      </Col>
      <Col md="6" lg="6">
        <Form.Group className="required form-group">
          <Form.Label>{localised.cvc}</Form.Label>

          <div
            className="tilled-js_input border rounded-md"
            id="card-cvv-element"
            {...register("cvv", { required: true, maxLength: 3 })}
          />
          {errors.cvv?.type === "required" && "cvv is required"}
        </Form.Group>
      </Col>
      {props.isAdminIvoice && props.saveCardShow &&
        <Col md="6">
          <Form.Group className="form-group d-flex align-items-center pt-md-4 mb-md-0 mt-md-2">
            <Form.Check
              type="checkbox"
              id="custom-switch"
              label="Save this card"
              checked={props.saveCard}
              onClick={(e) => props.setSaveMethod(e)}
            />
          </Form.Group>
        </Col>
      }
    </>
  );
}
