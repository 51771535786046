import moment from "moment";
import Payment from 'payment';

export const OTHERCARDS = [/[1-9]/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, /\d/];
export const AMERICANEXPRESS = [/[1-9]/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, /\d/, /\d/];
export const EXPIRYDATE = [/[0-9]/, /\d/, "/", /\d/, /\d/];
export const CVC = [/[0-9]/, /\d/, /\d/, /\d/];


export function cardNumberValidation(cardNumber) {
    const regexPattern = {
        MASTERCARD: /^5[1-5][0-9]{1,}|^2[2-7][0-9]{1,}$/,
        VISA: /^4[0-9]{2,}$/,
        AMERICAN_EXPRESS: /^3[47][0-9]{5,}$/,
        DISCOVER: /^6(?:011|5[0-9]{2})[0-9]{3,}$/,
        DINERS_CLUB: /^3(?:0[0-5]|[68][0-9])[0-9]{4,}$/,
        JCB: /^(?:2131|1800|35[0-9]{3})[0-9]{3,}$/
    };
    for (const card in regexPattern) {
        if (cardNumber.replace(/[^\d]/g, "").match(regexPattern[card])) {
            if (cardNumber) {
                return cardNumber &&
                    /^[1-6]{1}[0-9]{14,15}$/i.test(
                        cardNumber.replace(/[^\d]/g, "").trim()
                    )
                    ? ""
                    : "Enter a valid Card";
            }
        }
    }
    return "Enter a valid Card";
}

export const cardExpirValidation = (value) => {
    if (value) {
        if (/^(0[1-9]|1[0-2])\/[0-9]{2}$/i.test(value.trim())) {
            let today = new Date();
            let CurrentDate = moment(
                new Date(
                    today.getFullYear() +
                    "-" +
                    (today.getMonth() + 1) +
                    "-" +
                    new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate()
                )
            );
            let visaValue = value.split("/");
            let visaDate = new Date(`20${visaValue[1]}`, visaValue[0], 0);
            return CurrentDate < moment(visaDate)
                ? undefined
                : "Please enter valid date";
        } else {
            return "Invalid date format";
        }
    }
};
//Name of the card Validation
export const textWithSpacesOnly = (value) => {
    if (value) {

        if (/^[ A-Za-z0-9_@./#&+-]*$/i.test(value)) {
            return undefined;
        } else {
            return "Only alphabets";
        }
    } else {
        return undefined;
    }
};

// only name 

export const textAndSpace =(value)=>{
    if(value){
        if(/^[a-zA-Z ]*$/.test(value)){
            return undefined;
        } else {
            return "Only alphabets";
        }
    } else {
        return undefined;
    }
    
}

export const onlyText =(value) =>{

    if(value){
        if(/^[a-zA-Z]*$/.test(value)){
            return undefined;
        } else {
            return "Only alphabets";
        }
    } else {
        return undefined;
    }
    
}
//CVC Validation
export const minLength = (min) => (value) => value && value.length >= min && /^[0-9]*$/.test(value) && value.length > 10 ? 'Must be '+ min+' characters or more or number' : ``
//Ach account number
export const achAccountNumber = (value) => {

    if (value) {
        if (/^[0-9]*$/.test(value)) {
            return undefined;
        } else {
            return "Only number";
        }
    } else {
        return undefined;
    }
};

//Ach account number
export const achRountingNumber = (value) => {
    if (value) {
        if (/^[0-9]*$/.test(value)) {
            return undefined;
        } else {
            return "Only number";
        }
    } else {
        return undefined;
    }
};

//email validation
export const accountEmail = (value) => {
    let noRegx = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    if (value) {
        if (noRegx.test(value)) {
            return undefined;
        } else {
            return 'Please enter valid Email Address';
        }
    } else {
        return undefined;
    }
};

// expiry date

export const cardExpiryDate = (value) => {

    const [month, year] = (value && value.indexOf('/') > -1) ? value.split("/") : '00/12';
    if (!Payment.fns.validateCardExpiry(month, year)) {
        return "Expiry date incorrect."
    }
    return undefined;

}

export const cardExpiryMonth = (value) => {

    if (Number(value) > 12) {
        return "Expiry date incorrect."
    }
    if (Number(value) < 0) {
        return "Expiry date incorrect."
    }
    return undefined;

}
export const cardExpiryYear = (value) => {
    const currentYearStr = new Date().getFullYear().toString()
    const last2 = currentYearStr.substring(currentYearStr.length - 2);
    const last2Num = Number(last2);
    if (Number(value) < last2Num) {
        return "Expiry date incorrect."
    }
    return undefined;

}

const isDate = (date) => {
    var date_regex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
    if (!(date_regex.test(date))) {
        return " Invalid date ";
    }
    return "";
}

const isValid = (value) => {
    return value == "" || typeof value == 'undefined'|| value == null;
}

export const handleValidations = (type, value) => {
    let errorText;
    switch (type) {
        case "name":
            errorText = isValid(value) ? "This field is required" : '';
            return { name_error: errorText === '' ? undefined : errorText };
            break
        case "number":
            errorText = isValid(value) ? "This field is required" : cardNumberValidation(value);
            return { number_error: errorText === '' ? undefined : errorText };
            break;
        case "account_holder_name":
            errorText = isValid(value) ? "This field is required" : accountHolderName(value);
            return { account_holder_name_error: errorText };
            break;
        // case "expiry":
        //     errorText = value == "" ? "This field is required" : cardExpirValidation(value);
        //     return { expiry_error: errorText };
        //     break;
        case "cvc":
            errorText = value == "" ? "This field is required" : minLength(3)(value);
            return { cvc_error: errorText };
            break;
        case "account_number":
            errorText = isValid(value) ? "This field is required" : achAccountNumber(value);
            return { account_number_error: errorText };

            break;
        case "routing_number":
            errorText = isValid(value) ? "This field is required" : achRountingNumber(value);
            return { routing_number_error: errorText };

            break;
        case "email":
            errorText = isValid(value) ? "This field is required" : accountEmail(value);
            return { email_error: errorText };

            break;
        case "zip":
            errorText = isValid(value) ? "This field is required" : minLength(5)(value);
            return { zip_error: errorText };
            break;
        case "city":
            errorText = isValid(value) ? "This field is required" : undefined;
            return { city_error: errorText };
            break;
        case "state":
            errorText = isValid(value) ? "This field is required" : textWithSpacesOnly(value);
            return { state_error: errorText };
            break;
        case "street":
            errorText = isValid(value) ? "This field is required" : undefined;
            return { street_error: errorText };
            break;
        case "exp_month_year":
            errorText = isValid(value) ? "This field is required" : cardExpiryDate(value);
            return { exp_month_year_error: errorText === '' ? undefined : errorText };
            break;
        case "agreement_sign_date":
            errorText = isValid(value) ? "This field is required" : '';
            return { agreement_sign_date_error: errorText === '' ? undefined : errorText };
            break;
        case "exp_year":
            errorText = isValid(value) || typeof value == 'undefined' ? "This field is required" : cardExpiryYear(value);
            return { exp_month_year_error: errorText === '' ? undefined : errorText };
            break;
        case "exp_month":
            errorText = isValid(value) ? "This field is required" : cardExpiryMonth(value);
            return { exp_month_year_error: errorText === '' ? undefined : errorText };
            break;
        case "country":
            errorText = isValid(value) ? "This field is required" : '';
            return { country_error: errorText === '' ? undefined : errorText };
            break;
        case "state":
            errorText = isValid(value) ? "This field is required" : '';
            return { state_error: errorText === '' ? undefined : errorText };
            break;
        case "account_type":
            errorText = isValid(value) ? "This field is required" : '';
            return { account_type_error: errorText === '' ? undefined : errorText };
            break;
        default:
            break;
    }
};



function clearNumber(value = '') {
    return value.replace(/\D+/g, '')
}
export const isPriceAmount = (value) => /^\d+(\.\d{1,2})?$/.test(Math.abs(value));

export function formatCreditCardNumber(value) {
    if (!value) {
        return value
    }

    const issuer = Payment.fns.cardType(value)
    const clearValue = clearNumber(value)
    let nextValue

    switch (issuer) {
        case 'amex':
            nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
                4,
                10
            )} ${clearValue.slice(10, 15)}`
            break
        case 'dinersclub':
            nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
                4,
                10
            )} ${clearValue.slice(10, 14)}`
            break
        default:
            nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
                4,
                8
            )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`
            break
    }

    return nextValue.trim()
}

export function formatCVC(value, prevValue, allValues = {}) {
    const clearValue = clearNumber(value)
    let maxLength = 4

    if (allValues.number) {
        const issuer = Payment.fns.cardType(allValues.number)
    }

    return clearValue.slice(0, maxLength)
}

export function formatExpirationDate(value) {
    const clearValue = clearNumber(value)

    if (clearValue.length >= 3) {
        return (
          clearValue.replace(/\//g, "").substring(0, 2) +
          (clearValue.length > 2 ? "/" : "") +
          clearValue.replace(/\//g, "").substring(2, 4)
        );
    }
    return clearValue
}

export function tilledFormValidation(tilledForm, isTilledForm = false) {
    let formError;
    if (tilledForm.type == 'card') {
        if (!isTilledForm) {
            // for (const key in tilledForm?.card) {
            //     let err = handleValidations(key, tilledForm?.card[key]);
            //     formError = {
            //         ...formError,
            //         ...err
            //     };
            // }
            let err5 = handleValidations('exp_month_year', tilledForm?.card?.exp_month + '/' + tilledForm?.card?.exp_year);
            formError = {
                ...formError,
                ...err5
            };
        }

        let err3 = handleValidations('email', tilledForm?.billing_details?.email);
        let err4 = handleValidations('name', tilledForm?.billing_details?.name);

        formError = {
            ...formError,
            ...err3,
            ...err4,
        };

    }
   
    for (const key in tilledForm?.billing_details?.address) {

        let err2 = handleValidations(key, tilledForm?.billing_details?.address[key]);
        formError = {
            ...formError,
            ...err2
        };
    }

    if (tilledForm.type == 'ach_debit') {
        //# if tilled form then skip below key from validation
        let checkeys = ['account_holder_name', 'account_type'];
        for (const key in tilledForm?.ach_debit) {
            let err3 = handleValidations(key, tilledForm?.ach_debit[key]);
            if (isTilledForm && checkeys.includes(key)) {

                formError = {
                    ...formError,
                    ...err3
                };
            } else if (!isTilledForm) {
                formError = {
                    ...formError,
                    ...err3
                };
            }
        }
    }
    return formError;

}

export function tilledCCPayload(tilledForm) {
    let cardNo = tilledForm.card?.number?.replace(/\s+/g, '');
    let currentCentury = 20;
    let fullExpYear = `${currentCentury}${tilledForm.card?.exp_year}`;
    tilledForm = {
        ...tilledForm,
        card: {
            ...tilledForm.card,
            number: cardNo,
            exp_year: Number(fullExpYear)
        }
    }
  
    return tilledForm;
}


export const accountHolderName = (value) => {
    if (value) {

        if (/^[ A-Za-z0-9_@./#&+-]*$/i.test(value)) {
            if(value.length <= 60){
                return undefined;
            }else{
                return "Max 60 characters allowed for the account holder name"; 
            }
            
        } else {
            return "Only alphabets";
        }
    } else {
        return undefined;
    }
};


export function merchantIdValidation(cardNumber) {
    if (cardNumber) {
        if (/^\d+$/.test(cardNumber)) {
            if(cardNumber.length <= 19){
                return undefined;
            }else{
                return "Max 19 characters allowed for the MerchantId"; 
            }
            
        } else {
            return "Only Numbers";
        }
    } else {
        return undefined;
    }
}
