import React from "react";
import { useDispatch } from 'react-redux';
import { logout } from '../../../../redux/authentication/action';

const Logout = () => {
    const dispatch = useDispatch();

    dispatch(logout());

    return (<></>);
}


export default Logout;