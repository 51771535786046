const initState = {
  inventory:[]
};

const inventory = (state = initState, action) => {

  switch (action.type) {
    case 'SET_ALL_INVENTORIES':
      return {
        ...state,
        inventoryList: action.payload.data
      };

    case 'CLEAR_ALL_INVENTORIES':
      return {
        ...state,
        inventoryList: null
      };

    case 'SET_PROPERTY_INVENTORIES': {
      return {
        ...state,
        propertyInventories: action.payload.data
      };
    }

    case 'CLEAR_PROPERTY_INVENTORIES': {
      return {
        ...state,
        propertyInventories: null
      };
    }

    case 'SET_PROPERTY_MANAGER_INVENTORIES': {
      return {
        ...state,
        propertyManagerInventories: action.payload.data
      };
    }

    case 'SET_INVENTORY_TEMPLATE_LIST': {
      return {
        ...state,
        masterInventoryTemplateList : action.payload,
      };
    }
    
    case 'CLEAR_INVENTORY_TEMPLATE_LIST': {
      return {
        ...state,
        masterInventoryTemplateList:[],
      };
    }

      default:
        return state;
}
};

export default inventory;