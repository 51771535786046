import APIservice from "../apiService";
import { isLoader } from "../global/action";
import { microServices } from "../../config/global";
import { setError } from "../form/action";
import moment from "moment";

/**
 * DailyCloseoutMiddleware can be used to call any Daily closeout related API services
 * @param {*} store 
 * @returns 
 */

const DailyCloseoutMiddleware = (store) => (next) => async (action) => {
    // Our middleware
    const result = next(action);
    let endpoint = microServices.base + '/v1/daily-closeout';
    let today = moment().format('YYYY/MM/DD');
    switch (action.type) {
        
        case "DELETE_DAILY_CLOSEOUT_REPORT":
            APIservice
            .post(endpoint+'/reports/delete', action.payload.data)
            .then((response) => {
                store.dispatch({
                type: "SHOW_ALERT",
                payload: {
                    variant: "success",
                    message: action.payload.sucessMessage,
                    componentName: action.payload.componentName,
                },
                });
            })
            .catch((error) => {
                if (error.response.status !== 422) {
                store.dispatch({
                    type: "SHOW_ALERT",
                    payload: {
                    variant: "danger",
                    message: action.payload.errorMessage,
                    },
                });
                } else {
                    let err = {
                        componentName: action.payload.componentName,
                        statusCode: error.response.status,
                        data: error.response.data,
                    };
                    store.dispatch(setError(err));
                    }
                })
            .finally(() => {
                store.dispatch(isLoader(false));
            });
        break;
    
        case "DROPDOWN_DCP_PM": {
            store.dispatch(isLoader(true));
            endpoint = `${microServices.base}/v1/dropdown/daily-closeout/pm/process?date=${action.payload.reportDate}`;
            APIservice.get(endpoint)
                .then((response) => {
                    if (response?.data) {
                        let dcpPmOprions = prepareDcpPmOptions(response.data);
                        store.dispatch({
                            type: "SET_DROPDOWN_DCP_PM",
                            payload: dcpPmOprions,
                        });
                    }
                })
                .catch((error) => {
                    if (error?.response?.status !== 422) {
                      store.dispatch({ 
                        type: 'SET_DROPDOWN_DCP_PM', 
                        payload: [],
                      });
                    }
                })
                .finally(() => {
                    store.dispatch(isLoader(false));
                })
            break;
        }

        case "DCP_OVERDUE_NOT_COMPLETED_APPOINTMENTS": {
            store.dispatch(isLoader(true));
            let filters = `per_page=0&filter[overdueAptTillDate]=${action.payload.report_date}&filter[app_status_id]=2&filter[dnd_reconciled]=0&filter[dnd_status]=0&filter[is_client_service]=false`;
            endpoint += `/process/appointments/${action.payload.manager_id}?${filters}`;
            APIservice.get(endpoint, action?.payload?.params)
                .then((response) => {
                    if(response?.data) {
                        store.dispatch({
                            type: "SET_DCP_OVERDUE_NOT_COMPLETED_APPOINTMENTS",
                            payload: {
                                data: response.data.data
                            }    
                        });
                    }
                })
                .catch((error) => {
                    let err = {componentName:'DailyCloseoutModal', statusCode: error.response.status, data: error.response.data};
                    store.dispatch(setError(err));    
                })
                .finally(() => {
                    store.dispatch(isLoader(false));
                })
            break;
        }

        case "DCP_TODAY_NOT_COMPLETED_APPOINTMENTS": {
            store.dispatch(isLoader(true));
            let filters = `per_page=0&filter[aptBetween]=${action.payload.report_date}-${action.payload.report_date}&filter[app_status_id]=2&filter[dnd_reconciled]=0&filter[dnd_status]=0&filter[is_client_service]=false`;
            endpoint += `/process/appointments/${action.payload.manager_id}?${filters}`;
            APIservice.get(endpoint, action?.payload?.params)
                .then((response) => {
                    if(response?.data) {
                        store.dispatch({
                            type: "SET_DCP_TODAY_NOT_COMPLETED_APPOINTMENTS",
                            payload: {
                                data: response.data.data
                            }    
                        });
                    }
                })
                .catch((error) => {
                    let err = {componentName:'DailyCloseoutModal', statusCode: error.response.status, data: error.response.data};
                    store.dispatch(setError(err));    
                })
                .finally(() => {
                    store.dispatch(isLoader(false));
                })
            break;
        }

        case "DROPDOWN_DCP_PM_OPTIONS": {
          endpoint = `${microServices.base}/v1/dropdown/daily-closeout/pm/reports`;
          APIservice.get(endpoint)
              .then((response) => {
                  if (response?.data) {
                      let dcpPmOptions = prepareDcpPmOptions(response.data);
                      store.dispatch({
                          type: "SET_DROPDOWN_DCP_PM_LIST",
                          payload: dcpPmOptions,
                      });
                  }
              })
              .catch((error) => {
                  if (error?.response?.status !== 422) {
                    store.dispatch({ 
                      type: 'SET_DROPDOWN_DCP_PM_LIST', 
                      payload: [],
                    });
                  }
              })
          break;
        }

        case "DCP_DND_OVERDUE_NOT_COMPLETED_APPOINTMENTS": {
            store.dispatch(isLoader(true));
            let filters = `per_page=0&filter[overdueAptTillDate]=${action.payload.report_date}&filter[app_status_id]=2&filter[dnd_reconciled]=0&filter[dnd_status]=1,2,3&filter[is_client_service]=false`;
            endpoint += `/process/appointments/${action.payload.manager_id}?${filters}`;
            APIservice.get(endpoint, action?.payload?.params)
                .then((response) => {
                    if(response?.data) {
                        store.dispatch({
                            type: "SET_DCP_DND_OVERDUE_NOT_COMPLETED_APPOINTMENTS",
                            payload: {
                                data: response.data.data
                            }    
                        });
                    }
                })
                .catch((error) => {
                    let err = {componentName:'DailyCloseoutModal', statusCode: error.response.status, data: error.response.data};
                    store.dispatch(setError(err));    
                })
                .finally(() => {
                    store.dispatch(isLoader(false));
                })
            break;
        }

        case "DCP_DND_TODAY_NOT_COMPLETED_APPOINTMENTS": {
            store.dispatch(isLoader(true));
            let filters = `per_page=0&filter[aptBetween]=${action.payload.report_date}-${action.payload.report_date}&filter[app_status_id]=2&filter[dnd_reconciled]=0&filter[dnd_status]=1,2,3&filter[is_client_service]=false`;
            endpoint += `/process/appointments/${action.payload.manager_id}?${filters}`;
            APIservice.get(endpoint, action?.payload?.params)
                .then((response) => {
                    if(response?.data) {
                        store.dispatch({
                            type: "SET_DCP_DND_TODAY_NOT_COMPLETED_APPOINTMENTS",
                            payload: {
                                data: response.data.data
                            }    
                        });
                    }
                })
                .catch((error) => {
                    let err = {componentName:'DailyCloseoutModal', statusCode: error.response.status, data: error.response.data};
                    store.dispatch(setError(err));    
                })
                .finally(() => {
                    store.dispatch(isLoader(false));
                })
            break;
        }

        case "GET_DCP_BASELINE_ROLES": {
            store.dispatch(isLoader(true));
            endpoint += `/process/baseline-roles/${action.payload.manager_id}`;
            APIservice.get(endpoint, action?.payload?.params)
                .then((response) => {
                    if(response?.data) {
                        store.dispatch({
                            type: "SET_DCP_BASELINE_ROLES",
                            payload: {
                                data: response.data.data
                            }    
                        });
                    }
                })
                .catch((error) => {
                    let err = {componentName:'DcpBaselineRoles', statusCode: error.response.status, data: error.response.data};
                    store.dispatch(setError(err));    
                })
                .finally(() => {
                    store.dispatch(isLoader(false));
                })
            break;
        }

        case "GET_DCP_CONTRACT_POSITIONS": {
            store.dispatch(isLoader(true));
            endpoint += `/process/contract-positions/${action.payload.manager_id}`;
            APIservice.get(endpoint, action?.payload?.params)
                .then((response) => {
                    if(response?.data) {
                        store.dispatch({
                            type: "SET_DCP_CONTRACT_POSITIONS",
                            payload: {
                                data: response.data.data
                            }    
                        });
                    }
                })
                .catch((error) => {
                    let err = {componentName:'DcpContractPositions', statusCode: error.response.status, data: error.response.data};
                    store.dispatch(setError(err));    
                })
                .finally(() => {
                    store.dispatch(isLoader(false));
                })
            break;
        }

        case 'CREATE_DCP_REPORT': {
            endpoint += '/reports';
            APIservice.post(endpoint, action.payload.data)
                .then((response) => {
                    store.dispatch({
                        type: "SHOW_ALERT",
                        payload: {
                            variant: "success",
                            message: action.payload.sucessMessage,
                            componentName: action.payload.componentName,
                        },
                    });
                })
                .catch((error) => {
                    if (error.response.status !== 422) {
                    store.dispatch({
                            type: "SHOW_ALERT",
                            payload: {
                            variant: "danger",
                            message: action.payload.errorMessage,
                        },
                    });
                    } else {
                        let err = {
                            componentName: action.payload.componentName,
                            statusCode: error.response.status,
                            data: error.response.data,
                        };
                        store.dispatch(setError(err));
                    }
                })
                .finally(() => {
                    store.dispatch(isLoader(false));
                });
            break;
        }
        case "EXPORT_DAILY_CLOSEOUT_REPORT":
            endpoint = microServices.base + '/v1/'+action?.payload?.selectedLanguage+'/daily-closeout/reports/export';
            console.log(endpoint)
            store.dispatch(isLoader(true));
            APIservice.
            getBlobDataForMultipleRecord(endpoint, action.payload.formData)
            .then((response)=>{
                const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = blobUrl;
                link.setAttribute('download', `${(action.payload?.fileName) ? action.payload.fileName : "Daily Closeout Process Report"}_${Date.now()}.xlsx`);
                document.body.appendChild(link);
                link.click(); 
                link.parentNode.removeChild(link);
            })
            .catch((error)=>{
                if (error.response.status !== 422) {
                    store.dispatch({ 
                        type: 'SHOW_ALERT', 
                        payload: {
                            variant:'danger',
                            message: 'Unable to Download Excel Report!',
                        }
                    });
                } else {
                    let err = {componentName:'DownloadReportXlsx',statusCode: error.response.status,data: error.response.data};
                    store.dispatch(setError(err));
                }
            })
            .finally(()=>{
                store.dispatch(isLoader(false));
            });
        break;

        case "SEND_DAILY_CLOSEOUT_REPORT_EMAIL": {
                store.dispatch(isLoader(true));
                APIservice
                .post(endpoint+'/reports/SendEmail', action.payload.data)
                .then((response)=>{
                    if (response) {
                    store.dispatch({
                        type: 'SHOW_ALERT',
                        payload: {
                        variant: 'success',
                        message: action.payload.sucessMessage,
                        componentName: action.payload.componentName,
                        data : response.data.data
                        }
                    });
                    }
                })
                .catch((error)=>{
                    let err = {componentName:action.payload.componentName,statusCode: error.response.status,data: error.response.data};
                    store.dispatch(setError(err));
                })
                .finally(()=>{
                    store.dispatch(isLoader(false));
                })
                break;
            }

        case "DCP_COMPLETED_APPOINTMENTS": {
            store.dispatch(isLoader(true));
            let filters = `per_page=0&filter[dcp_id]=${action.payload.dcp_id}&filter[type]=0`;
            endpoint += `/reports/appointments?${filters}`;
            APIservice.get(endpoint, action?.payload?.params)
                .then((response) => {
                    if(response?.data) {
                        store.dispatch({
                            type: "SET_DCP_COMPLETED_APPOINTMENTS",
                            payload: {
                                data: response.data.data
                            }    
                        });
                    }
                })
                .catch((error) => {
                    let err = {componentName:'DailyCloseoutEditModal', statusCode: error.response.status, data: error.response.data};
                    store.dispatch(setError(err));    
                })
                .finally(() => {
                    store.dispatch(isLoader(false));
                })
            break;
        }
    
        case "DCP_DND_COMPLETED_APPOINTMENTS": {
            store.dispatch(isLoader(true));
            let filters = `per_page=0&filter[dcp_id]=${action.payload.dcp_id}&filter[type]=1`;
            endpoint += `/reports/appointments?${filters}`;
            APIservice.get(endpoint, action?.payload?.params)
                .then((response) => {
                    if(response?.data) {
                        store.dispatch({
                            type: "SET_DCP_DND_COMPLETED_APPOINTMENTS",
                            payload: {
                                data: response.data.data
                            }    
                        });
                    }
                })
                .catch((error) => {
                    let err = {componentName:'DailyCloseoutEditModal', statusCode: error.response.status, data: error.response.data};
                    store.dispatch(setError(err));    
                })
                .finally(() => {
                    store.dispatch(isLoader(false));
                })
            break;
        }

        case "DCP_OVERDUE_COMPLETED_APPOINTMENTS": {
            store.dispatch(isLoader(true));
            let filters = `per_page=0&filter[dcp_id]=${action.payload.dcp_id}&filter[type]=2`;
            endpoint += `/reports/appointments?${filters}`;
            APIservice.get(endpoint, action?.payload?.params)
                .then((response) => {
                    if(response?.data) {
                        store.dispatch({
                            type: "SET_DCP_OVERDUE_COMPLETED_APPOINTMENTS",
                            payload: {
                                data: response.data.data
                            }    
                        });
                    }
                })
                .catch((error) => {
                    let err = {componentName:'DailyCloseoutEditModal', statusCode: error.response.status, data: error.response.data};
                    store.dispatch(setError(err));    
                })
                .finally(() => {
                    store.dispatch(isLoader(false));
                })
            break;
        }

        case "DCP_DND_OVERDUE_COMPLETED_APPOINTMENTS": {
            store.dispatch(isLoader(true));
            let filters = `per_page=0&filter[dcp_id]=${action.payload.dcp_id}&filter[type]=3`;
            endpoint += `/reports/appointments?${filters}`;
            APIservice.get(endpoint, action?.payload?.params)
                .then((response) => {
                    if(response?.data) {
                        store.dispatch({
                            type: "SET_DCP_DND_OVERDUE_COMPLETED_APPOINTMENTS",
                            payload: {
                                data: response.data.data
                            }    
                        });
                    }
                })
                .catch((error) => {
                    let err = {componentName:'DailyCloseoutEditModal', statusCode: error.response.status, data: error.response.data};
                    store.dispatch(setError(err));    
                })
                .finally(() => {
                    store.dispatch(isLoader(false));
                })
            break;
        }
    
        case "DOWNLOAD_DAILY_CLOSEOUT_REPORT_PDF":
            endpoint = microServices.base + '/v1/'+action?.payload?.selectedLanguage+'/daily-closeout/reports/print';
            store.dispatch(isLoader(true));
            APIservice.
            getBlobDataForMultipleRecord(endpoint, action.payload.formData)
            .then((response)=>{
                const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = blobUrl;
                link.setAttribute('download', `${(action.payload?.fileName) ? action.payload.fileName : "Daily Closeout Process Report"}_${Date.now()}.pdf`);
                document.body.appendChild(link);
                link.click(); 
                link.parentNode.removeChild(link);
            })
            .catch((error)=>{
                if (error.response.status !== 422) {
                    store.dispatch({ 
                        type: 'SHOW_ALERT', 
                        payload: {
                            variant:'danger',
                            message: 'Unable to Download Report!',
                        }
                    });
                } else {
                    let err = {componentName:'DownloadReport',statusCode: error.response.status,data: error.response.data};
                    store.dispatch(setError(err));
                }
            })
            .finally(()=>{
                store.dispatch(isLoader(false));
            });
        break;

        case "GET_DCP_REPORT_BASELINE_ROLES": {
            store.dispatch(isLoader(true));
            endpoint += `/reports/baseline-roles/${action.payload.dcp_id}`;
            APIservice.get(endpoint, action?.payload?.params)
                .then((response) => {
                    if(response?.data) {
                        store.dispatch({
                            type: "SET_DCP_REPORT_BASELINE_ROLES",
                            payload: {
                                data: response.data.data
                            }    
                        });
                    }
                })
                .catch((error) => {
                    let err = {componentName:'DailyCloseoutEditBaselineRoles', statusCode: error.response.status, data: error.response.data};
                    store.dispatch(setError(err));    
                })
                .finally(() => {
                    store.dispatch(isLoader(false));
                })
            break;
        }

        case "GET_DCP_REPORT_SUPPLEMENTAL_HRS": {
            store.dispatch(isLoader(true));
            endpoint += `/reports/supplemental-hrs/${action.payload.dcp_id}`;
            APIservice.get(endpoint, action?.payload?.params)
                .then((response) => {
                    if(response?.data) {
                        store.dispatch({
                            type: "SET_DCP_REPORT_SUPPLEMENTAL_HRS",
                            payload: {
                                data: response.data.data
                            }    
                        });
                    }
                })
                .catch((error) => {
                    let err = {componentName:'DailyCloseoutEditSupplementalHrs', statusCode: error.response.status, data: error.response.data};
                    store.dispatch(setError(err));    
                })
                .finally(() => {
                    store.dispatch(isLoader(false));
                })
            break;
        }

        case "GET_DCP_REPORT_CONTRACT_POSITIONS": {
            store.dispatch(isLoader(true));
            endpoint += `/reports/contract-positions/${action.payload.dcp_id}`;
            APIservice.get(endpoint, action?.payload?.params)
                .then((response) => {
                    if(response?.data) {
                        store.dispatch({
                            type: "SET_DCP_REPORT_CONTRACT_POSITIONS",
                            payload: {
                                data: response.data.data
                            }    
                        });
                    }
                })
                .catch((error) => {
                    let err = {componentName:'DailyCloseoutEditContractPositions', statusCode: error.response.status, data: error.response.data};
                    store.dispatch(setError(err));    
                })
                .finally(() => {
                    store.dispatch(isLoader(false));
                })
            break;
        }

        case 'EDIT_DCP_REPORT': {
            endpoint += `/reports/${action.payload.params.dcp_id}`;
            APIservice.put(endpoint, action.payload.data)
                .then((response) => {
                    store.dispatch({
                        type: "SHOW_ALERT",
                        payload: {
                            variant: "success",
                            message: action.payload.sucessMessage,
                            componentName: action.payload.componentName,
                        },
                    });
                })
                .catch((error) => {
                    if (error.response.status !== 422) {
                    store.dispatch({
                            type: "SHOW_ALERT",
                            payload: {
                            variant: "danger",
                            message: action.payload.errorMessage,
                        },
                    });
                    } else {
                        let err = {
                            componentName: action.payload.componentName,
                            statusCode: error.response.status,
                            data: error.response.data,
                        };
                        store.dispatch(setError(err));
                    }
                })
                .finally(() => {
                    store.dispatch(isLoader(false));
                });
            break;
        }


    }

};

const prepareDcpPmOptions = (response) => {
    let _active = [];
    let _inactive = [];
    
    response.data.map((item) => {
        if (item?.manager_cleaner_status_id == '1' && item?.company_status != '2') {
            _active.push({
                value: item.company_id,
                label: item.company_name,
                status : 1
          });
        }
        else if (item?.manager_cleaner_status_id == '2' || item?.company_status == '2') {
            _inactive.push({
                value: item.company_id,
                label: item.company_name,
                status : 2
          });
        }
      });
    
    let _options = [{
        label: "Active",
        options: _active,
      },
      {
        label: "Inactive",
        options: _inactive,
      }
    ];
    return _options;
  }
  
      
export default DailyCloseoutMiddleware;