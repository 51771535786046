import { microServices } from "../config/global";
import apiService from "../redux/apiService";
import { isObjEmpty, preparePropertiesList,preparePropertyGroup, prepareUrlWithFilter } from "./utils";
import {prepareWorkorderPropertyDropdown} from "../redux/middleware/PropertyMiddleware";

const initialPageSize = 200;
const perPage = 20;

export const loadPropertyNameOptions = (inputValue, loginType = '', type, filterByCompnay = [], lazyLoad = false, pageSize = 0, page) => {
  let userType = type
  if (loginType !== '') {
    //loginType passed from CL/PM Common Components Only
    userType = loginType ? 'cleaner' : 'manager';
  }
  let endPt = microServices.base + '/v1/property/' + userType + '?filter[property_name]=' + inputValue;

  if (filterByCompnay.length > 0) {
      if(userType=='cleaner'){
        let ids =[];
        filterByCompnay.map((id)=>{
          ids.push(id.value);
        })
        endPt+='&filter[manager_id]='+ids.toString();
      }
      if(userType=='manager'){
        let ids =[];
        filterByCompnay.map((id)=>{
          ids.push(id.value);
        })
        endPt+='&filter[clenaer_id]='+ids.toString();
      }
  }
  endPt += '&per_page='+pageSize;
  if(lazyLoad){
    endPt += '&page='+page;
  }
  if (inputValue.length >= 3 || lazyLoad) {
    return apiService.get(endPt)
      .then((response) => { return preparePropertiesList(response, userType) });
  }
}

export const loadAdminPropertyNameOptions = (inputValue, loginType='', type) => {
  if (inputValue.length >= 3) {
    return apiService.get(microServices.base + '/v1/admin/properties?filter[property_name]=' + inputValue + '&per_page=0')
      .then((response) => { return preparePropertyGroup(response) });
  }
}

export  const filterPropertyOptions = async (type, filterByCompnay = []) => {
  
  let endPt  = microServices.base + '/v1/property/';
  if (type =='cleaner') {
    let fiterUrl1 = (filterByCompnay.length >0) ? '?filter[manager_id]='+filterByCompnay.toString()+ '&per_page=0' : '?per_page=0' ;
    endPt += type + fiterUrl1
  } else if (type =='manager') {
    let fiterUrl2 = (filterByCompnay.length >0) ? '?filter[cleaner_id]='+filterByCompnay.toString()+ '&per_page=0' : '?per_page=0' ;
    endPt += type + fiterUrl2
  }
  return await apiService.get(endPt)
    .then((response) => { return  preparePropertiesList(response, type) });
}

export const LOCAL_IVR =13002;


export const filterServiceByPmService =(services,pmId='',clId)=>{
  let serviceList = [
    {
      label: "",
      options: [],
    },
    {
      label: "Manager Service",
      options: [],
    }
  ];
  if(services){
   let selfService =  services.filter((ser=>{
      return ser.created_by_company == clId
    }))
    let pmService =[];
    if(pmId == ''){
       pmService =  services.filter((ser=>{
        return ser.created_by_company!=clId
      }))
    }else{
      pmService =  services.filter((ser=>{
        return ser.created_by_company==pmId
      }))
    }
   
    serviceList = [{
      label: "",
      options: selfService,
    },
    {
      label: (pmService.length > 0) ? "Manager Service" :'',
      options: pmService,
    }];
   
  }
  return serviceList
}

export const filterServiceByClService =(services,pmId)=>{
 
  let serviceList = [
    {
      label: "Internal service",
      options: [],
    },
    {
      label: "Outside Service",
      options: [],
    }
  ];
  if(services){
   let outsideService =  services.filter((ser=>{
      return (ser.created_by_company == pmId && ser.company_id!=pmId)
    }))
    let internalService =[];
 
      internalService =  services.filter((ser=>{
        return ser.created_by_company == pmId && ser.company_id==pmId
      }))
   
    serviceList = [{
      label: "",
      options: internalService,
    },
    {
      label: (outsideService.length > 0) ? "Oustside Service" :'',
      options: outsideService,
    }];
   
  }
  return serviceList
}

export const loadNextPage = async (userType,pageNo,pageNo1,fetchActiveProperty,propertyDropdownFilter,globalRegionAreaFilter,updatePropertyOptions,setNextPageLoading,allDataFetched,endpoint) => {
  let updatedPropertyFilter = {...propertyDropdownFilter};
  if(pageNo==0 && globalRegionAreaFilter && Object.keys(globalRegionAreaFilter).length > 0) {
      updatedPropertyFilter = {
          ...updatedPropertyFilter,
          'region_id': globalRegionAreaFilter?.region_id ?? null,
          'area_id': globalRegionAreaFilter?.area_id ?? null,
      }
  }
  
  let page = fetchActiveProperty ? pageNo : pageNo1;
  let pagSize = pageNo==0 ? initialPageSize: perPage;
  if(allDataFetched) return;
  await lazyLoadPropertyDropdown(userType,page+1,pagSize,updatedPropertyFilter,updatePropertyOptions,setNextPageLoading,endpoint);
};

export const lazyLoadPropertyDropdown = async (userType, page, pageSize, filter ={}, callback,setLoader,endpoint) => {
  
  let url = microServices.base + '/v1/dropdown/'+endpoint+'/'+userType+'?&sort=property_name,unit_no';

  try{
    setLoader(true);
    if (!isObjEmpty(filter)) {
      url = prepareUrlWithFilter(url,filter);
    }
    url += '&per_page='+pageSize+'&page='+page;
   
    
    const { activeProperties, inactiveProperties } = await apiService.get(url)
      .then((response) => {
        if (endpoint == "workorder-property") {
          let list = prepareWorkorderPropertyDropdown(response?.data, userType);
          let activeProp = list.find(item => item.label === 'Active Properties')?.options || [];
          let inactiveProp = list.find(item => item.label === 'Inactive Properties')?.options || [];
          return {activeProperties:activeProp, inactiveProperties:inactiveProp};
        }
        return preparePropertyDropdownAwait(response?.data?.data, userType);
      });
  
  
    let PropertiesList =  {
      activeProperties: activeProperties,
      inactiveProperties: inactiveProperties
  };
  
    callback(PropertiesList);
  }catch(e){
    console.log(e);
  }finally{
    setLoader(false);
  }

};

export const loadNameOptions = (inputValue,userType, endpoint, formType='search',globalRegionAreaFilter) => 
{
    let url = '';
    let filterName = '';
    let textLength = '';

    textLength = 2;
    filterName = 'filter[property_with_unit]';
    let inputVal = decodeURIComponent(inputValue).trim();
    inputVal = inputVal.replace(/\s*-\s*/g, '-');
    
    let activeProperties = [];
    let inactiveProperties = [];

    url = microServices.base + '/v1/dropdown/'+endpoint+'/'+userType+'?'+filterName+'='+inputVal;

    if(formType=="create"){
      url += '&filter[status_id]=1';
    }
    if(globalRegionAreaFilter && Object.keys(globalRegionAreaFilter).length > 0) {
      url = prepareUrlWithFilter(url,globalRegionAreaFilter);
    }
    url += '&per_page=0'; 

    if(inputValue.length >= textLength) {
        return apiService.get(url)
            .then((response) => {  
              if (endpoint == "workorder-property") {
                let list = prepareWorkorderPropertyDropdown(response, userType);
                let activeProp = list.find(item => item.label === 'Active Properties')?.options || [];
                let inactiveProp = list.find(item => item.label === 'Inactive Properties')?.options || [];
                activeProperties = activeProp;
                inactiveProperties = inactiveProp;
              } else {
                ({ activeProperties, inactiveProperties } = preparePropertyDropdownAwait(response?.data, userType));
              }
              
              let PropertiesList = [{
                label: "Active Properties",
                options: activeProperties,
              },
              {
                label: "Inactive Properties",
                options: inactiveProperties,
              }];
              
              return PropertiesList;
            }) 
    }
}

export const preparePropertyDropdownAwait = (response, userType) => {
  let ActivePropertiesList = [];
  let InactivePropertiesList = [];

  response.data.map((properties) => {

      let unit_no ='';
    
      if(properties?.unit_no){
        unit_no = (properties?.unit_no !='0') ? '- '+properties?.unit_no :'';
      }
      
      const property_name = properties.property_name;
      const properties_id = properties.properties_id;
      const region_id = properties?.region_id;
      const area_id = properties?.area_id;
      if(userType === 'cleaner') {
        if (properties.status_id == 1 && properties?.property_status_id == 1) {
          ActivePropertiesList.push({
            value: properties_id,
            label: `${property_name}${unit_no}`,
            group: "active",
            region_id: region_id,
            area_id: area_id
          });
        } else if (properties?.status_id == 2 || properties?.property_status_id == 2) {
          InactivePropertiesList.push({
            value: properties_id,
            label: `${property_name}${unit_no}`,
            group: "inactive",
            region_id: region_id,
            area_id: area_id
          });
        }

      } else if (userType === 'manager') {
        if (properties.status_id == 1) {
          ActivePropertiesList.push({
            value: properties_id,
            label: `${property_name}${unit_no}`,
            group: "active",
            region_id: region_id,
            area_id: area_id
          });
        } else if (properties.status_id == 2) {
          InactivePropertiesList.push({
            value: properties_id,
            label: `${property_name}${unit_no}`,
            group: "inactive",
            region_id: region_id,
            area_id: area_id
          });
        }
      }
  });

  return {
      activeProperties: ActivePropertiesList,
      inactiveProperties: InactivePropertiesList
  };

}

export const preparePropertyInventoryByContainerWise = (propertyInventryData) => {
  const groupedData = propertyInventryData.reduce((acc, item) => {
    const { inventory_container_id, container_name, item_name, quantity, display_order, item_image, property_inventory_template_item_id} = item;
      if (!acc[inventory_container_id]) {
          acc[inventory_container_id] = {
              container_name,
              items: []
          };
      }
    acc[inventory_container_id].items.push({item_name:item_name, quantity:quantity,item_image:item_image,display_order:display_order,property_inventory_template_item_id:property_inventory_template_item_id});
    return acc;
  }, {});
  return groupedData;
}
