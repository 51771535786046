const initState = {
  UnitStatusViewList : [],
  DayWiseCountUnitStatusView : [],
  UnitStatusViewMeta : []
};

const UnitStatusView = (state = initState, action) => {
  switch (action.type) {

    case "SET_UNIT_STATUS_VIEW_LIST": {
      return {
        ...state,
        UnitStatusViewList: action.payload?.data?.data,
        UnitStatusViewMeta : action.payload?.data?.meta
      }
    }

    case 'CLEAR_UNIT_STATUS_VIEW_LIST': {
      return {
        ...state,
        UnitStatusViewList : [],
        UnitStatusViewMeta : []
      };
    }

    case "SET_DAY_WISE_COUNT_FOR_UNIT_STATUS_VIEW": {
      return {
        ...state,
        DayWiseCountUnitStatusView: action.payload?.data,
      }
    }

    case 'CLEAR_DAY_WISE_COUNT_FOR_UNIT_STATUS_VIEW': {
      return {
        ...state,
        DayWiseCountUnitStatusView : []
      };
    }

    default:
      return state;
      
  }
};

export default UnitStatusView;
