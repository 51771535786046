import APIservice from "../apiService";
import { isLoader } from "../global/action";
import { microServices } from "../../config/global";
import { setError } from "../form/action";
import { setGridFilter } from "../../utils/gridUtil";

/**
 * ApiMiddleware can be used to call any User related API services
 * @param {*} store 
 * @returns 
 */
const WorkorderMiddleware = (store) => (next) => async (action) => {
    const result = next(action);
    let endpoint = microServices.base + '/v1/workorder/item';

    switch (action.type) {
        case "CREATE_WORKORDER_ITEM":
            store.dispatch(isLoader(true));
            APIservice
            .post(endpoint, action.payload.formData)
            .then((response)=>{
                store.dispatch({ 
                    type: 'SHOW_ALERT', 
                    payload: {
                        componentName: action.componentName,
                        variant:'success',
                        message: action.message
                    }
                });
            })
            .catch((error)=>{
                if (error.response.status !== 422) {
                    store.dispatch({ 
                        type: 'SHOW_ALERT', 
                        payload: {
                        variant:'danger',
                        message: 'Unable to save workorder item information!'
                        }
                    });
                } else {
                    let err = {componentName:action.componentName,statusCode: error.response.status,data: error.response.data};
                    store.dispatch(setError(err));
                }
            })
            .finally(()=>{
                store.dispatch(isLoader(false));
            })
        break;

        case "FETCH_WORKORDER_ITEM":
            store.dispatch(isLoader(true));
            APIservice
            .get(endpoint+'/'+action.payload.id)
            .then((response)=>{
                if(response && response.data){
                    store.dispatch({ 
                        type: 'SET_DATA_FOR_EDIT', 
                        payload: {
                            data:response.data.data
                        }
                    });
                }
            })
            .catch((error)=>{
                let err = {componentName:'EditWorkorder',statusCode: error.response.status,data: error.response.data};
                store.dispatch(setError(err));
            })
            .finally(()=>{
                store.dispatch(isLoader(false));
            })
        break;

        case "UPDATE_WORKORDER_ITEM":
            store.dispatch(isLoader(true));
            APIservice
            .put(endpoint+'/'+action.payload.id, action.payload.formData)
            .then((response)=>{
                store.dispatch({ 
                    type: 'SHOW_ALERT', 
                    payload: {
                        variant:'success',
                        message: 'Maintenance Inventory Updated Successfully!',
                        componentName:'UpdateWorkorderItem'
                    }
                });
            })
            .catch((error)=>{
                if (error.response.status !== 422) {
                    store.dispatch({ 
                        type: 'SHOW_ALERT', 
                        payload: {
                            variant:'danger',
                            message: 'Unable to update workorder item information!',
                        }
                    });
                } else {
                    let err = {componentName:'UpdateWorkorderItem',statusCode: error.response.status,data: error.response.data};
                    store.dispatch(setError(err));
                }
            })
            .finally(()=>{
                store.dispatch(isLoader(false));
            })
        break;

        case "ACTIVE_INACTIVE_WORKORDER_ITEM":
            store.dispatch(isLoader(true));
            APIservice
            .put(`${endpoint}/${action.payload.apiName}/${action.payload.id}`)
            .then((response)=>{
                store.dispatch({ 
                    type: 'SHOW_ALERT', 
                    payload: {
                        variant:'success',
                        message: `Maintenance inventory ${action.payload.message}d successfully!`,
                        componentName:'ActiveInactiveWorkorder'
                    }
                });
            })
            .catch((error)=>{
                if (error.response.status !== 422) {
                    store.dispatch({ 
                        type: 'SHOW_ALERT', 
                        payload: {
                            variant:'danger',
                            message: `Unable to ${action.payload.message}d Workorder item!`
                        }
                    });
                } else {
                    let err = {componentName:'ActiveInactiveWorkorder',statusCode: error.response.status,data: error.response.data};
                    store.dispatch(setError(err));
                }
            })
            .finally(()=>{
                store.dispatch(isLoader(false));
            })
        break;

        case "FETCH_WORKORDER_PHOTOS":
            endpoint = microServices.base+'/v1/workorder';
            store.dispatch(isLoader(true));
            APIservice
            .get(endpoint+'/'+action.payload.id+'/photo?per_page=0')
            .then((response)=>{
                if(response && response.data){
                    store.dispatch({ 
                    type: 'SET_WORKORDER_PHOTOS', 
                    payload: {
                        data:response.data.data,
                        no_of_photos:(response.data.data).length
                    }
                    });
                }
            })
            .catch((error)=>{
                let err = {componentName:'WorkorderPhotos',statusCode: error.response.status,data: error.response.data};
                store.dispatch(setError(err));
            })
            .finally(()=>{
                store.dispatch(isLoader(false));
            })
        break;

        case "SHARE_WORKORDER_PHOTO" :
            endpoint = microServices.base+'/v1/workorder/photo/share/'+action.payload.id;
            store.dispatch(isLoader(true));
            APIservice
            .put(endpoint)
            .then((response)=>{
                store.dispatch({ 
                    type: 'SHOW_ALERT', 
                    payload: {
                        variant:'success',
                        message: `Photo ${action.payload.msg} successfully.`,
                        componentName:'SharedWorkorderPhoto'
                    }
                });
            })
            .catch((error)=>{
                if (error.response.status !== 422) {
                    store.dispatch({ 
                        type: 'SHOW_ALERT', 
                        payload: {
                            variant:'danger',
                            message: 'Unable to share Workorder Photo!'
                        }
                    });
                } else {
                    let err = {componentName:'SharedWorkorderPhoto',statusCode: error.response.status,data: error.response.data};
                    store.dispatch(setError(err));
                }
            })
            .finally(()=>{
                store.dispatch(isLoader(false));
            })
        break;

        case "DELETE_WORKORDER_PHOTO" : 
            endpoint = microServices.base+'/v1/workorder/photo/'+action.payload.id;
            store.dispatch(isLoader(true));
            APIservice
            .delete(endpoint)
            .then((response)=>{
                store.dispatch({ 
                type: 'SHOW_ALERT', 
                payload: {
                    variant:'success',
                    message: 'Photo deleted successfully.',
                    componentName:'DeleteWorkorderPhoto',
                    workorderId: action.workorderId
                }
                });
            })
            .catch((error)=>{
                if (error.response.status !== 422) {
                store.dispatch({ 
                    type: 'SHOW_ALERT', 
                    payload: {
                    variant:'danger',
                    message: 'Unable to delete Workorder Photo!'
                    }
                });
                } else {
                let err = {componentName:'DeleteWorkorderPhoto',statusCode: error.response.status,data: error.response.data};
                store.dispatch(setError(err));
                }
            })
            .finally(()=>{
                store.dispatch(isLoader(false));
            })
        break;
        
        case "FETCH_WORKORDER_ITEMS":
            store.dispatch(isLoader(true));
            let itemsEndpoint = `${microServices.base}/v1/workorder/items`;
            if (action.payload.hasOwnProperty('params')) {
                itemsEndpoint = setGridFilter(itemsEndpoint, action.payload.params);
                itemsEndpoint += '&per_page=0';
            } else { 
                itemsEndpoint += '?per_page=0';
            }
            if(action?.payload?.isDropdown){
                itemsEndpoint += '&filter[isDropdown]=true';
            }
            APIservice
            .get(itemsEndpoint)
            .then((response)=>{
                if(response && response.data){
                    if(action?.payload?.isDropdown){
                        store.dispatch({ 
                            type: 'SET_UNIQUE_WORKORDER_ITEMS', 
                            payload: {
                                data:response.data.data
                            }
                        });
                    }else{
                        store.dispatch({ 
                            type: 'SET_WORKORDER_ITEMS', 
                            payload: {
                                data:response.data.data
                            }
                        });
                    }
                }
            })
            .catch((error)=>{
                let err = {componentName:'Workorder', statusCode: error.response.status, data: error.response.data};
                store.dispatch(setError(err));
            })
            .finally(()=>{
                store.dispatch(isLoader(false));
            })
        break;

        case "ROTATE_WORKORDER_PHOTO":
            endpoint = microServices.base+'/v1/workorder/photo/rotate/'+action.payload.direction+'/'+action.payload.id;
            store.dispatch(isLoader(true));
            APIservice
            .patch(endpoint)
            .then((response)=>{
                if(response && response.data){
                    store.dispatch({ 
                        type: 'SHOW_ALERT', 
                        payload: {
                            variant:'success',
                            message: 'Photo rotated successfully.',
                            componentName:'RotateWorkorderPhoto'
                        }
                    });
                }
            })
            .catch((error)=>{
                if (error.response.status !== 422) {
                    store.dispatch({ 
                        type: 'SHOW_ALERT', 
                        payload: {
                            variant:'danger',
                            message: 'Unable to rotate Workorder Photo!'
                        }
                    });
                } else {
                    let err = {componentName:'RotateWorkorderPhoto',statusCode: error.response.status,data: error.response.data};
                    store.dispatch(setError(err));
                }
            })
            .finally(()=>{
                    store.dispatch(isLoader(false));
            })          
        break;

        
        case "FETCH_WORKORDER_NOTES": {
            store.dispatch(isLoader(true));
            let endpoint = `${microServices.base}/v1/workorder/${action.payload.workorder_id}/notes`;
            APIservice.get(endpoint, action.payload.params)
                .then((response) => {
                    if(response && response.data) {
                        store.dispatch({
                            type: "SET_WORKORDER_NOTES",
                            payload: {
                                data: response.data.data
                            }    
                        });
                    }
                })
                .catch((error) => {
                    let err = {componentName:'WorkOrderViewAddNotes', statusCode: error.response.status, data: error.response.data};
                    store.dispatch(setError(err));    
                })
                .finally(() => {
                    store.dispatch(isLoader(false));
                })
            break;
        }

        case "CREATE_WORKORDER_NOTE": {
            store.dispatch(isLoader(true));
            let endpoint = `${microServices.base}/v1/workorder/note`;
            APIservice.post(endpoint, action.payload.data)
                .then((response) => {
                    if(response && response.data) {
                        store.dispatch({
                            type: 'SHOW_ALERT', 
                            payload: {
                                variant: "success",
                                message: action.message,
                                componentName: action.componentName,
                                workorderId: action.workorderId                          
                            }    
                        });
                    }
                })
                .catch((error) => {
                    if (error.response.status !== 422) {
                        store.dispatch({
                            type: "SHOW_ALERT",
                            payload: {
                                variant: "danger",
                                message: "Unable to save notes information!",
                          },
                        });
                    } else {
                        let err = {
                            componentName: action.componentName,
                            statusCode: error.response.status,
                            data: error.response.data,
                        };
                        store.dispatch(setError(err));
                    }
                })
                .finally(() => {
                    store.dispatch(isLoader(false));
                })
            break;
        }

        case "DELETE_WORKORDER_NOTE": {
            store.dispatch(isLoader(true));
            let endpoint = `${microServices.base}/v1/workorder/note/${action.payload.workorder_note_id}`;
            APIservice.delete(endpoint)
                .then((response) => {
                    store.dispatch({ 
                        type: 'SHOW_ALERT', 
                        payload: {
                            variant:'success',
                            message: action.message,
                            componentName: action.componentName,
                            workorderId: action.workorderId
                        }
                    });
                })
                .catch((error) => {
                    if (error.response.status !== 422) {
                        store.dispatch({
                            type: 'SHOW_ALERT', 
                            payload: {
                                variant:'danger',
                                message: 'Unable to delete Note!'
                            }
                        });
                    } else {
                        let err = {componentName:action.componentName, statusCode: error.response.status, data: error.response.data};
                        store.dispatch(setError(err));
                    }
                })
                .finally(() => {
                    store.dispatch(isLoader(false));
                })
            break;
        }

        case "SHARE_UNSHARE_WORKORDER_NOTE": {
            store.dispatch(isLoader(true));
            let endpoint = `${microServices.base}/v1/workorder/note/share/${action.payload.workorder_note_id}`;
            APIservice.put(endpoint)
                .then((response) => {
                    store.dispatch({ 
                        type: 'SHOW_ALERT', 
                        payload: {
                            variant:'success',
                            message: response?.data?.message || action.message,
                            componentName: action.componentName,
                        }
                    });
                })
                .catch((error) => {
                    if (error.response.status !== 422) {
                        store.dispatch({
                            type: 'SHOW_ALERT', 
                            payload: {
                                variant:'danger',
                                message: `Unable to Share/Unshare Note!`
                            }
                        });
                    } else {
                        let err = {componentName:action.componentName, statusCode: error.response.status, data: error.response.data};
                        store.dispatch(setError(err));
                    }
                })
                .finally(() => {
                    store.dispatch(isLoader(false));
                })
            break;
        }

        case "UPDATE_WORKORDER_NOTE": {
            store.dispatch(isLoader(true));
            let endpoint = `${microServices.base}/v1/workorder/note/${action.payload.params.workorder_note_id}`;
            APIservice.put(endpoint, action.payload.formData)
                .then((response) => {
                    if(response && response.data) {
                        store.dispatch({
                            type: 'SHOW_ALERT', 
                            payload: {
                                variant: "success",
                                message: action.message,
                                componentName: action.componentName,                            
                            }    
                        });
                    }
                })
                .catch((error) => {
                    if (error.response.status !== 422) {
                        store.dispatch({
                            type: "SHOW_ALERT",
                            payload: {
                                variant: "danger",
                                message: "Unable to update notes information!",
                          },
                        });
                    } else {
                        let err = {
                            componentName: action.componentName,
                            statusCode: error.response.status,
                            data: error.response.data,
                        };
                        store.dispatch(setError(err));
                    }
                })
                .finally(() => {
                    store.dispatch(isLoader(false));
                })
            break;
        }

        case "UPDATE_WORKORDER_ICON_DATA": {
            endpoint = `${microServices.base}/v1/workorder/info/${action.payload.workorder_id}`;
            store.dispatch(isLoader(true));
            APIservice.put(endpoint, action.payload.formData)
              .then((response) => {
                // store.dispatch({ type: "GET_LOGGED_USER_DETAILS" });
                store.dispatch({
                    type: 'SHOW_ALERT', 
                    payload: {
                        variant: "success",
                        message: action.message,
                        componentName: "WorkorderIconList",                            
                    }    
                });
              })
              .catch((error) => {
                let err = {
                  componentName: "WorkorderIcons",
                  statusCode: error.response.status,
                  data: error.response.data,
                };
                store.dispatch(setError(err));
              })
              .finally(() => {
                store.dispatch(isLoader(false));
              });
            break;  
        }

        case "CANCEL_COMPLETE_WORKORDER": {
            endpoint = `${microServices.base}/v1/workorder`
            store.dispatch(isLoader(true));
            APIservice
            .put(`${endpoint}/${action.payload.apiName}/${action.payload.id}`)
            .then((response)=>{
                store.dispatch({ 
                    type: 'SHOW_ALERT', 
                    payload: {
                        variant:'success',
                        message: `Workorder ${action.payload.message} successfully!`,
                        componentName:'CancelCompleteWorkorder'
                    }
                });
            })
            .catch((error)=>{
                if (error.response.status !== 422) {
                    store.dispatch({ 
                        type: 'SHOW_ALERT', 
                        payload: {
                            variant:'danger',
                            message: `Unable to ${action.payload.message} Workorder!`,
                            componentName:'CancelCompleteWorkorder'
                        }
                    });
                } else {
                        let err = {componentName:'CancelCompleteWorkorder',statusCode: error.response.status,data: error.response.data};
                        store.dispatch(setError(err));
                }
            })
            .finally(()=>{
            	store.dispatch(isLoader(false));
            })
            break;
        }

        case "CREATE_WORKORDER_ASSIGN_SUBTASKS":
            endpoint = `${microServices.base}/v1/workorder/subtasks/assign`;
            APIservice.post(endpoint, action.payload.formData)
            .then((response) => {
                store.dispatch({
                    type: "SHOW_ALERT",
                    payload: {
                        variant: "success",
                        message: "Maintenance Workorder created successfully.",
                        componentName: "CreateWorkorderAssignSubtask",
                    },
                });
            })
            .catch((error) => {
                if (error.response.status !== 422) {
                    store.dispatch({
                        type: "SHOW_ALERT",
                        payload: {
                            variant: "danger",
                            message: "Unable to assign subtask!",
                            componentName: "CreateWorkorderAssignSubtask",
                        },
                    });
                } else {
                    let err = {
                        componentName: "CreateWorkorderAssignSubtask",
                        statusCode: error.response.status,
                        data: error.response.data,
                    };
                    store.dispatch(setError(err));
                }
            })
        break;
        case "FETCH_WORKORDER_LIST":
      APIservice.get(action.payload.apptUrl)
        .then((response) => {
          if (response && response.data) {
            store.dispatch({
              type: "SET_WORKORDER_LIST",
              payload: {
                data: response.data.data,
              },
            });
          }
        })
        .catch((error) => {
          let err = {
            componentName: "WorkorderList",
            statusCode: error.response.status,
            data: error.response.data,
          };
          store.dispatch(setError(err));
        });
        case "FETCH_WORKORDER":
        APIservice.get(action.payload.apptUrl)
            .then((response) => {
            if (response && response.data) {
                store.dispatch({
                type: "SET_WORKORDER",
                payload: {
                    data: response.data.data,
                },
                });
            }
            })
            .catch((error) => {
            let err = {
                componentName: "WorkorderList",
                statusCode: error.response.status,
                data: error.response.data,
            };
            store.dispatch(setError(err));
            })
      break;    
      case "UPDATE_WORKORDER_ATTRIBUTES":
      endpoint = microServices.base + "/v1/user/additional-workorder-attributes";
      store.dispatch(isLoader(true));
      APIservice.post(endpoint, action.payload.formData)
        .then((response) => {
          store.dispatch({ type: "GET_LOGGED_USER_DETAILS" });
          store.dispatch({
            type: "SHOW_ALERT",
            payload: {
              variant: "success",
              message: action.payload.successMessage,
              componentName: "UpdateWorkorderColumn",
            },
          });
        })
        .catch((error) => {
          let err = {
            componentName: "UpdateWorkorderColumn",
            statusCode: error.response.status,
            data: error.response.data,
          };
          store.dispatch(setError(err));
        })
        .finally(() => {
          store.dispatch(isLoader(false));
        });
      break;
      case "UPDATE_WORKORDER" :
        endpoint = `${microServices.base}/v1/workorder/`;
        store.dispatch(isLoader(true));
        APIservice
            .put(endpoint + action.payload.workorder_id, action.payload.FormData)
            .then((response) => {
                store.dispatch({
                    type: "SHOW_ALERT",
                    payload: {
                      variant: "success",
                      message: action.payload.successMessage,
                      componentName: "UpdateWorkorder",
                    },
                  });
            }).catch((error) => {
                let err = {
                    componentName: "UpdateWorkorder",
                    statusCode: error.response.status,
                    data: error.response.data,
                };
                store.dispatch(setError(err));
            }).finally(() => {
                store.dispatch(isLoader(false));
            });

        break;
        case "FETCH_UPDATE_TIME_PER_HOUR":
            endpoint = microServices.base + "/v1/workorder/CostPerHour/"+action.payload.workorder_id;
            APIservice.get(endpoint)
                .then((response) => {
                if (response && response.data) {
                    store.dispatch({
                    type: "SET_COST_PER_HOUR",
                    payload: {
                        data: response.data,
                    },
                    });
                }
                })
                .catch((error) => {
                let err = {
                    componentName: "workorderCostPerHour",
                    statusCode: error.response.status,
                    data: error.response.data,
                };
                store.dispatch(setError(err));
                })
          break;   
          case "GET_WORKORDER_TOTAL": {
            endpoint = action.payload.endpoint + "&per_page=0";
            store.dispatch(isLoader(true));
            APIservice.get(endpoint)
              .then((response) => {
                if (response && response.data) {
                  store.dispatch({
                    type: "SET_WORKORDER_TOTAL",
                    payload: {
                      data: response.data,
                    },
                  });
                }
              })
              .catch((error) => {
                let err = {
                  componentName: "GetWorkorderTotal",
                  statusCode: error.response.status,
                  data: error.response.data,
                };
                store.dispatch(setError(err));
              })
              .finally(() => {
                store.dispatch(isLoader(false));
              });
              break;
            }

            case "FETCH_WORKORDER_LOGS": {
                endpoint = microServices.base+'/v1/workorder';
                store.dispatch(isLoader(true));
                APIservice.get(endpoint+'/'+action.payload.id+'/logs')
                  .then((response) => {
                    if (response && response?.data) {
                      store.dispatch({
                        type: "SET_WORKORDER_LOGS",
                        payload: {
                          data: response?.data,
                        },
                      });
                    }
                  })
                  .catch((error) => {
                    let err = {
                      componentName: "WorkorderLogs",
                      statusCode: error?.response?.status,
                      data: error?.response?.data,
                    };
                    store.dispatch(setError(err));
                  })
                  .finally(() => {
                    store.dispatch(isLoader(false));
                  })
                break;
              }

            case "QUICK_ASSIGN_WO_STAFF":
                endpoint = `${microServices.base}/v1/workorder/quickAssignWoStaff`;
                store.dispatch(isLoader(true));
                APIservice.post(endpoint, action.payload.formData)
                .then((response) => {
                  store.dispatch({
                    type: "SHOW_ALERT",
                    payload: {
                      variant: "success",
                      message: action.payload.successMessage,
                      componentName: "QuickAssignWoStaff",
                      data:response.data.data
                    },
                  });
                })
                .catch((error) => {
                  if (error.response.status !== 422) {
                    store.dispatch({
                      type: "SHOW_ALERT",
                      payload: {
                        variant: "danger",
                        message: "Unable to assign staff!",
                        componentName: "QuickAssignWoStaff",
                      },
                    });
                  } else {
                    let err = {
                      componentName: "QuickAssignWoStaff",
                      statusCode: error.response.status,
                      data: error.response.data,
                    };
                    store.dispatch(setError(err));
                  }
                })
                .finally(() => {
                  store.dispatch(isLoader(false));
                });
              break;

              case "BULK_CONFIRM_WORKORDER":
                endpoint = `${microServices.base}/v1/workorder/bulk/${action.payload.apiName}`;
                store.dispatch(isLoader(true));
                APIservice.put(endpoint, action.payload.formData)
                .then((response) => {
                  store.dispatch({
                    type: "SHOW_ALERT",
                    payload: {
                      variant: "success",
                      message: action.payload.successMessage,
                      componentName: "bulkConfirmWorkorder",
                      data:response.data.data
                    },
                  });
                })
                .catch((error) => {
                  if (error.response.status !== 422) {
                    store.dispatch({
                      type: "SHOW_ALERT",
                      payload: {
                        variant: "danger",
                        message: action.payload.failMessage,
                        componentName: "bulkConfirmWorkorder",
                      },
                    });
                  } else {
                    let err = {
                      componentName: "bulkConfirmWorkorder",
                      statusCode: error.response.status,
                      data: error.response.data,
                    };
                    store.dispatch(setError(err));
                  }
                })
                .finally(() => {
                  store.dispatch(isLoader(false));
                });
              break;
              case "FETCH_INTEGRATION_TRACK_WORKORDER":
                endpoint = `${microServices.base}/v1/workorder/integration/track`;
                store.dispatch(isLoader(true));
                APIservice.get(endpoint)
                .then((response) => {
                    if (response && response.data) {
                        store.dispatch({
                        type: "SET_INTEGRATION_TRACK_WORKORDER",
                        payload: {
                            data: response.data,
                        },
                        });
                    }
                })
                .catch((error) => {
                    let err = {
                        componentName: "integrationTrackWorkorder",
                        statusCode: error.response.status,
                        data: error.response.data,
                    };
                    store.dispatch(setError(err));
                })
                .finally(() => {
                  store.dispatch(isLoader(false));
                });
              break;
       
              
            case "GET_WO_ANALYTICS_COUNTS":
                endpoint = `${microServices.base}/v1/workorder/analytics`;
                store.dispatch(isLoader(true));
                APIservice.post(endpoint, action.payload.FormData)
                .then((response) => {
                    if (response && response.data) {
                        store.dispatch({
                        type: "SET_WO_ANALYTICS_COUNTS",
                        payload: {
                            data: response.data,
                        },
                        });
                    }
                })
                .catch((error) => {
                  if (error.response.status !== 422) {
                    store.dispatch({
                      type: "SHOW_ALERT",
                      payload: {
                        variant: "danger",
                        message: "Unable to get!",
                        componentName: "woAnalyticsCount",
                      },
                    });
                  } else {
                    let err = {
                      componentName: "woAnalyticsCountError",
                      statusCode: error.response.status,
                      data: error.response.data,
                    };
                    store.dispatch(setError(err));
                  }
                })
                .finally(() => {
                  store.dispatch(isLoader(false));
                });
              break;


              
            case "GET_WO_ANALYTICS_CHARTS":
                endpoint = `${microServices.base}/v1/workorder/analytics/graph/`+action.payload.type;
                store.dispatch(isLoader(true));
                APIservice.post(endpoint, action.payload.FormData)
                .then((response) => {
                    if (response && response.data) {
                        if(action.payload.type=='counts'){
                            store.dispatch({
                                type: "SET_WO_ANALYTICS_COUNT_CHARTS",
                                payload: {
                                    data: response.data,
                                },
                                });
                        }
                        else if(action.payload.type=='revenue'){
                            store.dispatch({
                                type: "SET_WO_ANALYTICS_REVENUE_CHARTS",
                                payload: {
                                    data: response.data,
                                },
                                });
                        }
                        else if(action.payload.type=='times'){
                            store.dispatch({
                                type: "SET_WO_ANALYTICS_TIME_CHARTS",
                                payload: {
                                    data: response.data,
                                },
                                });
                        }
                       
                    }
                })
                .catch((error) => {
                  if (error.response.status !== 422) {
                    store.dispatch({
                      type: "SHOW_ALERT",
                      payload: {
                        variant: "danger",
                        message: "Unable to get!",
                        componentName: "woAnalyticsChart",
                      },
                    });
                  } else {
                    let err = {
                      componentName: "woAnalyticsChartError",
                      statusCode: error.response.status,
                      data: error.response.data,
                    };
                    store.dispatch(setError(err));
                  }
                })
                .finally(() => {
                  store.dispatch(isLoader(false));
                });
              break;
            
              case "GET_WORKORDER_ASSIGNMENT_TRACKING_HK_DATA": {
                endpoint = `${microServices.base}/v1/company/staff/wo-assignment-tracking/hk?${action.payload.filters}`;
                store.dispatch(isLoader(true));
                APIservice.get(endpoint)
                  .then((response) => {
                    if (response && response.data) {
                      store.dispatch({
                        type: "SET_WORKORDER_ASSIGNMENT_TRACKING_HK_DATA",
                        payload: {
                          data: response.data,
                        },
                      });
                      store.dispatch({
                        type: "SET_WORKORDER_ASSIGNMENT_TRACKING_HK_TARGET",
                        payload: {
                          data: response.data,
                        },
                      });
                    }
                  })
                  .catch((error) => {
                    let err = {
                      componentName: "WorkorderAssignmentTracking",
                      statusCode: error.response.status,
                      data: error.response.data,
                    };
                    store.dispatch(setError(err));
                  })
                  .finally(() => {
                    store.dispatch(isLoader(false));
                  });
                break;
              }

              case "WO_QUICK_ASSIGN_TRACKING_COUNT": {
                endpoint = `${microServices.base}/v1/company/staff/wo-assignment-tracking/hk/quick-assign-count`;
                store.dispatch(isLoader(true));
                APIservice.post(endpoint, action.payload.formData)
                    .then((response) => {
                        if (response && response.data) {
                            store.dispatch({
                                type: "SET_WO_QUICK_ASSIGN_TRACKING_COUNT",
                                payload: {
                                    data: response.data,
                                },
                            });
                        }
                    })
                    .catch((error) => {
                        let err = {
                            componentName: "WoQuickAssignTrackingCount",
                            statusCode: error.response.status,
                            data: error.response.data,
                        };
                        store.dispatch(setError(err));
                    })
                    .finally(() => {
                        store.dispatch(isLoader(false));
                    });
                break;
              }
              case "ACTIVE_INACTIVE_WORKORDER_DEPARTMENT":
                endpoint = microServices.base + '/v1/workorder/department';
                store.dispatch(isLoader(true));
                APIservice
                .put(`${endpoint}/${action.payload.apiName}/${action.payload.id}`)
                .then((response)=>{
                    store.dispatch({ 
                        type: 'SHOW_ALERT', 
                        payload: {
                            variant:'success',
                            message: `Department ${action.payload.message}d successfully!`,
                            componentName:'ActiveInactiveWorkorder'
                        }
                    });
                })
                .catch((error)=>{
                    if (error.response.status !== 422) {
                        store.dispatch({ 
                            type: 'SHOW_ALERT', 
                            payload: {
                                variant:'danger',
                                message: `Unable to ${action.payload.message}d Workorder department!`
                            }
                        });
                    } else {
                        let err = {componentName:'ActiveInactiveWorkorder',statusCode: error.response.status,data: error.response.data};
                        store.dispatch(setError(err));
                    }
                })
                .finally(()=>{
                    store.dispatch(isLoader(false));
                })
            break;
            case "FETCH_WORKORDER_DEPARTMENT":
            endpoint = microServices.base + '/v1/workorder/department';
            store.dispatch(isLoader(true));
            APIservice
            .get(endpoint+'/'+action.payload.id)
            .then((response)=>{
                if(response && response.data){
                    store.dispatch({ 
                        type: 'SET_DATA_FOR_EDIT', 
                        payload: {
                            data:response.data.data
                        }
                    });
                }
            })
            .catch((error)=>{
                let err = {componentName:'EditWorkorderDepartment',statusCode: error.response.status,data: error.response.data};
                store.dispatch(setError(err));
            })
            .finally(()=>{
                store.dispatch(isLoader(false));
            })
        break;
        case "UPDATE_WORKORDER_DEPARTMENT":
            endpoint = microServices.base + '/v1/workorder/department';
            store.dispatch(isLoader(true));
            APIservice
            .put(endpoint+'/'+action.payload.id, action.payload.formData)
            .then((response)=>{
                store.dispatch({ 
                    type: 'SHOW_ALERT', 
                    payload: {
                        variant:'success',
                        message: 'Department Updated Successfully!',
                        componentName:'UpdateWorkorderDepartment'
                    }
                });
            })
            .catch((error)=>{
                if (error.response.status !== 422) {
                    store.dispatch({ 
                        type: 'SHOW_ALERT', 
                        payload: {
                            variant:'danger',
                            message: 'Unable to update workorder department information!',
                        }
                    });
                } else {
                    let err = {componentName:'UpdateWorkorderDepartment',statusCode: error.response.status,data: error.response.data};
                    store.dispatch(setError(err));
                }
            })
            .finally(()=>{
                store.dispatch(isLoader(false));
            })
        break;
        case "CREATE_WORKORDER_DEPARTMENT":
            endpoint = microServices.base + '/v1/workorder/department';
            console.log(endpoint)
            store.dispatch(isLoader(true));
            APIservice
            .post(endpoint, action.payload.formData)
            .then((response)=>{
                store.dispatch({ 
                    type: 'SHOW_ALERT', 
                    payload: {
                        componentName: action.componentName,
                        variant:'success',
                        message: action.message
                    }
                });
            })
            .catch((error)=>{
                if (error.response.status !== 422) {
                    store.dispatch({ 
                        type: 'SHOW_ALERT', 
                        payload: {
                        variant:'danger',
                        message: 'Unable to save workorder department information!'
                        }
                    });
                } else {
                    let err = {componentName:action.componentName,statusCode: error.response.status,data: error.response.data};
                    store.dispatch(setError(err));
                }
            })
            .finally(()=>{
                store.dispatch(isLoader(false));
            })
        break;
        case "WORKORDER_DEPARTMENT_LIST":
            APIservice.get(action.payload.api)
                .then((response) => {
                if (response && response.data) {
                    let departmentList = prepareDepartmentList(response.data)
                    store.dispatch({
                    type: "SET_WORKORDER_DEPARTMENT",
                    payload: {
                        data: departmentList,
                    },
                    });
                }
                })
                .catch((error) => {
                let err = {
                    componentName: "WorkorderDepartmentList",
                    statusCode: error.response.status,
                    data: error.response.data,
                };
                store.dispatch(setError(err));
                })
          break;    
              
              case "PUSH_INDIVIDUAL_ITEM_TO_OWNERREZ": {
                endpoint = `${microServices.base}/v1/workorder/workorder-item/ownerrez/${action?.payload?.workorder_items_relation_id}`;
                store.dispatch(isLoader(true));
                APIservice.put(endpoint, action.payload.formData)
                .then((response) => {
                    store.dispatch({
                      type: "SHOW_ALERT",
                      payload: {
                        variant: "success",
                        message: action.payload.successMessage,
                        componentName: "pushIndividualItemToOwnerRez",
                        data:response.data.data
                      },
                    });
                  })
                  .catch((error) => {
                    if (error.response.status !== 422) {
                      store.dispatch({
                        type: "SHOW_ALERT",
                        payload: {
                          variant: "danger",
                          message: action.payload.failMessage,
                          componentName: "pushIndividualItemToOwnerRez",
                        },
                      });
                    } else {
                      let err = {
                        componentName: "pushIndividualItemToOwnerRez",
                        statusCode: error.response.status,
                        data: error.response.data,
                      };
                      store.dispatch(setError(err));
                    }
                  })
                  .finally(() => {
                    store.dispatch(isLoader(false));
                  });
                break;
              }

              case "PUSH_WORKORDER_TO_INTEGRATION": {
                endpoint = `${microServices.base}/v1/workorder/push/${action?.payload?.type}/${action?.payload?.workorderId}`;
                store.dispatch(isLoader(true));
                APIservice.put(endpoint, action.payload.formData)
                .then((response) => {
                    store.dispatch({
                      type: "SHOW_ALERT",
                      payload: {
                        variant: "success",
                        message: action.payload.successMessage,
                        componentName: "pushWoToIntegration",
                        data:response.data.data
                      },
                    });
                  })
                  .catch((error) => {
                    if (error.response.status !== 422) {
                      store.dispatch({
                        type: "SHOW_ALERT",
                        payload: {
                          variant: "danger",
                          message: action.payload.failMessage,
                          componentName: "pushWoToIntegration",
                        },
                      });
                    } else {
                      let err = {
                        componentName: "pushWoToIntegration",
                        statusCode: error.response.status,
                        data: error.response.data,
                      };
                      store.dispatch(setError(err));
                    }
                  })
                  .finally(() => {
                    store.dispatch(isLoader(false));
                  });
                break;
              }

        default:{
            break;
        }
    }
    return result;
};

const prepareDepartmentList = (response) => {
    let departmentList = [];
    response.data.map((item) => {
        departmentList.push({ value: item.workorder_department_id, label: item.title });
    });
    return departmentList;
}
export default WorkorderMiddleware;
