import React, { useEffect } from 'react';
import { useState } from 'react';
import { useContext } from 'react';
import { Col, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { TilledAchType } from '../../../utils/paymentConstUtil';
import { BANKTRANSFER, buildForm, getTilled } from '../../../utils/tilledUtil';
import CustomSelect from '../../atoms/CustomSelect';
import InputText from '../../atoms/InputText';
import { LanguageContext } from '../../containers/Language';


export default function AchAccountFileds(props) {
  const { register, formState: { errors } } = useForm();
  const { localised } = useContext(LanguageContext);

  const [accountTypeOptions, setAccountTypeOptions] = useState(TilledAchType);

  useEffect(() => {
    setTimeout(() => {
      (async () => {
        BANKTRANSFER.tilled = getTilled(props.accountId);
        buildForm(BANKTRANSFER)

      })();

    }, 10);
    return () => {
      if (BANKTRANSFER.form) {
        BANKTRANSFER.form.teardown((success) => {
          BANKTRANSFER.form = undefined;
        });
      };
    }

  }, []);

  return (
    <>
      {props.tilledError ? <p className="text-danger">
        {props.tilledError}  </p> : ''}
      <Col md="6" lg="6">
        <Form.Group className="required form-group">
          <Form.Label>{localised.accountHolderName}</Form.Label>
          <InputText
            id={'accountHolderName'}
            name={'account_holder_name'}
            type={'text'}
            placeholder={localised.accountHolderName}
            value={props.tilledPayloadObj?.ach_debit?.account_holder_name}
            onChange={(e) => props.handleAchCardChange(e)}
            isInvalid={(props.formError?.account_holder_name_error) ? !!props.formError.account_holder_name_error : false}
            required={'required'}

          />
          <Form.Control.Feedback type="invalid">
            {props.formError && props.formError.account_holder_name_error && props.formError.account_holder_name_error.length > 0 && (
              props.formError.account_holder_name_error
            )}</Form.Control.Feedback>
        </Form.Group>
      </Col>
      <Col md="6" lg="6">

        <Form.Group className="required form-group">
          <Form.Label>{localised.accountType}</Form.Label>
          <CustomSelect
            // menuPortalTarget={document.querySelector('body')}
            id={'accountType'}
            name={'account_type'}
            closeMenuOnSelect={true}
            value={props.accountType}
            isMulti={false}
            onChange={(e) => props.handleAccountTypeChange(e)}
            isInvalid={(props.formError?.account_type_error) ? !!props.formError.account_type_error : false}
            options={accountTypeOptions}

          />
          <Form.Control.Feedback type="invalid" style={{ display: (props.formError && props.formError.account_type_error && props.formError.account_type_error.length > 0) ? "block" : "none" }}>
            {props.formError && props.formError.account_type_error && props.formError.account_type_error.length > 0 && (
              props.formError.account_type_error
            )}</Form.Control.Feedback>
        </Form.Group>

        {errors.accountType?.type === 'required' && "Account Type is required"}
      </Col>
      <Col md="6" lg="6">

        <Form.Label>{localised.accountNumber}</Form.Label>

        <div className='tilled-js_input max-width-max border rounded-md' id='bank-account-number-element' type="text" placeholder="" {...register("accountNumber", { required: true, maxLength: 16 })} />
        {errors.accountNumber?.type === 'required' && "Account Number is required"}
      </Col>
      <Col md="6" lg="6">
        <Form.Label>{localised.routingNumber}</Form.Label>

        <div className='tilled-js_input max-width-max border rounded-md' id='bank-routing-number-element' type="number" placeholder="" {...register("routingNumber", { required: true, maxLength: 10 })} />
        <Form.Control.Feedback type="invalid">
          {errors.routingNumber?.type === 'required' && "Routing Number is required"}
        </Form.Control.Feedback>


      </Col>
      {props.isAdminIvoice && props.saveCardShow &&
        <Col md="6">
          <Form.Group className="form-group d-flex align-items-center pt-md-4 mb-md-0 mt-md-2">
            <Form.Check
              type="checkbox"
              id="custom-switch"
              label="Save this card"
              checked={props.saveCard}
              onClick={(e) => props.setSaveMethod(e)}
            />
          </Form.Group>
        </Col>
      }
    </>
  );
}