import OneSignalReact from "react-onesignal";
import keycloak from "../../keycloak";
import { deleteAllCookies, getRedirectURI, getKeycloakClient } from "../../utils/utils";

export const login = (data) => async (dispatch, getState) => {
}


export const logout = (data) => (dispatch, getState) => {
    let redirectUri = getRedirectURI();
    dispatch({type:'LOG_OUT'});
    OneSignalReact.removeExternalUserId();
    localStorage.clear();
    keycloak.logout({redirectUri: redirectUri});
}
