import { microServices } from "../config/global";
import apiService from "../redux/apiService";
import APIservice from "../redux/apiService";
import { isObjEmpty, prepareUrlWithFilter } from "./utils";

// PM staff - Inspector payload
export const mnInspObj = {
  userType: 'pm',
  staffType: 'INSP',
  filter: {
    status_id: '1',
    profession_type_id: '7',
    region_id: '0',
    area_id_with_parent: '0',
  }
}

// CL staff - Inspector payload
export const clInspObj = {
  userType: 'cl',
  staffType: 'INSP',
  filter: {
    status_id: '1',
    profession_type_id: '2',
    region_id: '0',
    area_id_with_parent: '0',
  }
}

// PM staff - Team Member payload
export const tMObj = {
  userType: 'pm',
  staffType: 'TM',
  filter: {
    status_id: '1',
    profession_type_id: '5',
    region_id: '',
    area_id_with_parent: '',
  }
}

// Housekeeper and Group Dropdown Payload
export const HkDropdownObj = {
  hk: {
    userType: 'cl',
    staffType: 'HK',
    filter: {
      status_id: '1',
      profession_type_id: '1',
      region_id: '0',
      area_id_with_parent: '0',
    }
  },
  hkGroup: {
    filter: {
      status_id: '1',
      profession_type_id: '1',
      region_id: '0',
      area_id: '0'
    }
  }
}

export const HaDropdownObj = {
  userType: 'cl',
  staffType: 'HA',
  filter: {
    status_id: '1',
    profession_type_id: '4',
    region_id: '',
    area_id: '',
  }
}

// CL Inspector with Supervisor Payload
export const clInspSupObj = {
  userType: 'cl',
  staffType: 'INSP',
  perPage: 0,
  filter: {
    profession_type_id: '2,3',
  }
}

// PM Inspector with Supervisor Payload
export const mnInspSupObj = {
  userType: 'cl',
  staffType: 'INSP',
  perPage: 0,
  filter: {
    profession_type_id: '7,8',
  }
}

// Company staff constant
export const companyStaffs = {
  hk: 1,
  cl_insp: 2,
  pm_insp: 7,
  tm: 5,
  ha:4,
  cl_sup: 3,
  pm_sup : 8,
  caa: 6
}


export const HkWO_RegionObj = {
  hk: {
      userType: 'cl',
      staffType: 'HK',
      filter: {
      status_id: '1',
      profession_type_id: '1',
      }
  },
  hkGroup: {
      filter: {
      profession_type_id: '1',
      }
  },
  bulk: true
}

export const clInspWORegionObj = {
  userType: 'cl',
  staffType: 'INSPBULK',
  filter: {
      status_id: '1',
      profession_type_id: '2',
  }
  }
  
  export const mnInspWORegionObj = {
  userType: 'pm',
  staffType: 'INSPBULK',
  filter: {
      status_id: '1',
      profession_type_id: '7',
  }
}

export const mnTeamMemberWORegionObj = {
  userType: 'pm',
  staffType: 'TM',
  filter: {
      status_id: '1',
      profession_type_id: '5',
  }
}

export let apptHaWORegionStaff ={
  userType: 'cl',
  staffType: 'HA',
  filter: {
      status_id: '1',
      profession_type_id: '4',
  }
}

export let onDutyStaff = {
  perPage: "&per_page=0",
  filter: {
    status_id: '1',
    schedules_status: '1',
    date: '',
    profession_type_id: '',
    region_id: '',
    area_id: '',
  }
}

export const clSupObj = {
  userType: 'cl',
  staffType: 'SUP',
  filter: {
    status_id: '1',
    profession_type_id: '3',
    region_id: '0',
    area_id_with_parent: '0',
  }
}

export const pmSupObj = {
  userType: 'pm',
  staffType: 'SUP',
  filter: {
    status_id: '1',
    profession_type_id: '8',
    region_id: '0',
    area_id_with_parent: '0',
  }
}

export const companyStaffProf = {
  hk: 'House Keepers',
  insp: 'Inspectors',
  tm: 'Team Members',
  ha: 'House Attendants',
}



export const loadStaffNextPage = async (userType,pageNo,pageNo1,fetchActiveStaff,staffDropdownFilter,globalRegionAreaFilter,updatePropertyOptions,setNextPageLoading,allDataFetched, staffRole,fromPage='', withoutGrp) => {
  let updatedPropertyFilter = {...staffDropdownFilter};
  if(pageNo==0 && globalRegionAreaFilter && Object.keys(globalRegionAreaFilter).length > 0) {
      updatedPropertyFilter = {
          ...updatedPropertyFilter,
          'region_id': globalRegionAreaFilter?.region_id ?? null,
          'area_id': globalRegionAreaFilter?.area_id ?? null,
      }
  }
  
  let page = fetchActiveStaff ? pageNo : pageNo1;
  let pagSize = pageNo==0 ? 100: 20;
  if(allDataFetched) return;
  if(staffRole=='all'){
    await lazyLoadAllStaffDropdown(userType,page+1,pagSize,updatedPropertyFilter,updatePropertyOptions,setNextPageLoading,fromPage);
  }
  else{
    await lazyLoadStaffDropdown(userType,page+1,pagSize,fetchActiveStaff,updatedPropertyFilter,updatePropertyOptions,setNextPageLoading, staffRole, withoutGrp);
  }
};

export const lazyLoadStaffDropdown = async (userType, page, pageSize,fetchActiveStaff, filter ={}, callback,setLoader, staffRole, withoutGrp) => {
  
  let url = microServices.base + '/v1/company/staff/dropdown/' +userType+ '?';
  let url1 = microServices.base + '/v1/company/staff/group' + '?dropdown=true';
  try{
    setLoader(true);
    if (!isObjEmpty(filter)) {
      for (const key in filter) {
            if(filter?.hasOwnProperty(key) && filter[key] && typeof(filter[key]) === "object") {
                var objLength = filter[key].length;
                if(objLength>0){
                  url += `filter[${key}]=`
                    for(var i=0; i<objLength; i++){
                        if(i<objLength-1){
                          url += `${filter[key][i]['value']},`;
                          url1 += `${filter[key][i]['value']},`;
                        }else{
                          url += `${filter[key][i]['value']}`;
                          url1 += `${filter[key][i]['value']}`;
                        }
                    }
                    url += `&`
                    url1 += `&`
                }
            }
            else if (filter?.hasOwnProperty(key) && ((filter[key] && filter[key] !== '') || filter[key] === 0)) {
              url += `&filter[${key}]=${filter[key]}&`;
              url1 += `&filter[${key}]=${filter[key]}&`;
            }
        }
    }
    url += '&per_page='+pageSize+'&page='+page;
    url1 += '&per_page='+pageSize+'&page='+page;

      let activeGroups = {staffGroupList: []  };
      let activeStaffs = { staffList: []  };
      if(fetchActiveStaff)
      {
          activeStaffs = await apiService.get(url)
          .then((response) => {  
              return prepareStaffDropdownAwait(response,"staff", staffRole);
              });
      }
      if(!fetchActiveStaff && !withoutGrp)
      {
          activeGroups = await apiService.get(url1)
          .then((response) => {  
              return prepareStaffDropdownAwait(response,"staffGroup", staffRole);
              });
      }
      callback(activeStaffs, activeGroups);
  }catch(e){
    console.log(e);
  }finally{
    setLoader(false);
  }

};

export const loadStaffNameOptions = (inputValue,staffRole, userType, professionType, status, globalRegionAreaFilter) => 
{
    let filterName = '';
    let textLength = '';

    textLength = 2;
    filterName = 'filter[full_name]';
    let groupFilter = 'filter[group_name]';
    let inputVal = decodeURIComponent(inputValue).trim();

    let endpoint1 = microServices.base + '/v1/company/staff/group?dropdown=true&' + groupFilter+'='+inputVal+'' + '&filter[profession_type_id]='+ professionType +'&filter[status_id]='+status;
    let endpoint2 = microServices.base + '/v1/company/staff/dropdown/'+userType+'?'+filterName+'='+inputVal+'' + '&filter[profession_type_id]='+ professionType +'&filter[status_id]='+status;

    if(globalRegionAreaFilter && Object.keys(globalRegionAreaFilter).length > 0) {
      endpoint1 = prepareUrlWithFilter(endpoint1,globalRegionAreaFilter);
      endpoint2 = prepareUrlWithFilter(endpoint2,globalRegionAreaFilter);
    }

    endpoint1 += '&per_page=0';
    endpoint2 += '&per_page=0';

    if(inputValue.length >= textLength) {
         return Promise.all([APIservice.get(endpoint1), APIservice.get(endpoint2)]).then((response) => {
           const [result1, result2] = response;
              let hkStaffList = []
              hkStaffList.push(prepareStaffsList(result2,"staff", staffRole));
              hkStaffList.push(prepareStaffsList(result1,"staffGroup", staffRole));
              return hkStaffList;
            });
    }
}


export const prepareStaffsList = (response,staffType, staffRole) => {
  let staffGroupList = [];
  let staffList = [];

  let GroupList = {
    label: "Groups",
    options: staffGroupList,
  }
  
  let HousekeeperList = {
    label:staffRole=='hk' ?  "Housekeepers" : "Team Member",
    options: staffList,
  }

  let List = {
    options: staffList,
  }
  response?.data?.data?.map((group) => {
    if(staffType==="staffGroup") {
        staffGroupList.push({
          value: group.group_id,
          label: group.group_name,
          type: 'group',
          key:staffRole+"Group",
          members: group.Members
        });
    }
    else if (staffType==="staff") {
      staffList.push({
        value: group.user_id,
        label: group.name,
        type: staffRole,
        key:staffRole
      });
    }
  });
  if(staffRole=='ha' || staffRole=='insp') return List;
  if(staffType==="staffGroup") return GroupList;
  if(staffType==="staff") return HousekeeperList;
  
}


export const prepareStaffDropdownAwait = (response, staffType, staffRole) => {
  let staffGroupList = [];
  let staffList = [];

  response.data.data.map((group) => {
      if(staffType==="staffGroup") {
          staffGroupList.push({
            value: group.group_id,
            label: group.group_name,
            type: 'group',
            key:staffRole+"Group",
            members: group.Members
          });
      }
      else if (staffType==="staff") {
        staffList.push({
          value: group.user_id,
          label: group.name,
          type: staffRole,
          key:staffRole
        });
      }
    });

  return {
      staffList: staffList,
      staffGroupList: staffGroupList
  };

}


export const lazyLoadStaffInspSupDropdown = async (userType, page, pageSize,fetchActiveStaff, filter ={}, callback,setLoader, staffRole) => {
    
  let url = microServices.base + '/v1/company/staff/dropdown/' +userType+ '?';
  try{
    setLoader(true);
    if (!isObjEmpty(filter)) {
      url = prepareUrlWithFilter(url,filter);
    }
    url += '&per_page='+pageSize+'&page='+page;
   
    const { activeInspector, inactiveInspector, activeSupervisor, inactiveSupervisor }  = await apiService.get(url)
    .then((response) => {  
        return prepareInspSupDropdown(response?.data?.data );
    });
  
  
    let InspSupList =  {
      activeInspector: activeInspector,
      inactiveInspector: inactiveInspector,
      activeSupervisor: activeSupervisor,
      inactiveSupervisor: inactiveSupervisor,
  };
  
    callback(InspSupList);
  }catch(e){
    console.log(e);
  }finally{
    setLoader(false);
  }

};

export const lazyLoadAllStaffDropdown = async (userType, page, pageSize,filter ={}, callback,setLoader, fromPage) => {
  let url = microServices.base + '/v1/company/staff/dropdown/' +userType+ '?';

  try{
    setLoader(true);
    if (!isObjEmpty(filter)) {
      url = prepareUrlWithFilter(url,filter);
    }
    url += '&per_page='+pageSize+'&page='+page;
   
    const { activeStaff,inactiveStaff }  = await apiService.get(url)
    .then((response) => {  
      return prepareAllStaffFunction(response, fromPage);
    });
    
    let staffList =  {
      activeStaff: activeStaff,
      inactiveStaff: inactiveStaff
    };
    
    callback(staffList);
  }catch(e){
    console.log(e);
  }finally{
    setLoader(false);
  }

};


export const prepareInspSupDropdown = (response) => {
  let ActiveInspList = [];
  let InactiveInspList = [];
  let InactiveSupList = [];
  let ActiveSupList = [];

  response?.map((group) => {
    const professionIdsArray = group.profession_ids.split(',');
      professionIdsArray.forEach((prof) => {
          if(prof == 2 || prof == 7) {
            if (group.status_id == '1') {
              ActiveInspList.push({
                value: group.user_id,
                label: group.name,
                key : 'inspector',
                group: "active",
              })
            }
            if (group.status_id == '2') {
              InactiveInspList.push({
                value: group.user_id,
                label: group.name,
                key : 'inspector',
                group: "inactive"
              })
            }
          } 

          if(prof == 3 || prof == 8) {
            if (group.status_id == '1') {
              ActiveSupList.push({
                value: group.user_id,
                label: group.name,
                key : 'supervisor',
                group: "active",
              })
            }
            if (group.status_id == '2') {
              InactiveSupList.push({
                value: group.user_id,
                label: group.name,
                key : 'supervisor',
                group: "inactive"
              })
            }
          }
        })
  });

  return {
      activeInspector: ActiveInspList,
      inactiveInspector: InactiveInspList,
      activeSupervisor: ActiveSupList,
      inactiveSupervisor: InactiveSupList,
  };

}
export const prepareAllStaffFunction = (response, fromPage) => {
    let ActiveStaffList = [];
    let InactiveStaffList = [];
    response.data?.data.map((item) => {
    const labelName = fromPage=='payroll' ?  `${item.name} [${item.profession_names}]` : `${item.name}` ;
    if (item.status_id == 1) {
            ActiveStaffList.push({
              value: item.user_id,
              label: labelName,
              group: "active",
            });
          } else if (item.status_id == 2) {
            InactiveStaffList.push({
              value: item.user_id,
              label: labelName,
              group: "inactive",
            });
          }
    });
    
    return {
      activeStaff: ActiveStaffList,
      inactiveStaff: InactiveStaffList
    };

}

export const loadAllNameOptions = (inputValue,userType,fromPage, status, globalRegionAreaFilter) => 
  {
      let url = '';
      let filterName = '';
      let textLength = '';
  
      textLength = 2;
      filterName = 'filter[full_name]';
      let inputVal = decodeURIComponent(inputValue).trim();

      url = microServices.base + '/v1/company/staff/dropdown/'+userType+'?'+filterName+'='+inputVal+'&filter[status_id]='+status;
      if(globalRegionAreaFilter && Object.keys(globalRegionAreaFilter).length > 0) {
        url = prepareUrlWithFilter(url,globalRegionAreaFilter);
      }

      if(inputValue.length >= textLength) {
          return apiService.get(url)
              .then((response) => {  
                const { activeStaff,inactiveStaff  } = prepareAllStaffFunction(response, fromPage);;
                
                let StaffList = [{
                  label: "Active",
                  options: activeStaff,
                },
                {
                  label: "Inactive",
                  options: inactiveStaff,
                }];
                return StaffList;
              }) 
      }
  }
