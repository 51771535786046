const initState = {
  admin: [],
  companyBillingInfo : []
};

const admin = (state = initState, action) => {

  switch (action.type) {

    case 'SAVE_ADMIN':
      return {
        ...state,
        admins: action.payload
      };


    case 'SET_ADMIN_ITEM':
      return {
        ...state,
        singleAdmin: action.payload
      };

    case 'CLEAR_ADMIN_ITEM':
      return {
        ...state,
        singleAdmin: null
      };

    case 'CLEAR_ADMIN_DROPDOWN_LIST':
      return {
        ...state,
        adminList: []
      };

    case 'SET_ADMIN_DROPDOWN_LIST': {
      return {
        ...state,
        adminList: action.payload
      };
    }

    case 'SET_ADDITIONAL_FEATURES': {
      return {
        ...state,
        additionalFeature : action.payload.data
      };
    }

    case 'CLEAR_ADDITIONAL_FEATURES': {
      return {
        ...state,
        additionalFeature : null
      };
    }

    case 'SET_PM_CL': {
      return {
        ...state,
        companyList : action.payload.data
      };
    }

    case 'CLEAR_PM_CL': {
      return {
        ...state,
        companyList : []
      };
    }

    case 'SET_EMAIL_TEMPLATE': {
      return {
        ...state,
        emailTemplate : action.payload.data
      };
    }

    case 'CLEAR_EMAIL_TEMPLATE': {
      return {
        ...state,
        emailTemplate : null
      };
    }

    case 'SET_NOTIFICATION_TEMPLATE': {
      return {
        ...state,
        notificationTemplate : action.payload.data
      };
    }

    case 'CLEAR_NOTIFICATION_TEMPLATE': {
      return {
        ...state,
        notificationTemplate : null
      };
    }

    case 'SET_PM': {
      return {
        ...state,
        PMList : action.payload.data
      };
    }
    case 'CLEAR_PM':
      return {
        ...state,
        PMList: []
      };

    case 'SET_CL': {
      return {
        ...state,
        CLList : action.payload.data
      };
    }
    case 'CLEAR_CL':
      return {
        ...state,
        CLList: []
      };

      case 'SET_PM_CLEANER': {
        return {
          ...state,
          PMCLList : action.payload.data
        };
      }
      case 'CLEAR_PM_CLEANER':
        return {
          ...state,
          PMCLList: []
        };
        case 'SET_CL_PROJECTMANAGER': {
          return {
            ...state,
            CLPMList : action.payload.data
          };
        }
        case 'CLEAR_CL_PROJECTMANAGER':
          return {
            ...state,
            CLPMList: []
          };
          case 'SET_ADMIN_PROPERTY': {
            return {
              ...state,
              propertyList : action.payload.data
            };
          }
          case 'CLEAR_ADMIN_PROPERTY':
            return {
              ...state,
              propertyList: []
            };

      case 'SET_MANAGER_DROPDOWN_LIST': {
        return {
          ...state,
          propertymanagerList: action.payload
        };
      }
      case 'CLEAR_MANAGER_DROPDOWN_LIST':
        return {
          ...state,
          propertymanagerList: []
        };

        case 'SET_CLEANER_DROPDOWN_LIST': {
          return {
            ...state,
            cleanerList: action.payload
          };
        }
        case 'CLEAR_CLEANER_DROPDOWN_LIST':
          return {
            ...state,
            cleanerList: []
          };

          case 'SET_BROADCAST_MESSAGE_USER_LIST': {
            return {
              ...state,
              broadcastMessageUserList: action.payload
            };
          }
          case 'CLEAR_BROADCAST_MESSAGE_USER_LIST':
            return {
              ...state,
              broadcastMessageUserList: []
            };
          case 'SET_ACCOUNTMN_ADMIN_LIST': {
            return {
              ...state,
              accountMnAdminList: action.payload
            };
          }
          case 'CLEAR_ACCOUNTMN_ADMIN_LIST':
            return {
              ...state,
              accountMnAdminList: []
          };
          case 'SET_COMPANY_BILLING'  : {
            return {
              ...state,
              companyBillingInfo: action.payload?.data
            };
          }
          case 'CLEAR_COMPANY_BILLING':
            return {
              ...state,
              companyBillingInfo: []
          };


    default:
      return state;
  }
};

export default admin;
