import APIservice from "../apiService";
import { isLoader } from "../global/action";
import { microServices } from "../../config/global";
import { setError } from "../form/action";
/**
 * ApiMiddleware can be used to call any User related API services
 * @param {*} store 
 * @returns 
 */
const MessageCenterMiddleware = (store) => (next) => async (action) => {
    // Our middleware
    const result    = next(action);
    let endpoint;
    switch (action.type) {
        
        case "MESSAGE_CENTER_FUNCTIONALITY":
                endpoint = microServices.notification +'/v1/emaillog'+action.payload.url;
                APIservice
                .post(endpoint, action.payload.data)
                .then((response) => {
                    store.dispatch({
                    type: "SHOW_ALERT",
                    payload: {
                        variant: "success",
                        message: action.payload.sucessMessage,
                        componentName: action.payload.componentName,
                    },
                    });
                })
                .catch((error) => {
                    if (error.response.status !== 422) {
                    store.dispatch({
                        type: "SHOW_ALERT",
                        payload: {
                        variant: "danger",
                        message: action.payload.errorMessage,
                        },
                    });
                    } else {
                    let err = {
                        componentName: action.payload.componentName,
                        statusCode: error.response.status,
                        data: error.response.data,
                    };
                    store.dispatch(setError(err));
                    }
                })
                .finally(() => {
                    store.dispatch(isLoader(false));
                });
                break;
                case "FETCH_MESSAGES":
                    endpoint = microServices.notification+'/v1/emaillog/view/'+action.payload.id;
                    store.dispatch(isLoader(true));
                    APIservice
                      .get(endpoint)
                      .then((response)=>{
                        if(response && response.data){                
                            store.dispatch({ 
                              type: 'SET_MESSAGE_DATA_FOR_EDIT', 
                              payload: {
                                data:response.data.data
                                }
                              });
                        }
                      })
                      .catch((error)=>{
                        let err = {componentName:'EditMessages',statusCode: error.response.status,data: error.response.data};
                        store.dispatch(setError(err));
                      })
                      .finally(()=>{
                        store.dispatch(isLoader(false));
                      })
                  break;
                  case "DOWNLOAD_EMAILLOG_ATTACHMENT":
                    store.dispatch(isLoader(true));
                    let attachmentEndpoint  = microServices.notification+'/v1/emaillog/download/'+action.payload.id;
                    APIservice.
                    getBlobDataWithParams(attachmentEndpoint, '')
                    .then((response)=>{
                      console.log(response)
                      const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
                         const link = document.createElement('a');
                         link.href = blobUrl;
                         link.setAttribute('download', `${(action.payload?.fileName) ? action.payload.fileName : "Document"}_${Date.now()}.pdf`);
                         document.body.appendChild(link);
                         link.click(); 
                         link.parentNode.removeChild(link);
                    })
                    .catch((error)=>{
                      let err = {componentName:'EmaillogAttachment',statusCode: error.response.status,data: error.response.data};
                      store.dispatch(setError(err));
                    })
                    .finally(()=>{
                      store.dispatch(isLoader(false));
                    })
                  break;

        default:
            break;
        }
        return result;
      };
      
      export default MessageCenterMiddleware;