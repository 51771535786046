import APIservice from "../apiService";
import { isLoader } from "../global/action";
import { microServices } from "../../config/global";
import { setError } from "../form/action";
/**
 * ApiMiddleware can be used to call any Inventory related API InventorySmart Integrations.
 * @param {*} store 
 * @returns 
 */
const InventorySmartMiddleware = (store) => (next) => async (action) => {
    const result = next(action);
    let endpoint = "";
    switch (action.type) {
        case "GET_INVENTORY_SMART_CREDENTIAL":
                endpoint = `${microServices.base}/v1/company/integration/inventorySmart`;
                store.dispatch(isLoader(true));
                APIservice.get(endpoint)
                .then((response) => {
                    if (response && response.data) {
                        store.dispatch({
                            type: 'SET_INVENTORY_SMART_CREDENTIAL',
                            payload:  response.data.data
                        });
                    }
                })
                .catch((error)=>{
                    let err = {componentName:'PropertyNotes',statusCode: error.response.status,data: error.response.data};
                    store.dispatch(setError(err));
                })
                .finally(()=>{
                    store.dispatch(isLoader(false));
                });
        break;
        case "REPLACE_STOCKROOM_ITEM":
            endpoint = `${microServices.base}/v1/appointment/Inventory-service-request`;
            store.dispatch(isLoader(true));
            APIservice
                .post(endpoint, { inventory_smart: action.payload.inventory_smart })
                .then((response) => {
                    action.payload.serviceRequest.inventory_smart_request = response.data.data;
                    if (action.payload.userType === 'cleaner') {
                        store.dispatch({
                            type: 'CREATE_SERVICE_REQUEST',
                            payload: {
                                'formData': action.payload.serviceRequest,
                                message: "Service request Created successfully!",
                                failMessage: "Unable to Create Service Request!"
                            }
                        });
                    } else {
                        store.dispatch({
                            type: 'CREATE_INVENTORY_SMART_PM_TASK',
                            payload: action.payload.serviceRequest,
                        });
                    }
                })
                .catch((error) => {
                    if (error.response.status !== 422) {
                        store.dispatch({
                            type: 'SHOW_ALERT',
                            payload: {
                                variant: 'danger',
                                message: 'Unable to save stockroom item information!'
                            }
                        });
                    } else {
                        let err = { componentName: 'ReplaceStockroomItem', statusCode: error.response.status, data: error.response.data };
                        store.dispatch(setError(err));
                    }
                })
                .finally(() => {
                    store.dispatch(isLoader(false));
                })
            break;

        case "CREATE_INVENTORY_SMART_PM_TASK":
            endpoint = `${microServices.base}/v1/appointment/Inventory-service-request/pmTask`;
            store.dispatch(isLoader(true));
            APIservice
                .post(endpoint, action.payload)
                .then((response) => {
                    store.dispatch({
                        type: 'SHOW_ALERT',
                        payload: {
                            variant: 'success',
                            message: 'Inventory smart request created successfully!',
                            componentName: 'InventorySmartRequest'
                        }
                    });
                })
                .catch((error) => {
                    if (error.response.status !== 422) {
                        store.dispatch({
                            type: 'SHOW_ALERT',
                            payload: {
                                variant: 'danger',
                                message: 'Unable to Create inventory smart request!'
                            }
                        });
                    } else {
                        let err = { componentName: 'InventorySmartRequest', statusCode: error.response.status, data: error.response.data };
                        store.dispatch(setError(err));
                    }
                })
                .finally(() => {
                    store.dispatch(isLoader(false));
                })
            break;
        case "INVENTORY_SMART_REQUEST_ACTIONS":
            store.dispatch(isLoader(true));
            endpoint = `${microServices.base}/v1/appointment/Inventory-service-request/${action.payload.id}/${action.payload.action}`;
            APIservice.put(endpoint, action.payload.formData)
                .then((response) => {
                    if(response.data.success){
                        store.dispatch({
                            type: "SHOW_ALERT",
                            payload: {
                                variant: "success",
                                message: action.payload.successMessage,
                                componentName: "InventorySmartRequestActions",
                            },
                        });
                    }
                })
                .catch((error) => {
                    if (error.response.status !== 422) {
                        store.dispatch({
                            type: "SHOW_ALERT",
                            payload: {
                                variant: "danger",
                                message: action.payload.failMessage,
                            },
                        });
                    } else {
                        let err = {
                            componentName: "InventorySmartRequestActions",
                            statusCode: error.response.status,
                            data: error.response.data,
                        };
                        store.dispatch(setError(err));
                    }
                })
                .finally(() => {
                    store.dispatch(isLoader(false));
                });
            break;
        case "VIEW_ITEMS_IN_STOCKROOM":
            endpoint = `${microServices.base}/v1/appointment/Inventory-service-request/viewItemsInStockRoom/${action.payload.inventory_smart_pm_task_id}`;
            store.dispatch(isLoader(true));
            APIservice
                .get(endpoint)
                .then((response) => {
                    if (response && response.data) {
                        store.dispatch({
                            type: 'SET_INVENTORY_ITEMS_IN_STOCKROOM',
                            payload: response.data.data,
                        });
                    }
                })
                .catch((error) => {
                    let err = { componentName: 'InventorySmartRequest', statusCode: error.response.status, data: error.response.data };
                    store.dispatch(setError(err));
                })
                .finally(() => {
                    store.dispatch(isLoader(false));
                })
            break;
        default:
            break;
    }
    return result;
};

export default InventorySmartMiddleware;