import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import rootReducer from './root.reducer';
import loggerMiddleware from './middleware/Logger';
import ApiMiddleware from './middleware/ApiMiddleware';
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import UserMiddleware from './middleware/UserMiddleware';
import AdminMiddleware from './middleware/AdminMiddleware';
import PropertyMiddleware from './middleware/PropertyMiddleware';
import ServiceMiddleware from './middleware/ServiceMiddleware';
import BillingMiddleware from './middleware/BillingMiddleware';
import CompanyMiddleware from './middleware/CompanyMiddleware';
import InventoryMiddleware from './middleware/InventoryMiddleware';
import InspectionMiddleware from './middleware/InspectionMiddleware';
import InvoicePayrollMiddleware from './middleware/InvoicePayrollMiddleware';
import AppointmentMiddleware from './middleware/AppointmentMiddleware';
import WorkorderMiddleware from './middleware/WorkorderMiddleware';
import DashboardMiddleware from './middleware/DashboardMiddleware';
import MessageCenterMiddleware from './middleware/MessageCenterMiddleware';
import InventorySmartMiddleware from './middleware/InventorySmartMiddleware';
import CommonAreaMiddleware from './middleware/CommonAreaMiddleware';
import DailyCloseoutMiddleware from './middleware/DailyCloseoutMiddleware';
import UnitStatusViewMiddleware from './middleware/UnitStatusViewMiddleware';
import InventoryTemplateMiddleware from './middleware/InventoryTemplateMiddleware';

// Middleware: Redux Persist Config
const persistConfig = {
  // Root
  key: 'root',
  // Storage Method (React Native)
  storage,
  // Whitelist (Save Specific Reducers)
  whitelist: [
    'global', 
    'authentication', 
    'admin', 
    'property', 
    'BillingMiddleware', 
    'company', 
    'user',
    'inspection',
    'appointment',
    'InvoicePayroll',
    'MessageCenter',
    'inventorySmart',
    'CommonArea',
    'DailyCloseout',
    'UnitStatusView',
    'InventoryTemplate'
  ],
  // Blacklist (Don't Save Specific Reducers)
  blacklist: [
    'form'
  ],
};
// Middleware: Redux Persist Persisted Reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

const enhancerList = [];
const devToolsExtension = window && window.__REDUX_DEVTOOLS_EXTENSION__;

if (typeof devToolsExtension === 'function') {
  enhancerList.push(devToolsExtension());
}

const composedEnhancer = compose(applyMiddleware(thunk,
  ApiMiddleware,
  loggerMiddleware, 
  UserMiddleware,
  AdminMiddleware,
  PropertyMiddleware,
  ServiceMiddleware,
  BillingMiddleware,
  CompanyMiddleware,
  InventoryMiddleware,
  InspectionMiddleware,
  AppointmentMiddleware,
  WorkorderMiddleware,
  InvoicePayrollMiddleware,
  DashboardMiddleware,
  MessageCenterMiddleware,
  InventorySmartMiddleware,
  CommonAreaMiddleware,
  DailyCloseoutMiddleware,
  UnitStatusViewMiddleware,
  InventoryTemplateMiddleware
  ), ...enhancerList);

const store = createStore(persistedReducer, composedEnhancer);

// Middleware: Redux Persist Persister
const persistor = persistStore(store);

export { store, persistor };
