import { microServices } from "../config/global";

export const tilledObj = {
  "type": "card",
  "billing_details": {
    "address": {
      "street": "",
      "street2": "",
      "city": "",
      "state": "",
      "zip": "",
      "country": ""
    },
    "email": "",
    "name": "",
    "phone": ""
  }

};

export const tilledTypeCard = {
  'card': {
    "cvc": "",
    "exp_month": "",
    "exp_year": "",
    "number": ""
  }
}

export const tilledTypeAch = {
  'ach_debit': {
    "account_type": "",
    "account_number": "",
    "routing_number": "",
    "account_holder_name": ""
  }
}

export const TilledAchType = [
  { value: 'savings', label: 'savings' },
  { value: 'checking', label: 'checking' }
]

export const AchAutopayForm = {
  "account_type": "individual",
  "account_number": "",
  "routing_number": "",
  "account_holder_name": "",

}


export const STRIPE_CC_CARD = 2;
export const STRIPE_ACH_CARD = 1

export const PAYMENT_TILLED_CC = 2;
export const TILLED_CC_LABEL = 'card';
export const PAYMENT_TILLED_ACH = 1;
export const TILLED_ACH_LABEL = 'ach_debit';
export const WALLET_TILLED_CC = 1;
export const WALLET_TILLED_ACH = 2;

export const PAYMENT_GATEWAY = {
  PAYPAL: 1,
  STRIPE: 2,
  TILLED: 3,
  CARDPOINTE:4
}


export const MIN_TRANSACTION_AMT = {
  PAYPAL: 1,
  STRIPE: 0.50,
  TILLED: 0.0
}

export const requestFrm = {
  autopay_method: '',
  name: '',
  manager_cleaner_setting_id: '',
  agreement_sign_date: '',
  agreement_sign_date_frm: '',
  cleaner_name: '',
  payment_gateway: {},
}

export const StripeAchConfirmForm = {
 manager_cleaner_setting_id: '',
 micro_deposited_amount_first:'',
 micro_deposited_amount_second:'' 
}

export const autopayDisableForm = {
  payment_gateway: '',
  manager_cleaner_setting_id:'',
  keep_profile_disable:false,
  remove_profile_disable :false,
  
 }

 export const autopayEnableForm = {
  payment_gateway: '',
  manager_cleaner_setting_id:'',
  enable_profile:false,
  new_profile_create :false,
  autopay_method:{}
  
 }

export const payMethodCreateIntentUrl = microServices.base + '/v1/company/payment/stripe/auto-pay/intent';

export const payMethodSaveIntentUrl =  microServices.base + '/v1/company/payment/stripe/auto-pay/intent/';

export const payMethodUpdateIntentUrl = microServices.base + '/v1/company/payment/stripe/auto-pay/';


export const CardpointAchType = [
  { value: 'ECHK', label: 'Savings' },
  { value: 'ESAV', label: 'Checking' }
]

export const cardpointeDefaultForm = {
  ownerFirstName: "",
  ownerLastName: "",
  ownerTitle: "",
  ownerEmail: "",
  ownerBusinessName: "",
  merchant_id: "",
};

export const GATEWAYS = [
  {id:1,label:'Paypal'},
  {id:2,label:'Stripe'},
  {id:3,label:'Tilled'},
  {id:4,label:'Propelr'},
];

export const PAYMENTSUPPORTCOUNTRY = ['US','CA'];

export const filterPaymentSupportCountry = (countryObj) => {
  let countrys = [];
  countryObj.map((cos) => {
    let countryCode = cos.value;
    if (PAYMENTSUPPORTCOUNTRY.includes(countryCode)) {
      countrys.push({
        value: cos.value,
        label: cos.label,
      });
    }
  });
  return countrys;
};

export const checkCountryOrProvinces = (countryObj, state) => {
  if (countryObj?.value == "US") {
    if (validUSStateCodes.includes(state?.value)) {
      return state;
    }
  }
  if (countryObj?.value == "CA") {
    if (validCanadianProvinceCodes.includes(state?.value)) {
      return state;
    }
  }
  return '';
};

export const checkCountryOrProvincesForString = (country, state) => {
  if (country== "US") {
    if (validUSStateCodes.includes(state)) {
      return state;
    }
  }
  if (country == "CA") {
    if (validCanadianProvinceCodes.includes(state)) {
      return state;
    }
  }
  return '';
};


const validUSStateCodes = [  'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS',  'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY',  'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'];
const validCanadianProvinceCodes = [  'AB', 'BC', 'MB', 'NB', 'NL', 'NS', 'NT', 'NU', 'ON', 'PE', 'QC', 'SK', 'YT'];
export const isGtwSupportForCurrency = (selected, currency) => {
  if (selected) {
    let currencyArray = [];
    if (currency?.supported_at) {
      if (currency?.supported_at.indexOf("|") > -1) {
        currencyArray= currency?.supported_at.split("|");
        currencyArray = currencyArray.map(str => parseFloat(str));
        } else {
        currencyArray.push(Number(currency?.supported_at));
      }
      if (currencyArray.includes(selected?.value)) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  }
};

export const RC_INVOICE_TILLED_GATEWAY = 1;
export const RC_INVOICE_PROPELR_GATEWAY = 2;