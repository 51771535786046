import { microServices, webCompanyTilledConfig } from "../config/global";
import apiService from "../redux/apiService";


export function getTilled(account_id) {
    const tilledAccountId = account_id;
    
    const tilled = new window.Tilled(
        webCompanyTilledConfig.tilled_public_key,
        tilledAccountId,
        {
            sandbox: isProdMode(),
            log_level: 0
        }
    )
    return tilled
}

export async function createInvoicePaymentIntent(paymentTypeObj, clientSecret, tilledPayload) {

    const { tilled, form } = paymentTypeObj;
    let paymentMethod = {};

    if (!tilledPayload?.type) {
        return;
    }


    if (tilledPayload?.type == 'card') {
        paymentMethod = {
            form,
            type: tilledPayload?.type,
            nick_name: tilledPayload?.billing_details?.name,
            billing_details: {
                name: tilledPayload?.billing_details?.name,
                email: tilledPayload?.billing_details?.email,
                address: {
                    country: tilledPayload?.billing_details?.address.country,
                    zip: tilledPayload?.billing_details?.address.zip,
                    state: tilledPayload?.billing_details?.address.state,
                    city: tilledPayload?.billing_details?.address.city,
                    street: tilledPayload?.billing_details?.address.street,
                }
            }

        }
    } else if (tilledPayload?.type == 'ach_debit') {

        paymentMethod = {
            form,
            type: tilledPayload?.type,
            nick_name: tilledPayload?.billing_details?.name,
            billing_details: {
                name: tilledPayload?.ach_debit?.account_holder_name,
                address: {
                    country: tilledPayload?.billing_details?.address.country,
                    zip: tilledPayload?.billing_details?.address.zip,
                    state: tilledPayload?.billing_details?.address.state,
                    city: tilledPayload?.billing_details?.address.city,
                    street: tilledPayload?.billing_details?.address.street,
                }
            },
            ach_debit: {
                account_type: tilledPayload?.ach_debit?.account_type
            }
        }

    }
    
    return await tilled.confirmPayment(clientSecret, { payment_method: paymentMethod, })

}



export const createPaymentIntent = async (endpoint, formData) => {

    return await apiService.post(endpoint, formData).then((response) => {
        return response;
    })
}

export async function getTilledForm(tilled, type) {
    const form = await tilled.form({
        payment_method_type: type,
    })
    return form;
}

async function injectFields(fieldsObject, form) {
    const fieldOptions = {
        styles: {
            base: {
                fontFamily: 'Poppins, sans-serif',
                fontWeight: "500",
                fontSize: "14px",
                lineHeight:'25px',
                color: "#4d5875"
            },
            invalid: {
                ":hover": {
                    textDecoration: "underline dotted red",
                },
                color: "#f74f75",
            },
            valid: {
                color: "#4d5875",
            },
        },
    };

    Object.entries(fieldsObject).forEach((entry) => {
        const [field, fieldElement] = entry;
        // set placeholder for cardExpiry
        fieldOptions.placeholder = field === 'cardExpiry' ? 'MM/YY' : undefined;
        form.createField(field, fieldOptions).inject(fieldElement);
    });
}

export async function cancelPaymentIntent(endpoint, formData) {
    return await apiService.post(endpoint, formData).then((response) => {
        return response;
    })
}

export async function buildForm(paymentTypeObj) {
    paymentTypeObj.form = await getTilledForm(paymentTypeObj.tilled, paymentTypeObj.type)
    injectFields(paymentTypeObj.fields, paymentTypeObj.form)
    paymentTypeObj.form.build()
}

export async function cancelRcPaymentIntent(endpoint) {
    return await apiService.put(endpoint).then((response) => {
        return response;
    })
}


const navItems = [
    {
        id: 1,
        title: 'Credit Card',
        type: 'card',
        iconClass: 'nav-icon fa fa-credit-card',

        fields: {
            cardNumber: "#card-number-element",
            cardExpiry: "#card-expiration-element",
            cardCvv: "#card-cvv-element",
        },
    },
    {
        id: 2,
        title: 'Bank Transfer',
        type: 'ach_debit',
        iconClass: 'nav-icon fa fa-university',

        fields: {
            bankRoutingNumber: "#bank-routing-number-element",
            bankAccountNumber: "#bank-account-number-element",
        },
    }
];
export const CREDITCARD = navItems[0];
export const BANKTRANSFER = navItems[1];

export const TILLEDACCID= webCompanyTilledConfig.tilled_account;

export async function createPaymentMethod (paymentTypeObj,tilledPayload){

    const { tilled, form } = paymentTypeObj;
   
    if (!tilledPayload?.type) {
        return;
    }

    let paymentMethod = {};

    if (tilledPayload?.type == 'card') {
        paymentMethod = {
            form,
            type: tilledPayload?.type,
            nick_name: tilledPayload?.billing_details?.name,
            billing_details: {
                name: tilledPayload?.billing_details?.name,
                email: tilledPayload?.billing_details?.email,
                address: {
                    country: tilledPayload?.billing_details?.address.country,
                    zip: tilledPayload?.billing_details?.address.zip,
                    state: tilledPayload?.billing_details?.address.state,
                    city: tilledPayload?.billing_details?.address.city,
                    street: tilledPayload?.billing_details?.address.street,
                }
            }

        }
    } else if (tilledPayload?.type == 'ach_debit') {

        paymentMethod = {
            form,
            type: tilledPayload?.type,
            nick_name: tilledPayload?.billing_details?.name,
            billing_details: {
                name: tilledPayload?.ach_debit?.account_holder_name,
                address: {
                    country: tilledPayload?.billing_details?.address.country,
                    zip: tilledPayload?.billing_details?.address.zip,
                    state: tilledPayload?.billing_details?.address.state,
                    city: tilledPayload?.billing_details?.address.city,
                    street: tilledPayload?.billing_details?.address.street,
                }
            },
            ach_debit: {
                account_type: tilledPayload?.ach_debit?.account_type
            }
        }

    }
    return await tilled.createPaymentMethod(paymentMethod)
   
} 

export async function saveWalletDetials(formData) {
    let endpoint = microServices.base + '/v1/admin/invoice/payment/tilled/savemethod';
    return await apiService.post(endpoint, formData).then((response) => {
        return response;
    })
}

 export const isProdMode = ()=> {
    if (process.env.REACT_APP_ENV == 'prod') {
      return false;
    }
    return true;
  }

 export const isBetaMode = ()=> {
    if (process.env.REACT_APP_ENV == 'staging') {
      return false;
    }
    return true;
  }

  export const createPaymentIntentWithoutAuth = async (endpoint, formData) => {

    return await apiService.withoutKeycloakTokenPost(endpoint, formData).then((response) => {
        return response;
    })
}

export async function cancelPaymentIntentWithoutAuth(endpoint, formData) {
    return await apiService.withoutKeycloakTokenPost(endpoint, formData).then((response) => {
        return response;
    })
}

export const USA_COUNTRY = {value:"US",label:"USA"} ; 


