export const cleanerAssociatesList = (data) => async (dispatch, getState) => {

    dispatch({
        type: 'FETCH_CLEANER_ASSOCIATES',
        payload: data
    });
}

export const managerAssociatesList = (data) => async (dispatch, getState) => {

    dispatch({
        type: 'FETCH_MANAGER_ASSOCIATES',
        payload: data
    });
}

export const getManagerPaymentMethod = (data) => {
    return {
        type: 'GET_MANAGER_PAYMENT_METHOD',
        payload: data,
    };
}

export const changeBillingMethod = (data) => {
    return { 
      type: 'CHANGE_BILLING_METHD',
      payload:data
    };
}

export const getProfileInfo = () => {
    return { 
      type: 'FETCH_COMPANY_INFO',
    };
}

export const updateProfileInfo = (data) => {
    return { 
      type: 'UPDATE_COMPANY_INFO',
      payload:data
    };
}

export const getPaypalInfo = () => {
    return { 
      type: 'FETCH_PAYPAL_ADDRESS',
    };
}

export const updatePaypalInfo = (data) => {
    return { 
      type: 'UPDATE_PAYPAL_ADDRESS',
      payload: data
    };
}
export const initializeProfilePicture = (data) => {
    return {
        type: 'INITIALIZE_PROFILE_PICTURE',
        payload: data
    };
}

export const getProfilePicture = (model) => async (dispatch, getState) =>{
    dispatch({ 
      type: 'FETCH_PROFILE_PICTURE',
      payload:model
    });
  }

export const deleteProfilePicture = (model) => async (dispatch, getState) => {
    dispatch({ 
      type: 'DELETE_PROFILE_PICTURE',
      payload:model
    });
  }

export const getCompanySetting = (model) => async (dispatch, getState) =>{
  dispatch({ 
    type: 'FETCH_COMPANY_SETTING',
    payload:model
  });
}

export const updateCompanySetting = (model) => async (dispatch, getState) =>{
  dispatch({ 
    type: 'UPDATE_COMPANY_SETTING',
    payload:model
  });
}

export const getInvoiceSetting = (model) => async (dispatch, getState) =>{
  dispatch({ 
    type: 'FETCH_INVOICE_SETTING',
    payload:model
  });
}

export const updateInvoiceSetting = (model) => async (dispatch, getState) =>{
  dispatch({ 
    type: 'UPDATE_INVOICE_SETTING',
    payload:model
  });
}
export const updateUserSetting = (payload, callback) => async (dispatch, getState) => {
  dispatch({
    type: 'UPDATE_USER_SETTING',
    payload,
    callback,
  });
}

export const getApptListCompInfo = (model,company_id,comptype)=> async(dispatch, getState) => {
  dispatch({
    type: 'FETCH_APPT_LIST_COMPANY_INFO',
    payload:model,
    company_id:company_id,
    comptype:comptype,
  });
}

export const updateCompanyIntegration = (data) => {
  return { 
    type: 'UPDATE_COMPANY_INTEGRATION',
    payload: data
  };
}


export const updateItripContractor = (data) => {
  return { 
    type: 'UPDATE_ITRIP_CONTRACTOR',
    payload: data
  };
}

export const fetchLeftSidebarCount = (data) => {
  return {
    type: "FETCH_LEFT_SIDEBAR_COUNT",
    payload: data,
  }
};

export const getScheduleStaffSetting = (data) => {
  return {
    type: "FETCH_SCHEDULE_STAFF_SETTING",
    payload: data,
  }
};

export const handleScheduleStaffSetting = (data) => {
  return {
    type: "HANDLE_SCHEDULE_STAFF_SETTING",
    payload: data,
  }
};

export const getEmailSetting = (data) => {
  return {
    type: "FETCH_EMAIL_SETTINGS",
    payload: data
  }
}

export const getScheduleStaffShiftDropdown = (data) => {
  return {
    type: "DROPDOWN_SCHEDULE_STAFF_SHIFTS",
    payload: data,
  }
};

export const updateEmailSetting = (data) => {
  return { 
    type: 'UPDATE_EMAIL_SETTING',
    payload: data
  };
}
export const getStaffDefaultSchedule = (data) => {
  return {
    type: "FETCH_STAFF_DEFAULT_SCHEDULE",
    payload: data,
  }
};

export const alterStaffDefaultSchedule = (data) => {
  return {
    type: "ALTER_STAFF_DEFAULT_SCHEDULE",
    payload: data,
  }
};

export const addScheduleStaffListing = (data) => {
  return {
    type: "ADD_SCHEDULE_STAFF_LISTING",
    payload: data,
  }
};

export const addScheduleStaff = (data) => {
  return {
    type: "ADD_SCHEDULE_STAFF",
    payload: data,
  }
};

export const getPushNotifications = (data) => {
  return {
    type: "FETCH_PUSH_NOTIFICATIONS",
    payload: data
  }
}

export const updatePushNotifications = (data) => {
  return { 
    type: 'UPDATE_PUSH_NOTIFICATIONS',
    payload: data
  };
}

export const fetchMessageCount = (data) => {
  return {
    type: "FETCH_MESSAGE_COUNT",
    payload: data,
  }
};

export const getIntegrationClients = (data) => {
  return {
    type: 'FETCH_INTEGRATION_CLIENTS',
    payload: data
  };
}

export const getIntegrationPropertiesFeed = (data) => {
  return {
    type: 'GET_INTEGRATION_PROPERTIES_FEED',
    payload: data
  };
}

export const copyPrevScheduleStaffListing = (data) => {
  return {
    type: "COPY_PREV_SCHEDULE_STAFF_LISTING",
    payload: data,
  }
};

export const scheduleStaffTimesheetExport = (data) => {
  return {
    type: "SCHEDULE_STAFF_TIMESHEET_EXPORT",
    payload: data,
  }
};

export const getVacasaPm = (data) => {
  return {
    type: 'FETCH_VACASA_PM',
    payload: data
  };
}

export const scheduleStaffExport = (data) => {
  return {
    type: "SCHEDULE_STAFF_EXPORT",
    payload: data,
  }
};

export const deleteScheduleStaffLogsShift = (data) => {
  return {
    type: "DELETE_SCHEDULE_STAFF_LOGS_SHIFT",
    payload: data,
  }
};

export const approveShiftLogsTime = (data) => {
  return {
    type: "APPROVE_SHIFT_LOGS_TIME",
    payload: data,
  }
};

export const printPDFReport= (data) =>{
  return {
      type: 'PRINT_PDF_REPORT',
      payload: data
  }
}

export const updateLmpmCompanyIntegration = (data) => {
  return { 
    type: 'UPDATE_LMPM_COMPANY_INTEGRATION',
    payload: data
  };
}

export const exportClient= (data) =>{
  return {
      type: 'EXPORT_CLIENTS',
      payload: data
  }
}


export const updateSojoIntegration = (data) => {
  return { 
    type: 'UPDATE_SOJO_INTEGRATION',
    payload: data
  };
}

export const getAssignmentTrackingInfo = (model) => async (dispatch, getState) =>{
  dispatch({ 
    type: 'FETCH_ASSIGNMENT_TRACKING_INFO',
    payload:model
  });
}

export const updateAssignmentTrackingInfo = (data) => {
  return { 
    type: 'UPDATE_ASSIGNMENT_TRACKING_INFO',
    payload: data
  };
}

export const assignCollectionMethod = (data) => {
  return {
    type: 'ASSIGN_COLLECTION_METHOD',
    payload: data
  }
}
export const updateVacationRentProCompanyIntegration = (data) => {
  return { 
    type: 'UPDATE_VACATION_RENT_PRO_COMPANY_INTEGRATION',
    payload: data
  };
}
export const getQbClients =(data)=>{
  return { 
    type: 'GET_QB_CLIENTS',
    payload: data
  };
}


export const companyMappedToQbClients =(data)=>{
  return{
      type:'STORE_QUICKBOOK_CLIENTS',
      payload:data
  };
}



export const getAssignmentProgress = (data) => {
  return { 
    type: 'STAFF_ASSIGNMENT_PROGRESS',
    payload: data
  };
}

export const createDoNotBill = (data) => {
  return { 
    type: 'CREATE_DO_NOT_BILL', 
    payload: data
  }
}
export const hhsWeeklyInvoiceReport = (data) => {
  return { 
    type: 'HHS_WEEKLY_INVOICE_REPORT',
    payload: data
  };
}

export const updateDoNotBill = (data) => {
  return { 
    type: 'UPDATE_DO_NOT_BILL',
    payload: data,
  }
}
export const hhsEmployeePieceReport = (data) => {
  return { 
    type: 'HHS_EMPLOYEE_PIECE_REPORT',
    payload: data
  };
}


export const updateHkPerformanceCount = (data) => {
  return { 
    type: 'UPDATE_HK_PERFORAMANCE_COUNT',
    payload: data
  };
}


export const getCompanySubscription = (data) => {
  return { 
    type: 'GET_COMPANY_SUBSCRIPTION',
    payload: data
  };
}

export const updatePMServiceFeature = (data) => {
  return { 
    type: 'UPDATE_PM_SERVICE_FEATURE',
    payload: data
  };
}

export const uploadServiceReportToMerge = (data) => {
  return {
      type: 'UPLOAD_SERVICE_TRACK_REPORT_TO_MERGE',
      payload: data,
  }
}

export const updateCompanyHasAutopay =(data)=>{
  return{
      type:'UPDATE_COMPANY_HAS_AUTOPAY',
      payload:data
  };
}

export const getPropelrTempaltes =(data)=>{
  return{
      type:'DROPDOWN_PROPELR_TEMPLATE',
      payload:data
    }
  }
export const updateUplistingCompanyIntegration = (data) => {
  return { 
    type: 'UPDATE_UPLISTING_COMPANY_INTEGRATION',
    payload: data
  };
}

export const updateHostfullyCompanyIntegration = (data) => {
  return { 
    type: 'UPDATE_HOSTFULLY_COMPANY_INTEGRATION',
    payload: data
  };
}

export const getIntegrationPropertyList = (data) => {
  return {
    type: 'FETCH_INTEGRATION_PROPERTY_LIST',
    payload: data
  };
}

export const updateAutoshareSubtaskInBulk = (data) => {
  return { 
    type: 'UPDATE_AUTOSHARE_SUBTASK_IN_BULK',
    payload: data
  };
}

export const updateWizardStatus = (data) => {
  return {
      type: 'UPDATE_WIZARD_STATUS',
      payload: data
  };
}

export const updateLodgifyCompanyIntegration = (data) => {
  return { 
    type: 'UPDATE_LODGIFY_COMPANY_INTEGRATION',
    payload: data
  };
}
