import APIservice from "../apiService";
import { isLoader } from "../global/action";
import { microServices } from "../../config/global";
import { setError } from "../form/action";
import moment from "moment";

/**
 * ApiMiddleware can be used to call any User related API services
 * @param {*} store 
 * @returns 
 */
const InspectionMiddleware = (store) => (next) => async (action) => {
    const result    = next(action);
    let endpoint  = '';
    let per_page_condition = '';
    switch (action.type) {
        case "CREATE_TEMPLATE":
            endpoint  = microServices.inspection + action.endpoint;
            store.dispatch(isLoader(true));
            APIservice
            .postMultipartData(endpoint, action.payload.formData)
            .then((response)=>{
                if(response.data.template_id) {
                    store.dispatch({ 
                        type: 'ASSIGN_TEMPLATE_REGION_AREA', 
                        payload: { 
                            'assignData':action.payload.formData,
                            'templateId':response.data.template_id,
                        }
                    });
                    store.dispatch({ 
                        type: 'INITIALIZE_TEMPLATE_UPLOAD', 
                        payload: {
                            'templateId': response.data.template_id,
                            'assignData': action.payload.formData,
                            'alertName':action.payload.alertName
                        }
                    });
                }
            })
            .catch((error)=>{
                if (error.response.status !== 422) {
                store.dispatch({ 
                    type: 'SHOW_ALERT', 
                    payload: {
                    variant:'danger',
                    message: 'Unable to save staff information!'
                    }
                });
                } else {
                let err = {componentName:action.componentName,statusCode: error.response.status,data: error.response.data};
                store.dispatch(setError(err));
                }
            })
            .finally(()=>{
                store.dispatch(isLoader(false));
            })
        break;

        case "ASSIGN_TEMPLATE_REGION_AREA":
            const apiEndpoint  = microServices.inspection + '/v1/template/region-area/assign/' + action.payload.templateId;
            store.dispatch(isLoader(true));
            APIservice
            .post(apiEndpoint, action.payload.assignData)
            .then((response)=>{
                store.dispatch({ 
                type: 'SHOW_ALERT', 
                payload: {
                    variant:'success',
                    message: action.message,
                    componentName:action.componentName
                }
                });
            })
            .catch((error)=>{
                let err = {componentName:action.componentName,statusCode: error.response.status,data: error.response.data};
                store.dispatch(setError(err));
            }).finally(()=>{
                store.dispatch(isLoader(false));
            })
        break;

        case "INITIALIZE_TEMPLATE_UPLOAD":
            const uploadEndpoint = microServices.inspection + '/v1/template/upload/' + action.payload.templateId;
            store.dispatch(isLoader(true));
            APIservice
            .postMultipartData(uploadEndpoint, action.payload.assignData)
            .then((response)=>{
                store.dispatch({ 
                type: 'UPLOAD_TEMPLATE',
                payload: { 
                    'uploadUrl':response.data.data.upload,
                    'uploadFiledata':action.payload.assignData,
                    'templateId':action.payload.templateId,
                    'alertName':action.payload.alertName
                }
                });
            })
            .catch((error)=>{
                let err = {componentName:'Upload_Template',statusCode: error.response.status,data: error.response.data};
                store.dispatch(setError(err));
                store.dispatch(isLoader(false));
            });
        break;

        case "UPLOAD_TEMPLATE":
            const uploadTokenEndpoint = action.payload.uploadUrl;
            store.dispatch(isLoader(true));
            APIservice
            .postMultipartData(uploadTokenEndpoint,action.payload.uploadFiledata)
            .then((response)=>{
                if (response.status == 200) {
                    store.dispatch({ 
                        type: 'FETCH_TEMPLATE_DETAIL', 
                        payload: {
                            'template_id': action.payload.templateId,
                        }
                    });
                    store.dispatch({ 
                        type: 'VIEW_TEMPLATE_REGION_AREA_ACCESS', 
                        payload: {
                            'template_id': action.payload.templateId,
                        }
                    });
                    store.dispatch({ 
                        type: 'FETCH_TEMPLATE_CATEGORIES', 
                        payload: {
                            'template_id': action.payload.templateId,
                        }
                    });
                    
                        store.dispatch({ 
                            type: 'SHOW_ALERT', 
                            payload: {
                                componentName: (action.payload.alertName == "add") ?  'AddInspectionTemplate' : 'UploadInspectionTemplate',
                                variant:'success',
                                message: 'Inspection Template Added Successfully!'
                            }
                        });
                }
            })
            .catch((error)=>{
                let err = {componentName:action.componentName,statusCode: error.response.status,data: error.response.data};
                store.dispatch(setError(err));
            }).finally(()=>{
                store.dispatch(isLoader(false));
            })
        break;
        case "ACTIVE_INACTIVE_TEMPLATE":
            store.dispatch(isLoader(true));
            const activeInactiveEndpoint = microServices.inspection+`${action.endpoint}/${action.payload.apiName}/${action.payload.id}`;
            APIservice
            .put(activeInactiveEndpoint)
            .then((response)=>{
            store.dispatch({ 
                type: 'SHOW_ALERT', 
                payload: {
                variant:'success',
                message: `Template ${(action.payload.apiName === 'inactivate')?'deactivated':'activated'} successfully!`,
                componentName:action.componentName
                }
            });
            })
            .catch((error)=>{
            if (error.response.status !== 422) {
                store.dispatch({ 
                type: 'SHOW_ALERT', 
                payload: {
                    variant:'danger',
                    message: `Unable to ${action.payload.apiName} staff!`
                }
                });
            } else {
                let err = {componentName:action.componentName,statusCode: error.response.status,data: error.response.data};
                store.dispatch(setError(err));
                }
            })
            .finally(()=>{
            store.dispatch(isLoader(false));
            })
        break;
        case "INSPECTION_TEMPLATE_DROPDOWN_WITH_REGION_AREA":
            let inspEndpoint = microServices.inspection + '/v1/templates?per_page=0';
            if (action.payload.customFilterModel) {
                inspEndpoint += '&';
                let filterKeys = Object.keys(action.payload.customFilterModel);
                filterKeys.forEach((filter) => {
                    inspEndpoint += `filter[${filter}]=${action.payload.customFilterModel[filter]}&`;
                });
            }
            APIservice
            .get(inspEndpoint)
            .then((response)=>{
              store.dispatch({ 
                type: 'SET_INSPECTION_REGION_DATA', 
                payload: response.data.data,
              });
            })
            .catch((error)=>{
              if (error.response.status !== 422) {
                store.dispatch({ 
                  type: 'SET_INSPECTION_REGION_DATA', 
                  payload: [],
                });
              } 
            })
        break;

        case "FETCH_TEMPLATE_DETAIL":
            endpoint = microServices.inspection+'/v1/template';
            store.dispatch(isLoader(true));
            APIservice
            .get(endpoint+'/'+action.payload.template_id)
            .then((response)=>{
                if(response && response.data){
                    store.dispatch({ 
                        type: 'SET_TEMPLATE_DETAIL', 
                        payload: {
                            data:response.data.data
                        }
                    });
                }
            })
            .catch((error)=>{
                let err = {componentName:'EditTemplate',statusCode: error.response.status,data: error.response.data};
                store.dispatch(setError(err));
            })
            .finally(()=>{
                store.dispatch(isLoader(false));
            })
        break;

        case "FETCH_TEMPLATE_CATEGORIES" :
            per_page_condition = (action.payload.per_page || action.payload.per_page === 0) ? '?per_page='+action.payload.per_page : "";
            endpoint = microServices.inspection+'/v1/template';
            store.dispatch(isLoader(true));
            APIservice
            .get(endpoint+'/'+action.payload.template_id+'/categories'+per_page_condition)
            .then((response)=>{
                if(response && response.data){
                    store.dispatch({ 
                        type: 'SET_TEMPLATE_CATEGORIES', 
                        payload: {
                            data:response.data
                        }
                    });
                }
            })
            .catch((error)=>{
                let err = {componentName:'TemplateCategories',statusCode: error.response.status,data: error.response.data};
                store.dispatch(setError(err));
            })
            .finally(()=>{
                store.dispatch(isLoader(false));
            })
        break;

        case "VIEW_TEMPLATE_REGION_AREA_ACCESS":
            endpoint = microServices.inspection+'/v1/template';
            store.dispatch(isLoader(true));
            APIservice
            .get(endpoint+'/'+action.payload.template_id+'/regional-area')
            .then((response)=>{
                if(response && response.data){
                    store.dispatch({ 
                        type: 'SET_TEMPLATE_REGION_AREA_ACCESS', 
                        payload: {
                            data:response.data
                        }
                    });
                }
            })
            .catch((error)=>{
                let err = {componentName:"TemplateRegionArea",statusCode: error.response.status,data: error.response.data};
                store.dispatch(setError(err));
            })
            .finally(()=>{
                store.dispatch(isLoader(false));
            })
        break;

        case "UPDATE_TEMPLATE":
            endpoint = microServices.inspection+'/v1/template';
            store.dispatch(isLoader(true));
            APIservice
            .put(endpoint+'/'+action.payload.template_id, action.payload.formData)
            .then((response)=>{
                store.dispatch({ 
                    type: 'SHOW_ALERT', 
                    payload: {
                        variant:'success',
                        message: 'Template updated successfully!',
                        componentName:'EditTemplate'
                    }
                });
                store.dispatch({ 
                    type: 'SET_DATA_FOR_EDIT', 
                    payload: {
                        data:response.data.data,
                    }
                });
            })
            .catch((error)=>{
                if (error.response.status !== 422) {
                    store.dispatch({ 
                        type: 'SHOW_ALERT', 
                        payload: {
                            variant:'danger',
                            message: 'Unable to save template information!',
                        }
                    });
                } else {
                    let err = {componentName:'EditTemplate',statusCode: error.response.status,data: error.response.data};
                    store.dispatch(setError(err));
                }
            })
            .finally(()=>{
                store.dispatch(isLoader(false));
            })
        break;

        case "UPDATE_TEMPLATE_CATEGORY":
            endpoint = microServices.inspection+'/v1/template';
            store.dispatch(isLoader(true));
            APIservice
            .put(endpoint+'/'+action.payload.template_id+'/category/'+action.payload.template_category_id, 
            action.payload.formData)
            .then((response)=>{
                store.dispatch({ 
                    type: 'SHOW_ALERT', 
                    payload: {
                        variant:'success',
                        message: 'Category updated successfully!',
                        componentName:'EditTemplateCategory'
                    }
                });
            })
            .catch((error)=>{
                if (error.response.status !== 422) {
                    store.dispatch({ 
                        type: 'SHOW_ALERT', 
                        payload: {
                            variant:'danger',
                            message: 'Unable to save category information!',
                        }
                    });
                } else {
                    let err = {componentName:'EditTemplateCategory',statusCode: error.response.status,data: error.response.data};
                    store.dispatch(setError(err));
                }
            })
            .finally(()=>{
                store.dispatch(isLoader(false));
            })
        break;

        case "GET_TEMPLATE_CATEGORY" :
            endpoint = microServices.inspection+'/v1/template';
            store.dispatch(isLoader(true));
            APIservice
            .get(endpoint+'/'+action.payload.template_id+'/category/'+action.payload.category_id)
            .then((response)=>{
                if(response && response.data){
                    store.dispatch({ 
                        type: 'SET_TEMPLATE_CATEGORY', 
                        payload: {
                            data:response.data.data
                        }
                    });
                }
            })
            .catch((error)=>{
                let err = {componentName:"TemplateCategory",statusCode: error.response.status,data: error.response.data};
                store.dispatch(setError(err));
            })
            .finally(()=>{
                store.dispatch(isLoader(false));
            })
        break;

        case "ADD_TEMPLATE_CATEGORY":
            endpoint = microServices.inspection+'/v1/template';
            store.dispatch(isLoader(true));
            APIservice
            .post(endpoint+'/'+action.payload.template_id+'/category', 
            action.payload.formData)
            .then((response)=>{
                store.dispatch({ 
                    type: 'SHOW_ALERT', 
                    payload: {
                        variant:'success',
                        message: 'Category created successfully!',
                        componentName:'AddTemplateCategory'
                    }
                });
            })
            .catch((error)=>{
                if (error.response.status !== 422) {
                    store.dispatch({ 
                        type: 'SHOW_ALERT', 
                        payload: {
                            variant:'danger',
                            message: 'Unable to save category information!',
                        }
                    });
                } else {
                    let err = {componentName:'AddTemplateCategory',statusCode: error.response.status,data: error.response.data};
                    store.dispatch(setError(err));
                }
            })
            .finally(()=>{
                store.dispatch(isLoader(false));
            })
        break;
        case "FETCH_INSPECTION_REPORT":
            endpoint = microServices.base+'/v1/inspection';
            store.dispatch(isLoader(true));
            APIservice
            .get(endpoint+'/'+action.payload.id)
            .then((response)=>{
                if(response && response.data){
                    store.dispatch({
                        type: 'FETCH_TEMPLATE_CATEGORIES', 
                        payload: {
                            template_id:response.data.data[0].template_id
                        }      
                    })
                    store.dispatch({ 
                        type: 'SET_INSPECTION_DATA', 
                        payload: {
                            data:response.data.data[0]
                        }
                    });
                }
            })
            .catch((error)=>{
                let err = {componentName:'FetchInspectionReport',statusCode: error.response.status,data: error.response.data};
                store.dispatch(setError(err));
            })
            .finally(()=>{
                store.dispatch(isLoader(false));
            })
        break;
        case "FETCH_TEMPLATE_ITEMS" :
            per_page_condition = (action.payload.per_page || action.payload.per_page === 0)  ? '?per_page='+action.payload.per_page : "";
            endpoint = microServices.inspection+'/v1/template';
            store.dispatch(isLoader(true));
            APIservice
            .get(endpoint+'/category/'+action.payload.template_category_id+'/items'+per_page_condition)
            .then((response)=>{
                if(response && response.data){
                    store.dispatch({ 
                        type: 'SET_TEMPLATE_ITEMS', 
                        payload: {
                            data:response.data.data
                        }
                    });
                }
            })
            .catch((error)=>{
                let err = {componentName:'TemplateItems',statusCode: error.response.status,data: error.response.data};
                store.dispatch(setError(err));
            })
            .finally(()=>{
                store.dispatch(isLoader(false));
            })
        break;

        case "ADD_ITEM_NOTES" :
            endpoint = microServices.inspection+'/v1/template/notes';
            store.dispatch(isLoader(true));
            APIservice
            .post(endpoint,action.payload.formData)
            .then((response)=>{
                if(response && response.data){
                    store.dispatch({ 
                        type: 'SHOW_ALERT', 
                        payload: {
                          variant:'success',
                          message: 'Notes Added SuccessFully',
                          componentName:'AddInspectionItemNotes'
                        }
                      });
                }
            })
            .catch((error)=>{
                if (error.response.status !== 422) {
                  store.dispatch({
                    type: 'SHOW_ALERT', 
                    payload: {
                      variant:'danger',
                      message: 'Unable to Save Notes Information!'
                    }
                  });
                } else {
                  let err = {componentName:'AddInspectionItemNotes',statusCode: error.response.status,data: error.response.data};
                  store.dispatch(setError(err));
                }
              })
            .finally(()=>{
                store.dispatch(isLoader(false));
            })
        break;

        case 'DELETE_TEMPLATE_CATEGORY':
            endpoint = microServices.inspection+'/v1/template';
            store.dispatch(isLoader(true));
            APIservice
            .delete(endpoint+'/category/'+action.payload.template_category_id)
            .then((response)=>{
              store.dispatch({ 
                type: 'SHOW_ALERT', 
                payload: {
                  variant:'success',
                  message: 'Category deleted successfully.',
                  componentName:'DeleteTemplateCategory'
                }
              });
            })
            .catch((error)=>{
              if (error.response.status !== 422) {
                store.dispatch({
                  type: 'SHOW_ALERT', 
                  payload: {
                    variant:'danger',
                    message: 'Unable to delete Category!'
                  }
                });
              } else {
                let err = {componentName:'DeleteTemplateCategory',statusCode: error.response.status,data: error.response.data};
                store.dispatch(setError(err));
              }
            })
            .finally(()=>{
              store.dispatch(isLoader(false));
            })
        break;

        case "UPDATE_TEMPLATE_REGION_AREA":
            endpoint  = microServices.inspection+'/v1/template';
            store.dispatch(isLoader(true));
            APIservice
            .post(endpoint+'/region-area/update/'+action.payload.template_id, action.payload.formData)
            .then((response)=>{
                store.dispatch({ 
                    type: 'SHOW_ALERT', 
                    payload: {
                        variant:'success',
                        message: "Region Area updated successfully",
                        componentName:"UpdateTemplateRegionArea"
                    }
                });
            })
            .catch((error)=>{
                let err = {componentName:"UpdateTemplateRegionArea",statusCode: error.response.status,data: error.response.data};
                store.dispatch(setError(err));
            }).finally(()=>{
                store.dispatch(isLoader(false));
            })
        break;

        case "ADD_TEMPLATE_ITEM":
            endpoint = microServices.inspection+'/v1/template';
            store.dispatch(isLoader(true));
            APIservice
            .post(endpoint+'/'+action.payload.template_id+'/category/'+action.payload.template_category_id+'/item',
            action.payload.formData)
            .then((response)=>{
                store.dispatch({ 
                    type: 'SHOW_ALERT', 
                    payload: {
                        variant:'success',
                        message: 'Item created successfully!',
                        componentName:'AddTemplateItem'
                    }
                });
            })
            .catch((error)=>{
                if (error.response.status !== 422) {
                    store.dispatch({ 
                        type: 'SHOW_ALERT', 
                        payload: {
                            variant:'danger',
                            message: 'Unable to save item information!',
                        }
                    });
                } else {
                    let err = {componentName:'AddTemplateItem',statusCode: error.response.status,data: error.response.data};
                    store.dispatch(setError(err));
                }
            })
            .finally(()=>{
                store.dispatch(isLoader(false));
            })
        break;

        case 'UPDATE_TEMPLATE_ITEM':
            endpoint = microServices.inspection+'/v1/template';
            store.dispatch(isLoader(true));
            APIservice
            .put(endpoint+'/'+action.payload.template_id+'/category/'+action.payload.template_category_id+'/item/'+action.payload.template_item_id, 
            action.payload.formData)
            .then((response)=>{
                store.dispatch({ 
                    type: 'SHOW_ALERT', 
                    payload: {
                        variant:'success',
                        message: 'Item updated successfully!',
                        componentName:'EditTemplateItem'
                    }
                });
            })
            .catch((error)=>{
                if (error.response.status !== 422) {
                    store.dispatch({ 
                        type: 'SHOW_ALERT', 
                        payload: {
                            variant:'danger',
                            message: 'Unable to save item information!',
                        }
                    });
                } else {
                    let err = {componentName:'EditTemplateItem',statusCode: error.response.status,data: error.response.data};
                    store.dispatch(setError(err));
                }
            })
            .finally(()=>{
                store.dispatch(isLoader(false));
            })
        break;

        case "DELETE_TEMPLATE_ITEM":
            endpoint = microServices.inspection+'/v1/template';
            store.dispatch(isLoader(true));
            APIservice
            .delete(endpoint+'/item/'+action.payload.template_item_id)
            .then((response)=>{
              store.dispatch({ 
                type: 'SHOW_ALERT', 
                payload: {
                  variant:'success',
                  message: 'Item deleted successfully.',
                  componentName:'DeleteTemplateItem'
                }
              });
            })
            .catch((error)=>{
              if (error.response.status !== 422) {
                store.dispatch({
                  type: 'SHOW_ALERT', 
                  payload: {
                    variant:'danger',
                    message: 'Unable to delete Item!'
                  }
                });
              } else {
                let err = {componentName:'DeleteTemplateItem',statusCode: error.response.status,data: error.response.data};
                store.dispatch(setError(err));
              }
            })
            .finally(()=>{
              store.dispatch(isLoader(false));
            })
        break;
        
        case "INITIALIZE_ITEM_PHOTOS":
              endpoint = microServices.inspection+'/v1/template';
              store.dispatch(isLoader(true));
              APIservice
              .post(endpoint+'/item/image', action.payload)
                .then((response)=>{
                  store.dispatch({ 
                    type: 'UPLOAD_ITEM_PHOTOS',
                    payload: { 
                      'uploadUrl':response.data.data.upload,
                      'uploadImagedata':action.payload
                    }
                    });
                })
                .catch((error)=>{
                  let err = {componentName:'ItemImage',statusCode: error.response.status,data: error.response.data};
                  store.dispatch(setError(err));
                })
                .finally(()=>{
                  store.dispatch(isLoader(false));
                })
        break;

        case "UPLOAD_ITEM_PHOTOS":
            endpoint = action.payload.uploadUrl;
            store.dispatch(isLoader(true));
            APIservice
            .post(endpoint,action.payload.uploadImagedata)
            .then((response)=>{
                store.dispatch({ 
                    type: 'SHOW_ALERT', 
                    payload: {
                      variant:'success',
                      message: 'Photo uploaded successfully.',
                      componentName:'UploadItemPhotos'
                    } 
                });
            })
            .catch((error)=>{
                if (error.response.status !== 422) {
                store.dispatch({ 
                    type: 'SHOW_ALERT', 
                    payload: {
                    variant:'danger',
                    message: 'Unable to upload Item Image!'
                    }
                });
                } else {
                    let err = {componentName:action.componentName,statusCode: error.response.status,data: error.response.data};
                    store.dispatch(setError(err));
                }
            }).finally(()=>{
                store.dispatch(isLoader(false));
            })
        break;

        case "DELETE_ITEM_IMAGE" : 
       
          endpoint = microServices.inspection+'/v1/template';
          store.dispatch(isLoader(true));
          APIservice
          .delete(endpoint+'/item/image/'+action.payload.template_item_id)
          .then((response)=>{
            store.dispatch({ 
              type: 'SHOW_ALERT', 
              payload: {
                variant:'success',
                message: 'Photo deleted successfully.',
                componentName:'DeleteItemImage'
              }
            });
          })
          .catch((error)=>{
            if (error.response.status !== 422) {
              store.dispatch({ 
                type: 'SHOW_ALERT', 
                payload: {
                  variant:'danger',
                  message: 'Unable to delete item image!'
                }
              });
            } else {
              let err = {componentName:'DeleteItemImage',statusCode: error.response.status,data: error.response.data};
              store.dispatch(setError(err));
            }
          })
          .finally(()=>{
            store.dispatch(isLoader(false));
          })
        break;

        case "CLONE_TEMPLATE":
          endpoint  = microServices.inspection+action.endpoint;
          store.dispatch(isLoader(true));
          APIservice
            .post(endpoint+action.payload.id+'/clone',action.payload.formData)
            .then((response)=>{
              store.dispatch({ 
                type: 'SHOW_ALERT', 
                payload: { 
                  variant:'success',
                  message: action.message,
                  componentName:action.componentName
                }
                });
            })
            .catch((error)=>{
                if (error.response.status !== 422) {
                store.dispatch({ 
                    type: 'SHOW_ALERT', 
                    payload: {
                    variant:'danger',
                    message: 'Unable to clone Template!'
                    }
                });
                } else {
                let err = {componentName:action.componentName,statusCode: error.response.status,data: error.response.data};
                store.dispatch(setError(err));
                }
            }).finally(()=>{
                store.dispatch(isLoader(false));
            })
      break;


        case "UPDATE_TEMPLATE_CATEGORY_DISPLAY_ORDER":
            endpoint = microServices.inspection+'/v1/template';
            store.dispatch(isLoader(true));
            APIservice
            .post(endpoint+'/'+action.payload.template_id+'/category/display-order', 
            action.payload.formData)
            .then((response)=>{
                store.dispatch({ 
                    type: 'SHOW_ALERT', 
                    payload: {
                        variant:'success',
                        message: 'Category Display Order updated successfully!',
                        componentName:'UpdateTemplateCategoryDisplayOrder'
                    }
                });
            })
            .catch((error)=>{
                if (error.response.status !== 422) {
                    store.dispatch({ 
                        type: 'SHOW_ALERT', 
                        payload: {
                            variant:'danger',
                            message: 'Unable to save item display order information!',
                        }
                    });
                } else {
                    let err = {componentName:'UpdateTemplateCategoryDisplayOrder',statusCode: error.response.status,data: error.response.data};
                    store.dispatch(setError(err));
                }
            })
            .finally(()=>{
                store.dispatch(isLoader(false));
            })
        break;

        case "UPDATE_TEMPLATE_ITEM_DISPLAY_ORDER":
            endpoint = microServices.inspection+'/v1/template';
            store.dispatch(isLoader(true));
            APIservice
            .post(endpoint+'/'+action.payload.template_id+'/category/item/display-order', 
            action.payload.formData)
            .then((response)=>{
                store.dispatch({ 
                    type: 'SHOW_ALERT', 
                    payload: {
                        variant:'success',
                        message: 'Item Display Order updated successfully!',
                        componentName:'UpdateTemplateItemDisplayOrder'
                    }
                });
            })
            .catch((error)=>{
                if (error.response.status !== 422) {
                    store.dispatch({ 
                        type: 'SHOW_ALERT', 
                        payload: {
                            variant:'danger',
                            message: 'Unable to save item display order information!',
                        }
                    });
                } else {
                    let err = {componentName:'UpdateTemplateItemDisplayOrder',statusCode: error.response.status,data: error.response.data};
                    store.dispatch(setError(err));
                }
            })
            .finally(()=>{
                store.dispatch(isLoader(false));
            })
        break;
        case "FETCH_INSPECTION_LIST":
            store.dispatch(isLoader(true));
            APIservice.get(action.payload.apptUrl)
            .then((response) => {
                if (response && response.data) {
                    store.dispatch({
                        type: "SET_INSPECTION_LIST",
                        payload: {
                            data: response.data.data,
                            meta: response.data.meta,
                        },
                    });
                }
            })
            .catch((error) => {
                let err = {
                componentName: "InspectionList",
                statusCode: error.response.status,
                data: error.response.data,
                };
                store.dispatch(setError(err));
            })
            .finally(() => {
                store.dispatch(isLoader(false));
            });
            break;
            case "SCHEDULE_PROPERTY_INSPECTION":
            store.dispatch(isLoader(true));
            endpoint = microServices.base + "/v1/inspection";
            APIservice.post(endpoint+'/'+action.payload.type, action.payload.formData)
            .then((response) => {
                store.dispatch({
                    type: "SHOW_ALERT",
                    payload: {
                    variant: "success",
                    message: "Inspection scheduled successfully",
                    componentName: "ScheduleInspection",
                    },
                });
            })
            .catch((error) => {
                if (error.response.status !== 422) {
                    store.dispatch({
                        type: "SHOW_ALERT",
                        payload: {
                            variant: "danger",
                            message: "Unable to schedule appointment!",
                        },
                    });
                } else {
                    let err = {componentName: "ScheduleInspection",statusCode: error.response.status,data: error.response.data};
                    store.dispatch(setError(err));
                }
            })
            .finally(() => {
                store.dispatch(isLoader(false));
            });
        break;
        case "UPDATE_INSPECTION_REPORT":
            endpoint = microServices.base+'/v1/inspection';
            store.dispatch(isLoader(true));
            APIservice
            .post(endpoint+'/'+action.payload.id+'?_method=put', 
            action.payload.formData)
            .then((response)=>{
                store.dispatch({ 
                    type: 'SHOW_ALERT', 
                    payload: {
                        variant:'success',
                        message: 'Inspection Report updated successfully!',
                        statusMessage : action?.payload?.formData?.inspectionMessage,
                        notified : action.payload?.notifiedUser,
                        componentName:'UpdateInspectionReport'
                    }
                });
                store.dispatch({ type: "CALLBACK_INSP_COUNT" })
            })
            .catch((error)=>{
                if (error.response.status !== 422) {
                    store.dispatch({ 
                        type: 'SHOW_ALERT', 
                        payload: {
                            variant:'danger',
                            message: 'Unable to save Inspection Report information!',
                        }
                    });
                } else {
                    let err = {componentName:'UpdateInspectionReport',statusCode: error.response.status,data: error.response.data};
                    store.dispatch(setError(err));
                }
            })
            .finally(()=>{
                store.dispatch(isLoader(false));
            })
        break;
        case "UPDATE_ITEM_STATUS":
            endpoint = microServices.inspection+'/v1/template/item/status';
            store.dispatch(isLoader(true));
            APIservice
            .post(endpoint+'/'+action.payload.id+'?_method=put', 
            action.payload.formData)
            .then((response)=>{
                store.dispatch({ 
                    type: 'SHOW_ALERT', 
                    payload: {
                        variant:'success',
                        message: 'Item status updated successfully!',
                        componentName:'UpdateItemStatusPassFail'
                    }
                });
            })
            .catch((error)=>{
                if (error.response.status !== 422) {
                    store.dispatch({ 
                        type: 'SHOW_ALERT', 
                        payload: {
                            variant:'danger',
                            message: 'Unable to save Item Status information!',
                        }
                    });
                } else {
                    let err = {componentName:'UpdateItemStatusPassFail',statusCode: error.response.status,data: error.response.data};
                    store.dispatch(setError(err));
                }
            })
            .finally(()=>{
                store.dispatch(isLoader(false));
            })
        break;
         case "INITIALIZE_INSPECTION_ITEM_PHOTOS":
              endpoint = microServices.inspection+'/v1/template/photos';
              store.dispatch(isLoader(true));
              APIservice
              .post(endpoint, action.payload)
                .then((response)=>{
                  store.dispatch({ 
                    type: 'UPLOAD_NSPECTION_ITEM_PHOTOS',
                    payload: { 
                      'uploadUrl':response.data.data.upload,
                      'uploadImagedata':action.payload
                    }
                    });
                })
                .catch((error)=>{
                  let err = {componentName:'InspectionItemPhotos',statusCode: error.response.status,data: error.response.data};
                  store.dispatch(setError(err));
                })
                .finally(()=>{
                  store.dispatch(isLoader(false));
                })
        break;
        case "UPLOAD_NSPECTION_ITEM_PHOTOS":
          endpoint = action.payload.uploadUrl;
          store.dispatch(isLoader(true));
          APIservice
          .post(endpoint,action.payload.uploadImagedata)
          .then()
          .catch((error)=>{
            if (error.response.status !== 422) {
              store.dispatch({ 
                type: 'SHOW_ALERT', 
                payload: {
                  variant:'danger',
                  message: 'Unable to Add InspectionItem Photo!'
                }
              });
            } else {
             let err = {componentName:action.componentName,statusCode: error.response.status,data: error.response.data};
              store.dispatch(setError(err));
          }
          }).finally(()=>{
              store.dispatch(isLoader(false));
          })
          break;
          case "DELETE_INSPECTION_ITEM_PHOTO" : 
          endpoint = microServices.inspection+'/v1/template/photos/'+action.payload.id;
          store.dispatch(isLoader(true));
          APIservice
          .delete(endpoint)
          .then((response)=>{
            store.dispatch({ 
              type: 'SHOW_ALERT', 
              payload: {
                variant:'success',
                message: 'Photo deleted successfully.',
                componentName:'DeleteInspectionItemPhoto'
              }
            });
          })
          .catch((error)=>{
            if (error.response.status !== 422) {
              store.dispatch({ 
                type: 'SHOW_ALERT', 
                payload: {
                  variant:'danger',
                  message: 'Unable to delete Inspection Item Photo!'
                }
              });
            } else {
              let err = {componentName:'DeleteInspectionItemPhoto',statusCode: error.response.status,data: error.response.data};
              store.dispatch(setError(err));
            }
          })
          .finally(()=>{
            store.dispatch(isLoader(false));
          })
        break;
         
        case "UPDATE_INSPECTION_ATTRIBUTES":
        endpoint = microServices.base + "/v1/user/additional-inspection-attributes";
        store.dispatch(isLoader(true));
        APIservice.post(endpoint, action.payload.formData)
            .then((response) => {
            store.dispatch({ type: "GET_LOGGED_USER_DETAILS" });
            store.dispatch({
                type: "SHOW_ALERT",
                payload: {
                variant: "success",
                message: "Inspection Attributes updated successfully!",
                componentName: "EditAdmin",
                },
            });
            })
            .catch((error) => {
            let err = {
                componentName: "InspectionList",
                statusCode: error.response.status,
                data: error.response.data,
            };
            store.dispatch(setError(err));
            })
            .finally(() => {
            store.dispatch(isLoader(false));
            });
        break;

        case "DELETE_ITEM_NOTES" : 
        endpoint = microServices.inspection+'/v1/template/notes/'+action.payload.inspection_item_note_id;
        store.dispatch(isLoader(true));
        APIservice
        .delete(endpoint)
        .then((response)=>{
          store.dispatch({ 
            type: 'SHOW_ALERT', 
            payload: {
              variant:'success',
              message: 'Notes deleted successfully.',
              componentName:'DeleteItemNotes'
            }
          });
        })
        .catch((error)=>{
          if (error.response.status !== 422) {
            store.dispatch({ 
              type: 'SHOW_ALERT', 
              payload: {
                variant:'danger',
                message: 'Unable to delete Inspection Item Notes!'
              }
            });
          } else {
            let err = {componentName:'DeleteItemNotes',statusCode: error.response.status,data: error.response.data};
            store.dispatch(setError(err));
          }
        })
        .finally(()=>{
          store.dispatch(isLoader(false));
        })
      break;
      case "UPDATE_INSPECTION_STATUS":
          let baseApi = microServices.base ;
          if(action.payload.status == "reset"){
            baseApi = microServices.inspection;
          }
            endpoint = baseApi + "/v1/inspection/" + action.payload.inspid + "/" + action.payload.status;
            store.dispatch(isLoader(true));
            APIservice.put(endpoint)
                .then((response) => {
                    store.dispatch({
                        type: "SHOW_ALERT",
                        payload: {
                            variant: "success",
                            message: `${action.payload.inspectionMessage}`,
                            componentName: "UpdateInspectionStatus",
                        },
                    });
                    store.dispatch({ type: "CALLBACK_INSP_COUNT" })
                })
                .catch((error) => {
                    if (error.response.status !== 422) {
                        store.dispatch({
                            type: "SHOW_ALERT",
                            payload: {
                                variant: "danger",
                                message: "Unable to update Inspection status!",
                            },
                        });
                    } else {
                        let err = {
                            componentName: "UpdateInspectionStatus",
                            statusCode: error.response.status,
                            data: error.response.data,
                        };
                        store.dispatch(setError(err));
                    }
                })
                .finally(() => {
                    store.dispatch(isLoader(false));
                });
      break;

      case "UPDATE_INSPECTION_REPORT_POINTS":
            endpoint = microServices.base+'/v1/inspection';
            store.dispatch(isLoader(true));
            APIservice
            .post(endpoint+'/'+action.payload.id+'?_method=put', 
            action.payload.formData)
            .then((response)=>{
                store.dispatch({ 
                    type: 'SHOW_ALERT', 
                    payload: {
                        variant:'success',
                        componentName:'UpdateInspectionReportPoints'
                    }
                });
            })
            .catch((error)=>{
                if (error.response.status !== 422) {
                    store.dispatch({ 
                        type: 'SHOW_ALERT', 
                        payload: {
                            variant:'danger',
                            message: 'Unable to save Inspection Report information!',
                        }
                    });
                } else {
                    let err = {componentName:'UpdateInspectionReport',statusCode: error.response.status,data: error.response.data};
                    store.dispatch(setError(err));
                }
            })
            .finally(()=>{
                store.dispatch(isLoader(false));
            })
        break;

        case "MASS_COMPLETE_INSPECTION":
            endpoint = microServices.base + "/v1/inspection/complete/all";
            store.dispatch(isLoader(true));
            APIservice.put(endpoint, action.payload.inspectionIds)
                .then((response) => {
                    store.dispatch({
                        type: "SHOW_ALERT",
                        payload: {
                            variant: "success",
                            message: `${action.payload.alertMessage}`,
                            componentName: "UpdateInspectionStatus",
                        },
                    });
                    store.dispatch({ type: "CALLBACK_INSP_COUNT" })
                })
                .catch((error) => {
                    if (error.response.status !== 422) {
                        store.dispatch({
                            type: "SHOW_ALERT",
                            payload: {
                                variant: "danger",
                                message: "Unable to update Inspection status!",
                            },
                        });
                    } else {
                        let err = {
                            componentName: "UpdateInspectionStatus",
                            statusCode: error.response.status,
                            data: error.response.data,
                        };
                        store.dispatch(setError(err));
                    }
                })
                .finally(() => {
                    store.dispatch(isLoader(false));
                });
            break;

        case "ASSIGN_INSPECTOR_TO_INSPECTION":
            endpoint = microServices.base + "/v1/inspection/assign-inspector";
            store.dispatch(isLoader(true));
            APIservice.post(endpoint, {data: action.payload.postData})
                .then((response) => {
                    store.dispatch({
                        type: "SHOW_ALERT",
                        payload: {
                            variant: "success",
                            message: `${action.payload.alertMessage}`,
                            componentName: "AssignInspectorInspection",
                        },
                    });
                })
                .catch((error) => {
                    if (error.response.status !== 422) {
                        store.dispatch({
                            type: "SHOW_ALERT",
                            payload: {
                                variant: "danger",
                                message: "Unable to assign Inspector!",
                            },
                        });
                    } else {
                        let err = {
                            componentName: "AssignInspectorInspection",
                            statusCode: error.response.status,
                            data: error.response.data,
                        };
                        store.dispatch(setError(err));
                    }
                })
                .finally(() => {
                    store.dispatch(isLoader(false));
                });
            break;

            case "ASSIGN_CALLBACK_INSPECTION":
            endpoint = microServices.base + "/v1/inspection/callback/";
            store.dispatch(isLoader(true));
            APIservice.post(endpoint+action.payload.inspection_id,  action.payload.formData)
                .then((response) => {
                    store.dispatch({
                        type: "SHOW_ALERT",
                        payload: {
                            variant: "success",
                            message: `${action.payload.message}`,
                            componentName: "AssignCallback",
                        },
                    });
                    store.dispatch({ type: "CALLBACK_INSP_COUNT" })
                })
                .catch((error) => {
                    if (error.response.status !== 422) {
                        store.dispatch({
                            type: "SHOW_ALERT",
                            payload: {
                                variant: "danger",
                                message: "Unable to assign callback!",
                            },
                        });
                    } else {
                        let err = {
                            componentName: "AssignCallback",
                            statusCode: error.response.status,
                            data: error.response.data,
                        };
                        store.dispatch(setError(err));
                    }
                })
                .finally(() => {
                    store.dispatch(isLoader(false));
                });
            break;
            case "FETCH_FAILED_ITEMS_CATEGORY_WISE" :
            endpoint = microServices.inspection+'/v1/template';
            store.dispatch(isLoader(true));
            APIservice
            .get(endpoint+'/'+action.payload.template_id+'/failed-items'+per_page_condition)
            .then((response)=>{
                if(response && response.data){
                    store.dispatch({ 
                        type: 'SET_FAILED_ITEMS_CATEGORY_WISE', 
                        payload: {
                            data:response.data
                        }
                    });
                }
            })
            .catch((error)=>{
                let err = {componentName:'FailedItemsCategoryWise',statusCode: error.response.status,data: error.response.data};
                store.dispatch(setError(err));
            })
            .finally(()=>{
                store.dispatch(isLoader(false));
            })
        break;

            case "SEND_EMAIL_INSPECTION_REPORT":
            endpoint = microServices.inspection+'/v1/inspection/'+action.payload.template_id+'/report/email';
            store.dispatch(isLoader(true));
            APIservice
            .post(endpoint,action.payload.formData)
            .then((response) => {
                store.dispatch({
                    type: "SHOW_ALERT",
                    payload: {
                        variant: "success",
                        message: `${action.payload.sucessMessage}`,
                        componentName: "sendEmailInspection",
                    },
                });
            })
            .catch((error) => {
                if (error.response.status !== 422) {
                    store.dispatch({
                        type: "SHOW_ALERT",
                        payload: {
                            variant: "danger",
                            message: `${action.payload.failMessage}`,
                        },
                    });
                } else {
                    let err = {
                        componentName: "sendEmailInspection",
                        statusCode: error.response.status,
                        data: error.response.data,
                    };
                    store.dispatch(setError(err));
                }
            })
            .finally(() => {
                store.dispatch(isLoader(false));
            });
        break;
        case "CALLBACK_INSP_COUNT":
            let fromDate = moment().subtract(3, 'months').format('YYYY/MM/DD');
            let toDate = moment().format('YYYY/MM/DD');
            endpoint = `${microServices.base}/v1/inspection?filter[inspBetween]=${fromDate}-${toDate}&filter[status]=1,4&filter[callback]=1`;
            APIservice
            .get(endpoint)
            .then((response)=>{
                if(response && response.data){
                    let totalCount = response.data.meta.total;
                    store.dispatch({ 
                        type: 'SET_CALLBACK_INSP_COUNT', 
                        payload: {
                            data:totalCount
                        }
                    });
                }
            })
            .catch((error)=>{})
            .finally(()=>{})
        break;
        case "MASS_CANCEL_INSPECTION":
            endpoint = microServices.base + "/v1/inspection/cancel/all";
            store.dispatch(isLoader(true));
            APIservice.put(endpoint, action.payload.inspectionIds)
                .then((response) => {
                    store.dispatch({
                        type: "SHOW_ALERT",
                        payload: {
                            variant: "success",
                            message: `${action.payload.alertMessage}`,
                            componentName: "UpdateInspectionStatus",
                        },
                    });
                    store.dispatch({ type: "CALLBACK_INSP_COUNT" })
                })
                .catch((error) => {
                    if (error.response.status !== 422) {
                        store.dispatch({
                            type: "SHOW_ALERT",
                            payload: {
                                variant: "danger",
                                message: "Unable to update Inspection status!",
                            },
                        });
                    } else {
                        let err = {
                            componentName: "UpdateInspectionStatus",
                            statusCode: error.response.status,
                            data: error.response.data,
                        };
                        store.dispatch(setError(err));
                    }
                })
                .finally(() => {
                    store.dispatch(isLoader(false));
                });
            break;
            case "ENABLE_INSPECTION":
            endpoint = microServices.base + "/v1/inspection/schedule/";
            store.dispatch(isLoader(true));
            APIservice.put(endpoint+action.payload.inspectionId)
                .then((response) => {
                    store.dispatch({
                        type: "SHOW_ALERT",
                        payload: {
                            variant: "success",
                            message: `${action.payload.alertMessage}`,
                            componentName: "EnableInspection",
                        },
                    });
                   
                })
                .catch((error) => {
                    if (error.response.status !== 422) {
                        store.dispatch({
                            type: "SHOW_ALERT",
                            payload: {
                                variant: "danger",
                                message: error.response?.data?.data,
                                componentName: "EnableInspection",
                            },
                        });
                    } else {
                        let err = {
                            componentName: "EnableInspection",
                            statusCode: error.response.status,
                            data: error.response.data,
                            message:error.response.data
                        };
                        store.dispatch(setError(err));
                    }
                })
                .finally(() => {
                    store.dispatch(isLoader(false));
                });
            break;
            case "EXPORT_INSPECTION_LIST":
                endpoint = microServices.base + '/v1/'+action.payload.selectedLanguage+'/';
                APIservice.
                getBlobDataWithParams(endpoint+action.payload.url)
                .then((response)=>{
                    const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = blobUrl;
                        link.setAttribute('download', `${(action.payload?.fileName) ? action.payload.fileName : "View Inspection Report"}.xlsx`);
                        document.body.appendChild(link);
                        link.click(); 
                        link.parentNode.removeChild(link);
                })
                .catch((error)=>{
                    if (error.response.status !== 422) {
                        store.dispatch({ 
                            type: 'SHOW_ALERT', 
                            payload: {
                                variant:'danger',
                                message: 'Unable to Download Excel Report!',
                            }
                        });
                    } else {
                        let err = {componentName:'ExportInspectionReport',statusCode: error.response.status,data: error.response.data};
                        store.dispatch(setError(err));
                    }
                })
                .finally(()=>{
                });
            break;
            case "FETCH_HK_FAILED_ITEMS":
                endpoint = microServices.inspection + '/v1/template/hk-insp-failed-item/'+action.payload.user_id;
                store.dispatch(isLoader(true));
                APIservice
                  .get(endpoint)
                  .then((response) => {
                    if (response && response.data) {
                      store.dispatch({
                        type: 'SET_HK_FAILED_ITEMS',
                        payload: {
                          data: response.data
                        }
                      });
                    }
                  })
                  .catch((error) => {
                    let err = { componentName: 'Hk_failed_items', statusCode: error.response.status, data: error.response.data };
                    store.dispatch(setError(err));
                  })
                  .finally(() => {
                    store.dispatch(isLoader(false));
                  })
                break;


            case "REMOVED_HK_FAILED_ITEMS":
                endpoint = microServices.inspection + '/v1/template/remove-insp-failed-item';
                store.dispatch(isLoader(true));
                APIservice
                  .post(endpoint, action.payload)
                  .then((response) => {
                    if (response && response.data) {
                      store.dispatch({
                        type: 'SET_REMOVED_FAILED_ITEMS',
                        payload: {
                          data: response.data
                        }
                      });
                    }
                  })
                  .catch((error) => {
                    let err = { componentName: 'remove_Hk_failed_items', statusCode: error.response.status, data: error.response.data };
                    store.dispatch(setError(err));
                  })
                  .finally(() => {
                    store.dispatch(isLoader(false));
                  })
                break;

        default:
        break;
    }
    return result;
};

export default InspectionMiddleware;
